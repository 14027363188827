<template>
  <!-- <div class="left"> -->

  <!-- <teachercenter :userInfo="userinfo"></teachercenter> -->
  <!-- </div> -->
  <div class="course right">
    <div class="course-search">
      <div class="search">
        <img src="../../../assets/img/搜索.png" alt="">
        <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_course">
      </div>
      <button @click="search_course">搜索</button>
    </div>
    <div class="course-content">
      <div class="content-header">
        <p>全部课程({{ couselist.total_count }})</p>
        <p>已审核({{ couselist.exam_count }})</p>
        <p>未审核({{ couselist.no_exam_count }})</p>
      </div>
      <div class="content-body">
        <!-- <div class="body-header">
          <p>序号</p>
          <p>课程标题</p>
          <p>状态</p>
          <p>订购数</p>
          <p>置顶</p>  
          <p>操作</p>
            <p>查看</p>
        </div> -->
        <div class="body-content">
          <ul>
            <div class="body-header">
              <div class="xuhao">序号</div>
              <div class="biaoti">课程标题</div>
              <div class="zhuangtai">状态</div>
              <div class="zhuangtai">订购数</div>
              <div class="shuliang">置顶</div>
              <div class="caozuo"><span>操作</span></div>
              <div class="see"><span>查看</span></div>

            </div>
            <li v-for="(item, index) in couselist.list" :key="index">
              <div class="xuhao" @click="go_cousre_detail(item.id)">{{ index + 1 }}</div>
              <div class="biaoti" @click="go_cousre_detail(item.id)" v-html="item.title"></div>
              <div class="zhuangtai" v-if="item.is_sale == 1">已审核</div>
              <div class="zhuangtai" v-if="item.is_sale == 0">待审核</div>
              <div class="zhuangtai" v-if="item.is_sale == -1">未通过</div>
              <div class="shuliang">{{ item.buy_num }}</div>
              <div class="liulan">{{ item.set_top == 1 ? '已置顶' : '未置顶' }}</div>
              <div class="caozuo">
                <div class="moniti" @click="show_upload(index)">

                  <div class="shangchuan">上传</div>
                </div>
                <div class="zhiding-div">
                  <div class="edit" @click="edit_course(item.id)">编辑</div>
                  <div class="delete" @click="delete_course(index)">删除</div>
                </div>
                <div class="zhiding-div">
                  <button class="xin" @click="shuaxin_click(index)">刷新</button>
                  <button class="zhiding-btn" @click="zhiding_click(index)">置顶</button>
                </div>
              </div>
              <div class="see">
                <div @click="go_medio(item.id)">视频</div>
                <div @click="go_homework(item.id)">练习</div>
                <div @click="go_mocktes(item.id)">模拟题</div>
                <div @click="go_imitate(item.id)">真题</div>




              </div>

            </li>
          </ul>



        </div>

      </div>
      <div class="page">

        <el-pagination @current-change="course_change" :current-page="couselist.page" :page-sizes="[1]"
          :page-size="couselist.pagesize" layout="prev, pager, next, jumper" :total="couselist.total">
        </el-pagination>
      </div>
    </div>



    <div class="tanchuang" v-if="showModal">
      <div class="cha" v-if="showModal" @click="showModal = false"></div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="上传视频" name="first">
          <ul class="medio-list">

            <li class="video" v-for="(item, index) in  medio_list" :key="index">
              <video ref="veo" :src="ossurl + item.url"></video>
              <input type="text" placeholder="视频名称" class="video-input" v-model="medio_list[index].name">
              <div class="jinbi">
                <input type="number" class="input-jinbi" v-model="medio_list[index].price">
                <div>U币</div>
              </div>
              <div class="delect" @click="delete_file(item.url, index)">删除</div>
            </li>
          </ul>




          <div class="upload-file">
            <el-upload class="upload-demo avatar-uploader-icon" :action="upload_url" :on-preview="MedioPreview"
              :on-remove="MeidoRemove" :show-file-list="false" :data="{ filename: 'teachervideo' }" name="file"
              accept=".mp4" :http-request="upload_medio">

              <i class="el-icon-plus avatar-uploader-icon upload-medio-icon"></i>
            </el-upload>
          </div>
          <div class="tijiao" @click="submit_form" v-throttle>提交</div>
        </el-tab-pane>
        <el-tab-pane label="上传作业" name="second">
          <ul class="medio-list">


            <li class="zuoye" v-for="(item, index) in  qar_list" :key="index">
              <img src="../../../assets/img/icon/word.png" alt="" class="bangong">
              <input type="text" placeholder="作业名称" class="video-input" v-model="qar_list[index].name">
              <div class="jinbi">
                <input type="number" class="input-jinbi" v-model="qar_list[index].price">
                <div>U币</div>
              </div>
              <div class="delect" @click="delete_qar_file(item.url, index)">删除</div>
            </li>
          </ul>
          <div class="upload-file">
            <el-upload class="upload-demo avatar-uploader-icon" :action="upload_url" :on-preview="MedioPreview"
              :on-remove="MeidoRemove" :show-file-list="false" :data="{ filename: 'teacherpar' }" name="file"
              accept=".docx" :http-request="uplaod_par">

              <i class="el-icon-plus avatar-uploader-icon upload-medio-icon"></i>
            </el-upload>
          </div>
          <div class="tijiao" @click="submit_form" v-throttle>提交</div>
        </el-tab-pane>
        <el-tab-pane label="上传模拟题" name="third">
          <ul class="medio-list">

            <li class="zuoye" v-for="(item, index) in  qustion_list" :key="index">
              <img src="../../../assets/img/icon/word.png" alt="" class="bangong">
              <input type="text" placeholder="模拟题名称" class="video-input" v-model="qustion_list[index].name">
              <div class="jinbi">
                <input type="number" class="input-jinbi" v-model="qustion_list[index].price">
                <div>U币</div>
              </div>
              <div class="delect" @click="delete_question_file(item.url, index)">删除</div>
            </li>
          </ul>
          <div class="upload-file">
            <el-upload class="upload-demo avatar-uploader-icon" :action="upload_url" accept=".docx"
              :on-preview="MedioPreview" :on-remove="MeidoRemove" :show-file-list="false"
              :data="{ filename: 'teacherquestion' }" name="file" :http-request="uplaod_question">

              <i class="el-icon-plus avatar-uploader-icon upload-medio-icon"></i>
            </el-upload>
          </div>
          <div class="tijiao" @click="submit_form" v-throttle>提交</div>
        </el-tab-pane>
        <el-tab-pane label="上传历年真题" name="four">
          <ul class="medio-list">

            <li class="zuoye" v-for="(item, index) in  imitate_list" :key="index">
              <img src="../../../assets/img/icon/word.png" alt="" class="bangong">
              <input type="text" placeholder="真题名称" class="video-input" v-model="imitate_list[index].name">
              <div class="jinbi">
                <input type="number" class="input-jinbi" v-model="imitate_list[index].price">
                <div>金币</div>
              </div>
              <div class="delect" @click="delete_imitate_file(item.url, index)">删除</div>
            </li>
          </ul>
          <div class="upload-file">
            <el-upload class="upload-demo avatar-uploader-icon" :action="upload_url" :on-preview="MedioPreview"
              :on-remove="MeidoRemove" :show-file-list="false" accept=".docx" :data="{ filename: 'imitate' }" name="file"
              :http-request="uplaod_imitate">

              <i class="el-icon-plus avatar-uploader-icon upload-medio-icon"></i>
            </el-upload>
          </div>
          <div class="tijiao" @click="submit_form" v-throttle>提交</div>
        </el-tab-pane>
      </el-tabs>

    </div>


    <div class="shuaxin" v-if="shuaxin">
      <div class="cha" v-if="shuaxin" @click="shuaxin = false"></div>
      <div class="shuaxin-right">立即刷新</div>
      <div class="shuaxin-title">
        <div class="shuaxin-con">课程发布时间10天内享有免费刷新</div>
        <div class="shuaxin-btn" @click="renovate_course_free">立即刷新</div>
      </div>
      <div class="shuaxin-fufei">付费刷新</div>
      <div class="shuaxin-fufei-con">
        <div class="shuaxin-con">本次刷新将扣取：{{ revonateinfo.price }} U币</div>
        <div class="shuaxin-con-one">（可用U币{{ userinfo.account }}个，<p class="chongzhi"
            @click="$router.replace('/teacher/recharge')">充值U币</p>）
        </div>
      </div>
      <div class="zhifushuaxin" @click="pay_renovate_course">支付刷新</div>
    </div>

    <div class="zhiding" v-if="zhiding">
      <div class="cha" v-if="zhiding" @click="zhiding = false"></div>
      <div class="zhiding-title">立即置顶</div>
      <div class="category-scope">

        <div :class="['cate-item', { current: selectedItem === index }]" v-for="(category, index) in price_list"
          :key="index" @click="selectCategory(index)">
          {{ category.day_num }}天<br>{{ category.price }}U币
        </div>
      </div>
      <div class="keyong">（可用U币100个，<p @click="$router.replace('/teacher/recharge')">充值U币</p>）</div>
      <div class="zhiding-zhifu" @click="openpay">支付并置顶</div>
    </div>
  </div>
</template>

<script>

import teachercenter from './../../../components/common/teachercenter'
import BASEURL from "./../../../request/baseurl"
import OSSURL from "@/request/oss"
import { del_file, replase_couser, teacher_couse_list, batch_insert_course_file, topCourse, revonatePrice, freeRevonateCourse, payRevonateCourse } from "@/request/teacher.js"
import { getCoursePrice } from "@/request/pay"
import { del_course } from "@/request/course.js"
import { get_user_info } from "@/request/login"

import store from "./../../../store/index"
import axios from 'axios';
import { Loading } from 'element-ui';
import QRCode from "qrcode";
export default {
  name: 'teacherCourse',
  data() {
    return {
      price_list: [],
      selectedItem: 0,
      userinfo: {},
      baseurl: BASEURL,
      ossurl:OSSURL,
      is_default_index: '',
      showModal: false,
      shuaxin: false,
      zhiding: false,
      activeName: 'first',
      couselist: {
        total_count: 0,
        total: 0,
        exam_count: 0,
        no_exam_count: 0,
        pagesize: 5,
        current: 1,
        list: []

      },
    
      upload_url: BASEURL + '/api/upload/generaupload',
      medio_list: [],
      qustion_list: [],
      qar_list: [],
      imitate_list: [],
      course_active_index: -1,
      keyword: '',
      revonateinfo: {}

    }
  }, components: {
    teachercenter
  }, created() {
    let is_default_index = store.state.left_menu_default;
    this.is_default_index = is_default_index;
    var userinfo = localStorage.getItem('Userinfo');
    userinfo = JSON.parse(userinfo);

    if (userinfo) {


      if (userinfo.expiretime * 1000 < new Date().getTime()) {

        this.$router.push('/login');
        localStorage.clear("Userinfo");
        localStorage.clear('Authorization');

      } else {


        this.userinfo = userinfo;
      }

    } else {
      this.$router.push('/login');
    }


    this.getmycourseList(this.couselist.current, this.couselist.pagesize, '', userinfo.id);
    this.get_set_price();
    this.revonate_price();

  },
  methods: {
    go_mocktes(id) {

      this.$router.push('/teacher/Mocktes?course_id=' + id);



    },
    go_medio(id) {

      this.$router.push('/teacher/medio?course_id=' + id);
    }, go_homework(id) {

      this.$router.push('/teacher/homework?course_id=' + id);
    }, go_imitate(id) {
      this.$router.push('/teacher/imitate?course_id=' + id);

    },


    async get_set_price(id) {
      let res = await getCoursePrice();
      if (res.code == 200) {

        this.price_list = res.data
      }


    },
    //刷新价格
    async revonate_price() {
      let res = await revonatePrice();
      if (res.code == 200) {
        this.revonateinfo = res.data
      }

    },
    //免费刷新
    async renovate_course_free() {

      let course_active_index = this.course_active_index;
      let cousre_info = this.couselist.list[course_active_index];
      let res = await freeRevonateCourse({ course_id: cousre_info.id });
      if (res.code === 200) {
        this.$message.success("刷新成功");
      } else {
        this.$message.error(res.msg);
      }

    },
    //支付刷新
    async pay_renovate_course() {

      let revonateinfo = this.revonateinfo;
      let msg = "确定并支付" + revonateinfo.price + "U币刷新?"
      let res = await this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });

      if (res == 'confirm') {

        let course_active_index = this.course_active_index;
        let cousre_info = this.couselist.list[course_active_index];
        let result = await payRevonateCourse({ course_id: cousre_info.id });
        if (result.code === 200) {
          this.$message.success("课程刷新成功")
        } else {
          this.$message.error("刷新失败");
        }



      }



    }


    , zhiding_click(index) {
      this.zhiding = true;
      this.course_active_index = index;


    }, shuaxin_click(index) {
      this.shuaxin = true;
      this.course_active_index = index
    },
    //置顶
    async openpay() {

      let userinfo = this.userinfo;
      let selectedItem = this.selectedItem;
      let price_list = this.price_list;
      let price_info = price_list[selectedItem];
      let msg = "确定并支付" + price_info.price + "U币?"
      let res = await this.$confirm(msg, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });


      if (res == 'confirm') {
        //价格列表

        let userinfo = this.userinfo;
        let course_active_index = this.course_active_index;
        let course_info = this.couselist.list[course_active_index];
        let result = await topCourse({ course_id: course_info.id, price_id: price_info.id, user_id: userinfo.id, price: price_info.price });
        if (result.code === 200) {

          //更新用户信息
          get_user_info({ user_id: userinfo.id }).then(res => {
            if (res.code == 200) {
              userinfo.account = res.data.account;
              console.log(userinfo);
              this.userinfo = userinfo;
              var userinfo_json = JSON.stringify(userinfo);
              store.commit('setUserinfo', { userinfo: userinfo_json })
            }

            let couselist = this.couselist;
            couselist.list[course_active_index].set_top = 1;
            this.couselist = couselist;
            this.$message.success('置顶成功');

          })

        } else {
          this.$message.error(result.msg)
        }


      }






    },
    selectCategory(item) {
      this.selectedItem = item

    },
    handleClick(tab, event) {

    },
    show_upload(index) {
      this.showModal = true;
      this.course_active_index = index;

    },
    //获取我的课程列表
    async getmycourseList(current, pagesize, searchkerarod, user_id) {
      let _this = this;
      var user_id = _this.userinfo.id;


      let response = await teacher_couse_list({ current: current, pagesize: pagesize, keyword: searchkerarod, user_id: user_id });
      if (response.code === 200) {
        let couselist = this.couselist;
        couselist.total = Number(response.data.total);
        couselist.page = Number(response.data.current);
        couselist.total_page = Number(response.data.pages);
        couselist.exam_count = Number(response.data.exam_count);
        couselist.no_exam_count = Number(response.data.no_exam_count),
          couselist.list = response.data.list;


        this.couselist = couselist;
      } else {

        if (response.code === 1) {

          localStorage.clear("Userinfo");

          localStorage.clear('Authorization');
          this.$router.push('/login');
        }
      }
    }, //搜索课程
    search_course() {
      let that = this;
      this.getmycourseList(that.couselist.current, that.couselist.pagesize, that.keyword, that.userinfo.id);

    },   //分页
    async course_change(pager) {
      let keyword = this.keyword;
      let that = this;


      let res = await teacher_couse_list({ current: pager, pagesize: that.couselist.pagesize, keyword: keyword, user_id: that.userinfo.id });


      if (res.code == 200) {

        let couselist = this.couselist;
        couselist.total = Number(res.data.total);
        couselist.page = Number(res.data.current);
        couselist.total_page = Number(res.data.pages);
        couselist.list = res.data.list;

        this.couselist = couselist;
        // this.$emit('change', pager)
      }


    },




    async upload_medio(e) {

      let loadingInstance = Loading.service({ text: "视频正在上传，请耐心等待" });
      let that = this;

      let file = e.file;

      let filesize = file.size;
      let filename = file.name;
      const isLtM = file.size / 1024 / 1024 < 100;
      if (!isLtM) {
        this.$message.error("请上传100M以下的视频文件");
        return false;
      }

      const formData = new FormData();  // 声明一个FormData对象
      formData.append("file", file);
      formData.append("filename", 'teachervideo ')

      let result = await axios.post(e.action, formData, {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data"
        },
        timeout:300000 
      })

      if (result.data.code == 1) {
        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
        this.$message({
          message: '上传视频成功',
          type: 'success'
        });
        //构造数组，追加到数组视频列表中去
        let medio_info = { 'url': result.data.data.url, 'filesize': file.size / 1024 / 1024, name: file.name, price: 0 };

        let medio_list = that.medio_list;
        medio_list.push(medio_info);
        that.medio_list = medio_list;


      } else {
        this.$message.error("上传失败，请重新上传");
      }




    },   //上传模拟试题
    async uplaod_question(e) {

      let that = this;

      let file = e.file;
      var ext_index = file.name.lastIndexOf(".");
      var ext = file.name.substring(ext_index + 1);
      if (ext != 'docx') {
        this.$message.error("仅支持docx word 文件");
        return false;
      }

      let filesize = file.size;
      let filename = file.name;
      const isLtM = file.size / 1024 / 1024 < 10;
      if (!isLtM) {
        this.$message.error("请上传10M以下的试题文件");
        return false;
      }

      const formData = new FormData();  // 声明一个FormData对象
      formData.append("file", file);
      formData.append("filename", 'teacherquestion ')

      let result = await axios.post(e.action, formData, {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })



      if (result.data.code == 1) {
        this.$message({
          message: '上传试题成功',
          type: 'success'
        });
        //构造数组，追加到数组视频列表中去
        var ext_index = file.name.lastIndexOf(".");
        //获取后缀
        var ext = file.name.substring(ext_index + 1);
        var file_type = 3;
        if (ext == 'ppt') {
          file_type = 1;
        } else if (ext == 'xls') {
          file_type = 2;
        } else {
          file_type = 3;
        }

        let question_info = { 'url': result.data.data.url, 'filesize': file.size / 1024 / 1024, 'name': file.name, 'file_type': file_type, price: 0 };

        let qustion_list = that.qustion_list;
        qustion_list.push(question_info);
        that.qustion_list = qustion_list;


      } else {
        this.$message.error(result.data.msg);
      }






    }, //上传作业
    async uplaod_par(e) {

      let that = this;

      let file = e.file;

      var ext_index = file.name.lastIndexOf(".");
      var ext = file.name.substring(ext_index + 1);
      if (ext != 'docx') {
        this.$message.error("仅支持docx word 文件");
        return false;
      }
      let filesize = file.size;
      let filename = file.name;
      const isLtM = file.size / 1024 / 1024 < 10;
      if (!isLtM) {
        this.$message.error("请上传10M以下的作业练习");
        return false;
      }

      const formData = new FormData();  // 声明一个FormData对象
      formData.append("file", file);
      formData.append("filename", 'teacherpar')

      let result = await axios.post(e.action, formData, {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      if (result.data.code == 1) {
        this.$message({
          message: '上传作业成功',
          type: 'success'
        });
        //构造数组，追加到数组视频列表中去
        var ext_index = file.name.lastIndexOf(".");
        //获取后缀
        var ext = file.name.substring(ext_index + 1);
        var file_type = 3;
        if (ext == 'ppt') {
          file_type = 1;
        } else if (ext == 'xls') {
          file_type = 2;
        } else {
          file_type = 3;
        }

        let question_info = { 'url': result.data.data.url, 'filesize': file.size / 1024 / 1024, 'name': file.name, 'file_type': file_type, 'price': 0 };

        let qar_list = that.qar_list;
        qar_list.push(question_info);
        that.qar_list = qar_list;


      } else {
        this.$message.error(result.data.msg);
      }
    },

    //上传模拟真题
    async uplaod_imitate(e) {


      let that = this;

      let file = e.file;

      var ext_index = file.name.lastIndexOf(".");
      var ext = file.name.substring(ext_index + 1);
      if (ext != 'docx') {
        this.$message.error("仅支持docx word 文件");
        return false;
      }
      let filesize = file.size;
      let filename = file.name;
      const isLtM = file.size / 1024 / 1024 < 10;
      if (!isLtM) {
        this.$message.error("请上传10M以下的作业练习");
        return false;
      }

      const formData = new FormData();  // 声明一个FormData对象
      formData.append("file", file);
      formData.append("filename", 'imitate')

      let result = await axios.post(e.action, formData, {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      if (result.data.code == 1) {
        this.$message({
          message: '上传模拟真题成功',
          type: 'success'
        });
        //构造数组，追加到数组视频列表中去
        var ext_index = file.name.lastIndexOf(".");
        //获取后缀
        var ext = file.name.substring(ext_index + 1);
        var file_type = 3;
        if (ext == 'ppt') {
          file_type = 1;
        } else if (ext == 'xls') {
          file_type = 2;
        } else {
          file_type = 3;
        }

        let imitate_info = { 'url': result.data.data.url, 'filesize': file.size / 1024 / 1024, 'name': file.name, 'file_type': file_type, 'price': 0 };

        let imitate_list = that.imitate_list;
        imitate_list.push(imitate_info);
        that.imitate_list = imitate_list;


      } else {
        this.$message.error(result.data.msg);
      }

    },


    //删除文件(课程视频)

    async delete_file(file_url, index) {

      try {
        if (!file_url) {
          this.$message.error("删除文件不能为空");
        }

        let res = await del_file({ 'file_url': file_url });
        if (res == 2) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });

          let medio_list = this.medio_list;
          medio_list.splice(index, 1);

        } else {
          this.$message({
            message: '删除失败',
            type: 'success'
          });
        }

      } catch {
        this.$message.error("出现意外错误，请刷新重试");
      }


    },//删除模拟试题
    async delete_question_file(file_url, index) {

      try {
        if (!file_url) {
          this.$message.error("删除文件不能为空");
        }

        let res = await del_file({ 'file_url': file_url });
        if (res == 2) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });

          let qustion_list = this.qustion_list;
          qustion_list.splice(index, 1);

        } else {
          this.$message({
            message: '删除失败',
            type: 'success'
          });
        }

      } catch {
        this.$message.error("出现意外错误，请刷新重试");
      }


    }, async delete_qar_file(file_url, index) {

      try {
        if (!file_url) {
          this.$message.error("删除文件不能为空");
        }

        let res = await del_file({ 'file_url': file_url });
        if (res == 2) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });

          let qar_list = this.qar_list;
          qar_list.splice(index, 1);

        } else {
          this.$message({
            message: '删除失败',
            type: 'success'
          });
        }

      } catch {
        this.$message.error("出现意外错误，请刷新重试");
      }



    }, async delete_imitate_file(file_url, index) {
      try {
        if (!file_url) {
          this.$message.error("删除文件不能为空");
        }

        let res = await del_file({ 'file_url': file_url });
        if (res == 2) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });

          let imitate_list = this.imitate_list;
          imitate_list.splice(index, 1);

        } else {
          this.$message({
            message: '删除失败',
            type: 'success'
          });
        }

      } catch {
        this.$message.error("出现意外错误，请刷新重试");
      }

    },


    async submit_form() {

      let course_active_index = this.course_active_index;
      let couselist = this.couselist.list;
      let form_data = { user_id: '', course_id: '', medio_list: [], qustion_list: [], qar_list: [], imitate_list: [] }

      let userinfo = this.userinfo;
      form_data.user_id = userinfo.id;
      form_data.course_id = couselist[course_active_index].id;
      form_data.medio_list = this.medio_list;
      form_data.qustion_list = this.qustion_list;
      form_data.qar_list = this.qar_list;
      form_data.imitate_list = this.imitate_list;

      let res = await batch_insert_course_file(form_data);

      if (res.code == 200) {
        this.$message({
          message: '操作成功',
          type: 'success'
        });
      } else {
        this.$message.error(res.msg);
      }




    }, //编辑课程
    edit_course(course_id) {


      this.$router.push('/teacher/edit_coursee?course_id=' + course_id);



    }, async delete_course(index) {

      try {

        let cousre_info = this.couselist.list[index];

        let res = await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        if (res == 'confirm') {

          let result = await del_course({ id: cousre_info.id });

          if (result.code == 200) {
            this.$message({
              message: '删除成功',
              type: 'success'
            });


            console.log(index);

            let couselist = this.couselist;
            couselist.list.splice(index, 1);

            this.couselist = couselist;
          } else {

            this.$message.error("删除失败");
          }

        }

      } catch (error) {
        console.log(1);
      }




    }, go_cousre_detail(id) {

      this.$router.push('/coursedetail?id=' + id);


    }
  }

}
</script>

<style lang="less" scoped >
@import url("./../../../assets/css/teacher_common.less");

/deep/ .el-tabs__item.is-active {
  color: #289EB8;
}

/deep/ .el-tabs__active-bar {
  background-color: #289EB8;
}

/deep/ .el-tabs__item:hover {
  color: #289EB8;
}

p {
  padding: 0;
  margin: 0;
}

.center-main {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  margin-top: 40px;

}

.zuoye {
  display: flex;
  margin-top: 30px;
}

.zuoye .input-jinbi {
  height: 45px;
  width: 80px;
  height: 45px;
  background: #F8F8F8;
  border-radius: 8px;
  border: 1px solid #ECECEC;
  line-height: 45px;
}


.zuoye .video-input {
  width: 500px;
  height: 45px;
  background: #F8F8F8;
  border-radius: 8px;
  border: 1px solid #ECECEC;
}

.zuoye .jinbi {
  margin-top: 0px;
  line-height: 45px;

  div {
    margin-left: 20px;
  }
}

.zuoye .delect {
  cursor: pointer;
  color: #EA440A;
  line-height: 49px;
}

.bangong {
  width: 45px;
  height: 40px;
  margin-right: 30px;
  margin-top: 5px;
}

.tijiao {
  cursor: pointer;
  margin: 20px auto;
  width: 161px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  background: #289EB8;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
}

.tianjia {
  width: 91px;
  height: 80px;
  background: #F8F8F8;
  border-radius: 8px;
  margin: 20px auto;
  color: #ADADAD;
  font-size: 45px;
  text-align: center;
  padding-top: 5px;
}

.jinbi {
  margin-left: 20px;
  display: flex;
  margin-top: 28px;
  margin-right: 20px;

  div {
    margin-left: 20px;
    line-height: 45px;
  }
}

.input-jinbi {

  height: 45px;
  width: 80px;
  height: 45px;
  background: #F8F8F8;
  border-radius: 8px;
  border: 1px solid #ECECEC;
  line-height: 45px;
}

input {
  outline: none;
}

.video {
  display: flex;
}

.video video {
  width: 100px;
  height: 100px;
}

.video .video-input {
  margin-left: 20px;
  margin-top: 28px;
  width: 500px;
  height: 45px;
  background: #F8F8F8;
  border-radius: 8px;
  border: 1px solid #ECECEC;
}

.video .delect {
  margin-top: 40px;
  color: #EA440A;
}

.left {
  width: 310px;

}

.body-content ul {
  margin: 0;
  padding: 0 20px 20px 20px;
  text-align: center;
}

.body-content ul li {
  list-style: none;
  height: 52px;
  display: flex;
  display: -webkit-flex;
}

button {
  cursor: pointer;
  border: 0.5px solid #E9E9E9;
}


.xuhao {
  width: 80px;

  text-align: center;
  margin: 0 !important;

}

.biaoti {

  width: 300px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.shuliang {
  width: 100px;
  overflow: hidden;
}

.liulan {
  width: 100px;
  margin-left: 10px;
}

.zhuangtai {
  width: 120px;
}

.body-header .caozuo {
  border: none;
}

.caozuo {
  display: flex;
  width: 210px;
  display: flex;
  display: -webkit-flex;
  text-align: center;
  border-left: 1px solid #cdcdcd;
  border-right: 1px solid #cdcdcd;

  span {
    margin-left: 58px;
    text-align: center;

  }

  justify-content: space-between;

}

.caozuo .moniti {
  display: grid;
  font-size: 20px;
  line-height: 25px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #323B46;
  margin-left: 10px;
}



.shangchuan {
  color: #289EB8;
  line-height: 53px;
}

.bianji {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #EA440A;
}

.caozuo .wendang {
  display: grid;

  line-height: 25px;
}

.course {
  width: 1150px;
  /* height: 626px; */
  overflow: auto;
  height: auto;
  background: #FFFFFF;
  border-radius: 6px;


}

.course-search {
  margin-left: 31px;
  padding-top: 51px;
  display: flex;
}

.course .search {
  position: relative;
  width: 653px;
  height: 50px;
  border: 2px solid #ECECEC;
  border-radius: 21px;
}

.course .search input {
  outline: none;
  margin-left: 55px;
  border-style: none;
  width: 570px;
  height: 45px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ADADAD;
  line-height: 45px;
  margin-top: 2.5px;
}

.course .search img {
  position: absolute;
  left: 20px;
  top: 11px;
  width: 28px;
  height: 28px;
}

.course-search button {
  cursor: pointer;
  width: 115px;
  height: 50px;
  border: 1px solid #FFFFFF;
  background: #289EB8;
  border-radius: 8px;
  margin-left: 36px;
  margin-top: 0px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 50px;
}


.content-header {
  display: flex;
  margin-left: 74px;
  margin-top: 48px;
}

.content-header p {
  cursor: pointer;
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #323B46;
}


.content-header :nth-child(2) {
  margin-left: 122px;
}

.content-header p:nth-child(3) {
  margin-left: 131px;
}

.content-body {
  margin-left: 35px;
  margin-top: 0px;
  margin-bottom: 50px;
  width: 1105px;
  background: #FFFFFF;
  box-shadow: 0px 0px 65px 0px rgba(51, 51, 51, 0.1);
  border-radius: 16px;
}

.content-body .body-header {
  display: flex;
  width: 1060px;
  font-size: 22px;
  margin-top: 25px;
  height: 60px;
  line-height: 60px;
  color: #606060;
}

.content-body .body-header p {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #323B46;
  text-align: center;
}

.content-body .body-header p:nth-child(1) {
  width: 70px;

}

.content-body .body-header p:nth-child(2) {
  width: 400px;

}

.content-body .body-header p:nth-child(3) {
  width: 70px;
}

.content-body .body-header p:nth-child(4) {
  width: 100px;
}

.content-body .body-header p:nth-child(5) {
  width: 100px;
}

.content-body .body-header p:nth-child(6) {
  width: 300px;
}

.body-content li {
  cursor: pointer;
  display: flex;
  font-size: 18px;
  line-height: 52px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #323B46;
  margin: 10px auto;
}

.edit {
  color: #EA440A;
}

.body-content li:hover {
  background-color: #DCF6FB;
}



.body-content li .xin {
  background-color: #289EB8;
  color: #FFFFFF;
}

.body-content button {
  width: 55px;
  text-align: center;
  height: 25px;
  line-height: 24px;
  border-radius: 6px;
}

.zhiding-div {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  margin-right: 10px;

  div {
    line-height: 25px;

  }

  .delete {
    color: red;
  }

}

.zhiding-div button {
  padding: 0;
  margin: 0;
  text-align: center;
}

/* 弹窗界面 */
.tanchuang {
  position: absolute;
  top: 45%;
  left: 35%;
  z-index: 1000;
  width: 915px;
  min-height: 600px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 68px 0px rgba(51, 51, 51, 0.26);
  border-radius: 12px;
}

.cha {
  width: 25px;
  height: 25px;
  margin-left: 98%;
  margin-top: 10px;
  position: relative;
}

.cha::before,
.cha::after {
  content: "";
  position: absolute;
  /*方便进行定位*/
  height: 20px;
  width: 2.5px;
  top: 2px;
  right: 30px;
  /*设置top和right使图像在20*20框中居中*/
  background: #289EB8;
}

.cha::before {
  transform: rotate(45deg);
  /*进行旋转*/
}

.cha::after {
  transform: rotate(-45deg);
}

/deep/ .el-tabs__item {
  font-size: 18px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 35px;
}

.upload-file {
  width: 90px;
  height: 90px;
  margin: 0 auto;
  background-color: #F8F8F8;
}


.upload-file .upload-demo {
  width: 90px;
  height: 90px;

}

.upload-file .upload-demo /deep/ .el-upload--text {
  width: 100%;
  height: 100%;
}

.upload-medio-icon {
  height: 90px;
  width: 90px;
  text-align: center;
  line-height: 90px;
  color: #BFBFBF;
  font-size: 30px;
}

.medio-list {
  min-height: 280px;
}

// 刷新按钮
.shuaxin,
.zhiding {
  position: absolute;
  top: 45%;
  left: 35%;
  z-index: 1000;
  width: 640px;
  min-height: 370px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 68px 0px rgba(51, 51, 51, 0.26);
  border-radius: 10px;
  padding-left: 35px;
}

.shuaxin-right,
.shuaxin-fufei,
.zhiding-title {
  font-size: 22px;
  font-family: Source Han Sans CN-Bold, Source Han Sans CN;
  font-weight: 700;
  color: #333333;
}

.shuaxin-fufei {
  margin-top: 60px;
  margin-bottom: 10px;
}

.shuaxin-title {
  display: flex;
  margin-top: 10px;
}

.shuaxin-con,
.keyong {
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #333333;
  margin-left: 40px;
  margin-right: 20px;
}

.shuaxin-btn,
.zhifushuaxin {
  width: 90px;
  height: 30px;
  background: #289EB8;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
}

.zhifushuaxin {
  margin: 15px 0 0 40px;
}

.shuaxin-fufei-con {
  display: flex;
}

.chongzhi {
  display: inline-block;
  color: #EA440A;
  cursor: pointer;
}

// 置顶按钮
.zhiding {
  width: 560px;
}

.category-scope {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 15px;

  .cate-item {
    width: 80px;
    height: 60px;
    background: #fff;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    border: 1px solid #D1D1D1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 45px;
    text-align: center;
    white-space: pre-wrap;

    &.current {
      background-color: #289EB8;
      color: #ffffff;
    }
  }

  .cate-item:nth-child(3n + 3) {
    margin-right: 0;
  }
}

.keyong p {
  display: inline-block;
  color: #EA440A;
  cursor: pointer;
}

.zhiding-zhifu {
  width: 110px;
  height: 40px;
  background: #289EB8;
  border-radius: 5px 5px 5px 5px;
  opacity: 1;
  font-size: 18px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  line-height: 40px;
  margin: 20px auto;
  cursor: pointer;
}

.see {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  width: 250px;
  text-align: center;

  span {
    width: 100%;
    text-align: center;
  }

  div {
    flex: 1;
    font-size: 18px;
  }

}
</style>
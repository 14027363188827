<template>
    <div class="box">
        <div class="header">
            <div class="header-left">
                <img src="../assets/img/exam-3588192__340.png" alt="">
            </div>
            <div class="header-con">
                <img :src="baseurl + guaninfo.avatar" alt="">
            </div>
            <div class="header-right"><img src="../assets/img/online-education-3412473__340 1.png" alt=""></div>
        </div>
        <div class="content">

            <!-- <div class="ic" v-for="(item, key) in course_list.list" :key="key">
                <div class="img"><img :src="baseurl + item.cover_img" alt="" @click="go_detail(item.id)"></div>
                <div class="title" @click="go_detail(item.id)">{{ item.title }}</div>
                <div class="con" @click="go_detail(item.id)">{{ item.intro }}</div>
                <div class="time"> {{ item.createtime }}<img src="./../assets/img/no-zan.png" alt="" v-if="!item.is_like"
                        @click="go_zan(item.id, key)"><img src="./../assets/img/zan.png" alt="" v-else
                        @click="go_zan(item.id, key)"></div>
            </div> -->
            <div class="left">
                <div class="fabu" v-if="userinfo.user_type == 1 && userinfo.id == guaninfo.id">
                    <textarea v-model="form_data.content" name="" id="" cols="30" rows="10"
                        placeholder="快来发布一个动态吧"></textarea>

                    <div class="pre-img" v-if="form_data.imgs.length > 0 && form_data.is_type == 0">

                        <div class="pic-info" v-for="(item, key) in form_data.imgs" :key="key">

                            <img alt="" :src="baseurl + item">
                            <i class="el-icon-circle-close" @click="delete_img(key)"></i>
                        </div>
                        <div class="upload-img-input">
                            <el-upload :action="upload_url" :multiple="true" :show-file-list="false" :auto-upload="true"
                                :on-success="upload_img_success" :limit="9">
                                <i class="el-icon-plus"></i>
                            </el-upload>


                        </div>
                    </div>
                    <div class="pre-img meido-area">

                        <el-progress type="circle" :percentage="percentage"
                            v-if="percentage != 100 && form_data.is_type == 1"></el-progress>
                        <div class="pic-info" v-if="form_data.is_type == 1 && form_data.medio_url != ''">

                            <video :src="baseurl + form_data.medio_url" :controls="true"
                                class="medio">您的浏览器不支持video标签。</video>
                            <i class="el-icon-circle-close" @click="delete_medio"></i>
                        </div>
                    </div>
                    <div class="fabu-btn">
                        <div class="btn-tupian">
                            <el-upload class="upload-demo" :multiple="true" :limit="9" :action="upload_url"
                                :auto-upload="true" :on-success="upload_img_success" :show-file-list="false"
                                :on-change="uplaod_img">
                                <div class="btn-tupian">
                                    <img src="./../assets/img/trends/tupian.png" alt="">图片
                                </div>
                            </el-upload>




                            <el-upload class="upload-demo" :limit="1" :action="upload_url" :auto-upload="true"
                                :on-success="upload_medio" :on-progress="uploadVideoProcess" accept=".mp4"
                                :show-file-list="false">
                                <div class="btn-tupian">
                                    <img src="./../assets/img/trends/shipin.png" alt="">视频
                                </div>
                            </el-upload>

                        </div>


                        <div class="btn-yinsi">
                            <el-dropdown @command="handleCommand">
                                <span class="el-dropdown-link" v-if="form_data.is_public == 0">
                                    公开<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <span class="el-dropdown-link" v-if="form_data.is_public == 1">
                                    仅自己可看<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="0">公开</el-dropdown-item>
                                    <el-dropdown-item command="1">仅自己可看</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="btn-fasong" @click="submit_trend">发送</div>
                    </div>


                </div>
                <div class="back" v-if="userinfo.user_type == 1 && userinfo.id == guaninfo.id"></div>

                <div class="alldongtai">全部动态({{ trends_list.total }})</div>

                <div class="dongtai-list-content" v-if="trends_list.list.length > 0">
                    <div class="dongtai" v-for="(item, key) in trends_list.list" :key="key">
                        <div class="dongtai-header">
                            <div class="dongtai-header-tou">
                                <img :src="baseurl + teacher_info.avatar" alt="" v-if="teacher_info.avatar">
                                <img src="./../assets/img/header.png" alt="" v-else>


                            </div>
                            <div class="dongtai-header-xin">
                                <div class="header-xin-name">{{ teacher_info.nickname ? teacher_info.nickname : '' }}</div>
                                <div class="header-xin-time">{{ item.createtime }}</div>
                            </div>
                            <div class="dongtai-header-chu" v-if="userinfo.id == teacher_id"
                                @click="delete_trends(item.id, key)">删除</div>
                        </div>
                        <div class="dongtai-content">
                            <div class="content-wen">
                                {{ item.content }}
                            </div>
                            <div class="content-tu" v-if="item.type == 0">
                                <el-image style="width:240px; height: 150px" v-for="(imginfo, key1) in item.imgs"
                                    :key="key1" :src="imginfo" :preview-src-list="item.imgs">
                                </el-image>

                            </div>
                            <div class="content-tu" v-if="item.type == 1">
                                <video :src="baseurl + item.medio_url" :controls="true" class="medio-area">


                                </video>

                            </div>
                        </div>
                        <div class="dongtai-footer">
                            <div class="dongtai-footer-zhuan"><img src="./../assets/img/trends/zhuanfa.png" alt="">转发</div>
                            <div class="dongtai-footer-pinlun" @click="go_comment($event)"><img
                                    src="./../assets/img/trends/pinlun.png" alt="">评论</div>
                            <div class="dongtai-footer-dian" @click="atten_trends(item.id, key)" v-if="!item.is_like"><img
                                    src="./../assets/img/trends/dianzan.png" alt="">点赞</div>
                            <div class="dongtai-footer-dian" @click="atten_trends(item.id, key)" v-else><img
                                    src="./../assets/img/zan.png" alt="">取消点赞</div>
                        </div>
                        <div class="comment-area" style="display:none">

                            <div class="comment-input">
                                <input type="text" name="comment" v-model="comment_content" placeholder="输入评论">

                                <el-button type="primary" class="send" @click="send_comment(item.id)">发送</el-button>


                            </div>

                        </div>

                        <div class="comment-list" v-if="item.get_commentlist.length > 0">
                            <div class="comment-info" v-for="(comment, key2) in item.get_commentlist" :key="key2">
                                <div class="avatar">
                                    <img :src="baseurl + comment.avatar" alt="">

                                </div>
                                <div class="comment-name">
                                    <div>{{ comment.nickname ? comment.nickname : '--' }}：</div>
                                    <div>{{ comment.createtime }}</div>

                                </div>
                                <div class="comment-content">

                                    {{ comment.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 暂无动态 -->
                <div class="dongtai-list-content" v-else>
                    <el-empty description="暂无动态"></el-empty>
                </div>

            </div>
            <!-- <div class="page" v-if="course_list.total > 8">
            <el-pagination @current-change="course_change" :current-page="course_list.page" :page-sizes="[8]"
                :page-size="course_list.pagesize" layout="prev, pager, next, jumper" :total="course_list.total">
            </el-pagination>
        </div> -->
            <div class="right">
                <div class="right-area">
                    <div class="right-header"><img src="./../assets/img/trends/ke.png" alt="">考研真题下载</div>
                    <div class="right-con">
                        <div class="sou-suo">
                            <input type="text" class="sou-inp" placeholder="搜索想要的真题">
                            <button class="sou">搜索</button>
                        </div>
                        <ul>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                            <li>青岛农业大学-(135)园林与林学院-(095400)(专业学位)</li>
                        </ul>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { UserInfo, deleteTrendFile } from "@/request/guan"
import { InsertTrends, getTrendsList, commentTrends, deleteTrends, attenTrends, getTeacherCourse } from "@/request/teacher"
import { GuanInfo, GuanCurri } from "@/request/guan"
import { add_like_course } from "@/request/course"
import BASEURL from "@/request/baseurl"
import store from "@/store/index"
export default {
    name: 'authority',
    data() {

        return {
            teacher_info: {},
            trends_list: {
                'total': 0,
                'page': 1,
                'pagesize': 10000,
                'list': []

            },
            guaninfo: {},
            // course_list: {
            //     'total': 0,
            //     'page': 1,
            //     'pagesize': 8,
            //     'list': []

            // },
            form_data: {
                content: '',
                imgs: [],
                medio_url: '',

                is_public: 0,
                is_type: 0
            },
            teacher_id: null,

            imgs: [],
            baseurl: BASEURL,
            userinfo: {},
            percentage: 0,
            is_login: false,
            upload_url: BASEURL + '/api/upload/generaupload',
            comment_content: '',
        }



    }, computed: {
        getUserInfo() {


            return this.$store.state.Userinfo
        }

    }, watch: {
        userinfo(val, oldval) {

            if (val) {
                this.userinfo = val;
            }

        }



    },
    created() {
        let teacher_id = this.$route.query.user_id;
        this.teacher_id = teacher_id;
        var userinfo = localStorage.getItem('Userinfo');


        //如果拥有用户信息，则直接进入个人中心
        try {

            if (userinfo) {

                //判断时间是否过期
                userinfo = JSON.parse(userinfo);


                if (userinfo.expiretime * 1000 < new Date().getTime()) {
                    this.is_login = false;
                } else {
                    this.is_login = true;
                    this.userinfo = userinfo
                }

            }
        } catch (error) {

        }
        this.initdata(teacher_id);

    }, methods: {
        async initdata() {

            let res = await GuanInfo();
            if (res.code === 200) {

                this.guaninfo = res.data
                this.get_curri_list(this.page)

            } else {
                this.$messge.error("出现意外错误");
            }
        },



        //发布动态
        async submit_trend() {
            let form_data = this.form_data;
            if (!form_data.content && !form_data.imgs && !form_data.medio_url) {
                this.$message.error("发布动态不能为空");
                return false;
            }

            let res = await InsertTrends(form_data);
            if (res.code === 200) {
                this.$message.success("发布动态成功");
                let form_data = this.form_data;
                form_data.imgs = '';
                form_data.medio_url = '';
                form_data.content = '';
                form_data.is_public = 0,
                    form_data.is_type = 0;
                this.form_data = form_data


                this.get_trends_list(1, this.trends_list.pagesize, this.teacher_id)

            } else {
                this.$message.error('删除失败');
            }
        }, upload_img_success(e) {
            console.log(e);
            console.log();
            let form_data = this.form_data

            if (e.code == 1) {
                form_data.imgs.push(e.data.url);
                form_data.is_type = 0;
                this.form_data = form_data;
            } else {
                this.$messge.error(e.msg);
            }

        }, uploadVideoProcess(e) {
            let form_data = this.form_data
            form_data.is_type = 1;
            this.form_data = form_data;
            this.percentage = Number(e.percent.toFixed(2))

        },



        upload_medio(e) {

            let form_data = this.form_data
            if (e.code == 1) {
                form_data.medio_url = e.data.url;
                form_data.is_type = 1;
                this.form_data = form_data;
            } else {
                this.$messge.error(e.msg);
            }
        },

        //删除动态
        async delete_trends(id, key) {
            let result = await this.$confirm('此操作将永久动态吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });

            if (result == 'confirm') {
                let trends_list = this.trends_list;


                let res = await deleteTrends({ id: id })
                if (res.code == 200) {
                    trends_list.list.splice(key, 1)
                    this.trends_list = trends_list;
                    this.$message.success('删除成功');
                } else {
                    this.$messge.error(res.msg);
                }
            }
        }, go_detail(id) {

            this.$router.push('/coursedetail?id=' + id);
        }, course_change(page) {


            this.get_curri_list(page, this.course_list.pagesize, this.guaninfo.id);
        }, uplaod_img() {

        },


        //删除照片
        async delete_img(index) {

            let imgs_url = this.form_data.imgs[index]
            try {
                if (!imgs_url) {
                    this.$message.error("删除文件不能为空");
                }

                let res = await deleteTrendFile({ 'file_url': imgs_url });
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });

                    let form_data = this.form_data;
                    form_data.imgs.splice(index, 1);


                    this.form_data = form_data;
                } else {
                    this.$message({
                        message: '删除失败',
                        type: 'success'
                    });
                }

            } catch {
                this.$message.error("出现意外错误，请刷新重试");
            }

        },
        //删除视频
        async delete_medio() {

            let imgs_url = this.form_data.medio_url
            try {
                if (!imgs_url) {
                    this.$message.error("删除文件不能为空");
                }

                let res = await deleteTrendFile({ 'file_url': imgs_url });
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });

                    let form_data = this.form_data;
                    form_data.medio_url = '';


                    this.form_data = form_data;
                } else {
                    this.$message({
                        message: '删除失败',
                        type: 'success'
                    });
                }

            } catch {
                this.$message.error("出现意外错误，请刷新重试");
            }

        },
        //切换选择公开还是私密
        handleCommand(e) {
            let form_data = this.form_data;
            form_data.is_public = e;
            this.form_data = form_data

        }, go_comment(e) {
            console.log(e);
            console.log('click');
            e.currentTarget.parentElement.nextElementSibling.style.display = 'block';
        },
        //评论
        async send_comment(trends_id) {


            let comment_content = this.comment_content;
            let userinfo = this.userinfo;
            if (!userinfo) {
                this.$message.error("需要登录哦");
                return false;
            }

            if (!userinfo.id) {
                this.$message.error("需要登录哦");
                return false;
            }

            if (!comment_content) {
                this.$message.error('请输入评论内容');

                return false;
            }

            let res = await commentTrends({ trends_id: trends_id, content: comment_content })
            if (res.code === 200) {
                this.comment_content = '';
                this.$message.success(res.msg);
            } else {
                this.$message.error(res.msg);
            }





        },
        //点赞
        async atten_trends(id, index) {

            let trends_list = this.trends_list;

            let res = await attenTrends({ id: id });
            if (res.code === 200) {
                trends_list.list[index].is_like = !trends_list.list[index].is_like;

            } else {
                this.$message.error(res.msg)
            }


        }, close_chat: function () {
            this.is_show_chat = false;
        }, gou_tong: function (to_user_id) {
            let userinfo = this.userinfo;
            if (userinfo == null) {
                this.$message.warning("需要先登录哦")
                return false;
            }

            if (Object.keys(userinfo).length < 1) {

                this.$message.warning("需要先登录哦")
                return false;
            }

            if (userinfo.user_type == 1) {

                this.$message.warning("对不起，您不是学生")
                return false;

            }
            this.to_user_id = to_user_id;
            this.is_show_chat = true;
        }, async add_friend() {
            let userinfo = this.userinfo;
            if (userinfo == null) {
                this.$message.warning("需要先登录哦")
                return false;
            }

            if (Object.keys(userinfo).length < 1) {

                this.$message.warning("需要先登录哦")
                return false;
            }

            if (userinfo.user_type == 1) {

                this.$message.warning("对不起，您不是学生")
                return false;

            }

            let teacher_id = this.teacher_id;
            let res = await addTeacherFriend({ teacher_id: teacher_id });

            if (res.code === 200) {
                this.$message.success("添加好友成功")
                let teacher_info = this.teacher_info;
                teacher_info.is_friend = true;
                this.teacher_info = teacher_info;
            } else {
                this.$message.warning(res.msg)
                return false;
            }
        },
        // async get_curri_list(current, pagesize, user_id) {
        //     let res = await GuanCurri({ user_id: user_id, current: current, pagesize: pagesize });
        //     if (res.code == 200) {
        //         let course_list = this.course_list;
        //         course_list.total = res.data.total;
        //         course_list.page = Number(res.data.current);
        //         course_list.list = res.data.list;
        //         this.course_list = course_list;


        //     } else {
        //         this.$messge.error('获取官方动态出错');
        //     }

        // }, go_detail(id) {

        //     this.$router.push('/coursedetail?id=' + id);
        // }, course_change(page) {


        //     this.get_curri_list(page, this.course_list.pagesize, this.guaninfo.id);
        // }, async go_zan(couse_id, index) {

        //     let userinfo = this.userinfo;

        //     if (userinfo == null) {
        //         this.$message.warning("需要先登录哦")
        //         return false;
        //     }
        //     if (Object.keys(userinfo).length < 1) {

        //         this.$message.warning("需要先登录哦")
        //         return false;
        //     }
        //     let res = await add_like_course({ course_id: couse_id, type: 0 });
        //     if (res.code === 200) {
        //         let course_list = this.course_list

        //         if (course_list.list[index].is_like) {
        //             course_list.list[index].is_like = false;
        //             course_list.list[index].sign_up_num = Number(course_list.list[index].sign_up_num) - 1;
        //         } else {
        //             course_list.list[index].is_like = true;
        //             course_list.list[index].sign_up_num = Number(course_list.list[index].sign_up_num) + 1;
        //         }

        //         this.course_list = course_list;
        //     }

        // }

    }

}
</script>
<style lang="less" scoped>
@import url(./../assets/css/authority.less);

.header {
    display: flex;
    height: 355px;
    border-radius: 5px;
    background-color: #ffffff;
    opacity: 1;
    margin-top: 30px;
    margin-bottom: 15px;
}

.header-left img {
    width: 600px;
    height: 355px;

}

.header-con {
    width: 300px;
    height: 355px;
}

.header-con img {
    display: block;
    margin: 70px auto;
    width: 260px;
    height: 220px;
}

.introduce {
    font-size: 18px;
    color: #9E9E9E;
    text-align: center;
}

.header-right img {
    width: 600px;
    height: 355px;
}

.right-con {
    width: 560px;
    padding-bottom: 10px;

}

.right-con ul {
    padding: 0;
    margin-left: 20px;
}

.right-con li {
    width: 520px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 26px;
    cursor: pointer;
    margin-top: 10px;
    list-style: none;
}

.right-con li:hover {
    color: #2896B8;
}

.sou-inp {
    width: 400px;
    height: 40px;
    outline: none;
    border-radius: 100px;
    margin-left: 20px;
    margin-top: 15px;
    border: 1px solid #C4C4C4;
    padding-left: 20px;
    font-size: 20px;
}

.sou {
    margin-left: 10px;
    cursor: pointer;
    width: 80px;
    height: 46px;
    background: #2896B8;
    border-radius: 105px;
    opacity: 1;
    font-size: 26px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    border: none;
}

// .allke {
//     color: #333333;
//     font-size: 24px;
//     font-weight: 500;
//     margin-top: 8px;

// }

// .allke img {
//     vertical-align: middle;
//     width: 30px;
//     height: 35px;
// }

// .content {
//     display: flex;
//     justify-content: flex-start;
//     flex-wrap: wrap;
//     height: 850px;
// }

// .content .ic {
//     border-radius: 10px;
//     height: 400px;
//     background-color: #ffffff;
//     margin: 8px 33px 20px 0;
//     width: calc(1400px/ 4);
//     cursor: pointer;
//     transition: all 0.4s ease 0s;
//     overflow: hidden;
// }



// .ic:hover .img {
//     animation: move .4s linear forwards;
// }

// @keyframes move {
//     0% {
//         transform: scale(1);
//     }

//     100% {
//         transform: scale(1.03);
//     }
// }

// .content .ic:hover {
//     /* 盒子阴影 */
//     box-shadow: 4px 20px 40px 5px rgba(0, 0, 0, .1);
//     /* 盒子向上移动效果，改变上外边距 */
//     margin-top: 8px;
//     transition: all 0.4s ease 0s;
// }

// .content .ic:nth-of-type(4n+0) {
//     margin-right: 0;
// }

// .content .ic .img img {
//     width: 350px;
//     height: 220px;
//     border-radius: 10px 10px 0 0;
// }

// .title {
//     width: 312px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     overflow: hidden;
//     font-size: 20px;
//     font-family: Source Han Sans CN-Regular, Source Han Sans CN;
//     font-weight: 400;
//     color: #333333;
//     line-height: 26px;
//     margin: 15px 0 0 15px;
// }

// .con {
//     margin: 15px 10px 10px 15px;
//     font-size: 16px;
//     font-family: Source Han Sans CN-Regular, Source Han Sans CN;
//     font-weight: 400;
//     height: 63px;
//     width: 312px;
//     display: -webkit-box;
//     /* 设置或检索伸缩盒对象的子元素的排列方式 。 */
//     -webkit-box-orient: vertical;
//     /*  用来限制在一个块元素显示的文本的行数 */
//     -webkit-line-clamp: 3;
//     overflow: hidden;
// }

// .con:hover {
//     color: #289eb8;
//     text-decoration: underline;
// }

// .time {
//     display: flex;
//     font-size: 18px;
//     font-family: Source Han Sans CN-Regular, Source Han Sans CN;
//     font-weight: 400;
//     line-height: 30px;
//     color: #333333;
//     margin: 0 30px 0 15px;
// }

// .time img {
//     display: block;
//     width: 28px;
//     height: 28px;
//     margin-left: 175px;
// }


// .page {
//     width: 100%;

//     .el-pagination {
//         width: 520px;
//         margin: 0 auto;
//         font-size: 16px;

//         /deep/ .number {
//             min-width: 33px;
//             height: 33px;
//             text-align: center;
//             line-height: 33px;
//             border: 1px solid #d9d9d9 !important;
//             border-radius: 4px;
//             padding: 0 !important;
//             margin-right: 8px !important;
//             font-size: 14px;
//             font-weight: 500;
//         }

//         /deep/ .active {
//             font-weight: 500;
//             color: #ffffff;
//             border-color: #289EB8;
//             background-color: #289EB8;
//             font-size: 16px;
//         }

//         /deep/ .btn-prev {

//             text-align: center !important;
//             line-height: 33px;
//             border: 1px solid #d9d9d9 !important;
//             margin-right: 8px;
//             min-width: 33px;
//             font-size: 14px;
//             height: 33px;
//         }
//     }

//     /deep/ .btn-next {
//         width: 33px;
//         text-align: center !important;
//         line-height: 33px;
//         border: 1px solid #d9d9d9 !important;
//         height: 33px;
//         min-width: 33px;
//         font-size: 14px;

//     }


//     /deep/ .el-pagination__jump {
//         height: 33px;
//         line-height: 33px;
//     }



//     .el-pager li {

//         min-width: 35px;
//     }


// }
</style>
<template>
  <div class="box-body">
    <div class="big-box">
      <div class="student-box">
        <div class="student-header">
          <div class="student-message">
            <div class="header-left">

              <img alt="" class="header-logo" v-if="userInfo.avatar" :src="OSSURL + userInfo.avatar">
              <img src="./../../assets/img/header.png" alt="" class="header-logo" v-else>

            </div>
            <div class="header-right">
              <div class="username">{{ userInfo.nickname ? userInfo.nickname : userInfo.mobile }}</div>
              <div class="schoole-name">院校： {{ userInfo.gra_schoole }}</div>

            </div>

          </div>
          <div class="qian">
            <p v-if="userInfo.autograph">签名：{{ userInfo.autograph }}</p>
            <p v-else>签名：这个人很懒，还没签名，快写一个属于自己的签名吧。</p>

          </div>
          <div class="xian">--------------------------------</div>
          <div class="xinxi">
            <button @click="$router.push('/teacher/person')">个人信息</button>
            <button @click="out_login">退出登录</button>
          </div>
        </div>


        <div class="student-content">
          <el-menu :default-active="this.$route.path" class="el-menu-vertical-demo" @open="handleOpen"
            @close="handleClose" :router="true">
            <el-submenu index="1">

              <template slot="title">
                <span>课程管理</span>
              </template>

              <el-menu-item-group class="item">
                <el-menu-item index="/teacher/course" route="/teacher/course">课程管理</el-menu-item>
                <el-menu-item index="/teacher/medio" route="/teacher/medio">试听视频</el-menu-item>
                <el-menu-item index="/teacher/homework" route="/teacher/homework">作业练习</el-menu-item>
                <el-menu-item index="/teacher/Mocktes" route="/teacher/Mocktes">模拟试题</el-menu-item>
                <el-menu-item index="/teacher/imitate" route="/teacher/imitate">历年真题</el-menu-item>
              </el-menu-item-group>
            </el-submenu>


            <el-submenu index="2">
              <template slot="title">
                <span>报名订购</span>
              </template>
              <el-menu-item-group class="item">
                <el-menu-item index="/teacher/course-reg" route="/teacher/course-reg">课程报名</el-menu-item>
                <el-menu-item index="/teacher/Vidorder" route="/teacher/Vidorder">视频订购</el-menu-item>
                <el-menu-item index="/teacher/Joborder" route="/teacher/Joborder">作业订购</el-menu-item>
                <el-menu-item index="/teacher/Queorder" route="/teacher/Queorder">试题订购</el-menu-item>
                <el-menu-item index="/teacher/QuImitateorder" route="/teacher/QuImitateorder">历年订购</el-menu-item>
              </el-menu-item-group>
            </el-submenu>



            <el-submenu index="3">
              <template slot="title">
                <span>我的收藏</span>
              </template>
              <el-menu-item-group class="item">
                <el-menu-item index="/teacher/Colneed" route="/teacher/Colneed">收藏需求</el-menu-item>
              </el-menu-item-group>
            </el-submenu>


            <el-submenu index="4">
              <template slot="title">
                <span>站内信息</span>
              </template>
              <el-menu-item-group class="item">
                <el-menu-item index="/teacher/Siteinf" route="/teacher/Siteinf">站内信息</el-menu-item>
              </el-menu-item-group>
            </el-submenu>



            <el-submenu index="5">
              <template slot="title">
                <span>我的学生</span>
              </template>
              <el-menu-item-group class="item">
                <el-menu-item index="/teacher/Mystudents" route="/teacher/Mystudents">我的学生</el-menu-item>
              </el-menu-item-group>
            </el-submenu>


            <el-submenu index="6">
              <template slot="title">
                <span>U币管理</span>
              </template>
              <el-menu-item-group class="item">
                <el-menu-item index="/teacher/Recharge" route="/teacher/Recharge">我的U币</el-menu-item>
                <el-menu-item index="/teacher/Income" route="/teacher/Income">收支明细</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>

        </div>
      </div>

    </div>


  </div>
</template>

<script>

import BASEURL from "./../../request/baseurl"
import OSSURL from "@/request/oss"
//引入store 监听侧边栏，默认选项的改变，获取默认的侧边栏
import { mapMutations } from 'vuex';
import { logout } from './../../request/login'
import store from "./../../store/index"

export default {
  name: 'teachercenter',
  data() {
    return {
      OSSURL:OSSURL,
      BASEURL: BASEURL,
      isCollapse: true,
      is_default_index: ''
    }
  }, computed: {

  },

  props: {
    userInfo: {
      type: Object,
      default: function () { return {} },
      required: true

    },
    defaultIndex: {
      type: String,
      default: function () {
        return "";
      },
      required: false
    }
  }, beforeCreate() {

  },

  created() {
    let is_default_index = store.state.left_menu_default;
    this.is_default_index = is_default_index;

  }, methods: {
    ...mapMutations(['setDefaultMenu']),
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
      let index = key + '-'.keyPath[0];
      this.is_default_index = index;
      store.commit('setDefaultMenu', { index })

    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    }, go_nav(e) {
      let index = e.index;



      store.commit('setDefaultMenu', { index })

      this.is_default_index = index;
      //跳转至课程
      if (index == '1-1') {

        this.$router.push('/teacher/course');


      }
    },
    //退出登录  
    async out_login() {

      let result = await this.$confirm('确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });

      //如果确定退出登录
      if (result == 'confirm') {

        console.log(9999);

        //退出登录
        logout({}).then(respone => {

          if (respone.code === 200) {
            localStorage.clear("Userinfo");

            localStorage.clear('Authorization');
            //退出登录，同时去除store 中的信息
            store.commit('setUserinfo', { userinfo: '' })
            store.commit("changeLogin", { Authorization: '' })
            this.$router.push('/login');
          } else {
            console.log(6666);

          }

        }).catch(err => {
          localStorage.clear("Userinfo");

          localStorage.clear('Authorization');
          //退出登录，同时去除store 中的信息
          store.commit('setUserinfo', { userinfo: '' })
          store.commit("changeLogin", { Authorization: '' })
          this.$router.push('/login');
          console.log(err);
        })

      }


    }

  }




}






</script>
<style lang="less" scoped>
.big-box {
  position: relative;
  width: 310px;
  min-height: 1200px;
}


.el-menu {
  text-align: center !important;
}

.el-menu-item.is-active {
  color: #289EB8;
}

.student-box {

  width: 310px;
  height: 984px;
  background: #FFFFFF;
  border-radius: 6px;
}

.student-header {
  width: 310px;
  height: 250px;

}

.student-message {
  margin-left: 20px;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;

}

.student-message img {
  width: 80px;
  height: 80px;
  margin-top: 20px;

}

.student-message h4 {
  margin-left: 15px;
  margin-top: 30px;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
}

.student-message .header-right {
  margin-top: 20px;
  margin-left: 20px;
}

.student-message .header-right .username {

  height: 40px;
  line-height: 40px;
  font-size: 25px;
  font-weight: bolder;
  overflow: hidden;


}

.student-message .header-right .schoole-name {
  text-align: left;
  font-size: 14px;
  color: #666666;
  line-height: 35px;
  font-weight: bolder;
}



.student-message p {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666666;
  margin-top: 70px;
  margin-left: -85px;
}

.qian {
  margin-left: 20px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666666;
}

.xian {
  margin: 0;
  padding: 0;
  color: #999999;
  text-align: center;
}

.xinxi button {
  cursor: pointer;
  margin: 10px 10px;
  width: 125px;
  height: 40px;
  border: 1px solid #999999;
  border-radius: 6px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #999999;
  background-color: #ffffff;
}

.student-content {
  margin-top: 25px;
}

.student-content span {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
}

.student-content .item {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

.el-menu-item {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}
</style>

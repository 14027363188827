<template>
    <!-- <div class="left">

            <studentcenter :userInfo="userinfo"></studentcenter>
        </div> -->
    <div class="course right">
        <div class="course-search">
            <div class="search">
                <img src="../../../assets/img/搜索.png" alt="">
                <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_demand">
            </div>
            <button @click="search_demand">搜索</button>
        </div>
        <div class="course-content">
            <div class="content-header">
                <p>我的需求</p>
                <p>已审核({{ exam_num }})</p>
                <p>待审核({{ dai_exam_num }})</p>
            </div>
            <div class="content-body">
                <div class="body-header">
                    <p>需求ID</p>
                    <p>需求标题</p>
                    <p>状态</p>
                    <p>时间</p>
                    <p>操作</p>
                </div>
                <div class="body-content">
                    <ul>
                        <li v-for="(item, index) in demand_list.list " :key="index">
                            <div class="xuhao">{{ item.id }}</div>
                            <div class="biaoti" v-html="item.title"></div>
                            <div class="zhuangtai" v-if="item.is_exam == 1">已审核</div>
                            <div class="zhuangtai" v-else-if="item.is_exam == 0">待审核</div>
                            <div class="zhuangtai" v-else>审核失败</div>
                            <div class="liulan">{{ item.createtime }}</div>

                            <div class="shanchu">
                                <el-button type="primary" plain icon="el-icon-edit"
                                    @click="edit_demand(item.id)">编辑</el-button>

                                <el-button type="danger" icon="el-icon-delete" @click="delete_demand(index)">删除</el-button>
                            </div>
                        </li>
                    </ul>

                </div>
            </div>

            <div class="page">

                <el-pagination @current-change="demand_change" :current-page="demand_list.current" :page-sizes="[1]"
                    :page-size="demand_list.pagesize" layout="prev, pager, next, jumper" :total="demand_list.total">
                </el-pagination>
            </div>
        </div>



        <div class="edit_model">






        </div>
    </div>
</template>
  
<script>

import studentcenter from '../../../components/student/studentcenter.vue'
import BASEURL from "./../../../request/baseurl"
import store from "./../../../store/index"
import { myDemandTotal, get_my_demand, deleteDemand } from "@/request/student"
export default {
    name: 'studentDemand',
    data() {
        return {
            userinfo: {},
            baseurl: BASEURL,
            is_default_index: '',
            demand_list: {
                total: 0,
                current: 1,
                pagesize: 5,
                list: []
            },
            total: 0,
            exam_num: 0,
            dai_exam_num: 0,
            keyword: ''

        }
    }, components: {
        studentcenter
    },
    created() {
        let is_default_index = store.state.left_menu_default;
        this.is_default_index = is_default_index;
        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);

        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }

        this.initdata();

    },
    methods: {
        async initdata() {

            let userinfo = this.userinfo;
            this.get_demand_list(this.demand_list.current, this.demand_list.pagesize, '');
            //统计
            let result = await myDemandTotal();
            if (result.code === 200) {

                // this.total = result.data.total,
                this.exam_num = result.data.exam_count;
                this.dai_exam_num = result.data.dai_exam_count;



            }


        }, search_demand() {

            let keyword = this.keyword;
            this.get_demand_list(1, this.demand_list.pagesize, keyword);

        }, demand_change(pager) {

            let keyword = this.keyword;

            this.get_demand_list(pager, this.demand_list.pagesize, keyword);

        },




        async get_demand_list(current, pagesize, keyword) {


            let res = await get_my_demand({ current: current, pagesize: pagesize, keyword: keyword });

            if (res.code === 200) {
                let demand_list = this.demand_list;
                demand_list.total = Number(res.data.total);
                demand_list.current = Number(res.data.current);
                demand_list.list = res.data.list;
                this.demand_list = demand_list;
            } else {
                this.$message.error(res.msg);
            }

        },



        //删除需求
        async delete_demand(index) {


            try {
                let res = await this.$confirm('此操作将永久删除该需求, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });

                if (res == 'confirm') {

                    let demand_list = this.demand_list;

                    let demand_info = demand_list.list[index];

                    if (!demand_info) {
                        this.$message.error("出现意外错误");
                    }



                    let result = await deleteDemand({ id: demand_info.id });

                    if (result.code === 200) {
                        this.$message.success("删除成功");
                        demand_list.list.splice(index, 1);

                        this.demand_list = demand_list;
                    } else {

                        this.$message.error(result.msg);
                    }
                }

            } catch (error) {
                console.log(error);

            }



        }, edit_demand(id) {

            this.$router.push('/student/edit_demand?id=' + id);


        }


    }

}
</script>
  
<style lang="less" scoped >
@import url("./../../../assets/css/teacher_common.less");

.center-main {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 40px;

}

.xuhao {
    width: 30px;
    height: 52px;
    text-align: center;
}

.zhuangtai {
    text-align: center;
    width: 85px;
}

input {
    outline: none;
}

.left {
    width: 310px;

}

.body-content ul {
    margin: 0;
    padding-left: 42px;
    padding-bottom: 30px;
}

.body-content ul li {
    list-style: none;
    height: 52px;
}

button {
    cursor: pointer;
    border: 0.5px solid #E9E9E9;
}

.biaoti {
    margin: 0 28px;
    width: 390px;
    text-align: center;
    overflow: hidden;
}

.shuliang {
    text-align: center;
    width: 50px;
    margin: 0 33px;
}

.liulan {
    width: 150px;
    text-align: center;
    margin: 0 45px;
}



.course {

    /* height: 626px; */
    overflow: auto;

    background: #FFFFFF;
    border-radius: 6px;
    // margin-left: 20px;

}

.course-search {
    margin-left: 31px;
    padding-top: 51px;
    display: flex;
}

.course .search {
    position: relative;
    width: 653px;
    height: 50px;
    border: 2px solid #ECECEC;
    border-radius: 21px;
}

.course .search input {
    outline: none;
    margin-left: 55px;
    border-style: none;
    width: 570px;
    height: 45px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ADADAD;
    line-height: 45px;
    margin-top: 2.5px;
}

.course img {
    position: absolute;
    left: 20px;
    top: 11px;
    width: 28px;
    height: 28px;
}

.course-search button {
    cursor: pointer;
    width: 115px;
    height: 50px;
    border: 1px solid #FFFFFF;
    background: #289EB8;
    border-radius: 8px;
    margin-left: 36px;
    margin-top: 0px;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 50px;
}


.content-header {
    display: flex;
    margin-left: 74px;
    margin-top: 20px;
}

.content-header p {
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #323B46;
}

.content-header p:nth-child(2) {
    margin-left: 122px;
    width: 200px;
    text-align: center;
}

.content-header p:nth-child(3) {
    margin-left: 131px;
    width: 200px;
    text-align: center;
}



.content-body {
    margin-left: 35px;
    margin-top: 0px;
    margin-bottom: 50px;
    width: 1105px;
    background: #FFFFFF;
    box-shadow: 0px 0px 65px 0px rgba(51, 51, 51, 0.1);
    border-radius: 16px;
}

.content-body .body-header {
    display: flex;
}

.content-body .body-header p {
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #606060;
}

.content-body .body-header p:nth-child(1) {
    margin-left: 37px;
}

.content-body .body-header p:nth-child(2) {
    margin-left: 149px;
}

.content-body .body-header p:nth-child(3) {
    margin-left: 196px;
}

.content-body .body-header p:nth-child(4) {
    margin-left: 115px;
}

.content-body .body-header p:nth-child(5) {
    margin-left: 180px;
}



.body-content li {
    cursor: pointer;
    display: flex;
    font-size: 18px;
    line-height: 52px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333;
    margin: 10px auto;
}

.body-content li:hover {
    background-color: #DCF6FB;
}


.body-content li div:nth-child(7) {
    display: grid;
    margin-left: 100px;
}

.shanchu {
    height: 35px;
    width: 200px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 7.5px;
    margin-left: 10px;
}

/deep/ .el-tabs__item {
    font-size: 18px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 35px;
}
</style>
<template>
    <!-- <div class="left">

            <studentcenter :userInfo="userinfo"></studentcenter>
        </div> -->
    <div class="course right">
        <div class="course-search">
            <div class="search">
                <img src="../../../assets/img/搜索.png" alt="">
                <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_friend">
            </div>
            <button @click="search_friend">搜索</button>
        </div>
        <div class="course-content">
            <div class="content-header">
            </div>
            <div class="content-body">

                <div class="body-content">
                    <ul>
                        <li class="body-header">
                            <div class="xuhao">序号</div>
                            <div class="biaoti">真题名称</div>
                            <div class="biaoti">课程标题</div>
                            <div class="biaoti">订单号</div>
                            <div class="zhuangtai">学生昵称</div>
                            <div class="zhuangtai">学生电话</div>
                            <div class="caozuo"><span>下单时间</span></div>

                        </li>

                        <li v-for="(item, index) in order_list.list" :key="index">
                            <div class="xuhao">{{ (order_list.current - 1) * (order_list.pagesize) + index + 1 }}</div>
                            <div class="biaoti">{{ item.filename }}</div>
                            <div class="biaoti">{{ item.coursetitle }}</div>
                            <div class="biaoti order-sn">{{ item.order_sn }}</div>
                            <div class="zhuangtai">{{ item.user_info.nickname }}</div>
                            <div class="zhuangtai">{{ item.user_info.mobile }}</div>


                            <div class="caozuo">
                                {{ item.createtime }}

                            </div>
                        </li>

                    </ul>

                </div>
            </div>
        </div>



        <div class="page">

            <el-pagination @current-change="course_change" :current-page="order_list.current" :page-sizes="[1]"
                :page-size="order_list.pagesize" layout="prev, pager, next, jumper" :total="order_list.total">
            </el-pagination>
        </div>
        <Chat @closeChat="close_chat" v-if="is_show_chat" :toUserId="to_user_id" />
    </div>
</template>
  
<script>


import BASEURL from "./../../../request/baseurl"
import store from "./../../../store/index"
import { getOtherOrderList } from "@/request/teacher"
import Chat from "@/components/Chat"
export default {
    name: 'Mystudents',
    data() {
        return {
            userinfo: {},
            baseurl: BASEURL,
            is_default_index: '',
            order_list: {
                total: 0,
                current: 1,
                pagesize: 5,
                list: []
            },
            keyword: '',
            is_show_chat: false,
            to_user_id: 0
        }
    }, components: {

        Chat
    }, created() {

        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);

        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }

        this.initdata();

    }, methods: {

        initdata() {

            this.get_list(this.order_list.current, this.order_list.pagesize, '')

        },
        async get_list(current, pagesize, keyword) {

            let res = await getOtherOrderList({ current: current, pagesize: pagesize, keyword: keyword, type: 3 });
            if (res.code === 200) {
                let order_list = this.order_list;
                order_list.total = Number(res.data.total);
                order_list.current = Number(res.data.current);
                order_list.list = res.data.list;
                this.order_list = order_list;
            } else {
                this.$message.error(res.msg);

                return false;
            }




        }, search_friend() {
            let keyword = this.keyword;
            this.get_list(1, this.course_order.pagesize, keyword);

        }, course_change(pager) {
            let keyword = this.keyword;
            this.get_list(pager, this.order_list.pagesize, keyword);
        }, async del_friend(id, index) {

            try {

                let result = await this.$confirm('你确定要删除此好友吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                if (result == 'confirm') {

                    let res = await DelFriend({ id: id });
                    if (res.code === 200) {

                        let friend_list = this.friend_list;
                        friend_list.list.splice(index, 1);
                        this.friend_list = friend_list;
                        this.$message.success('删除成功');

                    } else {
                        this.$message.error('删除失败');
                    }

                }

            } catch (e) {
                console.log();

            }



        }, gou_tong(to_user_id) {

            this.to_user_id = to_user_id;
            this.is_show_chat = true;



        }, close_chat: function () {
            this.is_show_chat = false;
            this.to_user_id = null;
        },




    }
}
</script>
  
<style lang="less"  scoped >
@import url("./../../../assets/css/teacher_common.less");

.xuhao {
    width: 60px;
    height: 52px;
    text-align: center;
}

.zhuangtai {
    text-align: center;
    width: 170px;
    margin: 0 0px;
    overflow: hidden;
}

.center-main {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 40px;

}

.zuoye {
    display: flex;
    margin-top: 30px;
}

.zuoye .input-jinbi {
    width: 20px;
    height: 20px;
}

.zuoye .video-input {
    width: 500px;
    height: 45px;
    background: #F8F8F8;
    border-radius: 8px;
    border: 1px solid #ECECEC;
}

.zuoye .jinbi {
    margin-top: 10px;
}

.zuoye .delect {
    color: #EA440A;
    line-height: 49px;
}

.bangong {
    width: 45px;
    height: 40px;
    margin-right: 30px;
    margin-top: 5px;
}

.tijiao {
    margin: 20px auto;
    width: 161px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    background: #289EB8;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
}

.tianjia {
    width: 91px;
    height: 80px;
    background: #F8F8F8;
    border-radius: 8px;
    margin: 20px auto;
    color: #ADADAD;
    font-size: 45px;
    text-align: center;
    padding-top: 5px;
}

.jinbi {
    margin-left: 20px;
    display: flex;
    margin-top: 42px;
    margin-right: 20px;
}

.input-jinbi {
    width: 20px;
    height: 20px;
}

input {
    outline: none;
}

.video {
    display: flex;
}

.video video {
    width: 100px;
    height: 100px;
}

.video .video-input {
    margin-top: 28px;
    width: 500px;
    height: 45px;
    background: #F8F8F8;
    border-radius: 8px;
    border: 1px solid #ECECEC;
}

.video .delect {
    margin-top: 40px;
    color: #EA440A;
}

.left {
    width: 310px;

}

.body-content ul {
    margin: 0;
    padding-left: 42px;
    padding-bottom: 30px;
}

.body-content ul li {
    list-style: none;
    height: 52px;
}

button {
    cursor: pointer;
    border: 0.5px solid #E9E9E9;
}

.biaoti {

    width: 220px;
    text-align: center;
    overflow: hidden;
}

.shuliang {
    text-align: center;
    width: 170px;
    margin: 0 0px;
}

.liulan {
    width: 110px;
    text-align: center;

}

.caozuo {
    display: flex;
    margin: 0 0px;
    width: 130px;
    text-align: center !important;

}

.caozuo {
    span {
        width: 120px;
        text-align: center;

    }
}

.bianji {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #289EB8;
    width: 60px;
    text-align: center;
}

.shanchu {
    margin-left: 20px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #EA440A;
    width: 60px;
    text-align: center;
}


.course {

    /* height: 626px; */
    overflow: auto;

    background: #FFFFFF;
    border-radius: 6px;
    // margin-left: 20px;

}

.course-search {
    margin-left: 31px;
    padding-top: 51px;
    display: flex;
}

.course .search {
    position: relative;
    width: 653px;
    height: 69px;
    border: 2px solid #ECECEC;
    border-radius: 21px;
}

.course .search input {
    outline: none;
    margin-left: 55px;
    border-style: none;
    width: 570px;
    height: 65px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ADADAD;
    line-height: 45px;
}

.course img {
    position: absolute;
    left: 20px;
    top: 22px;
    width: 28px;
    height: 28px;
}

.course-search button {
    cursor: pointer;
    width: 115px;
    height: 57px;
    border: 1px solid #FFFFFF;
    background: #289EB8;
    border-radius: 8px;
    margin-left: 36px;
    margin-top: 8px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 45px;
}

.content-header {
    display: flex;
    margin-left: 74px;
    margin-top: 20px;
}

.content-body {
    margin-left: 35px;
    margin-top: 0px;
    margin-bottom: 50px;
    width: 1105px;
    background: #FFFFFF;
    box-shadow: 0px 0px 65px 0px rgba(51, 51, 51, 0.1);
    border-radius: 16px;
}

.content-body .body-header {
    display: flex;
    font-size: 22px !important;
    color: #606060 !important;
}

.content-body li:first-child:hover {
    background-color: #fff;
    cursor: auto;
}

.body-content li {
    cursor: pointer;
    display: flex;
    font-size: 18px;
    line-height: 52px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333;
    margin: 10px auto;
}

.body-content li:hover {
    background-color: #DCF6FB;
}

.body-content li .xin {
    background-color: #289EB8;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.body-content button {
    border-radius: 6px;
}


/deep/ .el-tabs__item {
    font-size: 18px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 35px;
}


.body-header {
    div {
        font-size: 24px !important;
    }

    height: 70px !important;
    line-height: 70px !important;

    .xuhao {
        font-weight: 500 !important;
    }


}

.order-sn {
    overflow: none !important;
    overflow-x: true !important;
}
</style>
<template>
  <div class="main  wrap">
    <div>
      <personal></personal>
    </div>
    <div class="box">
 
      <p class="xinxi">个人信息</p>
      <hr>
      <div class="touxiang">
        <p>当前头像:</p>
        <img :src="ossurl + form_data.avatar" alt="" v-if="form_data.avatar">
        <img src="./../../../assets/img/header.png" alt="" v-else>

        <el-upload class="avatar-uploader" :action="upload_url" :show-file-list="false" :on-success="handleAvatarSuccess"
          :auto-upload="true">

          <el-button size="small" type="primary">点击上传</el-button>

        </el-upload>
      </div>
      <p class="zhuyi">*只支持JPG或PNG,文件大小不能超过2M</p>
      <div class="name">
        <p>昵称:</p>
        <el-input placeholder="请输入内容" v-model="form_data.nickname" maxlength="10" show-word-limit></el-input>

      </div>



       <div class="email">
        <p>签名:</p>
        <el-input placeholder="请输入内容" v-model="form_data.autograph" maxlength="30" show-word-limit></el-input>

      </div>

        
      <div class="sex">
        <p>性别:</p>
        <input id="man" type="radio" :checked="form_data == 0?'checked':''" value="0" name="sex"  v-model="form_data.gender" />
        <p>男</p>
        <input id="woman" type="radio"  :checked="form_data == 1?'checked':''"  value="1" name="sex"  v-model="form_data.gender"/>
        <p>女</p>
      </div>

      <div class="tel">
        <p>手机号:</p><input type="tel" name="" id="" v-model="form_data.mobile">
      </div>

      <div class="email">
        <p>邮箱:</p><input type="email" name="" id="" v-model="form_data.email">
      </div>

      <div class="yuanxiao">
        <p>院校:</p><input type="text" v-model="form_data.gra_schoole">
      </div>
      <p class="zhuyi">*院校为必填项</p>
      <div class="xueli">
        <p>学历:</p><input type="text" v-model="form_data.education">
      </div>
      <p class="zhuyi">*学历为必填项</p>
      <div class="xueke">
        <p>学科:</p>
      

          <el-select v-model="form_data.dis_cate" placeholder="请选择">
            <el-option v-for="item  in cate_list" :key="item.id" :value="item.id" :label="item.name">
            </el-option>
          </el-select>

     
      </div> 

        <div class="jianjie" v-if="userinfo.user_type == 1">
        <p>案例:</p><textarea name="" id="" cols="30" rows="3" v-model="form_data.direction"></textarea>
      </div>

      <div class="jianjie">
        <p>简介:</p><textarea name="" id="" cols="30" rows="10" v-model="form_data.profile"></textarea>
      </div>

      <p class="zhuyi">*介绍，有利于他人更好的了解你</p>
      <div class="baocun"><button @click="submit_form" v-throttle>保存</button></div>

    </div>

  </div>
</template>

<script>

import personal from '../../../components/common/personal.vue'
import { update_userinfo } from "@/request/login.js"
import { getCatelist } from "@/request/course.js"
import BASEURL from "@/request/baseurl"
import OSSURL from "@/request/oss"
import store from "@/store/index"


export default {
  name: 'person',
  data() {
    return {

      form_data: {
        id: '',
        nickname: '',
        gender: 0,
        mobile: '',
        education: '',
        profile: '',
        dis_cate:'',
        avatar: '',
        email: '',
        direction:'',
        gra_schoole: null,
        autograph:''
      },
      baseurl: BASEURL,
      ossurl:OSSURL,
      upload_url: BASEURL + '/api/upload/generaupload',
      userinfo: null,
      cate_list: []


    }
  },
  components: {

    'personal': personal,
  },
  created() {
    var userinfo = localStorage.getItem('Userinfo');
    userinfo = JSON.parse(userinfo);
    console.log(userinfo);
   
    if (userinfo) {


      if (userinfo.expiretime * 1000 < new Date().getTime()) {

        this.$router.push('/login');
        localStorage.clear("Userinfo");
        localStorage.clear('Authorization');

      } else {


        this.userinfo = userinfo;
      }

    } else {
      this.$router.push('/login');
    }
  


    let form_data = this.form_data;
    form_data.id = userinfo.id;
    form_data.nickname = userinfo.nickname;
    form_data.gender = userinfo.gender;
    form_data.mobile = userinfo.mobile;
    form_data.education = userinfo.education;
    form_data.profile = userinfo.profile;
    form_data.direction = userinfo.direction;
    let dis_cate =  Number(userinfo.dis_cate);
    if(dis_cate == 0){
        dis_cate='';
    }
    form_data.dis_cate =  dis_cate;
    form_data.avatar = userinfo.avatar;
    form_data.email = userinfo.email;
    form_data.gra_schoole = userinfo.gra_schoole;
    form_data.autograph = userinfo.autograph

    this.form_data = form_data;



    this.get_cate_list();


  }, methods: {

    async get_cate_list() {

      let res = await getCatelist();
      if (res.code == 200) {
        this.cate_list = res.data;
      } else {
        this.$message.error("出现意外，请尝试刷新页面");
      }


    }, handleAvatarSuccess(res, file) {


      if (res.code == 1) {
        this.$message({
          message: '上传头像成功',
          type: 'success'
        });
        this.form_data.avatar = res.data.url;
      } else {
        this.$message.error(res.msg);
      }

    }, //更新用户信息
    async submit_form() {

      let form_data = this.form_data;
      console.log(form_data);

      if (!form_data.nickname) {
        this.$message.error("昵称不可为空");
        return false;
      }

      if (!form_data.gra_schoole) {
        this.$message.error("请输入院校");
        return false;

      }

      if (!form_data.education) {
        this.$message.error("请输入学历");
        return false;

      }

      if (!form_data.profile) {
        this.$message.error("请输入简介");
        return false;

      }

      let res = await update_userinfo(form_data);

      if (res.code == 200) {


        var userinfo_json = JSON.stringify(res.data);

        //将用户信息存入本地 
        var userinfo = localStorage.getItem('Userinfo');
        store.commit('setUserinfo', { userinfo: userinfo_json })
        this.$message({
          message: '更新成功',
          type: 'success'
        }); 
      }else{
           this.$message.error(res.msg);
        return false;
      }





    }


  }
}
</script>

<style  lang="less" scoped>
.el-input {
  width: 700px;

}

.el-select {
  width: 700px;
}

/deep/.el-select .el-input__inner:focus {
  border-color: #289EB8 !important;
}

/deep/ .el-input__inner {
  background-color: #f8f8f8 !important;

}

/deep/ .el-input__inner:focus {
  border-color: #289EB8 !important;

}

.el-select-dropdown__item.selected {
  color: #289EB8;
  font-weight: 700;
}

.el-select .el-input /deep/ .is-focus .el-input__inner {
  color: #289EB8 !important;
}

.el-input /deep/ .el-input__inner {

  background-color: #F8F8F8 !important;
  height: 45px;
  line-height: 45px;
}

.el-input /deep/ .el-input__count {
  height: 30px;
  line-height: 30px;
  margin-top: 5px;
}

.el-input /deep/ .el-input__count-inner {
  background: #F8F8F8 !important;
  height: 30px;
  line-height: 30px;

}

textarea {
  outline: none;
}

select {
  outline: none;
}

input {
  outline: none;
}

.main {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  margin-top: 40px;
}

.box {

  margin-left: 20px;

  width: 1260px;
 
  background: #FFFFFF;
  border-radius: 6px;
  margin-bottom: 30px;
}

hr {
  width: 1180px;
  height: 1px;
  background: #EEEEEE;
}

.xinxi {
  display: flex;
  margin-left: 39px;
  padding-top: 36px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.touxiang {
  display: flex;
  margin-left: 130px;
  margin-top: 30px;
}

.touxiang p {
  padding-top: 20px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.touxiang img {
  width: 97px;
  height: 97px;
  margin-left: 40px;
  border-radius: 50%;
}

.touxiang button {
  cursor: pointer;
  margin-top: 28px;
  margin-left: 25px;
  width: 120px;
  height: 40px;
  border: 1px solid #289EB8;
  color: #289EB8;
  background-color: #F8F8F8;
  border-radius: 6px;
}

.zhuyi {
  display: flex;
  margin-left: 230px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #FF8620;
}

.name {
  display: flex;
  margin-left: 155px;
}

.name p {
  margin-right: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.name input {
  width: 700px;
  height: 40px;
  background: #F8F8F8;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
}

.sex {
  display: flex;
  margin-left: 157px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sex p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  margin-right: 31px;
}

.tel {
  display: flex;
  margin-left: 143px;
}

.tel p {
  margin-right: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.tel input {
  padding-left: 15px;
  width: 680px;
  height: 40px;
  background: #F8F8F8;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
}

.tel input:focus {
  border-color: #289EB8;
}

.email {
  display: flex;
  margin-left: 157px;
  margin-top: 30px;
}

.email p {
  margin-right: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.email input {
  padding-left: 15px;
  width: 680px;
  height: 40px;
  background: #F8F8F8;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
}

.email input:focus {
  border-color: #289EB8;
}

.yuanxiao {
  display: flex;
  margin-left: 157px;
  margin-top: 30px;
}

.yuanxiao p {
  margin-right: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.yuanxiao input {
  padding-left: 15px;
  width: 680px;
  height: 40px;
  background: #F8F8F8;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
}

.yuanxiao input:focus {
  border-color: #289EB8;
}

.xueli {
  display: flex;
  margin-left: 157px;
  margin-top: 30px;
}

.xueli p {
  margin-right: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.xueli input {
  padding-left: 15px;
  width: 680px;
  height: 40px;
  background: #F8F8F8;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
}

.xueli input:focus {
  border-color: #289EB8;
}

.xueke {
  display: flex;
  margin-left: 157px;
  margin-top: 30px;
}

.xueke p {
  margin-right: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.xueke select {
  padding-left: 15px;
  width: 680px;
  height: 40px;
  background: #F8F8F8;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
}

.jianjie {
  display: flex;
  margin-left: 157px;
  margin-top: 30px;
}

.jianjie p {
  margin-right: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.jianjie textarea {
  padding: 15px;
  width: 680px;
  height: 100px;
  font-size: 18px;
  background: #F8F8F8;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
}

.baocun {
  display: flex;
}

.baocun button {
  margin: 30px auto;
  cursor: pointer;
  width: 195px;
  height: 40px;
  background: #289EB8;
  border-radius: 6px;
  color: #EEEEEE;
  border: 1px solid #EEEEEE;
}
</style>
<template>
    <!-- <div class="left">

            <teachercenter :userInfo="userinfo"></teachercenter>
        </div> -->
    <div class="course right">
        <div class="course-search">
            <div class="search">
                <img src="../../../assets/img/搜索.png" alt="">
                <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_home">
            </div>
            <button @click="search_home">搜索</button>
        </div>
        <div class="course-content">
            <div class="content-header">
                <p>全部课程</p>
                <p>已购买({{ list.total }})</p>

            </div>
            <div class="content-body">

                <div class="body-content">
                    <ul>

                        <li class="body-header">
                            <div class="xuhao">序号</div>
                            <div class="biaoti">真题</div>
                            <div class="biaoti">课程标题</div>
                            <div class="biaoti">订单</div>

                            <div class="shuliang">价格</div>



                        </li>
                        <li v-for="(item, index) in list.list" :key="index">
                            <div class="xuhao" @click="prew_word(index)">{{ index + 1 }}</div>
                            <div class="biaoti" @click="prew_word(index)">{{ item.title }}</div>
                            <div class="biaoti" @click="prew_word(index)">{{ item.course_title }}</div>
                            <div class="biaoti" @click="prew_word(index)">{{ item.order_sn }}</div>
                            <div class="shuliang" @click="prew_word(index)">{{ item.price }}</div>


                        </li>
                    </ul>

                </div>
            </div>

            <div class="page">

                <el-pagination @current-change="pra_change" :current-page="list.page" :page-sizes="[1]"
                    :page-size="list.pagesize" layout="prev, pager, next, jumper" :total="list.total">
                </el-pagination>
            </div>
        </div>


        <!-- 文件预览 -->
        <div class="preview-model" v-if="is_show_word">
            <Preview :previewData="active_word" @closePrew="close_privew"></Preview>

        </div>



    </div>
</template>
  
<script>


import BASEURL from "./../../../request/baseurl"
import store from "./../../../store/index"
import { getOtherOrderList } from "@/request/student.js"
import Preview from '@/components/Preview';
import axios from 'axios';
export default {
    name: 'homework',
    data() {
        return {
            userinfo: {},
            baseurl: BASEURL,
            is_default_index: '',
            showModal: false,
            activeName: 'first',
            list: {
                total: 0,
                list: [],
                current: 1,
                pagesize: 5
            },
            exam_num: 0,
            dai_exam_num: 0,
            active_index: -1,
            pra_info: {},
            upload_url: BASEURL + '/api/upload/generaupload',
            keyword: '',
            course_id: null,
            active_word: {
                type: 'doc',
                id: null,
                price: 0
            },
            is_show_word: false
        }
    },
    components: {

        Preview
    },

    created() {

        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);

        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }

        let that = this;

        this.get_list(that.list.current, that.list.pagesize, '');

    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },

        async search_home() {

            let keyword = this.keyword;

            this.get_list(1, this.list.pagesize, keyword);




        }, async pra_change(pager) {
            let keyword = this.keyword;
            let that = this;


            this.get_list(pager, that.list.pagesize, keyword);





        },

        async get_list(current, pagesize, keyword) {
            let that = this;

            let res = await getOtherOrderList({ current: current, pagesize: pagesize, keyword: keyword, type: 3 });


            if (res.code === 200) {

                //    let  pra_list = JSON.parse(JSON.stringify(this.pra_list));   
                let list = this.list;

                list.total = Number(res.data.total)
                list.current = Number(res.data.current)
                list.list = res.data.list;

                this.list = list;


            } else {
                this.$message.error(res.msg);
            }

        },

        //预览word
        prew_word(index) {

            this.is_show_word = true;
            let info = this.list.list[index];
            let price = 0;
            //1作业练习 2模拟试题 模拟真题
            let active_word = { id: info.other_id, type: 3, price: price }
            this.active_word = active_word
            this.is_show_word = true



        }, close_privew() {
            this.is_show_word = false
        }






    }

}
</script>
  
<style lang="less"  scoped >
@import url("./../../../assets/css/teacher_common.less");


h4 {
    margin-left: 30px;
    margin-top: 5px;
}

li {
    list-style: none;
}


.xuhao {
    width: 80px;
    height: 52px;
    text-align: center;
}

input {
    outline: none;
}

.left {
    width: 310px;

}

.body-content ul {
    margin: 0;
    padding-left: 42px;
    padding-bottom: 30px;
}

.body-content ul li {
    list-style: none;
    height: 52px;
}

button {
    cursor: pointer;
    border: 0.5px solid #E9E9E9;
}

.biaoti {
    margin: 0;
    width: 280px;
    text-align: center;
    overflow: hidden;
}

.shuliang {
    text-align: center;
    width: 110px;
    margin: 0 0px;
}

.course {
    width: 1150px;
    /* height: 626px; */
    overflow: auto;
    height: auto;
    background: #FFFFFF;
    border-radius: 6px;


}

.course-search {
    margin-left: 31px;
    padding-top: 51px;
    display: flex;
}

.course .search {
    position: relative;
    width: 653px;
    height: 50px;
    border: 2px solid #ECECEC;
    border-radius: 21px;
}

.course .search input {
    outline: none;
    margin-left: 55px;
    border-style: none;
    width: 570px;
    height: 45px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ADADAD;
    line-height: 45px;
    margin-top: 2.5px;
}

.course .search img {
    position: absolute;
    left: 20px;
    top: 11px;
    width: 28px;
    height: 28px;
}

.course-search button {
    cursor: pointer;
    width: 115px;
    height: 50px;
    border: 1px solid #FFFFFF;
    background: #289EB8;
    border-radius: 8px;
    margin-left: 36px;
    margin-top: 0px;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 50px;
}


.content-header {
    display: flex;
    margin-left: 74px;
    margin-top: 20px;
}

.content-header p {
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #323B46;
}

.content-header p:nth-child(2) {
    margin-left: 122px;
    width: 160px;
    text-align: center;
}

.content-header p:nth-child(3) {
    margin-left: 131px;
    width: 160px;
    text-align: center;
}

.content-body {
    margin-left: 35px;
    margin-top: 0px;
    margin-bottom: 50px;
    width: 1105px;
    background: #FFFFFF;
    box-shadow: 0px 0px 65px 0px rgba(51, 51, 51, 0.1);
    border-radius: 16px;
}

.content-body .body-header {
    display: flex;
    width: 100%;
    font-size: 22px !important;
    font-family: PingFang SC;
    font-weight: 500;
    color: #606060 !important
}

.content-body .body-header:hover {
    background-color: #fff;
    cursor: auto;
}

.body-content li {
    cursor: pointer;
    display: flex;
    font-size: 18px;
    line-height: 52px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333;
    margin: 10px auto;
}

.body-content li:hover {
    background-color: #DCF6FB;
}

/deep/ .el-tabs__item {
    font-size: 18px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 35px;
}

.preview-model {
    position: fixed;
    top: 10%;
    width: 1200px;
    height: 630px;
    left: 18.75%;
    z-index: 1000;
    background: #ffffff;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 68px 0px rgba(51, 51, 51, 0.26);
    border-radius: 12px;


}
</style>
<template>

 <div class="main-content wrap">
  
       <div class="left">
              <studentcenter :userInfo="userinfo"  @loginout="out_login"></studentcenter>
            </div> 

        <div class="right">

              <router-view></router-view>
            
        </div>

        
          

 </div> 

</template>
<script>  
import {logout} from "./../../../request/login"
import  studentcenter from './../../../components/student/studentcenter'
import BASEURL from  "./../../../request/baseurl"
import store from "./../../../store/index"
export default {
 
 data() {
    return {
         userinfo:{},
         baseurl:BASEURL,
    }  
  },components:{
        studentcenter
  },created(){

          var  userinfo =  localStorage.getItem('Userinfo');
         userinfo = JSON.parse(userinfo);
         
            if(userinfo){
         
       
            if(userinfo.expiretime*1000 <  new Date().getTime()){

                    this.$router.push('/login');
                    localStorage.clear("Userinfo");
                    localStorage.clear('Authorization');

            }else{  
             

                this.userinfo = userinfo;
            }
        
        }else{
            this.$router.push('/login');
        }

  },methods:{ 
         out_login:function(){
            let _this = this;
        var userinfo = _this.userinfo;
        //退出登录
        logout({id:userinfo.id}).then(respone=>{

              if(respone.code === 1){
                 localStorage.clear("Userinfo");
            
                localStorage.clear('Authorization');   
                  //退出登录，同时去除store 中的信息
                 store.commit('setUserinfo',{userinfo:''})
                 store.commit("changeLogin",{Authorization:''})
                this.$router.push('/login'); 
              }else{
                    
                    console.log(9999);
              }

        }).catch(err=>{
            console.log(err);
        })

      
            
       },


  }  
      
}
</script>
<style scoped > 


     .main-content{
          display: flex;
          display: -webkit-flex;
          flex-direction: row;
          margin-top: 40px;
     }
     .right {
         margin-left: 20px;
          width: 1170px;
height: 985px;
background: #FFFFFF;
border-radius: 6px;
     }
     .el-empty {
          padding: 145px 0;
     }

</style>
<template>
  <div class="big-box">
    <div class="box wrap">
      <div class="content">
        <div class="input-content">
        <img src="../../assets/img/搜索.png" alt="">
        <input type="text" placeholder="输入您要搜索需求的关键字" name="keyword" v-model="keyword" @keyup.enter="search_demand">
        <button @click="search_demand">搜需求</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'teachsearch',
  data() {
    return {
      keyword: ''
    }
  },
  components: {

  }, methods: {

    search_demand: function () {
      let keyword = this.keyword;
      this.$router.push('/demandlist?keyword=' + keyword)
    }

  }
}
</script>

<style scoped>
input {
  outline: none;
}

.big-box {
  width: 100%;
  min-height: 80vh;
  background: url(../../assets/img/teacher.png) no-repeat;
  background-size: 100% 100%;

}

.box {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/teacher.png) no-repeat;
}

input::-webkit-input-placeholder {

  /* placeholder字体大小 */
  font-size: 18px;
} 

.content {
  position: relative;
  padding-top: 350px;
 width: 840px;
 margin:  0 auto;
 display: flex;
 display: -webkit-flex;
 flex-direction: row;
  
 
  
}

.input-content{
  width: 840px;
  margin: 0 auto;
     height: 60px;
     display: flex;
     display: -webkit-flex;
     flex-direction: row;
}

.content img {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 368px;
  left: 8px;
} 

.content input {
  padding-left: 40px;
  margin-right: 15px;
  width: 666px;
  height: 60px;
  font-size: 18px;
  background: #FFFFFF;
  border: none;
  border-radius: 9px;
  line-height: 60px;
  
}  

.content button {
  cursor: pointer;
  border: 0.5px solid #FFFFFF;
  width: 113px;
   height: 60px;
  background: #FFFFFF;
  border-radius: 10px;
  font-size: 20px;
}
</style>
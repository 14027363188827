<template>
    <footer id="foot-bottom" class="footer">

        <div class="wrap">
            <div class="foot-content flex-row">
                <div class="foot-left">
                    <div> 优课栗栗（江西）教育咨询有限公司</div>

                    <div>Copyright © 2021-2023 Inc. All rights reserved版权所有<a target="_blank" title="51la网站统计"
                            href="https://v6.51.la/land/20DOeBlRHBlr6BsZ"><img src="https://sdk.51.la/icon/3-4.png"></a>
                    </div>


                    <div><a href="https://beian.miit.gov.cn" target="_blank" style="color:#999999;text-decoration:none;">赣ICP备2022005727号</a>   </div>
                </div>
                <div class="foot-right">
                    <div class="sama">
                        <div>客服热线：18501298187</div>
                        <div>客服邮箱：80963999@qq.com</div>
                        <div>扫一扫关注微信公众号</div>
                    </div>
                    <div class="qrcode">

                        <img src="./../../assets/img/qrcode.jpg" alt="">
                    </div>

                    <div>



                    </div>
                </div>

            </div>
        </div>
    </footer>
</template>
<script>
export default {
    name: "Footer",
    data() {
        return {};
    },
    mounted() {

    },
    methods: {}
};
</script>

<style lang="less" scoped>
.footer {
    width: 100%;
    height: 144px;
    background-color: #333333;

    font-size: 14px;

}

.wapp {
    height: 100%;
}

.foot-content {
    position: relative;
    top: 37px;
    left: 0;
    display: block;

    height: 70px;
    width: 100%;
    color: #999999;
    display: flex;
    justify-content: space-between;
    flex-direction: row;


}

.foot-content .foot-left {

    text-align: left;
    padding-left: 55px;
    width: 400px;

}

.foot-content .foot-left :nth-child(1) {
    margin-bottom: 13px;
}

.foot-content .foot-left :nth-child(2) {
    margin-bottom: 13px;
}


.foot-content .foot-right {
    width: 400px;

    text-align: left;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    padding-right: 60px;


}

.foot-content .foot-right .sama {
    width: 250px;
    margin-right: 30px;
}

.foot-content .foot-right .sama :nth-child(1) {
    margin-bottom: 13px;
}

.foot-content .foot-right .sama :nth-child(2) {
    margin-bottom: 13px;
}

.foot-content .qrcode {
    width: 76px;
    margin-left: 20px;
}

.foot-content .qrcode img {
    width: 76px;
    height: 76px;

}

img {
    margin-bottom: 0 !important;
    vertical-align: middle;
}
</style>
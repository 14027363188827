import {get, post } from './http.js'

export const GuanInfo = p => get('center/guan/get_guan_user_info', p)
export const GuanCurri = p => post('center/guan/guan_curri', p)

export const UserInfo = p => get('center/guan/get_user_info', p)


export const deleteTrendFile = p => get('center/teacher/delete_trend_file', p);

export const getOfficialFile = p => post('center/guan/get_official_file', p);

export const getOfficialBanner = p => post('center/guan/get_officaal_bannerlist', p);

export const getOfficialTrends = p => post('center/guan/official_trends_list', p);

export const buyOfficialFile = p => post('center/guan/buy_official_file', p);
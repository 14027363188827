<template>
    <!-- <div class="left">

            <studentcenter :userInfo="userinfo"></studentcenter>
        </div> -->
    <div class="course right">
        <div class="course-search">
            <div class="search">
                <img src="../../../assets/img/搜索.png" alt="">
                <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_friend">
            </div>
            <button @click="search_friend">搜索</button>
        </div>
        <div class="course-content">
            <div class="content-header">
            </div>
            <div class="content-body">

                <div class="body-content">
                    <ul>
                        <li class="body-header">
                            <div class="xuhao">序号</div>
                            <div class="biaoti">教师昵称</div>
                            <div class="zhuangtai">性别</div>
                            <div class="zhuangtai">操作</div>

                        </li>

                        <li v-for="(item, index) in friend_list.list" :key="index">
                            <div class="xuhao">{{ (friend_list.current - 1) * (friend_list.pagesize) + index + 1 }}</div>
                            <div class="biaoti">{{ item.nickname }}</div>
                            <div class="zhuangtai">{{ item.gender == 1 ? '女' : '男' }}</div>


                            <div class="caozuo">
                                <div class="bianji" @click="gou_tong(item.friend_id)">沟通</div>
                                <div class="shanchu" @click="del_friend(item.id, index)">删除</div>
                            </div>
                        </li>

                    </ul>

                </div>
            </div>
        </div>



        <div class="page">

            <el-pagination @current-change="friend_change" :current-page="friend_list.current" :page-sizes="[1]"
                :page-size="friend_list.pagesize" layout="prev, pager, next, jumper" :total="friend_list.total">
            </el-pagination>
        </div>
        <Chat @closeChat="close_chat" v-if="is_show_chat" :toUserId="to_user_id" />
    </div>
</template>
  
<script>


import BASEURL from "./../../../request/baseurl"
import store from "./../../../store/index"
import { getMyFriend, delMyFriend } from "@/request/student"
import Chat from "@/components/Chat"
export default {
    name: 'studentFriends',
    data() {
        return {
            userinfo: {},
            baseurl: BASEURL,
            is_default_index: '',
            friend_list: {
                total: 0,
                current: 1,
                pagesize: 5,
                list: []
            },
            keyword: '',
            is_show_chat: false,
            to_user_id: 0
        }
    }, components: {

        Chat
    }, created() {
        let is_default_index = store.state.left_menu_default;
        this.is_default_index = is_default_index;
        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);

        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }

        this.initdata();

    }, methods: {

        initdata() {

            this.get_list(this.friend_list.current, this.friend_list.pagesize, '')

        },
        async get_list(current, pagesize, keyword) {

            let res = await getMyFriend({ current: current, pagesize: pagesize, keyword: keyword });
            if (res.code === 200) {
                let friend_list = this.friend_list;
                friend_list.total = Number(res.data.total);
                friend_list.current = Number(res.data.current);
                friend_list.list = res.data.list;
                this.friend_list = friend_list;
            } else {
                this.$message.error(res.msg);

                return false;
            }




        }, search_friend() {
            let keyword = this.keyword;
            this.get_list(1, this.friend_list.pagesize, keyword);

        }, friend_change(pager) {
            let keyword = this.keyword;
            this.get_list(pager, this.friend_list.pagesize, keyword);
        }, async del_friend(id, index) {

            try {

                let result = await this.$confirm('你确定要删除此好友吗, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });
                if (result == 'confirm') {

                    let res = await delMyFriend({ id: id });
                    if (res.code === 200) {

                        let friend_list = this.friend_list;
                        friend_list.list.splice(index, 1);
                        this.friend_list = friend_list;
                        this.$message.success('删除成功');

                    } else {
                        this.$message.error('删除失败');
                    }

                }

            } catch (e) {
                console.log();

            }



        }, gou_tong(to_user_id) {

            this.to_user_id = to_user_id;
            this.is_show_chat = true;



        }, close_chat: function () {
            this.is_show_chat = false;
            this.to_user_id = null;
        },




    }
}
</script>
  
<style lang="less"  scoped >
@import url("./../../../assets/css/teacher_common.less");

.xuhao {
    width: 60px;
    height: 52px;
    text-align: center;
}

.zhuangtai {
    text-align: center;
    width: 230px;
    margin: 0 0px;
    overflow: hidden;
}

.center-main {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 40px;

}

input {
    outline: none;
}

.left {
    width: 310px;

}

.body-content ul {
    margin: 0;
    padding-left: 42px;
    padding-bottom: 30px;
}

.body-content ul li {
    list-style: none;
    height: 52px;
}

button {
    cursor: pointer;
    border: 0.5px solid #E9E9E9;
}

.biaoti {

    width: 500px;
    text-align: center;
    overflow: hidden;
}

.shuliang {
    text-align: center;
    width: 170px;
    margin: 0 0px;
}

.liulan {
    width: 110px;
    text-align: center;

}

.caozuo {
    display: flex;
    margin-left: 50px;
    width: 120px;
    text-align: center !important;

}

.bianji {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #289EB8;
    width: 60px;
    text-align: center;
}

.shanchu {
    margin-left: 20px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #EA440A;
    width: 60px;
    text-align: center;
}


.course {

    /* height: 626px; */
    overflow: auto;

    background: #FFFFFF;
    border-radius: 6px;
    // margin-left: 20px;

}

.course-search {
    margin-left: 31px;
    padding-top: 51px;
    display: flex;
}

.course .search {
    position: relative;
    width: 653px;
    height: 69px;
    border: 2px solid #ECECEC;
    border-radius: 21px;
}

.course .search input {
    outline: none;
    margin-left: 55px;
    border-style: none;
    width: 570px;
    height: 65px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ADADAD;
    line-height: 45px;
}

.course img {
    position: absolute;
    left: 20px;
    top: 22px;
    width: 28px;
    height: 28px;
}

.course-search button {
    cursor: pointer;
    width: 115px;
    height: 57px;
    border: 1px solid #FFFFFF;
    background: #289EB8;
    border-radius: 8px;
    margin-left: 36px;
    margin-top: 8px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 45px;
}

.content-header {
    display: flex;
    margin-left: 74px;
    margin-top: 20px;
}

.content-body {
    margin-left: 35px;
    margin-top: 0px;
    margin-bottom: 50px;
    width: 1105px;
    background: #FFFFFF;
    box-shadow: 0px 0px 65px 0px rgba(51, 51, 51, 0.1);
    border-radius: 16px;
}

.content-body .body-header {
    display: flex;
}

.content-body .body-header:hover {
    background-color: #fff;
    cursor: auto;
}

.body-content li {
    cursor: pointer;
    display: flex;
    font-size: 18px;
    line-height: 52px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333;
    margin: 10px auto;
}

.body-content li:hover {
    background-color: #DCF6FB;
}


/deep/ .el-tabs__item {
    font-size: 18px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 35px;
}


.body-header {
    div {
        font-size: 24px !important;
    }

    height: 70px !important;
    line-height: 70px !important;

    .xuhao {
        font-weight: 500 !important;
    }


}
</style>
<!-- eslint-disable no-redeclare -->
<template>
  <div class="box">
    <div class="wrap">
      <div class="content">
        <div class="login-content">

          <div class="login-header">
            <div class="active">注册</div>
          </div>

          <div class="login-main">

            <div class="login-input">
              <input type="text" placeholder="请输入手机号" v-model="fromData.mobile">
            </div>

            <div class="login-input">
              <input type="password" name="" id="" placeholder="请输入密码" v-model="fromData.pwd">
            </div>

            <div class="code">
              <input type="text" placeholder="请输入验证码" v-model="fromData.code">
              <button @click="getCode" :disabled="!show">
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{ count }} s</span>
              </button>
            </div>
            <div class="operation">
              <button class="login-button" @click="register">注册</button>
              <button class="registered" @click="$router.push('/login')">返回登陆</button>
            </div>
            <div class="agreement">
              <el-checkbox-group v-model="is_agree" class="is-agree">
                <el-checkbox></el-checkbox>

              </el-checkbox-group>


              <p>注册即同意</p>
              <p class="active" @click="$router.push('/agreement')">《优课理理网站协议》</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>  
<script>

import { register, send_msg } from "../../request/login"
import store from "../../store/index"
const TIME_COUNT = 60;
export default {
  name: 'register',
  data() {
    return {
      fromData: {
        mobile: '',
        pwd: '',
        code: '',
        openid: '',

      },
      userinfo: {},
      show: true,
      count: 60,
      timer: null,
      is_agree: false

    }
  }, created() {
    var openid = this.$route.query.openid;
    this.fromData.openid = openid;
  },
  methods: {

    change_login: function (e) {
      console.log(e);
      console.log(9999);
      if (e === 1) {

        this.is_teacher = false;
      } else {
        this.is_teacher = true;
      }

    }, register: function () {
      let that = this;

      if (!that.is_agree) {
        this.$message.error('请先勾选协议');

        return false;
      }
      var data = this.fromData;
      var user_type = 0;
      if (that.is_teacher) {
        user_type = 1;
      }

      if (!data.mobile) {
        this.$message.error('电话号码不能为空');

        return false;
      }

      var reg = /^(13[0-9]|19[0-9]|17[0-9]|14[0-9]|15[0-9]|16[0-9]|18[0-9])\d{8}$/;
      if (data.mobile.length == 11) {
        if (!reg.test(data.mobile)) {
          this.$message.error('电话号码格式不对');

          return false;
        }
      } else {
        this.$message.error('电话号码格式不对');
        return false;
      }

      console.log(data.code);
      if (!data.code) {
        this.$message.error('请输入验证码');
        return false;

      }

      register({ mobile: data.mobile, password: data.pwd, code: data.code, user_type: user_type, openid: data.openid }).then(res => {

        if (res.code === 1) {
          var userinfo = res.data.userinfo

          var userinfo_json = JSON.stringify(userinfo);

          // 将用户token保存到vuex中

          store.commit("changeLogin", { Authorization: userinfo.token })
          //将用户信息存入本地

          store.commit('setUserinfo', { userinfo: userinfo_json })
          if (user_type === 1) {
            that.$router.push('/selectRole');
          } else {

            that.$router.push('/selectRole');
          }

        } else {

          this.$message.error(res.msg);

        }

      })


    }, getCode() {

      let that = this;


      // eslint-disable-next-line no-redeclare
      var formData = that.fromData;


      if (!formData.mobile) {
        this.$message.error('请输入电话号码');

        return false;
      }
      var reg = /^(13[0-9]|19[0-9]|17[0-9]|14[0-9]|15[0-9]|16[0-9]|18[0-9])\d{8}$/;
      if (formData.mobile.length == 11) {
        if (!reg.test(formData.mobile)) {

          this.$message.error('电话号码格式不对');
          return false;
        }
      } else {
        this.$message.error('电话号码格式不对');

        return false;
      }
      send_msg({ mobile: formData.mobile, event: 'register' }).then(res => {


        if (res.code === 200) {
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000)
          }
        } else {


          this.$message.error(res.msg);

        }




      })


    }, go_agreement() {

      this.$router.push('/agreement');
    }



  }

}
</script>

<style lang="less"   scoped>
/deep/ body {
  background-color: #ffffff !important;
}

.box {
  background: url(../../assets/img/login.png) no-repeat !important;
  background-size: 100% 100% !important;
  min-height: 85vh;
  width: 100%;
}

.wrap {
  display: flex;
  justify-content: flex-end;
}

.content {
  margin-top: 15%;
  background-color: #FFFFFF;
  width: 480px;
  height: 573px;
  box-shadow: 0 0 10px 0;
  border-radius: 5px;

  .login-content {
    padding: 20px;
  }

  .login-header {
    display: flex;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;

    .active {
      color: #289EB8;
    }

    div {
      margin: 0 20px 10px 10px;
      color: #999999;
    }
  }

  .login-main {
    margin-top: 10px;

    .login-input {
      width: 420px;
      height: 60px;
      border-radius: 20px;
      margin-bottom: 20px;

      input {
        background-color: #F8F8F8;
        border: 0.5px #eee solid;
        outline: none;
        width: 420px;
        height: 60px;
        border-radius: 10px;
        font-size: 20px;
        padding: 0 0 0 10px;
      }
    }

    .code {
      margin: 0;
      padding: 0;
      margin-bottom: 20px;

      input {
        float: left;
        background-color: #F8F8F8;
        border: 0.5px #eee solid;
        outline: none;
        width: 220px;
        height: 60px;
        border-radius: 10px;
        font-size: 20px;
        padding: 0 0 0 10px;
      }

      button {
        margin-right: 7px;
        padding: 0;
        float: right;
        width: 156px;
        height: 60px;
        border-radius: 5px;
        border: 1px solid #eeeeee;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        cursor: pointer;

      }
    }

    .operation {
      margin-top: 170px;
      padding-left: 10px;
      width: 420px;
      height: 60px;

      button {
        width: 195px;
        height: 60px;
        margin: 0 -6px;
      }

      .login-button {
        cursor: pointer;
        border-radius: 5px;
        float: left;
        background-color: #289EB8;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        border: 1px solid #ffffff;
      }

      .registered {
        cursor: pointer;
        float: right;
        border-radius: 5px;
        background-color: #FFFFFF;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #289EB8;
        border: 1px solid #289EB8;
      }
    }

    .agreement {
      margin-top: 20px;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;

      .is-agree {
        margin-right: 10px;

        /deep/ .el-checkbox__inner {
          border-radius: 50%;
        }
      }

      p {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        display: inline;
        margin: 0;
      }

      .active {
        cursor: pointer;
        color: #FF8620;
      }
    }
  }
}
</style>
/* eslint-disable no-undef */
import Vue from 'vue'
import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => error)
}
import index from './../page/index'
import login from './../page/center/login' //登录界面
import wxlogin from './../page/center/wxlogin' //微信登陆
import forget from './../page/center/forget' //忘记密码
import changerole from './../page/center/select' //选择角色
import register from './../page/center/register.vue' //注册
import agreement from '../page/center/agreement.vue' //同意条款
import changepaw from '../page/studentperson/changepaw.vue' //改变密码
//官方主页

import authority from "../page/authority.vue"

//官方动态
import officialStrends from "../page/official_strends.vue"
//老师发布课程
import editing from '../page/teacherperson/editing.vue'

import personal from '../components/common/personal.vue' //个人信息和修改密码左边框
import teachsearch from '../page/teacherperson/teachsearch.vue' //老师搜索
import stusearch from '../page/studentperson/stusearch.vue' //学生搜索
//课程列表
import courselist from '../page/course/courselist.vue'
//需求列表
import demandlist from '../page/demand/demand.vue'
//需求详情
import demandDetail from '../page/demand/demandDetail.vue'
import courseDetail from '../page/course/coursedetail.vue'

//老师个人中心相关页面
import teachercenter from '../page/center/teacher/teachercenter.vue' //老师个人中心

import teacherindex from "../page/center/teacher/index.vue"

import courseedit from "../page/center/teacher/editcourse"

import teacherCourse from '../page/center/teacher/course' //课程管理
import teacherMedio from "../page/center/teacher/medio" //视频
import teacherhomework from '../page/center/teacher/homework' //作业
import teacherMocktes from '../page/center/teacher/Mocktes' //试题
import Imitate from "../page/center/teacher/imitate"
import teachercoursereg from '../page/center/teacher/course-reg'
import teacherVidorder from '../page/center/teacher/Vidorder'
import teacherJoborder from '../page/center/teacher/Joborder'
import teacherQueorder from '../page/center/teacher/Queorder'
import teacherColneed from '../page/center/teacher/Colneed'
import teacherSiteinf from '../page/center/teacher/Siteinf'
import teacherMystudents from '../page/center/teacher/Mystudents'
import teacherRecharge from "../page/center/teacher/recharge.vue"
import teacherIncome from "../page/center/teacher/income.vue"
import quimitate from "../page/center/teacher/imitateorder.vue"






import person from '../page/center/teacher/person.vue'
import utalk from '../page/utalk/utalk.vue'
//学生个人中心

import studentindex from "../page/center/student/index.vue"
import studentcenter from "../page/center/student/studentcenter.vue"
import studentCourse from '../page/center/student/course'
//视频
import studentmedio from '../page/center/student/medio'
//模拟真题
import studentDocument from '../page/center/student/Document'
import studentSiteinf from '../page/center/student/Siteinf'
import studentFriends from '../page/center/student/Friends'
import studentColneed from '../page/center/student/Colneed'
import studentDemand from "../page/center/student/demand"
//作业
import studentHomeWork from "../page/center/student/homework.vue"

//历年真题

import studentImitate from "../page/center/student/imitate.vue"

//学生购买官方文件
import studentOfficialFile from "../page/center/student/Official_file.vue"


import studentRecharge from "../page/center/student/recharge.vue"

import studentIncome from "../page/center/student/income.vue"

import editDemand from "../page/center/student/eidtdemand.vue"

//学生发布
import release from '../page/center/student/release'

//动态
import strends from "../page/strends"




Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [{
            path: '/authority',
            component: authority

        },
        {
            path: '/official_strends',
            component: officialStrends

        },
        {
            path: '/index',
            component: index
        }, {
            path: '/login',
            component: login
        },
        {
            path: '/wxlogin', //微信登录
            component: wxlogin
        },
        {
            path: '/forget', //忘记密码
            component: forget
        },
        {
            path: '/selectRole', //选择角色
            component: changerole
        },
        {
            path: '/register', //重置密码
            component: register
        },
        {
            path: '/agreement', //同意条约
            component: agreement
        },
        {
            path: '/changepaw', //改密码
            component: changepaw,
        }

        , {
            path: '/personal', //左边个人中心组件
            component: personal,
        }, {
            path: '/teachsearch', //老师搜索
            component: teachsearch,
        }, {
            path: '/stusearch', //学生搜索
            component: stusearch,
        }, {
            path: '/courselist', //老师搜索详情
            component: courselist,
        },
        {
            path: '/demandlist', //老师需求详情
            component: demandlist,
        },

        {
            path: '/demandDetail', //老师需求详情
            component: demandDetail,
        }, {
            path: '/coursedetail', //课程详情
            component: courseDetail,
        },
        {
            path: '/utalk',
            component: utalk,
        },
        //老师个人中心相关页面
        {
            path: '/teacher',
            component: teachercenter,
            redirect: '/index',
            children: [{
                    path: 'index', //课程管理
                    name: "teacherindex",
                    component: teacherindex
                },

                {
                    path: 'course', //课程管理
                    name: "tearcherCourse",
                    component: teacherCourse
                },
                {
                    path: 'medio', //视频管理
                    name: "teacherMedio",
                    component: teacherMedio
                },

                {
                    path: 'homework', //作业练习
                    name: 'homework',
                    component: teacherhomework
                },
                {
                    path: 'Mocktes', //模拟试题
                    name: 'Mocktes',
                    component: teacherMocktes
                },
                //历年真题

                {
                    path: 'imitate',
                    name: 'Imitate',
                    component: Imitate
                },



                {
                    path: '/teacher/course-reg', //课程报名
                    name: 'coursereg',
                    component: teachercoursereg
                },
                {
                    path: 'Vidorder', //视频订购
                    name: 'Vidorder',
                    component: teacherVidorder
                },
                {
                    path: 'Joborder', //作业订购
                    name: "Joborder",
                    component: teacherJoborder
                },
                {
                    path: 'Queorder', //试题订购
                    name: 'Queorder',
                    component: teacherQueorder
                },
                {
                    path: 'QuImitateorder', //历年订购
                    name: 'QuImitate',
                    component: quimitate
                },
                {
                    path: 'Colneed', //收藏需求
                    name: "Colneed",
                    component: teacherColneed
                },
                {
                    path: 'Siteinf', //站内信息
                    name: 'Siteinf',
                    component: teacherSiteinf
                },
                {
                    path: 'Mystudents', //我的学生
                    name: 'Mystudents',
                    component: teacherMystudents
                }, {
                    path: 'Recharge', //我的U币
                    name: 'Recharge',
                    component: teacherRecharge

                }, {
                    path: 'Income', //收支明细
                    name: 'Income',
                    component: teacherIncome

                }

            ]
        },



        {

            path: '/teacher/edit_coursee', //课程管理

            component: courseedit
        },



        {
            path: '/teacher/person', //个人信息面板
            component: person,
        }, {

            path: '/teacher/release',

            component: editing,
        }, //学生个人中心



        {
            path: '/student',
            redirect: 'index',
            component: studentcenter,
            children: [{
                    path: 'index', //学生课程
                    name: "studentindex",
                    component: studentindex
                },
                {
                    path: 'course', //学生课程
                    name: 'studentCourse',
                    component: studentCourse
                },
                {
                    path: 'homework', //学生课程
                    name: 'studentHomeWork',
                    component: studentHomeWork
                },

                {
                    path: 'medio', //学生视频
                    name: 'studentmedio',
                    component: studentmedio
                },
                {
                    path: 'Document', //学生视频
                    name: 'studentDocument',
                    component: studentDocument
                },
                {
                    path: 'imitate',
                    name: "imitateImitate",
                    component: studentImitate


                },
                {
                    path: 'officialfile',
                    name: 'officialfile',
                    component: studentOfficialFile
                },

                {
                    path: 'Siteinf', //站内信息 
                    name: 'studentSiteinf',
                    component: studentSiteinf
                },
                {
                    path: 'Friends', //好友列表
                    name: "studentFriends",
                    component: studentFriends
                },
                {
                    path: 'Colneed', //收藏列表
                    name: 'studentColneed',
                    component: studentColneed
                },
                {
                    path: 'demand', //需求列表
                    name: "studentDemand",
                    component: studentDemand
                },
                {
                    path: 'recharge',
                    name: "studentRecharge",
                    component: studentRecharge

                }, {
                    path: "income",
                    name: "studentIncome",
                    component: studentIncome
                }

            ]

        },
        {
            path: '/student/release', //学生发布
            component: release
        },
        {
            path: "/student/edit_demand",
            name: "editDemand",
            component: editDemand

        },


        {
            path: '/strends',
            component: strends
        },





    ]
});

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {


    if (to.path === '/login' || to.path === '/coursedetail' || to.path === '/stusearch' || to.path === '/strends' || to.path === '/official_strends' ||
        to.path === '/authority' || to.path === '/courselist' || to.path === '/demandlist' || to.path === '/demandDetail' || to.path === '/teachsearch' || to.path === '/index' || to.path === '/select' || to.path === '/student' || to.path === '/register' || to.path === '/forget' || to.path === '/coursedetail' || to.path === '/demanddetail' || to.path === '/agreement' || to.path === '/wxlogin') {
        next();
    } else {
        if (to.path === '/') {
            next('/index');
        } else {
            let token = localStorage.getItem('Authorization');


            if (token === null || token === '') {
                next('/login');
            } else {
                next();
            }
        }

    }

});

export default router;
import {get, post } from './http.js'

//获取我的需求
export const get_my_demand = p => post('student_center/get_my_question', p);

//删除我的需求

export const deleteDemand = p => post("student_center/del_question", p);
//我的需求，统计， 总数，待审核，
export const myDemandTotal = p => get('student_center/get_my_question_total', p)

export const addTeacherFriend = p => post('student_center/add_friend', p);

export const getMyFriend = p => post('student_center/get_friend_list', p);


export const delMyFriend = p => get('student_center/del_friend', p);

export const getMyZhanMessage = p => get("student_center/get_zhan_msg", p);

//删除站内消息（教师与学生共用）
//单个删除

export const delZhanMsg = p => get('student_center/del_zhan_msg_by_id', p);


//批量删除

export const delZhanMsgids = p => get('student_center/del_zhan_pi_messages', p);
//单个标记已阅读

export const readZhanMsgByid = p => get('student_center/is_read_message_id', p);

//单个标记已阅读 

export const readZhanMsgByids = p => get('student_center/is_read_message_ids', p);



//购买课程
export const buyCourse = p => post('student_order/buy_course', p);

//判断是否已经购买课程
export const ConfrimBuyCourse = p => post('student_order/confrim_buy_course', p);


//获取我购买的课程

export const getMyBuyCourse = p => post('student_order/my_buy_course', p);

export const buyCourseOther = p => post('student_order/buy_other_ti', p);

export const confirmBuyOther = p => post('student_order/confirm_buy_other', p);

export const getOtherOrderList = p => post('student_order/get_other_order_list', p);

export const getOfficialOrderList = p => post('student_order/get_official_order_list', p);
export const confirmBuyOfficicalFiel = p => post('student_order/confirm_official_order', p);
<template>
  <div class="main">


 
    <div class="content wrap">
      <div class="search ">
        <Search :catelist="cate_list" @searchcate="search_cate" :cate_id="cate_id"></Search>
      </div>

      <div class="list-content  flex-row">

        <!-- 缩小 -->
        <div class="course-content" v-show="(!is_to_right && !is_demand)">
          <div class="courser-header">

            <div class="icon">
              <img src="./../assets/img/course.png" />
              <span>优课程</span>
            </div>

            <div class="change-tap">
              <!-- <div  :class="is_img_list==1?'active':''"  @click="change_is_img(1)" >图片列表</div>
                       <div  :class="is_img_list==2?'active':''" @click="change_is_img(2)" >文字列表</div>   -->
            </div>

          </div>

          <!-- 课程列表 图片模式-->
            <!-- <vue-data-loading  @infinite-scroll="load_course"  :loading="course_loading"  :completed="course_completed" :distance="200"> -->
          <div class="course-list" v-show="(is_img_list == 1 && !is_to_right)">
           
            <div class="course-info" v-for="(item, index) in course_list.list" v-bind:key="index">

              <div :class="item.is_guanfang == 1 ? 'cover cover-fir' : 'cover'"
                @click="go_course_detail(item.id, item.is_guanfang)">
                <img :src="ossurl + item.cover_img" alt="" :onerror="default_img">
              </div>
              <div class="title small-title" @click="go_course_detail(item.id, item.is_guanfang)"
                v-if="item.is_guanfang != 1">

                {{ item.title }}
              </div>

              <div class="operation  flex-row">

                <div class="caozuo flex-row" @click="go_zan(item.id, index)" v-if="item.is_guanfang != 1">

                  <img src="./../assets/img/zan.png" alt="" v-if="item.is_like == true">
                  <img src="./../assets/img/no-zan.png" alt="" v-else>

                  <div class="number">{{ item.sign_up_num }}</div>
                </div>
                <!-- <div class="caozuo flex-row"  @click="go_course_detail(item.id,item.is_guanfang)" >
                                <img src="./../assets/img/liuyan.png" alt="">

                                <div class="number">{{item.liu_num}}</div>
                              </div> -->
                <div class="caozuo flex-row" v-if="item.is_guanfang != 1">
                  <img src="./../assets/img/see.png" alt="">

                  <div class="number">{{ item.click_num }}</div>
                </div>
                <div class="caozuo flex-row" v-if="item.is_guanfang != 1">
                  <!-- <img src="./../assets/img/see.png" alt=""> -->

                  <div class="number price">￥{{ item.price }}</div>
                </div>



              </div>

              <div class="teacher">

                <div class="teacher-info" @click="go_course_detail(item.id, item.is_guanfang)"
                  v-if="item.is_guanfang != 1">

                  <img src="./../assets/img/header.png" alt="" v-if="!item.userinfo.avatar">

                  <img :src="ossurl + item.userinfo.avatar" alt="" v-else :onerror="default_header_logo">

                  <div @click="go_course_detail(item.id, item.is_guanfang)">
                    {{ item.userinfo.nickname ? item.userinfo.nickname : '---' }}</div>
                </div>
                <div class="exp" @click="go_course_detail(item.id, item.is_guanfang)" v-if="item.is_guanfang != 1">

                  <div>经验值:</div>
                  <div>{{ item.userinfo.exp_num }}</div>


                </div>

                <div class="goutong" @click="show_chat(item.userinfo.id, 1)" v-if="item.is_guanfang != 1">

                  沟通
                </div>


              </div>
            </div>

           
            <div class="page">
              <el-pagination @current-change="changePage" :current-page="course_list.page" :page-sizes="[1]"
                :page-size="course_list.pagesize" layout="prev, pager, next, jumper" :total="course_list.total">
              </el-pagination>


            </div>


          </div>

            <!-- </vue-data-loading> -->

          <!-- 文字列表 -->
          <div class="course-wen-list" v-show="(is_img_list == 2 && !is_to_right)">

            <div class="list-info  flex-row" v-for="(item, index) in course_list_lie.list" v-bind:key="index">
              <div :class="index > 2 ? 'xuhao nomal' : 'xuhao'" @click="go_course_detail(item.id, item.is_guanfang)">{{
                index + 1 }}
              </div>
              <div class="cate" @click="go_course_detail(item.id, item.is_guanfang)">[{{ item.subjectinfo.name }}]</div>
              <div class="title" @click="go_course_detail(item.id, item.is_guanfang)">{{ item.title }}</div>
              <div class="see flex-row" @click="go_course_detail(item.id, item.is_guanfang)" :data-id="item.id">
                <img src="./../assets/img/see.png" alt="">
                <div>{{ item.click_num }}</div>

              </div>
              <div class="goutong" @click="show_chat(item.userinfo.id, 1)">沟通</div>

            </div>
            <div class="page">

              <!-- <Pagination
                  @change="course_list_lie_change"
                  :current="course_list_lie.page"
                  :pageSize="course_list_lie.pagesize"
                  :total="course_list_lie.total"
                    :hideOnSinglePage="hideOnSinglePage"
                  :showQuickJumper="true"
                  :showTotal="false"
                  placement="right"
                  /> -->
            </div>

          </div>


          <!-- <div class="to-right" @click="change_to_right(true)">向右展开</div>
          <div class="to-left" @click="change_to_right(false)">向左展开</div> -->
        </div>

        <div class="demand-content" v-show="(!is_to_right && !is_demand)">
          <!-- 课程需求标题 右边需求未展开 -->

          <div class="demand-header">
            <div class="icon">
              <img src="./../assets/img/demand.png" />
              <span>课程需求</span>

            </div>


          </div>


          <div class="list-content">
            <!-- <vue-data-loading  @infinite-scroll="load_demand"  :loading="demand_loading"  :completed="demand_completed" :distance="200"> -->
            <div class="list-info   flex-row" v-for="(item, index) in demand_list.list" v-bind:key="index">

              <div :class="index > 2 ? 'xuhao nomal' : 'xuhao'" @click="go_demand_detail(item.id)">{{ index + 1 }}</div>
              <div class="title" @click="go_demand_detail(item.id)">{{ item.title }}</div>
              <div class="see flex-row" @click="go_demand_detail(item.id)">
                <img src="./../assets/img/see.png" alt="">
                <div>{{ item.click_num }}</div>

              </div> 

              <div class="goutong" @click="show_chat(item.userinfo.id, 2)">沟通</div>

  
            </div>
         <!-- </vue-data-loading > -->

            <div class="page">

              <el-pagination @current-change="demand_change" :current-page="demand_list.page" :page-sizes="[18]"
                :page-size="demand_list.pagesize" layout="prev, pager, next, jumper" :total="demand_list.total">
              </el-pagination>

            </div>

          </div>
        </div>
      </div>

      <div class="course-zhankai" v-show="(is_to_right && is_demand == false)">
        <div class="courser-header ">

          <div class="icon">
            <img src="./../assets/img/course.png" />
            <span>优课程</span>

          </div>

          <div class="change-tap">
            <!-- <div  :class="is_img_list==1?'active':''"  @click="change_is_img(1)" >图片列表</div>
                                      <div  :class="is_img_list==2?'active':''" @click="change_is_img(2)" >文字列表</div>   -->

          </div>


        </div>
        <div class="course-zhankai-img-list" v-show="(is_img_list == 1)">


          <div class="course-list flex-row">

            <div class="course-info" v-for="(item, index) in course_list_img_lie.list" v-bind:key="index">
              <div class="cover" @click="go_course_detail(item.id, item.is_guanfang)">
                <img :src="ossurl + item.cover_img" alt="" :onerror="default_img">
              </div>
              <div class="title small-title" @click="go_course_detail(item.id, item.is_guanfang)">

                {{ item.title }}
              </div>

              <div class="operation  flex-row">

                <div class="caozuo flex-row" @click="go_zan(item.id, index)">

                  <img src="./../assets/img/zan.png" alt="" v-if="item.is_like == true">
                  <img src="./../assets/img/no-zan.png" alt="" v-else>

                  <div class="number">{{ item.sign_up_num }}</div>
                </div>
                <!-- <div class="caozuo flex-row"  @click="go_course_detail(item.id,item.is_guanfang)" >
                                <img src="./../assets/img/liuyan.png" alt="">

                                <div class="number">{{item.liu_num}}</div>
                              </div> -->
                <div class="caozuo flex-row" @click="go_course_detail(item.id, item.is_guanfang)">
                  <img src="./../assets/img/see.png" alt="">

                  <div class="number">{{ item.click_num }}</div>
                </div>
                <div class="caozuo flex-row" @click="go_course_detail(item.id, item.is_guanfang)">
                  <!-- <img src="./../assets/img/see.png" alt=""> -->

                  <div class="number price">￥{{ item.price }}</div>
                </div>

              </div>

              <div class="teacher">

                <div class="teacher-info" @click="go_course_detail(item.id, item.is_guanfang)">

                  <img src="./../assets/img/header.png" alt="" v-if="!item.userinfo.avatar">
                  <img :src="ossurl + item.userinfo.avatar" alt="" v-else>
                  <div @click="go_course_detail(item.id, item.is_guanfang)">
                    {{ item.userinfo.nickname ? item.userinfo.nickname : '---' }}</div>
                </div>
                <div class="exp" @click="go_course_detail(item.id, item.is_guanfang)">

                  <div>经验值:</div>
                  <div>{{ item.userinfo.exp_num }}</div>


                </div>

                <div class="goutong" @click="show_chat(item.userinfo.id, 1)">

                  沟通
                </div>

              </div>

              <div class="page" >

                <el-pagination @current-change="course_list_img_change" :current-page="course_list_img_lie.page"
                  :page-sizes="[8]" :page-size="course_list_img_lie.pagesize" layout="prev, pager, next, jumper"
                  :total="course_list_img_lie.total">
                 </el-pagination>
                   <Pagination
                          @change="course_list_img_change"
                          :current="course_list_img_lie.page"
                          :pageSize="course_list_img_lie.pagesize"
                          :total="course_list_img_lie.total"
                            :hideOnSinglePage="hideOnSinglePage"
                          :showQuickJumper="true"
                          :showTotal="false"
                          placement="right"
                          /> 
              </div>
  
            </div>

          </div> 
        </div>
        <div class="couse-zhankai-wen-list" v-show="(is_img_list == 2)">

          <div class="course-list">

            <div class="course-list-info flex-row" v-for="(item, index) in course_list_lie.list" v-bind:key="index">

              <div class="flex-row list-info-left" @click="go_course_detail(item.id, item.is_guanfang)">
                <div :class="index > 2 ? 'xuhao nomal' : 'xuhao'">{{ index + 1 }}</div>
                <div class="cate">[{{ item.subjectinfo.name }}]</div>
                <div class="course-title">{{ item.title }}</div>
              </div>
              <div class="flex-row list-info-right">

                <div class="teacher flex-row" @click="go_course_detail(item.id, item.is_guanfang)">
                  <img src="./../assets/img/header.png" alt="">
                  <div @click="go_course_detail(item.id)">{{ item.userinfo.nickname ? item.userinfo.nickname : '---' }}
                  </div>
                </div>
                <div class="collect flex-row icon" @click="go_course_detail(item.id, item.is_guanfang)">
                  <img src="./../assets/img/collect.png" alt="">
                  <div>{{ item.sign_up_num }}</div>

                </div>
                <div class="see  flex-row icon">
                  <img src="./../assets/img/see.png">
                  <div>{{ item.click_num }}</div>


                </div>
                <div class="chat" @click="show_chat(item.userinfo.id, 1)"> 沟通</div>

              </div>

              <div class="page">

                 <Pagination
                                @change="course_list_lie_change"
                                :current="course_list_lie.page"
                                :pageSize="course_list_lie.pagesize"
                                :total="course_list_lie.total"
                                  :hideOnSinglePage="hideOnSinglePage"
                                :showQuickJumper="true"
                                :showTotal="false"
                                placement="right"
                                /> 
              </div>
            </div>
          </div>
        </div>
        <div class="to-right" @click="change_to_right(true)">向右展开</div>
        <div class="to-left" @click="change_to_right(false)">向左展开</div>

      </div>


      <div class="demand-zhankai" v-show="is_demand">


        <div class="demand-header">
          <div class="icon">
            <img src="./../assets/img/demand.png" />
            <span>课程需求</span>

          </div>
        </div>
        <div class="demand-content">
          <div class="list-info   flex-row" v-for="(item, index) in demand_list.list" v-bind:key="index">

            <div :class="index > 2 ? 'xuhao nomal' : 'xuhao'" @click="go_demand_detail(item.id)">{{ index + 1 }}</div>
            <div class="title" @click="go_demand_detail(item.id)">{{ item.title }}</div>


            <div class="flex-row demand-right">
              <div class="see flex-row" @click="zan_demand(item.id, index)">
                <img src="./../assets/img/no-zan.png" alt="" v-if="!item.is_like">
                <img src="./../assets/img/zan.png" alt="" v-else>
                <div>{{ item.like_num }}</div>

              </div>

              <div class="see flex-row" @click="go_demand_detail(item.id)">
                <img src="./../assets/img/see.png" alt="">
                <div>{{ item.click_num }}</div>

              </div>
              <div class="goutong" @click="show_chat(item.userinfo.id, 2)">沟通</div>
            </div>
          </div>
          <div class="page">

            <el-pagination
             @current-change="demand_change" 
             :current-page="demand_list.page" :page-sizes="[20]"
              :page-size="demand_list.pagesize" layout="prev, pager, next, jumper" :total="demand_list.total">
            </el-pagination>

          </div>
        </div>
        <div class="to-right" @click="change_to_right(true)">向右展开</div>
        <div class="to-left" @click="change_to_right(false)">向左展开</div>
      </div>

    </div>


    <!-- 聊天弹框 -->
    <Chat @closeChat="close_chat" v-if="is_show_chat" :toUserId="to_user_id"></Chat>

  </div>
</template>
<script>
import Vue from 'vue'
import VueDataLoading from 'vue-data-loading'
import Search from "./../components/common/Search";
import OSSURL from '@/request/oss.js'

import { getCatelist, couserselist, add_like_course } from "./../request/course";
import { demandlist, likeDemand } from "./../request/demand";
import BASEURL from "./../request/baseurl";
import Chat from "./../components/Chat"
import store from "@/store/index"


export default {
  metaInfo: {
    title: '考研信息分类平台', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '考研信息,考研,学生提问,考研题库，历年真题，九江考研'
    },
    {
      name: "考研信息分类平台",
      content: "为老师，与学生教学学习，互动平台",
    },]
  },
  name: "index",
  data() {
    return {
      default_img: 'this.src="' + require('./../assets/img/no-img.png') + '"',
      default_header_logo: 'this.src="' + require('./../assets/img/header.png') + '"',
      cate_list: [
        { id: '', name: '', pid: '' }
      ],
      keywrod: '',
      course_list: {
        'total': 0,
        'page': 1,
        'pagesize': 12,
        'list': [
        ]

      },
      course_list_img_lie: {
        'total': 0,
        'page': 1,
        'pagesize': 8,
        'list': [

        ]
      },
      course_list_lie: {
        'total': 0,
        'page': 1,
        'pagesize': 20,
        'list': []


      },
      demand_list: {
        'total': 0,
        'page': 1,
        'pagesize': 57,
        'list': []
      },
      is_to_right: false,
      is_img_list: 1,
      is_demand: false,
      cate_id: 0,

      hideOnSinglePage: false,
      baseurl: BASEURL,
      is_show_chat: false,
      dialogFormVisible: '',
      userinfo: {},
      is_login: false,
      to_user_id: 0,
      ossurl: OSSURL,
      course_loading:false,    //课程下拉加载
      course_completed:false,
      demand_loading:false,     //需求下拉加载
      demand_completed:false

    }


  }, components: {
    Search,

    Chat,
    VueDataLoading

  },


  //自定义指令
  directives: {



  }, computed: {
    getUserInfo() {


      return this.$store.state.Userinfo
    }

  }, watch: {
    userinfo(val, oldval) {

      if (val) {
        this.userinfo = val;
      }

    }



  },

  created() {
    var userinfo = localStorage.getItem('Userinfo');


    //如果拥有用户信息，则直接进入个人中心
    try {

      if (userinfo) {

        //判断时间是否过期
        userinfo = JSON.parse(userinfo);


        if (userinfo.expiretime * 1000 < new Date().getTime()) {
          this.is_login = false;
        } else {
          this.is_login = true;
          this.userinfo = userinfo
        }

      }
    } catch (error) {

    }

    this.get_cate_list()
    this.get_course_list(1, 12, '', '', 1);
    this.get_demand_list(1, 57, '');

  }, methods: {

    //获取课程列表(未展开图片列表 type 1表示课程收缩的形式 2 表示展开图片形式 3 表示文字列表形式)

    async get_course_list(current, pagesize, searchkerarod, cate_id, type,is_search=false) {
      let that = this;
      let res = await couserselist({ current: current, pagesize: pagesize, keyword: searchkerarod, cate_id: cate_id });

      if (res.code === 200) {
        let course_list = {};
        if (type == 1) {
          course_list = this.course_list;
        } else if (type == 2) {
          course_list = this.course_list_img_lie
        } else if (type == 3) {

          course_list = this.course_list_lie;

        }

        course_list.total = Number(res.data.total);
        course_list.page = Number(res.data.current);
        course_list.total_page = Number(res.data.pages);
 course_list.list  = res.data.list;
        // if(is_search){
        //      course_list.list  = res.data.list;
        // }else{
        //      course_list.list = course_list.list.concat(res.data.list);
        // }
       
        if(res.data.list.length< this.course_list.pagesize){
            this.course_completed = true;
        }
        this.course_loading = false;
        if (type == 1) {
          this.course_list = course_list;
        } else if (type == 2) {
          this.course_list_img_lie = course_list;
        } else {
          this.course_list_lie = course_list;
        }

  
      }
    },

    //下拉加载课程 
    load_course(){ 
          this.course_loading = true;
             let course_list = this.course_list;
             let current = Number(course_list.page)+1;
             let searchkerarod = this.searchkerarod;

       this.get_course_list(current, course_list.pagesize, searchkerarod, this.cate_id, 1) 
              // this.get_officicial_list(current,trends_list.pagesize,this.trends_keyword);
         
    },

    //下拉加载课程需求

    load_demand(){
            this.demand_loading = true;
             let demand_list = this.demand_list;
             let current = Number(demand_list.page)+1;
            

          this.get_demand_list(current, demand_list.pagesize, '', this.cate_id) 
    },

    async get_cate_list() {
      let that = this;
      let res = await getCatelist()
      if (res.code == 200) {
        that.cate_list = res.data

      }

    },async get_demand_list(page, pagesize, keyword, cate_id,is_search=false) {

      let that = this;

      let res = await demandlist({ current: page, pagesize: pagesize, keyword: keyword, cate_id: cate_id });
      let demand_list = this.demand_list;
      if (res.code == 200) {
 
       
        this.demand_loading = false;
          demand_list.total = Number(res.data.total);
        demand_list.page = Number(res.data.current);

    //     if(is_search){
    //  demand_list.list = res.data.list
    //     }else{
    //         demand_list.list =    demand_list.list.concat(res.data.list)
    //     }
      demand_list.list = res.data.list
       
        if(res.data.list.length<demandlist.pagesize){
           this.demand_completed = true;
        }
      

        this.demand_list = demand_list;

      
      }



    },


    //切换发送请求(图片列表 文字列表 切换)
    change_is_img(e) {


      //let id = e.current
      let course_list_lie = this.course_list_lie;
      let keywrod = this.keywrod;
      let cate_id = this.cate_id;
      if (cate_id == 0) {
        cate_id = ''
      }

      let id = e;
      if (e == 2) {

        this.get_course_list(course_list_lie.page, course_list_lie.pagesize, keywrod, cate_id, 3);

      }


      this.is_img_list = id;



    }, //分页发送请求(课程列表图片模式)

    changePage(pager) { // 分页器回调


      let keywrod = this.keywrod;
      let cate_id = this.cate_id;
      if (cate_id == 0) {
        cate_id = '';
      }
      this.get_course_list(pager, this.course_list.pagesize, keywrod, cate_id, 1);

    }, go_course_to_page(e) {


    },



    //文字列表模式
    course_list_lie_change(pager) {
      let keywrod = this.keywrod;
      let cate_id = this.cate_id;
      if (cate_id == 0) {
        cate_id = '';
      }
      this.get_course_list(pager.page, this.course_list_lie.pagesize, keywrod, cate_id, 3);
      // this.$emit('change', pager)


    },
    course_list_img_change(pager) {
      let keywrod = this.keywrod;
      let cate_id = this.cate_id;
      if (cate_id == 0) {
        cate_id = '';
      }
      this.get_course_list(pager, this.course_list_img_lie.pagesize, keywrod, cate_id, 2);
      // this.$emit('change', pager)
    },
    //课程需求的分页  
    demand_change(pager) {

      let cate_id = this.cate_id;
      if (cate_id == 0) {
        cate_id = '';
      }
      this.get_demand_list(pager, this.demand_list.pagesize, cate_id);
      // this.$emit('change', pager)


    },


    //改变

    change_to_right(e) {

      let is_to_right = this.is_to_right;

      let is_demand = this.is_demand;
      let is_img_list = this.is_img_list

      if (!is_demand) {

        //课程未展开状态

        if (is_to_right == false) {


          if (!e) {
            this.is_demand = true
          } else {

            let keywrod = this.keywrod;
            let cate_id = this.cate_id;
            if (cate_id == 0) {
              cate_id = '';
            }

            this.get_course_list(this.course_list_img_lie.page, this.course_list_img_lie.pagesize, keywrod, cate_id, 2);
          }


        } else {

          if (is_demand) {

            if (e) {
              this.is_demand = false;

            }



          } else {


          }






        }

        this.is_to_right = e;

      } else {

        if (e) {
          this.is_demand = false;
          if (e) {
            this.is_to_right = false;
          } else {
            this.is_to_right = false;
          }

        } else {
          this.is_to_right = e;
        }



      }




    }, close_chat: function () {
      this.is_show_chat = false;
    }, show_chat: function (to_user_id, user_type) {
      let userinfo = this.userinfo;

      if (userinfo == null) {
        this.$message.warning("需要先登录哦")
        return false;
      }
      if (Object.keys(userinfo).length < 1) {

        this.$message.warning("需要先登录哦")
        return false;
      }


      if (user_type == 1) {

        if (userinfo.user_type == 1) {

          this.$message.warning("对不起，您不是学生")
          return false;

        }
      } else {

        if ((userinfo.user_type != 1)) {

          this.$message.warning("对不起，您不是教师")
          return false;

        }

      }


      this.to_user_id = to_user_id;
      this.is_show_chat = true;

    }, go_course_detail: function (e, is_guanfang) {

      let id = e;
      if (is_guanfang != 1) {
        this.$router.push('/coursedetail?id=' + id);
      } else {
        this.$router.push('/official_strends');
      }



    },//跳转至需求详情
    go_demand_detail: function (id) {



      this.$router.push("/demandDetail?id=" + id);


    }, search_cate(e) {
      this.cate_id = e;
      let is_img_list = this.is_img_list;
      this.course_loading = true;
      this.course_completed =false;
    
      this.demand_loading=true,     //需求下拉加载
      this.demand_completed =false
      if (is_img_list == 1 && !this.is_demand && this.is_to_right) {
        this.get_course_list(1, 8, '', e, 2);
      } else {
        this.get_course_list(1, 4, '', e, 1,true);
      }

      this.get_demand_list(1, 18, '', e,true)

    },//点赞 

    async go_zan(couse_id, index) {

      let userinfo = this.userinfo;

      if (userinfo == null) {
        this.$message.warning("需要先登录哦")
        return false;
      }
      if (Object.keys(userinfo).length < 1) {

        this.$message.warning("需要先登录哦")
        return false;
      }
      let res = await add_like_course({ course_id: couse_id, type: 0 });

      let is_to_right = this.is_to_right;
      let is_demand = this.is_demand;

      if (res.code == 200) {
        if (!is_to_right && !is_demand) {

          let course_list = this.course_list

          if (course_list.list[index].is_like) {
            course_list.list[index].is_like = false;
            course_list.list[index].sign_up_num = Number(course_list.list[index].sign_up_num) - 1;
          } else {
            course_list.list[index].is_like = true;
            course_list.list[index].sign_up_num = Number(course_list.list[index].sign_up_num) + 1;
          }

          this.course_list = course_list;

        } else if (is_to_right && is_demand == false) {


          let course_list_img_lie = this.course_list_img_lie
          if (course_list_img_lie.list[index].is_like) {
            course_list_img_lie.list[index].sign_up_num = Number(course_list_img_lie.list[index].sign_up_num) - 1;
          } else {
            course_list_img_lie.list[index].sign_up_num = Number(course_list_img_lie.list[index].sign_up_num) + 1;
          }

          course_list_img_lie.list[index].is_like = !course_list_img_lie.list[index].is_like;
          this.course_list_img_lie = course_list_img_lie;
        }

      } else {
        this.$message.warning(res.msg)
        return false;
      }

    },
    //给需求点赞

    async zan_demand(demand_id, index) {

      let userinfo = this.userinfo;

      if (userinfo == null) {
        this.$message.warning("需要先登录哦")
        return false;
      }

      if (Object.keys(userinfo).length < 1) {

        this.$message.warning("需要先登录哦")
        return false;
      }

      let res = await likeDemand({ demand_id: demand_id });
      if (res.code === 200) {

        let demand_list = this.demand_list;
        if (demand_list.list[index].is_like) {
          demand_list.list[index].like_num = Number(demand_list.list[index].like_num) - 1;
        } else {
          demand_list.list[index].like_num = Number(demand_list.list[index].like_num) + 1;
        }
        demand_list.list[index].is_like = !demand_list.list[index].is_like;

        this.demand_list = demand_list

      } else {
        this.$message.warning(res.msg)
        return false;
      }


    }


  }
}
</script>

<style lang="less" scoped>
@import "./../assets/css/index.less";

.content {
  margin-bottom: 30px;
}

.wrapper {

  justify-content: center;

  width: 500px;
  height: 300px;
  background-color: red;
  z-index: 100;

}

.cover-fir img {
  width: 400px !important;
  height: 345px !important;
  border: 1px solid #e9e9e9;
}
</style>
<template>
  <div class="content">
      <div class="login-content">

        <div class="login-select">

          <div class="student">

            <button @click="selectTime(1)"  :class="user_type== 0?'student-btn':''">我是学生</button>
            <p >*我是学生需要参加课程</p>

          </div>
          <div class="teacher">

            <button @click="selectTime(2)"  :class="user_type== 1?'teacher-btn':''">我是教师</button>
            <p>*我是教师需要传授课程</p>

          </div>
         
            
        </div>
        <div class="clear"></div>
        <div class="login-footer">
          <p>*选择角色一经注册不可更改</p>
          <button @click="confirm_role">确定</button>
        </div>
      </div>
  </div>
</template>
 
<script>


import {select_role} from "@/request/login"
import store  from "@/store/index"
export default {
  name:"changerole",
  data() {
    return {
      beforeTarget:{},
      user_type:-1,
      Userinfo:null,
      is_login:false
    }
  },created(){

        try {
                    var  userinfo =  localStorage.getItem('Userinfo');
                  
                 
                    if(userinfo){
                      
                         userinfo = JSON.parse(userinfo);
                        //判断时间是否过期
                        if(userinfo.expiretime*1000  < new Date().getTime()){
                              this.is_login = false;
                              this.$router.push('/login');
                        }else{
                            this.is_login = true;
                            this.Userinfo = userinfo;
                          
                        }
                    
                    }
               }catch{
                  console.log(1111);
               }

  },computed:{
                getUserInfo() {
                   
                  
                    return store.state.Userinfo
                }
    
    }, watch:{ 
       getUserInfo: {
          handler:function(val){
               console.log(val);
               console.log('userinfo');
              if(!val){
                     this.is_login = false;
              }else{
                   this.is_login = true;  
                    
                   this.Userinfo = JSON.parse(val);
              }
                  
            }
       }  
 
  },



  methods: {
    selectTime(user_type) {
      console.log(user_type);
    let local_user_type = this.user_type;
    if(user_type==1){
         this.user_type = 0;
    }else{
        this.user_type = 1;
    }  
  
   }, async confirm_role(){

     let user_type = this.user_type;
      if(user_type ==-1){
         this.$message.error('请选择角色');
          
      }
  
      let Userinfo = this.Userinfo;

       if(!Userinfo ){
           this.$router.push('/login');
       }
        

  let res = await select_role({user_id:Userinfo.user_id,user_type:(Number(user_type)+1)});
  
 
  if(res.code == 200){
  if(user_type ==0){ 
       Userinfo.user_type = 0;
   }else{
        Userinfo.user_type = 1;
   }    
    
    let userinfo_json = JSON.stringify(Userinfo);
       store.commit('setUserinfo', { userinfo: userinfo_json })
        this.$router.push('/student/index');

        
  }else{
       this.$message.error(res.msg);
  }

   






       
   }
  },
}
</script>

<style lang="less" scoped>
.content {
 
  height: 800px;
  .login-content {
  background-color:#FFFFFF;
  width: 647px;
  height: 382px;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px 0px rgba(43,117,235,0.2);
  border-radius: 6px;
  margin: 200px auto 0;
  .login-footer {
    padding-top: 40px;
     width: 195px;
     margin: 0 auto;
    p {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #FF8620;
    }
    button {
      cursor: pointer;
      width: 195px;
      height: 60px;
      border-radius: 6px;
      border: 1px solid #ffffff;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      background-color: #f8f8f8;
    }
    button:hover {
      cursor: pointer;
      width: 195px;
      height: 60px;
      border-radius: 6px;
      border: 1px solid #ffffff;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f8f8f8;
      background-color: #289EB8;
    }
  }

  .login-select {
    .show {
      color: #f8f8f8;
      background-color: #289EB8;
    }
    p {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #999999;
    }
    div {
      float: left;
    }
    button {
      cursor: pointer;
      margin-top: 74px;
      width: 195px;
      height: 60px;
      border-radius: 6px;
      border: 1px solid #ffffff;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      background-color: #f8f8f8;
    }
    .student {
  margin-left: 57px;

}
.teacher {
  float:right;
  margin-right: 58px;
}

.login-select::after{
 clear: both;
}
  }
}
}

.clear{
  clear: both;
}

.student-btn{
   background-color:  #289EB8  !important;
   color:#ffffff !important;
}
.teacher-btn{
   background-color:  #289EB8 !important;
   color:#ffffff !important;
}
</style>
<template>
  <div class="wrap box">
    <div class="nav">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/stusearch' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>学生搜课程</el-breadcrumb-item>
        <el-breadcrumb-item>
          <p class="xuqiu">课程详情</p>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="video-content">
        <div class="video" v-if="coursedetail.mediolist[0]">
          <video-player :options="videoOptions" class="video" v-if="videoOptions.sources[0].src" />
          <div class="no-paly" v-else>视频暂时无法播放</div>

        </div>
        <div class="cover-img" v-else>

          <img :src="ossurl + coursedetail.cover_img" :onerror="default_img" />
        </div>
        <div class="product">
          <h4>{{ coursedetail.title }} </h4>
          <p class="intro">课程简介：{{ coursedetail.intro }}</p>
          <div class="he">
            <div class="price">￥{{ coursedetail.price }}</div>
            <div>{{ coursedetail.buy_num }}人最近购买</div>
            <div>{{ coursedetail.goods_rate }}%好评</div>
          </div>

          <div class="hefoot">
            <img src="../../assets/img/no-zan.png" alt="" class="img1" v-if="!coursedetail.is_like"
              @click="like_course(coursedetail.id)">
            <img src="../../assets/img/zan.png" alt="" class="img1" v-else @click="like_course(coursedetail.id)">
            <p @click="like_course(coursedetail.id)">{{ coursedetail.sign_up_num }}</p>
            <img src="../../assets/img/转发.png" alt="" class="img2">
            <p>{{ coursedetail.click_num }}人次</p>
            <button class="shou" @click="collect_course" v-if="!coursedetail.is_atten">收藏</button>
            <button class="shou" @click="collect_course" v-else>已收藏</button>
            <button class="gou" @click="gou_tong(coursedetail.userinfo.id)">沟通</button>
            <button class="gou buy" @click="go_buy">购买</button>
          </div>

        </div>
      </div>
    </div>
    <div class="pro">
      <div class="course-pro">
        <div class="title">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="课程详情" name="first">
              <div class="content-pro" v-html="coursedetail.content">

              </div>
            </el-tab-pane>
            <el-tab-pane label="课程视频" name="meido-list">
              <div class="lianxi course-medio-list" v-for="(item, index) in coursedetail.mediolist" :key="index">
                <div class="el-icon-video-camera-solid  meido-img" @click="play_meido_model(index)"></div>
                <div class="biaoge" @click="play_meido_model(index)">
                  <p>{{ item.title }}</p>
                </div>
                <div class="mianfei" @click="yulan(index, 2)">
                  <p v-if="item.price > 0">{{ item.price }}U币</p>
                  <p v-else>免费</p>
                </div>
                <div class="xiazai goumai">
                  <p @click="go_buy_question(index, 4)">立即购买</p>
                </div>

                <!-- <div class="xiazai"><p @click="download(index,3)">下载</p></div> -->
              </div>
            </el-tab-pane>


            <el-tab-pane label="作业练习" name="third">
              <div class="lianxi" v-for="(item, index) in coursedetail.pralist" :key="index">
                <img src="../../assets/img/icon/word.png" alt="">
                <div class="biaoge" @click="yulan(index, 1)">
                  <p>{{ item.title }}</p>
                </div>
                <div class="mianfei" @click="yulan(index, 1)">
                  <p v-if="item.price > 0">{{ item.price }}U币</p>
                  <p v-else>免费</p>
                </div>

                <div class="xiazai">
                  <p @click="download(index, 3)">下载</p>
                </div>
                <div class="xiazai goumai">
                  <p @click="go_buy_question(index, 1)">立即购买</p>
                </div>

              </div>

            </el-tab-pane>

            <el-tab-pane label="模拟试题" name="fourth">
              <div class="lianxi" v-for="(item, index) in coursedetail.questionlist" :key="index">
                <img src="../../assets/img/icon/word.png" alt="">
                <div class="biaoge" @click="yulan(index, 2)">
                  <p>{{ item.title }}</p>
                </div>
                <div class="mianfei" @click="yulan(index, 2)">
                  <p v-if="item.price > 0">{{ item.price }}U币</p>
                  <p v-else>免费</p>
                </div>
                <div class="xiazai">
                  <p @click="download(index, 2)">下载</p>
                </div>
                <div class="xiazai goumai">
                  <p @click="go_buy_question(index, 2)">立即购买</p>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="历年真题" name="five">
              <div class="lianxi" v-for="(item, index) in coursedetail.imitate" :key="index">
                <img src="../../assets/img/icon/word.png" alt="">
                <div class="biaoge" @click="yulan(index, 3)">
                  <p>{{ item.title }}</p>
                </div>
                <div class="mianfei" @click="yulan(index, 3)">
                  <p>免费</p>
                </div>
                <div class="xiazai">
                  <p @click="download(index, 2)">下载</p>
                </div>
                <div class="xiazai goumai">
                  <p @click="go_buy_question(index, 3)">立即购买</p>
                </div>
              </div>
            </el-tab-pane>


            <el-tab-pane label="学员评论" name="six">
              <p class="haoping">好评率{{ coursedetail.goods_rate }}%</p>
              <div class="pinglun-header">

                <el-tabs v-model="evaluateName" type="card" @tab-click="change_evaluate">
                  <el-tab-pane label="全部" name="evaluate1">

                    <div class="evaluate-content" v-if="eva_all.total > 0">
                      <div class="evaluate-list" v-for="(item, index) in eva_all.list " :key="index">

                        <div class="eva-left">

                          <img src="./../../assets/img/header.png" alt="" v-if="!item.userinfo.avatar">
                          <img :src="ossurl + item.userinfo.avatar" alt="" v-else>

                        </div>

                        <div class="eva-right">
                          <div class="user-nickname">
                            昵称： {{ item.userinfo.nickname ? item.userinfo.nickname : '网友' }}


                          </div>
                          <div class="eva-time">
                            <div class="time">{{ item.create_time_text }}</div>
                            <div class="line"></div>

                            <div class="pingxing">
                              <img src="./../../assets/img/xingxing.png" alt="" v-for="key in item.spot" :key="key">

                            </div>
                          </div>

                          <div class="eva-info">

                            {{ item.content }}


                          </div>

                        </div>


                      </div>


                      <div class="eva-draw-down">
                        <div>向下展开</div>
                        <div class="jian-down">
                          <img src="./../../assets/img/to-down.png" alt="">
                        </div>


                      </div>

                      <div class="eva-btn"><button @click="go_eva">评价</button></div>

                    </div>

                    <!-- 没有评论 -->
                    <div class="evaluate-content" v-else>

                      <div class="no-comment">
                        <div class="no-comment">
                          <el-empty description="暂无评论"></el-empty>
                        </div>
                      </div>



                      <!-- <div class="eva-draw-down">
                                 <div>向下展开</div>
                                 <div class="jian-down">
                                     <img src="./../../assets/img/to-down.png" alt="">
                                 </div>


                         </div> -->

                      <div class="eva-btn"><button @click="go_eva">评价</button></div>
                    </div>



                  </el-tab-pane>


                  <!-- 好评 -->
                  <el-tab-pane label="好评" name="evaluate2">

                    <div class="evaluate-content" v-if="eva_good.total > 0">
                      <div class="evaluate-list" v-for="(item, index) in eva_good.list " :key="index">

                        <div class="eva-left">

                          <img src="./../../assets/img/header.png" alt="" v-if="!item.userinfo.avatar">
                          <img :src="ossurl + item.userinfo.avatar" alt="" v-else>

                        </div>

                        <div class="eva-right">
                          <div class="user-nickname">
                            昵称： {{ item.userinfo.nickname ? item.userinfo.nickname : '网友' }}


                          </div>
                          <div class="eva-time">
                            <div class="time">{{ item.create_time_text }}</div>
                            <div class="line"></div>

                            <div class="pingxing">
                              <img src="./../../assets/img/xingxing.png" alt="" v-for="key in item.spot" :key="key">

                            </div>
                          </div>

                          <div class="eva-info">
                            {{ item.content }}



                          </div>

                        </div>


                      </div>



                      <div class="eva-draw-down">
                        <div>向下展开</div>
                        <div class="jian-down">
                          <img src="./../../assets/img/to-down.png" alt="">
                        </div>


                      </div>

                      <div class="eva-btn"><button @click="go_eva">评价</button></div>

                    </div>

                    <div class="evaluate-content" v-else>


                      <div class="no-comment">
                        <div class="no-comment">
                          <el-empty description="暂无好评"></el-empty>
                        </div>
                      </div>



                      <!-- <div class="eva-draw-down">
                                 <div>向下展开</div>
                                 <div class="jian-down">
                                     <img src="./../../assets/img/to-down.png" alt="">
                                 </div>


                         </div> -->

                      <div class="eva-btn"><button @click="go_eva">评价</button></div>

                    </div>






                  </el-tab-pane>
                  <!-- 中评 -->
                  <el-tab-pane label="中评" name="evaluate3">

                    <div class="evaluate-content" v-if="eva_zhong.total > 0">
                      <div class="evaluate-list" v-for="(item, index) in eva_zhong.list " :key="index">

                        <div class="eva-left">

                          <img src="./../../assets/img/header.png" alt="" v-if="!item.userinfo.avatar">
                          <img :src="ossurl + item.userinfo.avatar" alt="" v-else>

                        </div>

                        <div class="eva-right">
                          <div class="user-nickname">
                            昵称： {{ item.userinfo.nickname ? item.userinfo.nickname : '网友' }}


                          </div>
                          <div class="eva-time">
                            <div class="time">{{ item.create_time_text }}</div>
                            <div class="line"></div>

                            <div class="pingxing">
                              <img src="./../../assets/img/xingxing.png" alt="" v-for="key in item.spot" :key="key">

                            </div>
                          </div>

                          <div class="eva-info">
                            {{ item.content }}



                          </div>

                        </div>


                      </div>


                      <div>
                        <div class="eva-draw-down">
                          <div>向下展开</div>
                          <div class="jian-down">
                            <img src="./../../assets/img/to-down.png" alt="">
                          </div>


                        </div>

                        <div class="eva-btn"><button @click="go_eva">评价</button></div>
                      </div>

                    </div>

                    <div class="evaluate-content" v-else>


                      <div class="no-comment">
                        <el-empty description="暂无中评"></el-empty>
                      </div>

                      <div>
                        <!-- <div class="eva-draw-down">
                                 <div>向下展开</div>
                                 <div class="jian-down">
                                     <img src="./../../assets/img/to-down.png" alt="">
                                 </div>


                         </div> -->

                        <div class="eva-btn"><button @click="go_eva">评价</button></div>
                      </div>



                    </div>








                  </el-tab-pane>
                  <!-- 差评 -->
                  <el-tab-pane label="差评" name="evaluate4">


                    <div class="evaluate-content" v-if="eva_cha.total > 0">
                      <div class="evaluate-list" v-for="(item, index) in eva_cha.list " :key="index">

                        <div class="eva-left">

                          <img src="./../../assets/img/header.png" alt="" v-if="!item.userinfo.avatar">
                          <img :src="ossurl + item.userinfo.avatar" alt="" v-else>

                        </div>

                        <div class="eva-right">
                          <div class="user-nickname">
                            昵称： {{ item.userinfo.nickname ? item.userinfo.nickname : '网友' }}


                          </div>
                          <div class="eva-time">
                            <div class="time">{{ item.create_time_text }}</div>
                            <div class="line"></div>

                            <div class="pingxing">
                              <img src="./../../assets/img/xingxing.png" alt="" v-for="key in item.spot" :key="key">

                            </div>
                          </div>

                          <div class="eva-info">
                            {{ item.content }}



                          </div>

                        </div>


                      </div>



                      <div class="eva-draw-down">
                        <div>向下展开</div>
                        <div class="jian-down">
                          <img src="./../../assets/img/to-down.png" alt="">
                        </div>


                      </div>

                      <div class="eva-btn"><button @click="go_eva">评价</button></div>

                    </div>

                    <div class="evaluate-content" v-else>



                      <div class="no-comment">
                        <el-empty description="暂无差评"></el-empty>
                      </div>
                      <div class="eva-btn"><button @click="go_eva">评价</button></div>

                    </div>


                  </el-tab-pane>
                </el-tabs>



              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="course-pro-right">
        <div class="trends-content" @click="go_trends(coursedetail.userinfo.id)">


          <div class="trends-title">
            <img src="./../../assets/img/icon/guangbo.png" alt=""> 最新动态

          </div>
          <div class="trends-intro" v-if="coursedetail.new_trends">
            {{ coursedetail.new_trends.content }}
          </div>


        </div>
        <div class="tea-mess">
          <div>
            <img src="../../assets/img/header.png" alt="" v-if="!coursedetail.userinfo.avatar">
            <img :src="ossurl + coursedetail.userinfo.avatar" alt="" v-else>
            <span class="add-friend" @click="add_friend" v-if="!coursedetail.is_friend">加为好友</span>
            <span class="add-friend" v-else>已为好友</span>
          </div>

          <div class="tea-mess-name">
            <p>{{ coursedetail.userinfo.nickname ? coursedetail.userinfo.nickname : '---' }}</p>
            <p>个人介绍：{{ coursedetail.userinfo.profile ? coursedetail.userinfo.profile : '---' }}</p>
          </div>

        </div>


        <div class="xuexiao">
          <p>学校: {{ coursedetail.userinfo.gra_schoole ? coursedetail.userinfo.gra_schoole : '---' }}</p>
          <p>学历：{{ coursedetail.userinfo.education ? coursedetail.userinfo.education : '---' }}</p>
        </div>
        <div class="shanchang">
          <p>成功学员案例: {{ coursedetail.userinfo.direction ? coursedetail.userinfo.direction : '---' }}</p>
        </div>
      </div>



      <Chat @closeChat="close_chat" v-if="is_show_chat" :toUserId="to_user_id" />

    </div>

    <div class="fankui" @click="show_feedback">
      <img src="../../assets/img/select.png" alt="">
      <p>反馈</p>
    </div>

    <div class="feedback" v-show="is_show_feedback">
      <div class="feedback-title">反馈的类型</div>
      <div>
        <el-form ref="form" :model="feedback_from" label-width="0px" size="18px">

          <el-form-item class="feed-type">
            <el-checkbox-group v-model="feedback_from.type" class="check-group">
              <el-checkbox label="老师的投诉" name="type" class="check-box"></el-checkbox>
              <el-checkbox label="学生的投诉" name="type" class="check-box"></el-checkbox>
              <el-checkbox label="课程不清晰" name="type" class="check-box"></el-checkbox>
              <el-checkbox label="老师的课后作业没有批改" name="type" class="check-box"></el-checkbox>
            </el-checkbox-group>
          </el-form-item>

          <div class="feedback-title">其他建议</div>
          <el-form-item class="jianyi">
            <el-input type="textarea" v-model="feedback_from.desc" class="jianyi-text"></el-input>
          </el-form-item>

          <div class="feedback-title">联系方式</div>

          <el-form-item label="" class="input">
            <el-input v-model="feedback_from.name" placeholder="留下你的联系方式，方便我们联系您"></el-input>
          </el-form-item>



          <div class="feedback-submit">
            <button class="cancel" type="button" @click="cancel_feedback"> 取消</button> <button class="submit">提交</button>
          </div>
        </el-form>

      </div>


    </div>



    <div class="eva-model" v-show="is_show_eva">
      <div class="model-header">
        <div class="eva-model-name">欢迎您对于老师的课程评价 </div>
        <div class="model-close" @click="close_eva_model">
          <i class="el-icon-close"></i>
        </div>

      </div>

      <div class="eva-model-content">

        <textarea name="" id="" placeholder="请输入200字以内" placeholder-style="color:#ECECEC"
          v-model="eva_from.value"></textarea>

      </div>

      <div class="pingjia">
        <div class="jia-title">评星 :</div>
        <div class="pingxing">
          <img @click="select_xing(1)" src="./../../assets/img/xingxing.png" alt="" v-if="eva_from.xing > 0">
          <img @click="select_xing(1)" src="./../../assets/img/no-xing.png" alt="" v-else>
          <img @click="select_xing(2)" src="./../../assets/img/xingxing.png" alt="" v-if="eva_from.xing > 1">
          <img @click="select_xing(2)" src="./../../assets/img/no-xing.png" alt="" v-else>
          <img @click="select_xing(3)" src="./../../assets/img/xingxing.png" alt="" v-if="eva_from.xing > 2">
          <img @click="select_xing(3)" src="./../../assets/img/no-xing.png" alt="" v-else>
          <img @click="select_xing(4)" src="./../../assets/img/xingxing.png" alt="" v-if="eva_from.xing > 3">
          <img @click="select_xing(4)" src="./../../assets/img/no-xing.png" alt="" v-else>

          <img @click="select_xing(5)" src="./../../assets/img/xingxing.png" alt="" v-if="eva_from.xing > 4">
          <img @click="select_xing(5)" src="./../../assets/img/no-xing.png" alt="" v-else>
        </div>

      </div>

      <div class="opration">

        <button @click="submit_eva" v-throttle> 提交</button>
      </div>


    </div>






    <div v-if="play_model">
      <div class="zheyan" @click="hide_play_model"></div>

      <div class="meido-play-model">
        <div class="meido-header">
          <div class="el-icon-close close" @click="close_medio_play"></div>
        </div>
        <div class="mideo-content">

          <video-player :options="video_info_option" class="video" />

        </div>
        <div class="medio-title">
          {{ video_info.title }}
        </div>
      </div>

    </div>


    <div class="preview-model" v-if="is_show_word">
      <Preview :previewData="active_word" @closePrew="close_privew"></Preview>

    </div>

  </div>
</template>

<script>

import { get_couser_info, commentList, student_collect_course, add_like_course } from "./../../request/course"

import Preview from '@/components/Preview';
import { addTeacherFriend, buyCourse, ConfrimBuyCourse, buyCourseOther, confirmBuyOther } from "./../../request/student"
import { evaComment } from "@/request/demand"
import VideoPlayer from "../../components/VideoPlayer.vue";
import Chat from "../../components/Chat"
import { Loading } from 'element-ui';
import BASEURL from "../../request/baseurl"
import OSSURL from "@/request/oss"
import store from "@/store/index"
export default {

  data() {
    return {
      default_img: 'this.src="' + require('../../assets/img/no-img.png') + '"',
      activeName: 'first',
      activeNames: ['1'],
      coursedetail: {},
      ossurl:OSSURL,
      baseurl: BASEURL,
      is_login: false,
      userinfo: null,
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: "",
            type: "video/mp4"
          }
        ]
      },
      is_show_chat: false,
      is_show_feedback: false,
      is_show_eva: false,
      feedback_from: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''

      },
      evaluateName: 'evaluate1',
      //评价form
      eva_from: {
        value: '',
        xing: 0,
        user_id: 0,
        course_id: ''

      },

      play_model: false,
      video_info_option: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: "",
            type: "video/mp4"
          }
        ]


      },
      video_info: {},
      to_user_id: 0,
      eva_all: {
        total: 0,
        page: 0,
        pagesize: 10000,
        list: []

      },
      eva_good: {
        total: 0,
        page: 0,
        pagesize: 10000,
        list: []

      },
      eva_zhong: {
        total: 0,
        page: 0,
        pagesize: 10000,
        list: []


      },
      eva_cha: {

        total: 0,
        page: 0,
        pagesize: 10000,
        list: []

      },
      active_word: {
        type: 'doc',
        id: null,
        price: 0
      },
      is_show_word: false





    }
  },
  created() {
    // 当前页面允许双指缩放


    try {
      var userinfo = localStorage.getItem('Userinfo');


      if (userinfo) {

        userinfo = JSON.parse(userinfo);
        //判断时间是否过期
        if (userinfo.expiretime * 1000 < new Date().getTime()) {
          this.is_login = false;
        } else {
          this.is_login = true;
          this.userinfo = userinfo;

        }

      }
    } catch {
      console.log(1111);
    }

    let id = this.$route.query.id;
    this.init_data(id);



  }, computed: {
    getUserInfo() {


      return store.state.Userinfo
    }

  },
  watch: {
    getUserInfo: {
      handler: function (val) {

        if (!val) {
          this.is_login = false;
        } else {
          this.is_login = true;

          this.userinfo = JSON.parse(val);
        }

      }
    }

  },
  components: {
    VideoPlayer,
    Chat,
    Preview
  },
  methods: {


    handleChange(val) {

    }, async init_data(id) {

      let loadingInstance1 = Loading.service({ fullscreen: true });

      let res = await get_couser_info({ id: id, click: true });
      this.get_comment_list(id, 0)
      this.get_comment_list(id, 1)
      this.get_comment_list(id, 2)
      this.get_comment_list(id, 3)
      if (res.code == 200) {
        document.title = res.data.title;
        loadingInstance1.close();
        this.coursedetail = res.data;

        if (res.data.mediolist.length > 0) {
          let medio_url = OSSURL + res.data.mediolist[0].medio_url;
          let videoOptions = this.videoOptions;
          videoOptions.sources[0].src = medio_url;

          this.videoOptions = videoOptions;

        }


      }





    },   //点赞
    async like_course(course_id) {

      let userinfo = this.userinfo;


      if (userinfo == null) {
        this.$message.warning("需要先登录哦")
        return false;
      }
      if (Object.keys(userinfo).length < 1) {

        this.$message.warning("需要先登录哦")
        return false;
      }


      let res = await add_like_course({ course_id: course_id });
      if (res.code == 200) {
        let coursedetail = this.coursedetail;
        if (coursedetail.is_like) {
          coursedetail.is_like = false;
          coursedetail.sign_up_num = Number(coursedetail.sign_up_num) - 1;
        } else {
          coursedetail.is_like = true;
          coursedetail.sign_up_num = Number(coursedetail.sign_up_num) + 1;
        }
        this.coursedetail = coursedetail;

      } else {
        this.$message.warning(res.msg)
        return false;
      }


    },
    handleClick() {


    }, async get_comment_list(id, type) {

      let course_id = id;
      let eva_area;
      if (type == 0) {
        eva_area = this.eva_all;

      } else if (type == 1) {
        eva_area = this.eva_good;
      } else if (type == 2) {

        eva_area = this.eva_zhong
      } else if (type == 3) {

        eva_area = this.eva_cha;
      }

      let page = Number(eva_area.page) + 1;

      let res = await commentList({ course_id: course_id, current: page, pagesize: eva_area.pagesize, type: type });
      if (res.code === 200) {
        eva_area.page = Number(res.data.current);
        eva_area.total = Number(res.data.total);
        if (eva_area.list.length == 0) {
          eva_area.list = res.data.list;


        } else {
          eva_area.list.push(res.data.list);

        }

        if (type == 0) {
          this.eva_all = eva_area;
        } else if (type == 1) {
          this.eva_good = eva_area;
        } else if (type == 2) {
          this.eva_zhong = eva_area;

        } else if (type == 3) {
          this.eva_cha = eva_area;
        }


      }

    }, hide_play_model() {


      this.play_model = false;


    }, async add_friend() {

      let userinfo = this.userinfo;
      if (userinfo == null) {
        this.$message.warning("需要先登录哦")
        return false;
      }

      if (Object.keys(userinfo).length < 1) {

        this.$message.warning("需要先登录哦")
        return false;
      }


      if (userinfo.user_type == 1) {

        this.$message.warning("对不起，您不是学生")
        return false;

      }

      let teacher_id = this.coursedetail.userinfo.id;
      let res = await addTeacherFriend({ teacher_id: teacher_id });

      if (res.code === 200) {

        let coursedetail = this.coursedetail
        coursedetail.is_friend = !coursedetail.is_friend
        this.coursedetail = coursedetail
        this.$message.success("添加好友成功")


      } else {
        this.$message.warning(res.msg)
        return false;
      }







    },



    gou_tong: function (to_user_id) {
      let userinfo = this.userinfo;
      if (userinfo == null) {
        this.$message.warning("需要先登录哦")
        return false;
      }

      if (Object.keys(userinfo).length < 1) {

        this.$message.warning("需要先登录哦")
        return false;
      }



      if (userinfo.user_type == 1) {

        this.$message.warning("对不起，您不是学生")
        return false;

      }



      this.to_user_id = to_user_id;
      this.is_show_chat = true;
    }, close_chat: function () {
      this.is_show_chat = false;
    }, cancel_feedback: function () {
      this.is_show_feedback = false;
    }, show_feedback: function () {

      this.is_show_feedback = true
    }, change_evaluate(val) {
      console.log(val);
      console.log(11111);
    }, async go_eva() {

      let is_login = this.is_login;
      if (is_login) {
        this.is_show_eva = true;



      } else {
        let res = await this.$confirm('评论需先登录，确定要评论吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        if (res == 'confirm') {
          this.$router.push('/login')
        }

      }





    },
    //收藏课程
    async collect_course() {

      let userinfo = this.userinfo;
      if (!userinfo) {
        this.$message.error("收藏需要先登录");
        return false;
      }

      if (userinfo.user_type == 1) {
        this.$message.error("老师无权利收藏课程");
        return false;
      }

      let res = await student_collect_course({ course_id: this.coursedetail.id, user_id: userinfo.id });
      if (res.code === 200) {

        let coursedetail = this.coursedetail;
        coursedetail.is_atten = !coursedetail.is_atten;
        this.coursedetail = coursedetail;

        this.$message.success(res.msg);
      } else {
        this.$message.error(res.msg);
      }


    },
    close_eva_model: function () {

      this.is_show_eva = false;

    },
    //选择评价的星星
    select_xing: function (num) {

      let eva_from = this.eva_from;
      eva_from.xing = num;
      this.eva_from = eva_from;


    }, async submit_eva() {

      let eva_from = this.eva_from;
      let eva = { "content": eva_from.value, 'user_id': this.userinfo.id, 'spot': eva_from.xing, 'course_id': this.coursedetail.id, 'pid': 0 };
      if (!eva.content) {
        this.$message.error("评论内容不能为空");
        return false;
      }

      if (!eva.course_id) {
        this.$message.error("参数丢失");
        return false;
      }

      if (!eva.user_id) {
        this.$message.error("请先登录或者刷新页面");
        return false;

      }

      let res = await evaComment(eva);
      if (res.code === 200) {
        this.is_show_eva = false;
        this.$message.success("评论成功");

      } else {
        this.$message.error(res.msg);

      }




    }, play_meido_model(index) {

      let video_info_option = this.video_info_option;
      let medio_info = this.coursedetail.mediolist[index];
      video_info_option.sources[0].src = OSSURL + medio_info.medio_url;
      //增加视频点击数量
      console.log(index);
      console.log(video_info_option);
      this.video_info_option = video_info_option;
      this.video_info = medio_info;
      this.play_model = true;

    }, close_medio_play() {
      this.play_model = false;
    }

    , download(index, type) {

      //作业
      let info;
      if (type == 3) {

        info = this.coursedetail.pralist[index];
      } else if (type == 2) {

        info = this.coursedetail.questionlist[index];
      }
      var a = document.createElement('a') // 创建一个<a></a>标签
      a.href = this.ossurl + info.url         //重点（如测试发现下载文件不存在/找不到，检查路径）
      a.download = info.title         // 设置下载文件文件名
      a.style.display = 'none'           // 隐藏a标签
      document.body.appendChild(a)       // 将a标签追加到文档对象中
      a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove()                         // 一次性的，用完就删除a标签

      // getData.exportexcel = (data)=>{ 
      //   return http({
      //     method: 'post',
      //     url: this.baseUrl + url, 
      //     data: {
      //         firstName: 'Fred',
      //         lastName: 'Flintstone'
      //     },
      //     responseType: 'blob'
      //   })
      // } 
    },//预览

    yulan(index, type) {
      var vm = this;
      //模拟题
      if (type == 2) {

        let info = this.coursedetail.questionlist[index];

        let price = Number(info.price);
        //1作业练习 2模拟试题 模拟真题
        let active_word = { id: info.id, type: type, price: price }
        this.active_word = active_word
        this.is_show_word = true

      } else if (type === 3) {
        let info = this.coursedetail.imitate[index];
        let price = Number(info.price);
        //1作业练习 2模拟试题 模拟真题
        let active_word = { id: info.id, type: type, price: price }
        this.active_word = active_word
        this.is_show_word = true

      } else if (type === 1) {
        let info = this.coursedetail.pralist[index];
        let price = Number(info.price);
        //1作业练习 2模拟试题 模拟真题
        let active_word = { id: info.id, type: type, price: price }
        this.active_word = active_word
        this.is_show_word = true
      }


    }, go_trends(user_id) {

      this.$router.push('/strends?user_id=' + user_id);
    }, close_privew() {

      this.is_show_word = false;
    },
    //购买
    async go_buy() {

      let userinfo = this.userinfo;
      if (userinfo == null) {
        this.$message.warning("需要先登录哦")
        return false;
      }

      if (Object.keys(userinfo).length < 1) {

        this.$message.warning("需要先登录哦")
        return false;
      }



      if (userinfo.user_type == 1) {

        this.$message.warning("对不起，您不是学生")
        return false;

      }
      

      let coursedetail = this.coursedetail;

      let confirm_res = await ConfrimBuyCourse({ course_id: coursedetail.id });
      if (confirm_res.code == 200) {
        if (confirm_res.data) {
          let confirm_result = await this.$confirm('您已经购买够,还继续购买吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })

          if (confirm_result == 'confirm') {
            if (Number(userinfo.account) < Number(coursedetail.price)) {

              let result = await this.$confirm('你当前余额不足，需要去充值?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })

              if (result == 'confirm') {
                this.$router.push('/student/recharge');
              }

              return false;

            }


            let msg = '你的当前余额' + userinfo.account + 'U币,确定要花费' + coursedetail.price + 'U币 ,购买此课程吗?'


            let res = await this.$confirm(msg, '购买', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })


            if (res == 'confirm') {

              let bus_res = await buyCourse({ course_id: coursedetail.id });
              if (bus_res.code === 200) {
                this.$router.push('/student/course');
              } else {
                this.$message.error(bus_res.msg);
              }


            }



          }


        } else {
          if (Number(userinfo.account) < Number(coursedetail.price)) {

            let result = await this.$confirm('你当前余额不足，需要去充值?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })

            if (result == 'confirm') {
              this.$router.push('/student/recharge');
            }

            return false;

          }


          let msg = '你的当前余额' + userinfo.account + 'U币,确定要花费' + coursedetail.price + 'U币 ,购买此课程吗?'


          let res = await this.$confirm(msg, '购买', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })


          if (res == 'confirm') {

            let bus_res = await buyCourse({ course_id: coursedetail.id });
            if (bus_res.code === 200) {
              this.$router.push('/student/course');
            } else {
              this.$message.error(bus_res.msg);
            }


          }




        }



      } else {
        this.$message.error(confirm_res.msg);

        return false;
      }






    },
    //购买试题
    async go_buy_question(index, type) {


      let userinfo = this.userinfo;
      if (userinfo == null) {
        this.$message.warning("需要先登录哦")
        return false;
      }

      if (Object.keys(userinfo).length < 1) {

        this.$message.warning("需要先登录哦")
        return false;
      }



      if (userinfo.user_type == 1) {

        this.$message.warning("对不起，您不是学生")
        return false;

      }


      let coursedetail = this.coursedetail;
      let list = [];
      console.log(coursedetail);

      if (type === 1) {
        list = coursedetail.pralist

      } else if (type === 2) {

        list = coursedetail.questionlist;

      } else if (type === 3) {

        list = coursedetail.imitate
      } else if (type == 4) {
        list = coursedetail.mediolist
        console.log(list);


      }

      let info = list[index];
      console.log(info);
      if (!info) {
        this.$message.error('记录不存在');
      }


      let confirm_res = await confirmBuyOther({ id: info.id, type: type });
      console.log(confirm_res);

      if (confirm_res.code == 200) {

        //如果已经购买
        if (confirm_res.data) {
          let confirm_result = await this.$confirm('您已经购买够,还继续购买吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })
          //确定继续购买
          if (confirm_result == 'confirm') {
            //非免费的状态
            if (info.price > 0) {

              if (Number(userinfo.account) < Number(info.price)) {

                let result = await this.$confirm('你当前余额不足，需要去充值?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                })

                if (result == 'confirm') {
                  this.$router.push('/student/recharge');
                }

                return false;

              }

            }

            let msg = '你的当前余额' + userinfo.account + 'U币,确定要花费' + info.price + 'U币 ,购买此资料吗?'
            let res = await this.$confirm(msg, '购买', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })


            if (res == 'confirm') {

              let bus_res = await buyCourseOther({ id: info.id, type: type });
              if (bus_res.code === 200) {
                if (type == 1) {
                  this.$router.push('/student/homework');

                } else if (type == 2) {
                  this.$router.push('/student/Document');

                } else if (type == 3) {
                  this.$router.push('/student/imitate');
                } else if (type == 4) {
                  this.$router.push('/student/medio');
                }

              } else {
                this.$message.error(bus_res.msg);
              }


            }

          } else {
            return false;
          }



        } else {

          //从未购买过
          //非免费的状态
          if (info.price > 0) {

            if (Number(userinfo.account) < Number(info.price)) {

              let result = await this.$confirm('你当前余额不足，需要去充值?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              })

              if (result == 'confirm') {
                this.$router.push('/student/recharge');
              }

              return false;

            }

          }

          let msg = '你的当前余额' + userinfo.account + 'U币,确定要花费' + info.price + 'U币 ,购买此资料吗?'
          let res = await this.$confirm(msg, '购买', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          })


          if (res == 'confirm') {

            let bus_res = await buyCourseOther({ id: info.id, type: type });
            if (bus_res.code === 200) {
              if (type == 1) {
                this.$router.push('/student/homework');

              } else if (type == 2) {
                this.$router.push('/student/Document');

              } else if (type == 3) {
                this.$router.push('/student/imitate');
              } else if (type == 4) {
                this.$router.push('/student/medio');
              }

            } else {
              this.$message.error(bus_res.msg);
            }


          } else {

            return false;
          }







        }



      } else {
        this.$message.error(confirm_res.msg);

        return false;
      }









    }

  }

}
</script>

<style lang="less" scoped>
@import "../../assets/css/common.less";
@import "../../assets/css/couse_detail.less";
</style>
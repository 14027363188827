import { render, staticRenderFns } from "./imitateorder.vue?vue&type=template&id=427e627c&scoped=true&"
import script from "./imitateorder.vue?vue&type=script&lang=js&"
export * from "./imitateorder.vue?vue&type=script&lang=js&"
import style0 from "./imitateorder.vue?vue&type=style&index=0&id=427e627c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "427e627c",
  null
  
)

export default component.exports
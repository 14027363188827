import { post } from './http.js'


export const send_msg = p => post('sms/send', p);

export const userlogin = p => post('user/login', p)
export const mobilelogin = p => post('user/mobilelogin', p)

export const logout = p => post('user/logout', p)

export const register = p => post('user/register', p)

export const fogetpwd = p => post('user/resetpwd', p)
export const update_userinfo = p => post('user/profile', p);


export const select_role = p => post('user/change_role', p);

export const center_reset_pwd = p => post('user/center_reset_pwd', p);
export const get_user_info = p => post('user/get_user_info', p)
<template>
  <div class="main wrap">
    <div>
      <personal></personal>
    </div>
    <div class="change">
      <div class="change-header">修改密码</div>
      <div class="line"></div>
      <div class="change-body">
        <div>
          <p>原密码</p><input type="password" placeholder="请输入密码" v-model="old_pwd.old_pwd">
        </div>
        <div>
          <p>新密码</p><input type="password" placeholder="请输入新密码" v-model="old_pwd.new_pwd">
        </div>
        <div>
          <p>确认新密码</p><input type="password" placeholder="请再次输入新密码" v-model="old_pwd.new_old_pwd">
        </div>
        <button class="btn" @click="save_pwd">保存</button>
      </div>
    </div>

  </div>
</template>

<script>

import personal from '../../components/common/personal.vue'
import { center_reset_pwd } from "@/request/login.js"

export default {
  name: 'changepaw',
  data() {
    return {
      old_pwd: {
        old_pwd: '',
        new_pwd: '',
        new_old_pwd: ''
      },
      userinfo: {},

    }
  },
  components: {
    'personal': personal,

  }, created() {


    var userinfo = localStorage.getItem('Userinfo');
    userinfo = JSON.parse(userinfo);


    //如果拥有用户信息，则直接进入个人中心

    if (userinfo) {


      if (userinfo.expiretime * 1000 < new Date().getTime()) {

        this.$router.push('/login');
        localStorage.clear("Userinfo");
        localStorage.clear('Authorization');

      } else {

        this.userinfo = userinfo;
      }

    } else {
      this.$router.push('/login');
    }

  }, methods: {

    async save_pwd() {

      try {


        let res = await this.$confirm('确定要更改密码吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        if (res == 'confirm') {


          let result = await center_reset_pwd(this.old_pwd);
          if (result.code == 200) {
            localStorage.clear("Userinfo");
            localStorage.clear('Authorization');
            this.$router.push('/login');
          } else {
            this.$message.error(result.msg);
          }


        }

      } catch (error) {
        console.log(error);

      }





    }


  }
}
</script>

<style scoped>
.main {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  margin-top: 40px;
  margin-bottom: 30px;
}

.change-body p {
  width: 180px;
  text-align: right;
}

.change {
  width: 1220px;
  padding: 0 40px;
  height: 682px;
  background: #FFFFFF;
  border-radius: 6px;
  margin-left: 20px;


}

.change .change-header {
  display: flex;


  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  border-bottom: 1px solid #EEEEEE;
  color: #333333;
  height: 80px;
  line-height: 80px;
}

.change-body>div {
  display: flex;
  margin-left: 20px;
  margin-top: 45px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.change-body>div>input {
  outline: none;
  margin-left: 14px;
  width: 590px;
  height: 40px;
  background: #F8F8F8;
  border: 1px solid #EEEEEE;
  border-radius: 6px;
  padding-left: 20px;
}

.change-body button {
  margin-top: 120px;
  width: 195px;
  height: 40px;
  background: #289EB8;
  border-radius: 6px;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
  margin-left: 40%;
}</style>
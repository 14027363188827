<template>
  <div class="big-box wrap">
    <div class="box">
      <div class="search">
        <img src="../../assets/img/搜索.png" alt="">
        <input type="text" placeholder="输入您想搜索的课程" name="keyword" v-model="keyword" @keyup.enter="search" >
        <button @click="search">搜索</button>
      </div>
      <div class="button">
        <el-select v-model="cate_id" placeholder="课程类型" @change="paixu_change">
          <el-option key="0" label="类型不限" value="0">
          </el-option>
          <el-option v-for="item in cate_list" :key="item.value" :label="item.name" :value="item.id">
          </el-option>
        </el-select>

        <el-select v-model="time_value" collapse-tags style="margin-left: 20px;" placeholder="时间不限"
          @change="paixu_change">
          <el-option v-for="item in time_order" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>

        <el-select v-model="expe_value" collapse-tags style="margin-left: 20px;" placeholder="经验值" @change="paixu_change">
          <el-option v-for="item in expe" :key="item.value" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="line"></div>
    </div>



    <div class="improtant" v-if="course_list.list.length > 0">
      <ul>

        <li class="li-course-info" v-for="(item, index) in course_list.list" :key="index">
          <div class="title" @click="go_detail(item.id)">
            <p v-html="item.title"></p>
          </div>
          <div class="content" @click="go_detail(item.id)">
            <p v-html="item.intro"></p>
          </div>
          <div class="foot">
            <div class="teacher">
              <img :src="ossurl +item.userinfo.avatar" alt="" v-if="item.userinfo.avatar">
              <img src="../../assets/img/header.png" alt="" v-else>
              <div>{{ item.userinfo.nickname ? item.userinfo.nickname : '---' }}</div>
            </div>
            <div class="express">
              <p>经验值： {{ item.userinfo.exp_num }}</p>
            </div>
            <div class="dianzan" @click="go_zan(item.id,index)">
              <img src="../../assets/img/no-zan.png" alt="" v-if="!item.is_like">
               <img src="../../assets/img/zan.png" alt="" v-else>
              <p>{{ item.sign_up_num }}</p>
            </div>
            <div class="liuyan"><img src="../../assets/img/留言.png" alt="">
              <p>{{ item.liu_num }}</p>
            </div>
            <div class="liulan"><img src="../../assets/img/see.png" alt="">
              <p>{{ item.click_num }}</p>
            </div>
            <div class="replase-time"><i class="el-icon-time"></i>
              <p>{{ item.createtime }}</p>
            </div>
             <div class="replase-time price">
              <p class="price"> ￥{{ item.price }}</p>
            </div>
            <div class="talking"><button @click="show_chat" :data-id="item.userinfo.id">沟通</button></div>
          </div>
        </li>


      </ul>

      <div class="page" v-show="course_list.total > 10">

        <!-- <Pagination @change="course_change" :current="course_list.page" :pageSize="course_list.pagesize"
          :total="course_list.total" :hideOnSinglePage="false" :showQuickJumper="true" :showTotal="false"
          placement="right" /> -->
                     <el-pagination
       
                  @current-change="course_change"
                  :current-page="course_list.page"
                  :page-sizes="[10]" 
                  :page-size="course_list.pagesize"
                  layout="prev, pager, next, jumper"
                  :total="course_list.total">
                </el-pagination> 


      </div> 
    </div>
    <div class="no-history" v-else>
    </div>

    <!-- 聊天弹框 -->
    <Chat @closeChat="close_chat" :toUserId="to_user_id"  v-if="is_show_chat" ></Chat>
  </div>
</template>
  
<script>
import BASEURL from "./../../request/baseurl";
import OSSURL from "@/request/oss"
import { getCatelist, couserselist,add_like_course} from "./../../request/course";
import Chat from "./../../components/Chat"

import store from "@/store/index"

export default {
  name: 'courselist',
  data() {
    return {
      course_list: {
        'total': 0,
        'page': 1,
        'pagesize': 10,
        'list': [

        ]

      },
      ossurl:OSSURL,
      baseurl: BASEURL,
      is_show_chat: false,
      keyword: '',
      time_order: [
        { "name": '时间不限', value: 0 },
        { "name": '一天之内', value: 1 },
        { "name": '一周之内', value: 7 },
        { "name": '一月之内', value: 30 },
        { "name": '一年之内', value: 365 },
      ],


      expe: [
        { "name": "50以下", value: 50 },
        { "name": "50至100", value: 100 },
        { "name": "100至200", value: 200 },
        { "name": "200至500", value: 500 },
        { "name": "500至1000", value: 1000 },
        { "name": "1000至2000", value: 2000 },
        { "name": "2000至5000", value: 5000 },
        { "name": "5000以上", value: 5001 },
      ],
      value1: [],
      value2: [],
      cate_list: [],
      cate_id: '',
      time_value: 0,
      expe_value: '',
      to_user_id:0
    }
  },computed:{
        getUserInfo() {
       
            
            return this.$store.state.Userinfo
        }
       
  },watch:{ 
       userinfo(val,oldval){

           if(val){
                this.userinfo = val;
           }

       }
        
        
 
  }, 
  components: {
    Chat,
  

  }, created() {
       try {
      var userinfo = localStorage.getItem('Userinfo');


      if (userinfo) {

        userinfo = JSON.parse(userinfo);
        //判断时间是否过期
        if (userinfo.expiretime * 1000 < new Date().getTime()) {
          this.is_login = false;
        } else {
          this.is_login = true;
          this.userinfo = userinfo;

        }
 
      }
    } catch {
      console.log();
    }
    let keyword = this.$route.query.keyword;
    this.keyword = keyword;
    this.init_data(keyword);
  }, methods: {

    async init_data(keyword) {
      let that = this;
      let res = await couserselist({ current: 1, pagesize: 10, keyword: keyword, cate_id: '' });

      if (res.code == 200) {
        console.log(res);
        let course_list = this.course_list;
        course_list.total = Number(res.data.total);
        course_list.page = Number(res.data.current);
        course_list.total_page = Number(res.data.pages);
        course_list.list = res.data.list;

        this.course_list = course_list;
      }

      let cate_list_res = await getCatelist();

      if (cate_list_res.code == 200) {
        this.cate_list = cate_list_res.data;
      }



    }, close_chat: function () { 
      this.is_show_chat = false;
    }, show_chat: function (e) {



let userinfo = this.userinfo;
       if(userinfo == null){
           this.$message.warning("需要先登录哦")
        return false;
       }
        
      if (Object.keys(userinfo).length < 1) {

        this.$message.warning("需要先登录哦")
        return false;
      }
         if(userinfo.user_type !=0){
  this.$message.warning("对不起,您不是学生")
        return false;
          
      }  

      let to_user_id = e.target.dataset.id;
      
    this.to_user_id =Number(to_user_id); 

      this.is_show_chat = true;
    }, async search() {
      let keyword = this.keyword;
      let res = await couserselist({ current: 1, pagesize: 10, keyword: keyword, cate_id: '' });
      if (res.code == 200) {
        console.log(res);
        let course_list = this.course_list;
        course_list.total = Number(res.data.total);
        course_list.page = Number(res.data.current);
        course_list.total_page = Number(res.data.pages);
        course_list.list = res.data.list;

        this.course_list = course_list;
      }

    }, async paixu_change(e) {

      let keyword = this.keyword;
      let cate_id = this.cate_id;
      let expe_value = this.expe_value;
      let time_value = this.time_value;
      let res = await couserselist({ current: 1, pagesize: 10, keyword: keyword, cate_id: cate_id, time_value: time_value, expe_value: expe_value });
      if (res.code == 200) {

        let course_list = this.course_list;
        course_list.total = Number(res.data.total);
        course_list.page = Number(res.data.current);
        course_list.total_page = Number(res.data.pages);
        course_list.list = res.data.list;

        this.course_list = course_list;
      }

    },
    
     //分页
    async course_change(pager) {
      let keyword = this.keyword;
      let cate_id = this.cate_id;
      let expe_value = this.expe_value;
      let time_value = this.time_value;


      let res = await couserselist({ current: pager, pagesize: 10, keyword: keyword, cate_id: cate_id, time_value: time_value, expe_value: expe_value });


      if (res.code == 200) {

        let course_list = this.course_list;
        course_list.total = Number(res.data.total);
        course_list.page = Number(res.data.current);
        course_list.total_page = Number(res.data.pages);
        course_list.list = res.data.list;

        this.course_list = course_list;
        // this.$emit('change', pager)
      }


    }, go_detail(id) {

      
      this.$router.push("/coursedetail?id=" + id);
    
  

    },  
    
    //点赞
    async  go_zan(couse_id,index){


         let userinfo = this.userinfo;
            
            if(userinfo ==null){
                 this.$message.warning("需要先登录哦")
             return false;
            }
           if( Object.keys(userinfo).length  <1){ 
               
             this.$message.warning("需要先登录哦")
             return false;
           }   
           let res = await add_like_course({course_id:couse_id});

           if(res.code === 200){
                let course_list = this.course_list;
                  if(  course_list.list[index].is_like ){
                        course_list.list[index].is_like  = false;
                          course_list.list[index].sign_up_num = Number(course_list.list[index].sign_up_num)-1;
                  }else{
                          course_list.list[index].is_like  = true; 
                           course_list.list[index].sign_up_num = Number(course_list.list[index].sign_up_num)+1;
                  }
                 
                   this.course_list = course_list;

 
               
           }else{
              this.$message.warning(res.msg)
             return false;
           }
    }

  }
}
</script>

<style lang="less" scoped>
.el-select-dropdown__item.selected {
  color: #289EB8;
  font-weight: 700;
}

/deep/.el-select .el-input__inner:focus {
  border-color: #289EB8 !important;
}

li {
  list-style: none;
}

.line {


  width: 1420px;
  margin: 25px auto 25px;
  height: 1px;



}

.wrap {
  border-radius: 6px;
}

.box {
  width: 1460px;
  margin: 40px auto 50px;


}

.li-course-info {
  list-style: none;
  margin-bottom: 40px;
}

.big-box {
  background-color: #FFFFFF;
  padding-bottom: 50px;
  min-height: 900px;
  margin-bottom: 30px;

}

input {
  outline: none;
}

.search {
  position: relative;
  display: flex;
  padding-top: 36px;
  margin-left: 20px;
}

.search img {
  position: absolute;
  left: 10px;
  top: 48px;
  width: 24px;
  height: 24px;
}

.search input {
  padding-left: 50px;
  width: 783px;
  height: 42px;
  border: 2px solid #ECECEC;
  border-radius: 18px;
}

.search button {
  cursor: pointer;
  width: 80px;
  height: 42px;
  border: 0.5px solid #ffffff;
  background: #289EB8;
  border-radius: 8px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;

  margin-left: 30px;
}

.button {

  margin-top: 30px;
  display: flex;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 45px;
  margin-left: 20px;
}

.button p {
  color: #ECECEC;
  margin-left: 70px;
  margin-top: 0px;
  font-weight: 700;
  font-size: 25px;
}

.button .el-select {
  margin-right: 45px;
}

/deep/ .el-input__inner::placeholder {

  color: #606266 !important;



}

/* 谷歌 */

.el-input__inner::-webkit-input-placeholder {

  color: #606266 !important;



}

/* 火狐 */

.el-input__inner:-moz-placeholder {

  color: #606266 !important;



}

/*ie*/

.el-input__inner:-ms-input-placeholder {

  color: #606266 !important;
}



.el-input /deep/ input::input-placeholder {
  color: #606266 !important;
}

.el-input /deep/ input::-webkit-input-placeholder {
  color: #606266 !important;
}

.title {
  cursor: pointer;
  margin-top: 20px;
  height: 35px;
  line-height: 35px;
  display: flex;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #289EB8;
  line-height: 21px;

}

.title p {

  height: 35px;
  line-height: 35px;
  border-bottom: 1px solid #dfdfdf;
}

.title p:hover {

  color: #289EB8;
}

.content {
  line-height: 35px;
  width: 100%;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  padding-top: 20px;
}

.content p {
  vertical-align: middle;
  line-height: 35px;

  text-align: left
}

.foot {
  display: flex;

  height: 28px;
}

.teacher {
  display: flex;
  margin-right: 50px;
  width: 150px;


}

.teacher img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.teacher div {
  height: 28px;
  line-height: 28px;
  width: 130px;

  margin-left: 10px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #010000;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

.express {
  height: 28px;
  line-height: 28px;
}

.express p {
  margin: 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 28px;
}

.dianzan {
  display: flex;
  margin-left: 55px;
}

.dianzan img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-top: 5px;
}

.dianzan p {
  margin: 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  height: 28px;
  line-height: 28px;
}

.liuyan {

  display: flex;
  margin-left: 55px;
  height: 28px;
  line-height: 28px;
}

.liuyan img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-top: 5px;

}

.liuyan p {
  margin: 0px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 28px;
  height: 28px;
}

.liulan {
  display: flex;
  margin-left: 55px;

}

.liulan img {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-top: 5px;
}

.liulan p {
  margin: 0;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 28px;
  height: 28px;
}

.talking {
  margin-left: 50px;
}

.talking button {
  cursor: pointer;
  border: none;
  width: 70px;
  height: 25px;

  border-radius: 6px;
  color: #FFFFFF;
  margin-top: 1px;
  border: 2px solid #E6E6E6;
  background-color: #ffffff;
  color: #289EB8;
}

.talking button:hover {
  background-color: #289EB8;
  color: #ffffff;
  border: none;
}

.no-history {
  margin-top: 50px;
}

.no-history {
  margin-top: 50px;
}

.replase-time {
  margin-left: 50px;
  height: 28px;
  line-height: 28px;
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
}

.replase-time i {
  font-size: 18px;
  line-height: 28px;
  margin-right: 10px;
}

.replase-time p {
  margin: 0;
  height: 28px;
  line-height: 28px;
  font-size: 14px;
}

.page {
  margin-top: 50px;
}
.page{
      width: 100%;
    
      
      .el-pagination{
           width: 520px;
            margin: 0 auto;
            font-size: 16px;
           /deep/ .number{
                   min-width: 33px;
                    height: 33px;
                   text-align: center;
                   line-height: 33px;
                   border: 1px solid #d9d9d9 !important;
                   border-radius: 4px;
                   padding: 0 !important;
                   margin-right: 8px !important;
                   font-size: 14px;
                   font-weight: 500;
            }

            /deep/ .active{
               font-weight: 500;
               color: #ffffff;
               border-color: #289EB8;
               background-color: #289EB8;
               font-size: 16px;
            }

         /deep/   .btn-prev{
             
               text-align: center !important;
               line-height: 33px;
               border: 1px solid #d9d9d9 !important;
               margin-right: 8px;
               min-width: 33px;
               font-size: 14px;
               height: 33px;
            }
      }

      /deep/  .btn-next{ 
          width: 33px;
          text-align: center !important;
          line-height: 33px;
          border: 1px solid #d9d9d9 !important;
          height: 33px;
          min-width: 33px;
          font-size: 14px;
            
      } 


      /deep/ .el-pagination__jump{
             height: 33px;
             line-height: 33px;
      }

      

      .el-pager li{
 
           min-width: 35px;
      } 

    
}

.price{

  color:red; 
}
</style>  
import { render, staticRenderFns } from "./Document.vue?vue&type=template&id=0990567c&scoped=true&"
import script from "./Document.vue?vue&type=script&lang=js&"
export * from "./Document.vue?vue&type=script&lang=js&"
import style0 from "./Document.vue?vue&type=style&index=0&id=0990567c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0990567c",
  null
  
)

export default component.exports
import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({

    state: {
        // 存储token
        Authorization: localStorage.getItem('Authorization') ? localStorage.getItem('Authorization') : '',
        Userinfo: localStorage.getItem('Userinfo') ? localStorage.getItem('Userinfo') : '',
        webSocketMsg: '',
        //个人中心的侧边栏，默认的展开的
        left_menu_default: ''
    },

    mutations: {
        // 修改token，并将token存入localStorage
        changeLogin(state, user) {
            state.Authorization = user.Authorization;
            localStorage.setItem('Authorization', user.Authorization);
        },
        //存储用户信息，便于全局操作
        setUserinfo(state, Userinfo) {

            state.Userinfo = Userinfo.userinfo;
            localStorage.setItem('Userinfo', Userinfo.userinfo);

        },
        getUserInfo(state, Userinfo) {

        },
        SET_WS_MSG: (state, msg) => {
            state.webSocketMsg = msg
        },
        setDefaultMenu(state, defaultMenu) {


            state.left_menu_default = defaultMenu.index;

        }

    }
});

export default store;
<template>
    <!-- <div class="left">

            <teachercenter :userInfo="userinfo"></teachercenter>
        </div> -->
    <div class="course right">
        <div class="course-search">
            <div class="search">
                <img src="../../../assets/img/搜索.png" alt="">
                <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_btn">
            </div>
            <button @click="search_btn">搜索</button>
        </div>
        <div class="course-content" v-if="is_msg_system">

            <div class="content-header">
                <p :class="is_msg_system ? 'header-active' : ''" @click="change_type(1)">系统通知</p>
                <p :class="!is_msg_system ? 'header-active' : ''" @click="change_type(2)">商家信息</p>
            </div>
            <div class="content-body">
                <div class="content-select">
                    <el-select placeholder="全部" v-model="is_read" @change="search_read_change">
                        <el-option label="全部" value="">
                        </el-option>
                        <el-option label="已读" :value="1">
                        </el-option>
                        <el-option label="未读" :value="0">
                        </el-option>
                    </el-select>
                    <div class="weidu">共 {{ msg_list.total }} 封 1 封未读</div>
                </div>


                <div class="body-content">
                    <ul>
                        <li class="body-header">

                            <div class="xuhao">序号</div>
                            <div class="zhuangtai">发件人</div>
                            <div class="biaoti">消息内容</div>
                            <div class="shuliang">时间</div>
                            <div class="xuhao">状态</div>
                            <div class="caozuo">操作</div>

                        </li>
                        <el-checkbox-group v-model="checked_msgs_ids" @change="handleCheckedCitiesChange">
                            <li v-for="(item, index) in msg_list.list" :key="index">

                                <div class="xuhao"> <el-checkbox :label="item.id" :key="item.id"></el-checkbox></div>
                                <div class="zhuangtai">{{ item.send_nickname }}</div>
                                <div class="biaoti">{{ item.message_title }}</div>
                                <div class="shuliang">{{ item.push_time }}</div>
                                <div class="xuhao">{{ item.status == 0 ? '未读' : '已读' }}</div>
                                <div class="caozuo">

                                    <div class="shanchu" @click="del(item.id, index)">删除</div>
                                </div>
                            </li>
                        </el-checkbox-group>
                    </ul>
                    <div class="body-bottom">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                            @change="handleCheckAllChange">全选</el-checkbox>

                        <button class="gouzhong" @click="del_by_dis">删除选中项</button>
                        <button class="yidu" @click="is_set_read">设为已读</button>
                    </div>
                </div>
            </div>

            <div class="page">

                <el-pagination @current-change="msg_list_change" :current-page="msg_list.current" :page-sizes="[1]"
                    :page-size="msg_list.pagesize" layout="prev, pager, next, jumper" :total="msg_list.total">
                </el-pagination>
            </div>
        </div>



        <div class="course-content" v-if="!is_msg_system">

            <div class="content-header">
                <p :class="is_msg_system ? 'header-active' : ''" @click="change_type(1)">系统通知</p>
                <p :class="!is_msg_system ? 'header-active' : ''" @click="change_type(2)">商家信息</p>
            </div>
            <div class="content-body">
                <div class="content-select">
                    <el-select placeholder="全部" v-model="is_read1" @change="search_read_change1">
                        <el-option label="全部" value="">
                        </el-option>
                        <el-option label="已读" :value="1">
                        </el-option>
                        <el-option label="未读" :value="0">
                        </el-option>
                    </el-select>
                    <div class="weidu">共 {{ msg_list1.total }} 封 1 封未读</div>
                </div>


                <div class="body-content">
                    <ul>
                        <li class="body-header">

                            <div class="xuhao">序号</div>
                            <div class="zhuangtai">发件人</div>
                            <div class="biaoti">消息内容</div>
                            <div class="shuliang">时间</div>
                            <div class="shuliang">状态</div>
                            <div class="caozuo">操作</div>

                        </li>
                        <el-checkbox-group v-model="checked_msgs_ids1" @change="handleCheckedCitiesChange1">
                            <li v-for="(item, index) in msg_list1.list" :key="index">

                                <div class="xuhao"> <el-checkbox :label="item.id" :key="item.id"></el-checkbox></div>
                                <div class="zhuangtai">{{ item.send_nickname }}</div>
                                <div class="biaoti">{{ item.message_title }}</div>
                                <div class="shuliang">{{ item.push_time }}</div>
                                <div class="xuhao">{{ item.status == 0 ? '未读' : '已读' }}</div>
                                <div class="caozuo">

                                    <div class="shanchu" @click="del(item.id, index)">删除</div>
                                </div>
                            </li>
                        </el-checkbox-group>
                    </ul>
                    <div class="body-bottom">
                        <el-checkbox :indeterminate="isIndeterminate1" v-model="checkAll1"
                            @change="handleCheckAllChange1">全选</el-checkbox>

                        <button class="gouzhong" @click="del_by_dis1">删除选中项</button>
                        <button class="yidu" @click="is_set_read1">设为已读</button>
                    </div>
                </div>
            </div>

            <div class="page">

                <el-pagination @current-change="msg_list_change1" :current-page="msg_list1.current" :page-sizes="[1]"
                    :page-size="msg_list1.pagesize" layout="prev, pager, next, jumper" :total="msg_list1.total">
                </el-pagination>
            </div>
        </div>


    </div>
</template>
  
<script>

import teachercenter from './../../../components/common/teachercenter'
import BASEURL from "./../../../request/baseurl"
import store from "./../../../store/index"
import { getMyZhanMessage } from "@/request/teacher"

import { delZhanMsg, delZhanMsgids, readZhanMsgByid, readZhanMsgByids } from "@/request/student"

export default {
    name: 'teacherSiteinf',
    data() {
        return {
            userinfo: {},
            baseurl: BASEURL,
            is_default_index: '',
            showModal: false,
            activeName: 'first',
            checked: true,

            options: [{
                value: '选项1',
                label: '已读'
            }, {
                value: '选项2',
                label: '未读'
            }],
            msg_list: {
                'total': 0,
                'current': 1,
                'pagesize': 5,
                'list': []

            },
            msg_list1: {
                total: 0,
                current: 1,
                pagesize: 5,
                list: []


            },
            checkAll: false,
            checkAll1: false,
            checkmsgs: [],
            check_msgs_id: [],
            check_msgs_id1: [],
            checked_msgs_ids: [],
            checked_msgs_ids1: [],
            isIndeterminate: false,
            isIndeterminate1: false,
            keyword: '',
            keyword1: '',
            is_read: null,
            is_read1: null,
            is_msg_system: true

        }
    }, components: {
        teachercenter
    }, created() {
        let is_default_index = store.state.left_menu_default;
        this.is_default_index = is_default_index;
        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);

        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }

        this.initdata()

    },
    methods: {
        handleClick(tab, event) {

        }, search_btn() {

            let keyword = this.keyword;
            let is_msg_system = this.is_msg_system;
            if (is_msg_system) {
                this.get_list(1, this.msg_list.pagesize, keyword, '', 0);
            } else {
                this.get_list(1, this.msg_list1.pagesize, keyword, '', 1);
            }



        }, change_type(type) {

            if (type == 1) {
                this.is_msg_system = true;
            } else {
                this.is_msg_system = false;
            }

        },

        async initdata() {

            this.get_list(this.msg_list.current, this.msg_list.pagesize, '', null, 0);
            this.get_list(this.msg_list1.current, this.msg_list1.pagesize, '', null, 1);

        }, async get_list(current, pagesize, keyword, type, status) {


            let res = await getMyZhanMessage({ current: current, pagesize: pagesize, keyword: keyword, type: type, status: status });
            if (res.code == 200) {
                if (status == 0) {
                    let msg_list = this.msg_list;
                    msg_list.current = Number(res.data.current);
                    msg_list.total = Number(res.data.total);
                    msg_list.list = res.data.list;
                    let check_msgs_id = [];
                    if (res.data.list.length > 0) {
                        res.data.list.forEach(info => {
                            check_msgs_id.push(info.id)

                        });


                        this.check_msgs_id = check_msgs_id
                    } else {
                        this.check_msgs_id = check_msgs_id
                    }

                    this.msg_list = msg_list;
                } else {
                    let msg_list1 = this.msg_list1;
                    msg_list1.current = Number(res.data.current);
                    msg_list1.total = Number(res.data.total);
                    msg_list1.list = res.data.list;
                    let check_msgs_id = [];
                    if (res.data.list.length > 0) {
                        res.data.list.forEach(info => {
                            check_msgs_id.push(info.id)

                        });


                        this.check_msgs_id1 = check_msgs_id
                    } else {
                        this.check_msgs_id1 = check_msgs_id
                    }

                    this.msg_list1 = msg_list1


                }


            } else {

                this.$message.error(res.msg);
                return false;
            }


        }, handleCheckAllChange(val) {


            let check_msgs_id = this.check_msgs_id;

            this.checked_msgs_ids = val ? check_msgs_id : [];
            this.isIndeterminate = false;
        }, handleCheckAllChange1(val) {
            let check_msgs_id1 = this.check_msgs_id1;

            this.checked_msgs_ids1 = val ? check_msgs_id1 : [];
            this.isIndeterminate1 = false;


        },
        handleCheckedCitiesChange(value) {



        }, async del(id, index) {

            let result = await this.$confirm('你确定要删除此消息吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });

            if (result == 'confirm') {
                if (!id) {
                    this.$message.error('缺少必备参数');
                }

                let is_msg_system = this.is_msg_system;

                let res = await delZhanMsg({ id: id });
                if (res.code === 200) {

                    if (is_msg_system) {
                        let msg_list = this.msg_list;
                        msg_list.list.splice(index, 1);

                        this.msg_list = msg_list;
                        this.$message.success('删除成功');


                    } else {
                        let msg_list1 = this.msg_list1;
                        msg_list1.list.splice(index, 1);

                        this.msg_list1 = msg_list1;
                        this.$message.success('删除成功');
                    }




                } else {
                    this.$message.error(res.msg);
                }
            }



        },




        //设为已读
        async is_set_read() {

            let checked_msgs_ids = this.checked_msgs_ids;

            if (checked_msgs_ids.length == 0) {

                this.$message.warning('请选择通知');
                return false;

            }

            let result = await this.$confirm('确定将此消息设为已读, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });

            let checked_msgs_ids_str = checked_msgs_ids.toString();


            let res = await readZhanMsgByids({ ids: checked_msgs_ids_str });

            if (res.code === 200) {
                this.get_list(this.msg_list.current, this.msg_list.pagesize, this.keyword, this.is_read);

                this.$message.success('操作成功');
            } else {

                this.$message.warning(res.msg);
                return false;
            }

        },
        async is_set_read1() {
            let checked_msgs_ids1 = this.checked_msgs_ids1;

            if (checked_msgs_ids1.length == 0) {

                this.$message.warning('请选择通知');
                return false;

            }

            let result = await this.$confirm('确定将此消息设为已读, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });

            let checked_msgs_ids_str = checked_msgs_ids1.toString();


            let res = await readZhanMsgByids({ ids: checked_msgs_ids_str });

            if (res.code === 200) {
                this.get_list(this.msg_list1.current, this.msg_list1.pagesize, this.keyword, this.is_read);

                this.$message.success('操作成功');
            } else {

                this.$message.warning(res.msg);
                return false;
            }


        },



        async del_by_dis() {
            let checked_msgs_ids = this.checked_msgs_ids;

            if (checked_msgs_ids.length == 0) {

                this.$message.warning('请选择通知');
                return false;

            }

            let result = await this.$confirm('确定将选择的删除吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });

            let checked_msgs_ids_str = checked_msgs_ids.toString();


            let res = await delZhanMsgids({ ids: checked_msgs_ids_str });

            if (res.code === 200) {

                this.get_list(this.msg_list.current, this.msg_list.pagesize, this.keyword, this.is_read, 0);

                this.$message.success('操作成功');
            } else {

                this.$message.warning(res.msg);
                return false;
            }


        }, async del_by_dis1() {

            let checked_msgs_ids1 = this.checked_msgs_ids1;

            if (checked_msgs_ids1.length == 0) {

                this.$message.warning('请选择通知');
                return false;

            }

            let result = await this.$confirm('确定将选择的删除吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });

            let checked_msgs_ids_str = checked_msgs_ids1.toString();


            let res = await delZhanMsgids({ ids: checked_msgs_ids_str });

            if (res.code === 200) {
                this.get_list(this.msg_list1.current, this.msg_list1.pagesize, this.keyword, this.is_read, 1);

                this.$message.success('操作成功');
            } else {

                this.$message.warning(res.msg);
                return false;
            }



        }, search_read_change(e) {

            this.get_list(1, this.msg_list.pagesize, this.keyword, e, 0);


        }, search_read_change1(e) {
            this.get_list(1, this.msg_list1.pagesize, this.keyword, e, 1);

        }, msg_list_change(page) {

            let keyword = this.keyword;
            let is_read = this.is_read;
            this.get_list(pager, this.msg_list.pagesize, keyword, is_read, 0);

        }, msg_list_change1() {
            let keyword = this.keyword;
            let is_read1 = this.is_read1;
            this.get_list(pager, this.msg_list1.pagesize, keyword, is_read1, 1);
        }


    }

}
</script>
  
<style lang="less"  scoped >
@import url("./../../../assets/css/teacher_common.less");

.center-main {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 40px;

}

.content-select {
    display: flex;
    margin-left: 35px;
    padding-top: 20px;
}

/deep/ .el-checkbox__inner {
    width: 30px;
    height: 30px;
    margin-top: 10px;

    margin-bottom: 20px;
}

.body-bottom {
    margin-left: 60px;
}

/deep/ .el-checkbox__inner::after {
    height: 20px;
    left: 7px;
    top: 0px;
    width: 13px;
    border-color: #289EB8;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #FFFFFF;
}

.gouzhong {
    margin-left: 50px;
    width: 113px;
    height: 36px;
    border: 1px solid #289EB8;
    border-radius: 10px;
    background-color: #FFFFFF;
    color: #289EB8;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;

}

.yidu {
    margin-left: 30px;
    width: 113px;
    height: 36px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    background-color: #289EB8;
    color: #ffffff;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;

}

.el-select {
    width: 125px;
    height: 40px;
    border: 1px solid #999999;
    border-radius: 6px;
    margin-right: 30px;
}

.xuhao {
    width: 100px;
    text-align: center;
}

.zhuangtai {
    text-align: center;
    width: 160px;
    margin: 0 1px;
    overflow: hidden;
}

.weidu {
    text-align: center;
    width: 170px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #323B46;
    line-height: 42px;
}

input {
    outline: none;
}

.left {
    width: 310px;

}

.body-content ul {
    margin: 0;
    padding-left: 42px;
    padding-bottom: 30px;
}

.body-content ul li {
    list-style: none;
    height: 52px;
}

button {
    cursor: pointer;
    border: 0.5px solid #E9E9E9;
}

.biaoti {
    margin: 0 0px;
    width: 250px;
    text-align: center;
    overflow: hidden;
}

.shuliang {
    text-align: center;
    width: 170px;
    margin: 0 5px;
}

.liulan {
    width: 80px;
    text-align: center;
    margin: 0 20px;
}

.caozuo {
    width: 200px;
    text-align: center;
}

.bianji {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
}

.shanchu {
    font-weight: 500;
    color: #EA440A;
}


.course {
    width: 1150px;
    /* height: 626px; */
    overflow: auto;
    height: auto;
    background: #FFFFFF;
    border-radius: 6px;
    // margin-left: 20px;

}

.course-search {
    margin-left: 31px;
    padding-top: 51px;
    display: flex;
}

.course .search {
    position: relative;
    width: 653px;
    height: 69px;
    border: 2px solid #ECECEC;
    border-radius: 21px;
}

.course .search input {
    outline: none;
    margin-left: 55px;
    border-style: none;
    width: 570px;
    height: 65px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ADADAD;
    line-height: 45px;
}

.course img {
    position: absolute;
    left: 20px;
    top: 22px;
    width: 28px;
    height: 28px;
}

.course-search button {
    cursor: pointer;
    width: 115px;
    height: 57px;
    border: 1px solid #FFFFFF;
    background: #289EB8;
    border-radius: 8px;
    margin-left: 36px;
    margin-top: 8px;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 45px;
}

.content-header {
    display: flex;
    margin-left: 74px;
    margin-top: 20px;
}

.content-header p {
    cursor: pointer;
    color: #999999;
    margin-right: 120px;

}

.content-header .header-active {
    font-family: PingFang SC !important;
    font-weight: 500 !important;
    color: #323B46 !important;

}



.content-body {
    margin-left: 35px;
    margin-top: 0px;
    margin-bottom: 50px;
    width: 1070px;
    background: #FFFFFF;
    box-shadow: 0px 0px 65px 0px rgba(51, 51, 51, 0.1);
    border-radius: 16px;
}

.content-body .body-header {
    display: flex;
    font-size: 22px !important;
    color: #606060;
}

.content-body .body-header:hover {
    background-color: #fff;
    cursor: auto;
}

.body-content li {
    cursor: pointer;
    display: flex;
    font-size: 18px;
    line-height: 52px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333;
    margin: 10px auto;
}

.body-content li:hover {
    background-color: #DCF6FB;
}

/deep/ .el-tabs__item {
    font-size: 18px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 35px;
}

.body-header {
    div {
        font-size: 22px !important;
    }

    height: 70px !important;
    line-height: 70px !important;

    .xuhao {
        font-weight: 500 !important;
    }


}
</style>
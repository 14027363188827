<template>
<div class="big-box" style="width:100%;">
    <div class="wrap">
     <div class="chat-content">
        <div class="chat_person">
            <div class="sousuo">
                <input type="text" placeholder="搜索" class="search">
                <img src="../../assets/img/搜索.png" alt="" class="search-img">
            </div>
            <div class="talk">
                <button :class="index == number ? 'btn1' : 'btn'" @click="tab(index)" v-for="(item, index) in dataList"
                    :key="index">{{ item.option }}
                </button>
            </div>
            <ul id="content1" v-show="number == 0">
                <div v-if="friend_list.length > 0">
                    <li v-for="(item, index) in friend_list" :key="index" @click="setChatInfo(index)">
                        <div class="touxiang">

                            <img src="../../assets/img/header.png" alt="" v-if="!item.avatar && item.gender == 0">
                            <img src="../../assets/img/female.png" alt="" v-else-if="item.avatar && item.gender == 1">
                            <img :src="ossurl + item.avatar" alt="" v-else>


                        </div>
                        <div class="xinxi">
                            <div class="xingming-time">
                                <div class="xingming">{{ item.nickname }}</div>
                                <div class="day-time" v-if="item.createtime">{{ item.createtime }}</div>
                            </div>
                            <div class="huifu" v-if="item.content">

                                {{ item.content }}

                            </div>
                            <div class="huifu" v-else>

                                你们还没有聊天，赶紧去聊天吧

                            </div>
                        </div>
                    </li>
                </div>

                <div v-else class="no-firend">
                    暂无好友

                </div>
            </ul>



            <ul id="content2" v-show="number == 1">
                <li v-for="(item, index) in chat_history_list " :key="index" @click="setChatInfoHis(index)">
                    <div class="touxiang">



                        <img src="../../assets/img/header.png" alt="" v-if="!item.header && item.gender == 0">
                        <img src="../../assets/img/female.png" alt="" v-else-if="item.header && item.gender == 1">
                        <img :src="ossurl + item.header" alt="" v-else>



                    </div>
                    <div class="xinxi">
                        <div class="xingming-time">
                            <div class="xingming">{{ item.name }}</div>
                            <div class="day-time">{{ item.send_time }}</div>
                        </div>
                        <div class="huifu">{{ item.message }}</div>
                    </div>
                </li>

            </ul>
        </div>
        <div class="chat_commento">
          
            <div class="chat_middle" id="chat_middle_item">
                <div class="teacher">{{ chat_user_info.nickname }}</div>
               
                <div class="chat-history-content" id="chatContent" @scroll="handleScroll">

                    <div class="chat-history-info" v-for="(item, index) in chat_list" :key="index">

                        <div class="chat_left clearfix" v-if="item.fid != userinfo.id">
                            <div class="chat_left_item_1 ">

                                <img src="../../assets/img/header.png" alt=""
                                    v-if="!chat_user_info.avatar && !chat_user_info.gender == 0">
                                <img src="../../assets/img/female.png" alt=""
                                    v-else-if="!chat_user_info.header && item.gender == 1">
                                <img :src="ossurl + chat_user_info.avatar" alt="" v-else>


                            </div>
                            <div class="chat_left_item_2">
                                <div class="chat_time">{{ item.createtime }}</div>
                                <div class="chat_left_content">
                                    {{ item.content }}
                                </div>
                            </div>
                        </div>


                       
                        <div class="chat_right" v-else>
                            <div class="chat_right_item_1 ">
                                <img src="../../assets/img/header.png" alt=""
                                    v-if="!userinfo.avatar && !userinfo.gender == 0">
                                <img src="../../assets/img/female.png" alt=""
                                    v-else-if="!userinfo.header && item.gender == 1">
                                <img :src="ossurl + userinfo.avatar" alt="" v-else>

                            </div>
                            <div class="chat_right_item_2 ">
                                <div class="chat_right_time">{{ item.createtime }}</div>
                                <div class="chat_right_content">
                                    {{ item.content }}
                                </div>
                            </div>
                        </div>

                        <div class="clear"></div>

                    </div>

                </div>



            </div>
           
            <div class="line"></div>
           
            <div class="chat_foot">
                <!-- context -->
                <textarea class="chat_context" id="chat_context_item" cols="30" rows="10" placeholder="请输入你的内容"
                    v-model="sendmsg"></textarea>
                <div class="chat_commit" id="button" @click="send_message">发送</div>
            </div>
        </div>
    </div>  
    </div>
  

</div>
</template>
  
<script>

import { get_my_friend, contacts_list, get_user_info, get_chat_history_list, is_set_chat_read, get_chat_student_friend } from "@/request/utalk"
import BASEURL from "@/request/baseurl"
import OSSURL from "@/request/oss"
import SOCKETURL from "@/request/websockurl"
import store from "@/store/index"
import { initWebSocket, webSocketClose, sendSock } from "@/util/websock"
export default {
    name: 'utalk',
    data() {
        return {
            ossurl:OSSURL,
            baseurl: BASEURL,
            chat_context_item: null,
            number: 0, //点击后的值，与下标同步，为0表示默认第一个按钮与div为选中状态
            dataList: [
                { option: '我的老师' },
                { option: '最近聊天' },
            ],
            userinfo: {},
            friend_list: [],
            chat_history_list: [],
            sendmsg: '',
            wb_url: SOCKETURL,
            chat_user_info: {},
            chat_index: 0,  //默认选中0
            friend_user_id: '',
            chat_history: {},
            chat_list: [],
        }
    },
    computed: {
        getSocketMsg() {


            return this.$store.state.webSocketMsg
        }


    }, watch: {
        "$store.state.Userinfo": {
            deep: true,
            handler: function (val) {
                userinfo = JSON.parse(val);
                this.userinfo = userinfo;

            }
        },
        getSocketMsg: {
            handler: function (val) {

                let that = this;


                that.getConfigResult(val)
            }
        },
        chat_list: function () {
            this.$nextTick(() => {

                var div = document.getElementById('chatContent')

                div.scrollTop = div.scrollHeight

            })
        }
    },
    created() {

        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);

        if (userinfo) {
            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {



                let wb_url = this.wb_url;
                let is_connect = initWebSocket(this.wb_url);
                if (!is_connect) {
                    let inter = setInterval(function () {
                        is_connect = initWebSocket(wb_url);
                    },
                        1000);
                    if (is_connect) {
                        clearInterval(inter);
                    }

                }

                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }


        window.addEventListener('keydown', this.handkeycode, true)
        this.init();

    },
    methods: {

        async init() {

            let userinfo = this.userinfo;


            //教师，获取教师的朋友列表
            if (userinfo.user_type === 1) {

                let dataList = this.dataList;
                dataList[0].option = '我的学生';
                this.dataList = dataList;
                let res = await get_my_friend();


                if (res.code === 200) {

                    this.friend_list = res.data;
                    this.getcontacts_list(0);

                } else {

                    this.$message.error(res.msg);

                }




            } else {
                //获取学生好友列表
                let dataList = this.dataList;
                dataList[0].option = '我的老师';
                this.dataList = dataList;
                let res = await get_chat_student_friend();
                if (res.code === 200) {
                    this.friend_list = res.data;
                }

                this.getcontacts_list(0);


            }




        }, handkeycode(e) {

            if (e.keyCode === 13) {
                this.send_message();
            }

        },
        //0 默认读取聊天 列表，1 有消息来时，保证聊天对象为原对象，因为直接从服务器拉取，并未处理，赋值等 操作 2：点击沟通，跳转至相关的成员，
        async getcontacts_list(user_id, type) {

            let userinfo = this.userinfo;

            let result = await contacts_list({ user_id: userinfo.id });
            if (result.code === 200) {
                let chat_history_list = result.data;

                if (chat_history_list.length > 0) {

                    if (type == 0) {

                        this.setChatInfoHis(this.chat_index);

                    } else if (type == 1) {

                        for (var i = 0; i < chat_history_list.length; i++) {
                            if (chat_history_list[i].id == that.chat_user_info.id) {

                                that.chat_index = i;
                                return;
                            }

                        }

                    }


                }



            }






            if (result.code === 200) {


                this.chat_history_list = result.data;




            } else {
                this.$message.error(res.msg);

            }






        }
        ,
        //从我的好友中切换聊天
        setChatInfo(index) {



            let that = this;
            let friend_list = this.friend_list;
            let userinfo = this.userinfo;
            let info = friend_list[index];

            let to_user_id = friend_list[index].friend_id;



            this.friend_user_id = to_user_id;
            this.chat_list = []
            this.get_user_info_fuc(to_user_id);
            this.get_chat_history_list(to_user_id);
            this.chat_index = index;




        },

        //从聊天记录切换到聊天状态
        setChatInfoHis(index) {
            let chat_history_list = this.chat_history_list;
            let user_info = this.userinfo;

            let to_user_id;
            if (chat_history_list[index].fid == user_info.id) {
                to_user_id = chat_history_list[index].tid;

            } else {
                to_user_id = chat_history_list[index].fid;
            }

            this.friend_user_id = to_user_id;
            this.chat_list = []
            this.get_user_info_fuc(to_user_id);
            this.get_chat_history_list(to_user_id);
            this.chat_index = index;


        },




        async get_user_info_fuc(user_id) {

            let that = this;

            let res = await get_user_info({ user_id: user_id });

            if (res.code === 200) {
                res.data.user_id = user_id;

                this.chat_user_info = res.data;
            }



            this.get_chat_history_list(user_id);


        }, async get_chat_history_list(user_id) {
            let that = this;
            let res = await get_chat_history_list({ user_id: user_id, current: 1, pagesize: 200 })

            that.chat_list = res.data



        },//发送消息
        send_message() {

            let that = this;
            var msg = this.sendmsg;
            if (!msg) {
                return false;
            }
            var date = new Date();
            var seperator1 = "-";
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var strDate = date.getDate();
            if (month >= 1 && month <= 9) {
                month = "0" + month;
            }
            if (strDate >= 0 && strDate <= 9) {
                strDate = "0" + strDate;
            }
            var currentdate = year + seperator1 + month + seperator1 + strDate;
            var data = { "fid": that.userinfo.id, "tid": that.chat_user_info.id, 'content': msg, 'createtime': currentdate, 'send_sucess': true }

            try {

                let res = sendSock(data)

                if (!res) {
                    data.send_sucess = false;
                } else {
                    let chat_history_list = that.chat_history_list;
                    let chat_index = that.chat_index;
                    let chat_history_info = chat_history_list[chat_index];

                    chat_history_info.message = msg;
                    chat_history_info.send_time = '刚刚';
                    chat_history_list[chat_index] = chat_history_info;
                    that.chat_history_list = chat_history_list


                }
            } catch (error) {
                data.send_sucess = false;
            }


            var chat_list = that.chat_list
            chat_list.push(data);
            that.chat_list = chat_list;
            that.sendmsg = ''
            document.getElementById("chatContent").scrollIntoView(true);



        }, //监听聊天框的
        handleScroll: function (e) {


        },//监听接收到消息，然后push 到聊天框

        getConfigResult(val) {

            //将接收信息，push 到聊天记录
            let that = this;

            var friend_user_id = that.friend_user_id;

            var data_json = val.data;

            var data = JSON.parse(data_json);

            if (data.message === 'success') {

                var chat_content_data = data.data;
                //如果是本人则，则更新最新聊天记录

                let userinfo = that.userinfo;
                if (chat_content_data.fid == friend_user_id) {

                    var chat_list = that.chat_list;
                    chat_list.push(chat_content_data);
                    that.chat_list = chat_list;
                    that.is_set_chat_read(chat_content_data.fid);


                    that.getcontacts_list(userinfo.id, 1);


                } else if (chat_content_data.tid == userinfo.id) {

                    that.getcontacts_list(userinfo.id, 1);


                }


            } else {

            }


        },

        //标记信息已读

        async is_set_chat_read(user_id) {

            //只需要发送，不需要返回结果
            let res = await is_set_chat_read({ usr_id: user_id });



        },
        tab(index) {
            this.number = index;
            // console.log(index, this.number);
        }

    }
}
</script> 

<style scoped>
.no-firend {
    height: 80px;
    line-height: 80px;
    font-size: 16px;
    text-align: center;
    color: #717577
}

.chat-history-content {
    width: 1130px;
    padding: 0 20px;
    height: 515px;
    overflow: hidden;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.chat-history-content::-webkit-scrollbar {
    display: none;
}

input {
    outline: none;
    font-size: 16px;
}

.sousuo {
    position: relative;
    margin-left: 10px;
    margin-top: 10px;
}

.search {
    width: 280px;
    height: 40px;
    border: 0.5px solid #f4f5f7;
    border-radius: 10px;
    padding-left: 8px;
}

.search-img {
    position: absolute;
    top: 5px;
    right: 20px;
    z-index: 999;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

* {
    padding: 0;
    margin: 0;
}

li {
    list-style: none;
    display: flex;
    margin-top: 18px;
    padding-left: 10px;
}

.wrap {
     
     margin:0 auto !important;
     overflow:hidden;
   
   
}

.chat-content{
     height: 830px;
      
 margin: 35px auto 50px;
     display:flex;
       
               display: -webkit-flex;
               flex-direction: row; 
                  justify-content: space-between;
}

.chat_person {
    width: 311px;
    height: 830px;
    background: #FFFFFF;
    border-radius: 6px;
    
}

.touxiang {
    width: 76px;
    height: 76px;
    border-radius: 6px;
    margin-right: 10px;
}

.touxiang img {
    width: 76px;
    height: 76px;

    border-radius: 50%;
}

.xinxi {
    margin-top: 10px;
}


.xin .xingming-time {
    display: flex;
    flex-direction: row;
    display: -webkit-flex;
    justify-content: space-between;
}

.xinxi .xingming-time .xingming {

    width: 130px;
    height: 20px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #717577;
    line-height: 20px;
    overflow: hidden;

}

.xinxi .xingming-time .day-time {
    width: 70px;
    text-align: right;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #BFBFBF;
}

.xingming-time {
    display: flex;
    margin-bottom: 15px;
}

.huifu {
    width: 200px;
    height: 23px;
    overflow: hidden;
    white-space: nowrap;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #BFBFBF;
    text-overflow: ellipsis;
}

.chat_commento {
    width: 1170px;
    height: 830px;
    background: #FFFFFF;
    border-radius: 6px;
}

.clearfix::after {
    content: "";
    display: block;
    clear: both;
    width: 0;
    height: 0;
    line-height: 0;
    visibility: hidden;
}

/* top */
/* middle */
/* 左边 */
.chat_middle {
    width: 1170px;
    height: 600px;
    position: relative;
    box-sizing: border-box;
    overflow: auto;
    border-width: 0px;
}

.chat_left {
    width: 100%;
    height: 120px;
    margin-top: 20px;
}

.chat_left_item_1 {
    width: 50px;
    height: 50px;
    border: 1px solid #cfcfcf;
    float: left;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
    line-height: 50px;
    color: white;
    border-radius: 25px;
}

.chat_left_item_1 img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.chat_left_item_2 {
    width: 55%;
    height: 100px;
    float: left;
    margin-top: 10px;
}

.chat_left_item_2 .chat_left_chat {
    float: left;
}

.chat_left_item_2 .chat_left_content {
    padding: 15px;
    margin-top: 10px;
    background-color: #f4f5f7;
    display: inline-block;
    border-radius: 10px;
    border-top-left-radius: 0px;
}

/* 右边 */
.chat_right {
    width: 100%;
    min-height: 120px;
    margin-top: 20px;
}

.chat_right_item_1 {
    width: 50px;
    height: 50px;
    border: 1px solid #cfcfcf;
    float: right;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    line-height: 50px;
    color: white;
    border-radius: 25px;
}

.chat_right_item_1 img {
    width: 50px;
    height: 50px;
    border-radius: 50%;

}

.chat_right_item_2 {
    width: 55%;
    min-height: 100px;
    float: right;
    margin-top: 10px;
}

.chat_right_time {
    width: 100%;
    text-align: right;
}

.chat_right_content {
    float: right;
    padding: 15px;
    border-radius: 10px;
    margin-top: 10px;
    border-top-right-radius: 0px;
    background-color: #289EB8;
    color: white;
    max-width: 500px;
    word-break: break-all;


}

/* foot */
.chat_foot {
    width: 1170px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: relative;
}

.chat_context {
    width: 100%;
    height: 100%;
    font-size: 17px;
    box-sizing: border-box;
    outline: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-width: 0px;
    padding: 16px;

}

textarea::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #c3c3c3;
}

.chat_commit {
    width: 80px;
    height: 30px;
    color: white;
    background-color: #289EB8;
    line-height: 30px;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    bottom: 20px;
    margin-right: 10px;
}

.chat_context {
    resize: none;
}

.chat_context::placeholder {
    color: black;
    font-weight: 500k;
}

.line {
    width: 100%;
    border-top: 1px;
    border-color: #f4f5f7;
    border-style: solid;
}

.talk {
    display: flex;
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    border-bottom: 1px dotted #adadad;
}



.btn1 {
    color: #289EB8;
    cursor: pointer;
    border: none;
    width: 80px;
    height: 30px;
    font-size: 18px;
    background-color: #FFFFFF;
    margin-right: 30px;
    border-bottom: 3px solid #289EB8;
}

.btn {
    cursor: pointer;
    border: none;
    width: 80px;
    height: 30px;
    font-size: 18px;
    background-color: #FFFFFF;
    margin-right: 30px;

}

.myteacher {

    color: #289EB8;
}

.teacher {
    color: #289EB8;
    margin: 15px;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #CDCDCD;
}

.clear {

    clear: both;
}

#content1 {

    height: 700px;
    overflow: hidden;
    overflow-y: true;
}

#content2 {
    height: 700px;
    overflow: hidden;
    overflow-y: true;

}
</style>
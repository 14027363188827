<template>
    <header id="header-top" class="head">
        <div class="header-nav">
            <div class="top-line"></div>
            <div class="nav">

                <div class="wrap nav-tap">

                    <div class="logo" @click="$router.push('/index')" >
                        <img src="./../../assets/img/logo.png" alt="首页" title="首页">
                       
                    </div>

                    <div class="nav-content">
                        <!-- <div :class="$route.path === '/index' ? 'tap active' : 'tap'" @click="$router.push('/index')">首页
                        </div> -->
                        <div :class="$route.path === '/stusearch' || $route.path === '/courselist' || $route.path === '/coursedetail' ? 'tap active' : 'tap'"
                            @click="$router.push('/stusearch')">
                            学生搜课程</div>
                        <div :class="$route.path === '/teachsearch' || $route.path === '/demandlist' || $route.path === '/demandDetail' ? 'tap active' : 'tap'"
                            @click="$router.push('/teachsearch')">老师搜需求</div>
                        <div :class="$route.path === '/teacher/release' || $route.path === '/student/release' ? 'tap active' : 'tap'"
                            @click="go_release">我要发布</div>
                        <div :class="$route.path === '/utalk' ? 'tap active' : 'tap'" @click="$router.push('/utalk')">UTalk</div>


                        <div v-show="!is_login" @click="$router.push('/login')"
                            :class="($route.path === '/login' || $route.path === '/register' || $route.path === '/forget' || $route.path === '/agreement') ? 'tap active' : 'tap'">
                            登录</div>
                        <div :class="$route.path === '/index' || $route.path === '/stusearch' || $route.path === '/courselist' || $route.path === '/coursedetail' || $route.path === '/utalk' || $route.path === '/teachsearch' || $route.path === '/demandlist' || $route.path === '/demandDetail' || $route.path === '/teacher/release' || $route.path === '/student/release' ? 'tap' : 'tap active'"
                            v-show="is_login" @click="go_center">个人中心</div>
                        <!-- <div class="fangwen"><span class="white">目前访问 </span> <span class="number">{{web_count}}人次 </span><span class="white"> 优课栗栗学员-老师互动学习平台</span></div> -->

                    </div>
                </div>
            </div>

        </div>

    </header>
</template>
<script>
import store from "./../../store/index";
import { webCount } from "@/request/demand"
export default {
    name: 'Header',
    data() {
        return {
            Userinfo: null,
            is_login: false,
            is_teacher: false,
            web_count: 0
        }

    }, created() {
        try {
            var userinfo = localStorage.getItem('Userinfo');


            if (userinfo) {

                userinfo = JSON.parse(userinfo);
                //判断时间是否过期
                if (userinfo.expiretime * 1000 < new Date().getTime()) {
                    this.is_login = false;
                } else {
                    this.is_login = true;
                    this.Userinfo = userinfo;

                }

            }
        } catch {
            console.log(1111);
        }
        this.init();

    }, computed: {
        getUserInfo() {


            return store.state.Userinfo
        }

    },
    watch: {
        getUserInfo: {
            handler: function (val) {

                if (!val) {
                    this.is_login = false;
                } else {
                    this.is_login = true;

                    this.Userinfo = JSON.parse(val);
                }

            }
        }

    }, methods: {
        go_center: function () {

            let userinfo = this.Userinfo;
            console.log(userinfo);
            if (!userinfo) {
                this.$router.push('/login');
            } else {
                //如果是老师，跳转至老师个人中心
                console.log(userinfo);
                console.log('用户信息');
                if (userinfo.user_type == 1) {
                    this.$router.push('/teacher/index');
                } else {
                    this.$router.push('/student/index')
                }
            }

        }, go_release: function () {

            let userinfo = this.Userinfo;

            if (!userinfo) {
                this.$router.push('/login');
            } else {
                //   如果是老师进入发布页面
                if (userinfo.user_type == 1) {
                    this.$router.push('/teacher/release');
                } else if (userinfo.user_type == -1) {
                    //跳转至学生发布页面
                    this.$router.push('/selectRole');
                } else {
                    this.$router.push('/student/release');
                }
            }


        }, async init() {

            let res = await webCount();
            if (res.code === 200) {
                this.web_count = res.data.count;
            }
        }
    }
}
</script> 
<style lang="less" scoped>
@import "./../../assets/css/header.less";
@import "./../../assets/css/common.less";

.login {
    height: 162px;
    line-height: 162px;
    font-size: 23px;
    color: #ffffff;
    font-family: pingfang;
    font-weight: lighter;
    cursor: pointer;
    opacity: 0.8;
}
</style>
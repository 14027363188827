import store from '@/store'
import SOCKETURL from "@/request/websockurl"
var webSocket = null;
let isConnect = false;
let reconnectionNum = 0 // 重连次数
const limitReconnectionNum = 5 // 最大重连次数
const web_url = SOCKETURL
let globalCallback = function() {}; //定义外部接收数据的回调函数

//初始化websocket
export function initWebSocket(url) {

    //判断浏览器是否支持websocket
    if ("WebSocket" in window) {
        webSocket = new WebSocket(url); //创建socket对象
        isConnect = true;
    } else {
        alert("该浏览器不支持websocket!");
        isConnect = false;
    }
    //打开
    webSocket.onopen = function() {
        webSocketOpen();
    };
    //收信
    webSocket.onmessage = function(msg) {
        webSocketOnMessage(msg);
    };
    //关闭
    webSocket.onclose = function() {
        isConnect = false;
        webSocketClose();

    };
    //连接发生错误的回调方法
    webSocket.onerror = function() {

        reConnect();
    };
    return isConnect;
}

let createWebSocket = () => {
    try {
        let userInfo = store.state.Userinfo;
        let wb_url = web_url + "&&uid=" + userInfo.id;


        initWebSocket(wb_url); //初始化websocket连接
    } catch (e) {

        reConnect(); //如果无法连接上webSocket 那么重新连接！可能会因为服务器重新部署，或者短暂断网等导致无法创建连接
    }
};



//定义重连函数
let reConnect = () => {

    if (isConnect) return; //如果已经连上就不在重连了
    reconnectionNum && clearTimeout(reconnectionNum);
    reconnectionNum = setTimeout(function() { // 延迟5秒重连  避免过多次过频繁请求重连
        createWebSocket();

    }, 5000);
};


//连接socket建立时触发
export function webSocketOpen() {

}

//客户端接收服务端数据时触发,e为接受的数据对象
export function webSocketOnMessage(e) {
    // 存储在store中，然后在聊天界面中监控变化 自动拿取收到的信息
    store.commit('SET_WS_MSG', e)
}

//发送数据
export function webSocketSend(data) {
    console.log('发送数据');
    //在这里根据自己的需要转换数据格式
    return webSocket.send(JSON.stringify(data));
}

//关闭socket
export function webSocketClose() {
    webSocket.close()

    // }
}

//心跳设置
var heartCheck = {
    timeout: 20000, //每段时间发送一次心跳包 这里设置为20s
    timeoutObj: null, //延时发送消息对象（启动心跳新建这个对象，收到消息后重置对象）

    start: function() {
        this.timeoutObj = setTimeout(function() {
            if (isConnect) websock.send(checkMsg);
        }, this.timeout);
    },

    reset: function() {
        clearTimeout(this.timeoutObj);
        this.start();
    }
};




//在其他需要socket地方调用的函数，用来发送数据及接受数据
export function sendSock(agentData, callback) {
    globalCallback = callback; //此callback为在其他地方调用时定义的接收socket数据的函数，此关重要。
    //下面的判断主要是考虑到socket连接可能中断或者其他的因素，可以重新发送此条消息。
    let is_send = false;

    if (!webSocket) {
        createWebSocket();

        return false;
    }

    switch (webSocket.readyState) {
        //CONNECTING：值为0，表示正在连接。
        case webSocket.CONNECTING:
            setTimeout(function() {

                webSocketSend(agentData, callback);
            }, 1000);

            break;
            //OPEN：值为1，表示连接成功，可以通信了。
        case webSocket.OPEN:


            webSocketSend(agentData, callback);

            break;
            //CLOSING：值为2，表示连接正在关闭。
        case webSocket.CLOSING:
            setTimeout(function() {

                webSocketSend(agentData, callback);
            }, 1000);
            break;
            //CLOSED：值为3，表示连接已经关闭，或者打开连接失败。
        case webSocket.CLOSED:

            // do something

            let userInfo = JSON.parse(store.state.Userinfo);
            console.log(userInfo);
            let wb_url = web_url + "&&uid=" + userInfo.id;
            initWebSocket(wb_url);
            break;
        default:
            // this never happens
            break;
    }

    return isConnect;
}

//将初始化socket函数、发送（接收）数据的函数、关闭连接的函数export出去
export default {
    initWebSocket,
    webSocketClose,
    sendSock
};
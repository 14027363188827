<template>
  <div class="big-box">

    <div class="wrap">
      <div class="content">
        <div class="login-content">

          <div class="login-header">
            <div class="active">微信登录</div>
          </div>

          <div class="login-main">

            <div class="QRcode" v-show="!is_time_out">
              <img :src="qr_colde_src" alt="" class="qr-code">
            </div>

            <div v-show="is_time_out" class="time-out" @click="refresh">
              <img :src="qr_colde_src" alt="" class="qr-code">

              <div class="refresh-img">
                <img src="../../assets/refresh.png" alt="">
              </div>
            </div>
            <div class="explain">
              <p>请使用微信扫描二维码登录</p>
              <p>“优课理理”</p>
            </div>
          </div>

          <div class="sms-login">
            <button class="SMS-login" @click="$router.push('/login')">短信登录</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>   
  
<script>

import { get_ticket, qr_login } from "../../request/api"
import store from "../../store/index"
export default {
  name: 'wxlogin',
  data() {
    return {
      is_teacher: false,
      qr_colde_src: '',
      is_time_out: false,
      timer: null

    }
  },
  components: {

  },
  created() {

    this.get_ticket();



  }
  ,
  methods: {

    change_login: function (e) {
      if (e === 1) {

        this.is_teacher = false;
      } else {
        this.is_teacher = true;
      }

    }, get_ticket: function () {

      let that = this;
      get_ticket({}).then(res => {
        var url = "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + res.ticket;
        that.qr_colde_src = url;
        var count = 300;

        var timer = setInterval(function () {

          // 请求参数是二维码中的场景值

          qr_login({ scene_id: res.scene_id }).then(response => {
            if (response.code == 200) {
              clearInterval(timer);//关闭轮循环

              response.data.userinfo.youxiaotime = new Date().getTime();

              this.userinfo = response.data.userinfo

              var userinfo_json = JSON.stringify(this.userinfo);



              // 将用户token保存到vuex中

              store.commit("changeLogin", { Authorization: response.data.userinfo.token })
              //将用户信息存入本地

              store.commit('setUserinfo', { userinfo: userinfo_json })

              if (response.data.userinfo.user_type == 1) {
                that.$router.push('/teacher/index')

              } else {
                that.$router.push('/student/index')
              }

            } else if (response.code == -1) {

              clearInterval(timer);//关闭轮循环

              that.$layer.msg(response.msg);

            } else if (response.code == 1) {

              clearInterval(timer);//关闭轮循环

              that.$router.push({ path: '/register', 'query': { 'openid': response.data } })

            } else if (response.code == 2) {

              clearInterval(timer);//关闭轮循环

              that.is_time_out = true

            }

            // 



          })

          count--
          if (count <= 0) {
            clearInterval(timer);//关闭轮循环 

            that.is_time_out = true
          }
        }, 1000);

        this.$once('hook:beforeDestroy', () => {
          console.log("销毁了");
          clearInterval(timer);
          timer = null;

        })



      })
    }, refresh: function () {
      this.is_time_out = false
      this.get_ticket();
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
/deep/ #app {
  background-color: #ffffff !important;
}

.big-box {
  background: url(../../assets/img/login.png) no-repeat;
  background-size: 100% 100%;
  min-height: 85vh;
  width: 100%;
}

.wrap {
  display: flex;
  justify-content: flex-end;
}

.content {
  margin-top: 15%;
  background-color: #FFFFFF;
  width: 480px;
  height: 573px;
  box-shadow: 0px 0px 10px 0;
  border-radius: 5px;

  .login-content {
    padding: 20px;

    .login-header {
      display: flex;

      .active {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #289EB8;
      }
    }

    .login-main {
      .time-out {
        position: relative;
        width: 280px;
        height: 280px;

        .refresh-img {
          position: absolute;
          top: 0;
          left: 0;
          width: 280px;
          height: 280px;
          opacity: 0.85;

          img {
            display: block;
            height: 40px;
            width: 40px;
            margin: 100px auto;
          }
        }
      }

      margin: 40px;

      .QRcode {
        padding-top: 15px;

        .qr-code {
          margin-left: 40px;
          display: block;
          width: 280px;
          height: 280px;
        }
      }

      .explain {
        margin: auto;
        width: 168px;
        height: 39px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        text-align: center;
      }
    }

    .sms-login {
      .SMS-login {
        cursor: pointer;
        width: 195px;
        height: 60px;
        background-color: #FFFFFF;
        border: 1px solid #289EB8;
        color: #289EB8;
        border-radius: 6px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-left: 125px;
      }

      .SMS-login:hover {
        cursor: pointer;
        width: 195px;
        height: 60px;
        background-color: #289EB8;
        border: 1px solid #289EB8;
        color: #FFFFFF;
        border-radius: 6px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
    }
  }
}
</style>

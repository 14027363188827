import { render, staticRenderFns } from "./studentcenter.vue?vue&type=template&id=841518a2&scoped=true&"
import script from "./studentcenter.vue?vue&type=script&lang=js&"
export * from "./studentcenter.vue?vue&type=script&lang=js&"
import style0 from "./studentcenter.vue?vue&type=style&index=0&id=841518a2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "841518a2",
  null
  
)

export default component.exports
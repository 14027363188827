/**   
 * api接口统一管理
 */
import {get, post } from './http.js'

// 查询轮播图详细

export const couserselist = p => get('/index/get_curri_list', p)

//课程详情
export const get_couser_info = p => get('/index/get_curri_info', p)

export const demandlist = p => get('/index/get_question_list', p)

export const phonelogin = p => post('user/login', p)
export const mobilelogin = p => post('user/mobilelogin', p)

export const logout = p => post('user/logout', p)

export const register = p => post('user/register', p)

export const fogetpwd = p => post('user/resetpwd', p)

export const teacher_couse_list = p => post('teachercenter/get_my_couserlist', p)
    //收藏的课程列表

export const student_my_course_list = p => post('student_center/get_my_atten_course', p)
    //用户收藏
export const student_collect_course = p => post('student_center/atten_course', p)

//用户取消收藏
export const student_del_collect_course = p => post('student_center/del_atten_course', p);

//判断用户是否已经关注

export const confirm_collect_course = p => post('student_center/confrim_atten_courde', p);

//获取我得发布需求
export const student_my_question = p => post('student_center/get_my_question', p)

export const update_userinfo = p => post('user/profile', p);

export const center_reset_pwd = p => post('user/center_reset_pwd', p);
//获取学科树状
export const project_tree_list = p => get('index/get_project_list', p)

//学生发布需求

export const student_replase_question = p => post('student_center/release_question', p);

//获取需求详情

export const get_question_info = p => get('index/get_question_info', p)


export const edit_question = p => post('student_center/edit_question', p)

export const del_question = p => post('student_center/del_question', p)


export const replase_couser = p => post('teachercenter/teacher_release', p);

export const del_course = p => post('teachercenter/del_course', p)
    //更新课程

export const update_course = p => post('teachercenter/update_release', p)

//获取教师列表
export const get_teacher_list = p => post('index/get_teacher_list', p)

export const get_user_info = p => post('user/get_user_info', p)

export const get_cate_list = p => get('index/get_cate_list', p)

export const get_hot_curri_keyword = p => get('index/get_hot_curri_keyword', p)

export const get_hot_demand_keyword = p => get('index/get_hot_question_keyword', p)

//关注教师

export const student_atten_teacher = p => post('student_center/atten_teacher', p);

export const student_confirm_teacher = p => post('student_center/confirm_atten_teacher', p);

export const student_del_atten_teacher = p => post('student_center/del_atten_teacher', p);

export const get_atten_teacher_list = p => post('student_center/atten_teacher_list', p)

export const mutual_friend = p => post('index/mutual_friend', p);


//教师获取我得好友

export const get_my_friend = p => post('teachercenter/get_my_friend', p)

export const get_chat_student_friend = p => post('student_center/get_chat_student_list', p);



//获取最新聊天的好友(只获取为学生的朋友)

export const get_friend_history_list = p => post('teachercenter/get_friend_history_list', p)


export const is_set_chat_read = p => post('teachercenter/set_chat_history_read', p)

export const get_chat_history_list = p => post('teachercenter/get_history_chat_list', p);

export const send_msg = p => post('sms/send', p);

export const del_file = p => post('teachercenter/del_file', p)

export const get_ticket = p => post('wechat/getTicket', p)

export const qr_login = p => post('user/qr_login', p)

export const get_my_atten_teacher_chat = p => post('teachercenter/get_my_atten_teacher')
export const get_all_student_list = p => post('teachercenter/get_all_student_list')
    //获取教师，聊天列表

export const contacts_list = p => get('teachercenter/contacts_list', p)

export const add_contackts_list = p => post('teachercenter/add_chat_list', p);
<template>
  <div class="big-box wrap">
    <el-form ref="form" :model="form">
      <div class="box">

        <div class="coursename">
          <p>课程标题</p>
          <el-input type="text" placeholder="" v-model="form.title" maxlength="30" show-word-limit>
          </el-input>

        </div>

        <div class="guifan">*课程标题字数不得超过20字</div>
        <div class="coursename">
          <p>课程价格</p>
          <el-input type="number" placeholder="" v-model="form.price" maxlength="20" show-word-limit>
          </el-input>

        </div>

        <div class="guifan">*金币，默认0</div>
        <div class="classification">
          <p>课程分类</p>



          <el-select placeholder="请选择" v-model="form.cate_id">
            <el-option v-for="item in cate_list" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>


        </div>


        <div class="file">
          <p>课程封面</p>

          <el-upload class="avatar-uploader" :action="upload_url" name="file" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :auto-upload="true" :multiple="false"
            :data="{ filename: 'teachercouse' }">
            <img v-if="form.cover" :src="ossUrl + form.cover" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>




        </div>
        <div class="guifan">*仅支持上传 jpg，png 大小在2M范围内</div>

        <p class="kejianjie">课程简介</p>
        <div class="jianjie"><textarea name="" id="" cols="30" rows="10" v-model="form.intro"></textarea></div>
        <p class="kejianjie">课程详情</p>
        <div class="jianjie">

          <VueUeditorWrap v-model="form.content" :config="editorConfig" editor-id="editor-demo-01"></VueUeditorWrap>
        </div>




        <div class="baocun"><button type="button" @click="submit_form" v-throttle>保存</button></div>
      </div>
    </el-form>

  </div>
</template>

<script>
// Basic Use - Covers most scenarios


// Advanced Use - Hook into Quill's API for Custom Functionality
import VueUeditorWrap from 'vue-ueditor-wrap';
import { getCatelist, get_couser_info, update_course } from "@/request/course"
import { del_file, replase_couser } from "@/request/teacher.js"

import BASEURL from "@/request/baseurl"
import OSSURL from "@/request/oss"
import store from "@/store/index"
import axios from 'axios';
import { Loading } from 'element-ui';

export default {
  name: 'courseedit',
  data() {
    return {
      form: {
        id: 0,
        title: '',
        intro: '',
        cover: '',
        content: '',
        cate_id: '',
        price: 0,

        user_id: ""
      },
      medio_list: [],
      qustion_list: [],
      qar_list: [],
      ossUrl: OSSURL,
      baseurl: BASEURL,
      upload_url: BASEURL + '/api/upload/generaupload',
      imageUrl: '',
      dialogVisible: false,
      disabled: false,

      cate_list: [],
      file: '',
      Userinfo: null,
      is_login: false,
      editorConfig: {
        initialFrameHeight: 500,
        UEDITOR_HOME_URL: "/UEditor/", // 访问 UEditor 静态资源的根路径，可参考常见问题1
        serverUrl: BASEURL + "/ueditorcon/controller.php"

      },




    }
  },
  components: {

    VueUeditorWrap
  },
  created() {

    let id = this.$route.query.course_id;
    if (!id) {
      this.$message.error('缺少必备参数');
      this.$router.push("/index");
    }
    try {
      var userinfo = localStorage.getItem('Userinfo');


      if (userinfo) {

        userinfo = JSON.parse(userinfo);
        //判断时间是否过期
        if (userinfo.expiretime * 1000 < new Date().getTime()) {
          this.$router.push('/login');
        } else {


          this.is_login = true;
          this.Userinfo = userinfo;

        }

      } else {
        this.$router.push('/login');
      }
    } catch {
      this.$router.push('/login');
    }
    this.initdata(id);

  }, computed: {
    getUserInfo() {


      return store.state.Userinfo
    }

  },
  watch: {
    getUserInfo: {
      handler: function (val) {

        if (!val) {
          this.is_login = false;
        } else {
          this.is_login = true;

          this.Userinfo = JSON.parse(val);
        }

      }
    }

  },
  methods: {
    async initdata(id) {

      let res = await getCatelist();

      if (res.code === 200) {
        this.cate_list = res.data;
      }

      let result = await get_couser_info({ id: id });
      if (result.code == 200) {

        let course_info = result.data;
        let form = this.form;
        form.title = course_info.title;
        form.intro = course_info.intro;
        form.id = course_info.id;
        form.cover = course_info.cover_img;
        form.content = course_info.content;
        form.cate_id = course_info.subject_id;
        form.user_id = this.Userinfo.id;
        form.price = course_info.price

        this.form = form;



      } else {
        this.$message.error(result.msg);
        this.$router.push('/teacher/course');
      }


    },
    handleRemove(file) {


      this.imageUrl = '';
    },
    handlePreview() {

    },
    handlePictureCardPreview(file) {
      this.imageUrl = file.url;

    },
    handleDownload(file) {

    }, handleAvatarSuccess(res, file) {

      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
        return false;
      }
      if (res.code == 1) {
        this.$message({
          message: '上传封面成功',
          type: 'success'
        });
        this.form.cover = res.data.url;
      } else {
        this.$message.error(res.msg);
      }

    },
    beforeAvatarUpload(file) {



      const isJPG = file.type === 'image/jpeg';
      let isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }

    }, MedioPreview(file) {



    }, MeidoRemove(file) {


    },



    async submit_form() {

      let form = this.form;

      //
      if (!form.title) {
        this.$message.error("请输入标题");
        return false;
      }

      if (!form.cate_id) {
        this.$message.error("请选择课程分类");
        return false;
      }

      if (!form.cover) {

        this.$message.error("请上传课程封面");
        return false;

      }


      if (!form.intro) {

        this.$message.error("请输入课程简介");
        return false;

      }

      if (!form.content) {
        this.$message.error("请输入课程内容");
        return false;
      }


      if (form.content.length < 30) {
        this.$message.error("请输入课程内容不得少于30字");
        return false;
      }



      let userinfo = this.Userinfo;
      if (!userinfo) {
        this.$message.error("未登录，需要先登录");
        this.$router.push('/login');
        return false;
      }

      form.user_id = userinfo.id;
      let res = await update_course(form);
      if (res.code == 200) {
        this.$message({
          message: '编辑课程成功',
          type: 'success'
        });
        this.$router.push('/teacher/index');

      } else {
        this.$message.error(res.msg);
      }







    }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  width: 1420px !important;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 30px 40px;
  margin-top: 40px;
  margin-bottom: 30px;
}

/deep/ .el-input__inner:focus {
  border-color: #289EB8 !important;
}

.el-select-dropdown__item.selected {
  color: #289EB8;
  font-weight: 700;
}

textarea {
  font-size: 18px;
  outline: none;
}

select {
  border: 0.5px solid #ffffff;
  padding-left: 30px;
  outline: none;
  font-size: 18px;
}

input {

  outline: none;
  background-color: #F5F5F5;
  border: none;
}

.coursename {
  display: flex;

  margin-top: 79px;

  height: 50px;
  line-height: 50px;
}

.coursename p {
  width: 80px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;

  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 0 40px 0 0;
}

.coursename input {
  width: 516px;
  height: 50px;
  background: #F5F5F5;
  border-radius: 8px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #BFBFBF;
  line-height: 28px;
  padding-left: 30px;
}

.coursename /deeo/ .el-input {
  width: 516px !important;
}

.coursename /deep/ .el-color-picker__icon,
.el-input,
.el-textarea {
  width: 516px !important;
  height: 50px;
  font-size: 18px;
  font-family: PingFang SC;
}

.el-select-dropdown__item {

  text-align: center;

}

.classification {
  display: flex;
  display: -webkit-flex;

  height: 50px;
  line-height: 50px;

}

.classification p {
  font-size: 20px;
  height: 50px;

  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;

  margin: 0 40px 0 0;
  line-height: 50px;
}

.classification select {
  width: 551px;
  height: 50px;
  background: #F5F5F5;
  border-radius: 8px;
}

.classification select option {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;

}

.file {
  display: flex;
  margin-top: 35px;

}

.el-upload-list {
  margin-left: 40px !important;
}

.avatar-uploader {
  margin-left: 40px;
}


.avatar-uploader .el-upload {

  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;

}

.avatar {
  width: 150px;
  height: 150px;
  display: block;

}

.el-upload-dragger {
  border: none;
}

.file input {
  margin-top: 20px;

}

.guifan {
  display: flex;

  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FF8620;
  height: 50px;
  line-height: 50px;
  width: 300px;
  margin-left: 120px;
}

.kejianjie {
  display: flex;

  margin-top: 35px;
}

.jianjie textarea {
  border: 1px solid #FFFFFF;

  display: flex;
  width: 1375px;
  height: 185px;
  background: #f6f6f6;
  border-radius: 6px;

  padding: 20px;
}

.baocun {
  display: flex;
  width: 300px;
  margin: 60px auto 0;
}

.baocun button {
  cursor: pointer;
  border: 0.5px solid #FFFFFF;
  width: 300px;
  height: 62px;
  background: #289EB8;
  border-radius: 6px;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}


.ziliao {

  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
}

.ziliao .list {

  width: 500px;

  min-height: 350px;
}

.ziliao .list .title {
  text-align: left;
  font-size: 26px;
}

.ziliao .input-file-list {
  display: flex;
  display: -webkit-flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.ziliao .list-content {
  margin-top: 30px;
}

.ziliao .upload-demo {
  display: flex;
  display: -webkit-flex;
  flex-direction: column-reverse;

}




.ziliao .upload-img-bn {
  margin-top: 20px;
  float: left;

}

.ziliao .el-upload__tip {
  color: #FF8620;
  text-align: left;
  height: 50px;
  line-height: 50px;
}

ul {
  margin: 0;
  padding: 0;
}

ul>li {

  padding: 0;
  margin: 0;
  list-style: none !important;
}

.medio-list .medio-info {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  width: 455px;

}

.medio-list .medio-info .medio-img {
  height: 120px;
  height: 100px;

}


.medio-info {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  margin-bottom: 20px !important;

}

.medio-info .medio-img {
  width: 120px;
  height: 100px;


}

.medio-info .medio-img video {
  width: 100%;
  height: 100%;
}

.medio-info .input {
  width: 230px;
  height: 50px;
  margin-top: 25px;
  margin-left: 30px;

}

.medio-info input {
  width: 230px;
  height: 50px;
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 8px;

}

.medio-info .delete {

  line-height: 100px;
  width: 80px;
  text-align: center;
  color: #EA440A;
}


.medio-list .new-info {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 455px;
  margin-bottom: 25px;
}

.medio-list .new-info .icon-img {
  width: 45px;
  height: 40px;
}

.medio-list .new-info .icon-img img {
  width: 45px;
  height: 40px;
  margin-top: 5px;
}

.medio-list .new-info .input {
  text-align: left;
  height: 50px;
  line-height: 50px;
  margin-left: 22px;
  width: 390px;
}

.medio-list .new-info .delete {
  width: 80px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  color: #EA440A
}
</style>
<template>
  <div class="big-box wrap">
    <el-form ref="form" :model="form">
      <div class="box">

        <div class="coursename">
          <p>课程标题</p>
          <el-input type="text" placeholder="" v-model="form.title" maxlength="30" show-word-limit>
          </el-input>

        </div>

        <div class="guifan">*课程标题字数不得超过20字</div>
        <div class="coursename">
          <p>课程价格</p>
          <el-input type="number" placeholder="" v-model="form.price" maxlength="20" show-word-limit>
          </el-input>

        </div>

        <div class="guifan">*U币，默认0</div>

        <div class="classification">
          <p>课程分类</p>



          <el-select placeholder="请选择" v-model="form.cate_id">
            <el-option v-for="item in cate_list" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>


        </div>


        <div class="file">
          <p>课程封面</p>

          <el-upload class="avatar-uploader" :action="upload_url" name="file" :show-file-list="false" accept=".png,.jpg"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" :auto-upload="true" :multiple="false"
            :data="{ filename: 'teachercouse' }">
            <img v-if="form.cover" :src="ossurl + form.cover" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>




        </div>
        <div class="guifan">*仅支持上传 jpg，png 大小在2M范围内</div>

        <p class="kejianjie">课程简介</p>
        <div class="jianjie"><textarea name="" id="" cols="30" rows="10" v-model="form.intro"></textarea></div>
        <p class="kejianjie">课程详情</p>
        <div class="jianjie">

          <VueUeditorWrap v-model="form.content" :config="editorConfig" editor-id="editor-demo-01"></VueUeditorWrap>
        </div>




        <div class="ziliao">

          <div class="list">
            <div class="title">上传试听视频</div>
            <div class="list-content">
              <div class="input-file-list">




              </div>
              <div>

                <div class="medio-list">
                  <ul>
                    <li class="medio-info" v-for="(item, index) in medio_list" :key="index">
                      <div class="medio-img">
                        <video ref="veo" :style="`object-fit: ${zoom};`" :src="ossurl + item.url" :poster="videoCover"
                          :width="width" :height="height" :autoplay="false" :controls="!originPlay && controls"
                          :loop="loop" :muted="autoplay || muted" :preload="preload" @click.prevent.once="onPlay">

                        </video>
                      </div>
                      <div class="input">

                        <input v-model="medio_list[index].name">

                      </div>
                      <div class="delete" @click="delete_file(item.url, index)">删除</div>


                    </li>

                  </ul>
                </div>
                <el-upload class="upload-demo" :action="upload_url" :show-file-list="false"
                  :data="{ filename: 'teachervideo' }" name="file" accept=".mp4" :http-request="upload_medio">
                  <div>

                    <el-button size="small" type="primary" class="upload-img-bn"></el-button>
                  </div>


                </el-upload>

              </div>

              <div slot="tip" class="el-upload__tip">*仅上传2个视频(100兆以内,仅mp4) 上传更多视频请到个人中心上传</div>

            </div>

          </div>
          <div class="list">
            <div class="title">上传模拟试题</div>
            <div class="list-content">
              <div class="input-file-list">




              </div>
              <div>

                <div class="medio-list" v-for="(item, index) in qustion_list" :key="index">
                  <ul>
                    <li class="new-info">
                      <div class="icon-img">
                        <img src="./../../assets/img/icon/ppt.png" alt="" v-if="item.file_type == 1">
                        <img src="./../../assets/img/icon/ppt.png" alt="" v-else-if="item.file_type == 2">
                        <img src="./../../assets/img/icon/word.png" alt="" v-else>
                      </div>
                      <div class="input">

                        <input v-model="qustion_list[index].name">


                      </div>
                      <div class="delete" @click="delete_question_file(item.url, index)">删除</div>


                    </li>


                  </ul>
                </div>



                <el-upload class="upload-demo" :action="upload_url" :show-file-list="false"
                  :data="{ filename: 'teacherquestion' }" name="file" accept=".docx" :http-request="uplaod_question">
                  <div>

                    <el-button size="small" type="primary" class="upload-img-bn"></el-button>
                  </div>


                </el-upload>

              </div>

              <div slot="tip" class="el-upload__tip">*仅上传3个文档 上传更多文档请到个人中心上传(仅支持docx格式)</div>

            </div>
          </div>
          <div class="list">
            <div class="title">上传作业练习</div>
            <div class="list-content">
              <div class="input-file-list">




              </div>
              <div>

                <div class="medio-list">
                  <ul>
                    <li class="new-info" v-for="(item, index) in qar_list" :key="index">
                      <div class="icon-img">
                        <img src="./../../assets/img/icon/ppt.png" alt="" v-if="item.file_type == 1">
                        <img src="./../../assets/img/icon/ppt.png" alt="" v-else-if="item.file_type == 2">
                        <img src="./../../assets/img/icon/word.png" alt="" v-else>
                      </div>
                      <div class="input">

                        <input v-model="qar_list[index].name">
                      </div>
                      <div class="delete" @click="delete_qar_file(item.url, index)">删除</div>


                    </li>


                  </ul>
                </div>



                <el-upload class="upload-demo" :action="upload_url" :show-file-list="false"
                  :data="{ filename: 'teacherpar' }" name="file" accept=".docx" :http-request="uplaod_par">
                  <div>

                    <el-button size="small" type="primary" class="upload-img-bn"></el-button>
                  </div>


                </el-upload>

              </div>

              <div slot="tip" class="el-upload__tip">*仅上传3个试题 上传更多试题请到个人中心上传(仅支持docx格式)</div>

            </div>
          </div>



        </div>
        <div class="baocun"><button type="button" @click="submit_form" v-throttle>保存</button></div>
      </div>
    </el-form>

  </div>
</template>

<script>
// Basic Use - Covers most scenarios


// Advanced Use - Hook into Quill's API for Custom Functionality
import VueUeditorWrap from 'vue-ueditor-wrap';

import { getCatelist } from "@/request/course"
import { del_file, replase_couser } from "@/request/teacher.js"
import BASEURL from "@/request/baseurl"
import OSSURL from "@/request/oss"
import store from "@/store/index"
import axios from 'axios';
import { Loading } from 'element-ui';

export default {
  name: 'editing',
  data() {
    return {
      form: {
        id: 0,
        title: '',
        intro: '',
        cover: '',
        content: '',
        cate_id: '',
        medio_list: [],
        qustion_list: [],
        qar_list: [],
        user_id: "",
        price: 0
      },
      medio_list: [],
      qustion_list: [],
      qar_list: [],
      ossurl: OSSURL,
      baseurl: BASEURL,
      upload_url: BASEURL + '/api/upload/generaupload',
      imageUrl: '',
      dialogVisible: false,
      disabled: false,
      editorConfig: {
        initialFrameHeight: 500,
        initialFrameWidth: 935,
        UEDITOR_HOME_URL: "/UEditor/", // 访问 UEditor 静态资源的根路径，可参考常见问题1
        serverUrl: BASEURL + "/ueditorcon/controller.php",
        imagePath: BASEURL

      },
      cate_list: [],
      file: '',
      Userinfo: null,
      is_login: false,



    }
  },
  components: {

    VueUeditorWrap

  },
  created() {
    try {
      var userinfo = localStorage.getItem('Userinfo');


      if (userinfo) {

        userinfo = JSON.parse(userinfo);
        //判断时间是否过期
        if (userinfo.expiretime * 1000 < new Date().getTime()) {
          this.$router.push('/login');
        } else {


          this.is_login = true;
          this.Userinfo = userinfo;

        }

      } else {
        this.$router.push('/login');
      }
    } catch {
      this.$router.push('/login');
    }



    this.initdata();

  }, computed: {
    getUserInfo() {


      return store.state.Userinfo
    }

  },
  watch: {
    getUserInfo: {
      handler: function (val) {

        if (!val) {
          this.is_login = false;
        } else {
          this.is_login = true;

          this.Userinfo = JSON.parse(val);
        }

      }
    }

  },
  methods: {
    async initdata() {

      let res = await getCatelist();

      if (res.code === 200) {
        this.cate_list = res.data;
      }


    },
    handleRemove(file) {


      this.imageUrl = '';
    },
    handlePreview() {

    },
    handlePictureCardPreview(file) {
      this.imageUrl = file.url;

    },
    handleDownload(file) {

    }, handleAvatarSuccess(res, file) {


      if (res.code == 1) {
        this.$message({
          message: '上传封面成功',
          type: 'success'
        });
        this.form.cover = res.data.url;
      } else {
        this.$message.error(res.msg);
      }

    },

    beforeAvatarUpload(file) {



      const isJPG = file.type === 'image/jpeg';
      let isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.$message.error('上传头像图片只能是 JPG或PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }

    }, MedioPreview(file) {



    }, MeidoRemove(file) {


    },


    async upload_medio(e) {

      let loadingInstance = Loading.service({ text: "视频正在上传，请耐心等待" });
      let that = this;

      let file = e.file;

      var ext_index = file.name.lastIndexOf(".");
      var ext = file.name.substring(ext_index + 1);
      if (ext != 'mp4') {
        this.$message.error("仅支持mp4");
        return false;
      }

      let filesize = file.size;
      let filename = file.name;
      const isLtM = file.size / 1024 / 1024 < 100;
      if (!isLtM) {
        this.$message.error("请上传100M以下的视频文件");
        return false;
      }

      const formData = new FormData();  // 声明一个FormData对象
      formData.append("file", file);
      formData.append("filename", 'teachervideo ')

      let result = await axios.post(e.action, formData, {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data"
        },
        timeout: 300000
      })

      if (result.data.code == 1) {
        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
        this.$message({
          message: '上传视频成功',
          type: 'success'
        });
        //构造数组，追加到数组视频列表中去
        let medio_info = { 'url': result.data.data.url, 'filesize': file.size / 1024 / 1024, name: file.name };

        let medio_list = that.medio_list;
        medio_list.push(medio_info);
        that.medio_list = medio_list;


      } else {
        this.$message.error("上传失败，请重新上传");
      }




    }, async handleImageAdded(file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("filename", 'fuwenben')
      const res = await uploads(formData)

      Editor.insertEmbed(cursorLocation, "image", res.data.url);
      resetUploader();



    },


    //上传模拟试题
    async uplaod_question(e) {

      let that = this;

      let file = e.file;


      var ext_index = file.name.lastIndexOf(".");
      var ext = file.name.substring(ext_index + 1);
      if (ext != 'docx') {
        this.$message.error("仅支持docx word 文件");
        return false;
      }


      let filesize = file.size;
      let filename = file.name;
      const isLtM = file.size / 1024 / 1024 < 10;
      if (!isLtM) {
        this.$message.error("请上传10M以下的试题文件");
        return false;
      }

      const formData = new FormData();  // 声明一个FormData对象
      formData.append("file", file);
      formData.append("filename", 'teacherquestion ')

      let result = await axios.post(e.action, formData, {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      if (result.data.code == 1) {
        this.$message({
          message: '上传试题成功',
          type: 'success'
        });
        //构造数组，追加到数组视频列表中去
        var ext_index = file.name.lastIndexOf(".");
        //获取后缀
        var ext = file.name.substring(ext_index + 1);
        var file_type = 3;


        let question_info = { 'url': result.data.data.url, 'filesize': file.size / 1024 / 1024, 'name': file.name, 'file_type': file_type };

        let qustion_list = that.qustion_list;
        qustion_list.push(question_info);
        that.qustion_list = qustion_list;


      } else {
        this.$message.error(result.data.msg);
      }






    }, //上传作业
    async uplaod_par(e) {

      let that = this;
      let file = e.file;

      var ext_index = file.name.lastIndexOf(".");
      var ext = file.name.substring(ext_index + 1);
      if (ext != 'docx') {
        this.$message.error("仅支持docx word 文件");
        return false;
      }


      let filesize = file.size;
      let filename = file.name;
      const isLtM = file.size / 1024 / 1024 < 10;
      if (!isLtM) {
        this.$message.error("请上传10M以下的作业练习");
        return false;
      }

      const formData = new FormData();  // 声明一个FormData对象
      formData.append("file", file);
      formData.append("filename", 'teacherpar ')

      let result = await axios.post(e.action, formData, {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })

      if (result.data.code == 1) {
        this.$message({
          message: '上传作业成功',
          type: 'success'
        });
        //构造数组，追加到数组视频列表中去
        var ext_index = file.name.lastIndexOf(".");
        //获取后缀
        var ext = file.name.substring(ext_index + 1);
        var file_type = 3;


        let question_info = { 'url': result.data.data.url, 'filesize': file.size / 1024 / 1024, 'name': file.name, 'file_type': file_type };

        let qar_list = that.qar_list;
        qar_list.push(question_info);
        that.qar_list = qar_list;


      } else {
        this.$message.error(result.data.msg);
      }
    },


    //删除文件(课程视频)

    async delete_file(file_url, index) {

      try {
        if (!file_url) {
          this.$message.error("删除文件不能为空");
        }

        let res = await del_file({ 'file_url': file_url });
        if (res == 2) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });

          let medio_list = this.medio_list;
          medio_list.splice(index, 1);

        } else {
          this.$message({
            message: '删除失败',
            type: 'success'
          });
        }

      } catch {
        this.$message.error("出现意外错误，请刷新重试");
      }


    },//删除模拟试题
    async delete_question_file(file_url, index) {

      try {
        if (!file_url) {
          this.$message.error("删除文件不能为空");
        }

        let res = await del_file({ 'file_url': file_url });
        if (res == 2) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });

          let qustion_list = this.qustion_list;
          qustion_list.splice(index, 1);

        } else {
          this.$message({
            message: '删除失败',
            type: 'success'
          });
        }

      } catch {
        this.$message.error("出现意外错误，请刷新重试");
      }


    }, async delete_qar_file(file_url, index) {

      try {
        if (!file_url) {
          this.$message.error("删除文件不能为空");
        }

        let res = await del_file({ 'file_url': file_url });
        if (res == 2) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });

          let qar_list = this.qar_list;
          qar_list.splice(index, 1);

        } else {
          this.$message({
            message: '删除失败',
            type: 'success'
          });
        }

      } catch {
        this.$message.error("出现意外错误，请刷新重试");
      }



    }, async submit_form() {

      let form = this.form;

      //
      if (!form.title) {
        this.$message.error("请输入标题");
        return false;
      }


      if (!(form.price >= 0)) {

        this.$message.error("价格格式不正确");
        return false;

      }

      if (!form.cate_id) {
        this.$message.error("请选择课程分类");
        return false;
      }

      if (!form.cover) {

        this.$message.error("请上传课程封面");
        return false;

      }


      if (!form.intro) {

        this.$message.error("请输入课程简介");
        return false;

      }

      if (!form.content) {
        this.$message.error("请输入课程内容");
        return false;
      }


      if (form.content.length < 30) {
        this.$message.error("请输入课程内容不得少于30字");
        return false;
      }



      form.medio_list = this.medio_list;
      form.qustion_list = this.qustion_list;
      form.qar_list = this.qar_list;
      let userinfo = this.Userinfo;
      if (!userinfo) {
        this.$message.error("未登录，需要先登录");
        this.$router.push('/login');
        return false;
      }

      form.user_id = userinfo.id;
      let res = await replase_couser(form);
      if (res.code == 200) {
        this.$message({
          message: '发布成功',
          type: 'success'
        });
        this.$router.push('/teacher/course');

      } else {
        this.$message.error(res.msg);
      }







    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .el-input__inner::placeholder {

  color: #606266 !important;

}

.el-select-dropdown__item.selected {
  color: #289EB8;
  font-weight: 700;
}

/deep/ .el-input__inner:focus {
  border-color: #289EB8 !important;
}

.big-box {
  margin-bottom: 30px;
}

.wrap {
  width: 1420px !important;
  background-color: #ffffff;
  border-radius: 6px;
  padding: 30px 40px;
  margin-top: 40px;
}

textarea {
  font-size: 18px;
  outline: none;
}

select {
  border: 0.5px solid #ffffff;
  padding-left: 30px;
  outline: none;
  font-size: 18px;
}

input {

  outline: none;
  background-color: #F5F5F5;
  border: none;
}

.coursename {
  display: flex;

  margin-top: 30px;

  height: 50px;
  line-height: 50px;
}

.coursename p {
  width: 80px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;

  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  margin: 0 40px 0 0;
}

.coursename input {
  width: 516px;
  height: 50px;
  background: #F5F5F5;
  border-radius: 8px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #BFBFBF;
  line-height: 28px;
  padding-left: 30px;
}

.coursename /deeo/ .el-input {
  width: 516px !important;
}

.coursename /deep/ .el-color-picker__icon,
.el-input,
.el-textarea {
  width: 516px !important;
  height: 50px;
  font-size: 18px;
  font-family: PingFang SC;
}

.el-select-dropdown__item {

  text-align: center;

}

.classification {
  display: flex;
  display: -webkit-flex;

  height: 50px;
  line-height: 50px;

}

.classification p {
  font-size: 20px;
  height: 50px;

  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;

  margin: 0 40px 0 0;
  line-height: 50px;
}

.classification select {
  width: 551px;
  height: 50px;
  background: #F5F5F5;
  border-radius: 8px;
}

.classification select option {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;

}

.file {
  display: flex;
  margin-top: 35px;

}

.el-upload-list {
  margin-left: 40px !important;
}

.avatar-uploader {
  margin-left: 40px;
}


.avatar-uploader .el-upload {

  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
  border: 1px dashed #d9d9d9 !important;

}

.avatar {
  width: 150px;
  height: 150px;
  display: block;

}

.el-upload-dragger {
  border: none;
}

.file input {
  margin-top: 20px;

}

.guifan {
  display: flex;

  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FF8620;
  height: 50px;
  line-height: 50px;
  width: 300px;
  margin-left: 120px;
}

.kejianjie {
  display: flex;

  margin-top: 35px;
}

.jianjie textarea {
  border: 1px solid #FFFFFF;

  display: flex;
  width: 1375px;
  height: 185px;
  background: #f6f6f6;
  border-radius: 6px;

  padding: 20px;
}

.baocun {
  display: flex;
  width: 300px;
  margin: 60px auto 0;
}

.baocun button {
  cursor: pointer;
  border: 0.5px solid #FFFFFF;
  width: 300px;
  height: 62px;
  background: #289EB8;
  border-radius: 6px;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}


.ziliao {

  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 60px;
}

.ziliao .list {

  width: 500px;

  min-height: 350px;
}

.ziliao .list .title {
  text-align: left;
  font-size: 26px;
}

.ziliao .input-file-list {
  display: flex;
  display: -webkit-flex;
  -ms-flex-direction: row;
  flex-direction: row;
}

.ziliao .list-content {
  margin-top: 30px;
}

.ziliao .upload-demo {
  display: flex;
  display: -webkit-flex;
  flex-direction: column-reverse;

}


.ziliao .upload-demo button {

  width: 100px;
  height: 100px;
  border-radius: 5px;

  background: url('./../../assets/img/upload.png') no-repeat #ffffff;
  background-size: 30px 30px;
  background-position: center;
  border: 1px dashed #d9d9d9 !important;

}


.ziliao .upload-img-bn {
  margin-top: 20px;
  float: left;

}

.ziliao .el-upload__tip {
  color: #FF8620;
  text-align: left;
  height: 50px;
  line-height: 50px;
}

ul {
  margin: 0;
  padding: 0;
}

ul>li {

  padding: 0;
  margin: 0;
  list-style: none !important;
}

.medio-list .medio-info {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  width: 455px;

}

.medio-list .medio-info .medio-img {
  height: 120px;
  height: 100px;

}


.medio-info {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  margin-bottom: 20px !important;

}

.medio-info .medio-img {
  width: 120px;
  height: 100px;


}

.medio-info .medio-img video {
  width: 100%;
  height: 100%;
}

.medio-info .input {
  width: 230px;
  height: 50px;
  margin-top: 25px;
  margin-left: 30px;

}

.medio-info input {
  width: 230px;
  height: 50px;
  background-color: #ffffff !important;
  border: none !important;
  border-radius: 8px;

}

.medio-info .delete {

  line-height: 100px;
  width: 80px;
  text-align: center;
  color: #EA440A;
}


.medio-list .new-info {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: space-between;
  width: 455px;
  margin-bottom: 25px;
}

.medio-list .new-info .icon-img {
  width: 45px;
  height: 40px;
}

.medio-list .new-info .icon-img img {
  width: 45px;
  height: 40px;
  margin-top: 5px;
}

.medio-list .new-info .input {
  text-align: left;
  height: 50px;
  line-height: 50px;
  margin-left: 22px;
  width: 390px;
}

.medio-list .new-info .delete {
  width: 80px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  color: #EA440A
}
</style>
<template>
  <!-- <div class="left"> -->

  <!-- <teachercenter :userInfo="userinfo"></teachercenter> -->
  <!-- </div> -->
  <div class="course right">
    <div class="course-search">
      <div class="search">
        <img src="../../../assets/img/搜索.png" alt="">
        <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_meido">
      </div>
      <button @click="search_meido">搜索</button>
    </div>
    <div class="course-content">
      <div class="content-header">
        <p>全部视频</p>
        <p>已审核({{ exam_num }})</p>
        <p>未审核({{ dai_exam_num }})</p>
      </div>
      <div class="content-body">

        <div class="body-content">
          <ul>
            <li class="body-header">
              <div class="xuhao">序号</div>
              <div class="biaoti">视频标题</div>
              <div class="biaoti">课程标题</div>
              <div class="zhuangtai">状态</div>
              <div class="shuliang">订购数</div>
              <div class="liulan">浏览量</div>
              <div class="liulan">操作</div>

            </li>


            <li v-for="(item, index) in medio_list.list" :key="index">
              <div class="xuhao" @click="play_medio(index)">{{ index + 1 }}</div>
              <div class="biaoti" @click="play_medio(index)">{{ item.title }}</div>
              <div class="biaoti" @click="play_medio(index)">{{ item.curri.title }}</div>
              <div class="zhuangtai" @click="play_medio(index)" v-if="item.statue == 0">待审核</div>
              <div class="zhuangtai" @click="play_medio(index)" v-else-if="item.statue == 1">已审核</div>
              <div class="zhuangtai" @click="play_medio(index)" v-else>未通过</div>
              <div class="shuliang">0</div>
              <div class="liulan">{{ item.play_num }}</div>
              <div class="caozuo">
                <div class="bianji" @click="edit_medio(index)">编辑</div>
                <div class="shanchu" @click="delete_medio(index)">删除</div>
              </div>
            </li>
          </ul>

        </div>
      </div>

      <div class="page">

        <el-pagination @current-change="medio_change" :current-page="medio_list.page" :page-sizes="[1]"
          :page-size="medio_list.pagesize" layout="prev, pager, next, jumper" :total="medio_list.total">
        </el-pagination>
      </div>
    </div>

    <div class="tanchuang" v-if="showModal">
      <div class="cha" v-if="showModal" @click="close_model"></div>
      <h4>视频编辑</h4>
      <ul>
        <li class="video-bianji">
          <video :src="baseurl + medio_info.medio_url" :autoplay="false" controls="controls"></video>
          <div class="shangchuan">
            <el-upload class="avatar-uploader" :show-file-list="false" :action="upload_url"
              :data="{ filename: 'teachervideo' }" name="file" accept="mp4" :http-request="upload_medio">
              <el-button size="small" type="primary">上传</el-button>

            </el-upload>


          </div>
          <div class="ruanjian">
            <input type="text" v-model="medio_info.title" />
          </div>
          <div class="jinbi">
            <input type="text" class="input-jinbi" v-model="medio_info.price">
            <div>U币</div>
          </div>
        </li>
      </ul>
      <button class="tijiao" @click="submit_edit">保存</button>
    </div>

    <div v-if="play_model">
      <div class="zheyan"></div>

      <div class="meido-play-model">
        <div class="meido-header">
          <div class="el-icon-close close" @click="close_medio_play"></div>
        </div>
        <div class="mideo-content">

          <video-player :options="videoOptions" class="video" />

        </div>
        <div class="medio-title">
          {{ video_info.title }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>


import BASEURL from "./../../../request/baseurl"
import OSSURL from "@/request/oss"
import store from "./../../../store/index"
import { get_medio_list, delete_medio, editMedio } from "@/request/teacher.js"
import VideoPlayer from "@/components/VideoPlayer.vue";
import { Loading } from 'element-ui';
import axios from 'axios';
export default {
  name: 'teacherMedio',
  data() {
    return {
      userinfo: {},
      baseurl: BASEURL,
      is_default_index: '',
      showModal: false,
      ossurl:OSSURL,
      activeName: 'first',
      medio_list: {
        total: 0,
        current: 1,
        pagesize: 5,
        list: []

      },
      exam_num: 0,
      dai_exam_num: 0,
      medio_info: {
        id: '',
        title: '',
        medio_url: '',
        statue: '',
        medio_size: 0,
        price: 0,

      },
      videoOptions: {
        autoplay: false,
        controls: true,
        sources: [
          {
            src: "",
            type: "video/mp4"
          }
        ]
      },
      play_model: false,
      video_info: {},
      course_id: null,
      active_index: -1,
      upload_url: BASEURL + '/api/upload/generaupload',
    }
  }, components: {

    VideoPlayer,
  }, created() {
    let is_default_index = store.state.left_menu_default;
    this.is_default_index = is_default_index;
    var userinfo = localStorage.getItem('Userinfo');
    userinfo = JSON.parse(userinfo);

    if (userinfo) {


      if (userinfo.expiretime * 1000 < new Date().getTime()) {

        this.$router.push('/login');
        localStorage.clear("Userinfo");
        localStorage.clear('Authorization');

      } else {


        this.userinfo = userinfo;
      }

    } else {
      this.$router.push('/login');
    }
    let course_id = this.$route.query.course_id;

    if (!course_id) {
      course_id = ''
    } else {
      this.course_id = course_id;
    }


    this.get_list(this.medio_list.current, this.medio_list.pagesize, '', course_id);

  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },

    async get_list(current, pagesize, keyword, course_id) {

      let res = await get_medio_list({ current: current, pagesize: pagesize, keyword: keyword, course_id: course_id });
      if (res.code === 200) {
        let medio_list = this.medio_list;
        medio_list.total = Number(res.data.total)
        medio_list.current = Number(res.data.current)
        medio_list.pagesize = Number(res.data.pagesize)
        medio_list.list = res.data.list
        this.medio_list = medio_list
        this.exam_num = res.data.exam_num;
        this.dai_exam_num = res.data.dai_exam_num;

      } else {
        this.$message.error(res.msg);
      }


    }, search_meido() {

      let keyword = this.keyword;
      let course_id = this.course_id;

      this.get_list(1, this.medio_list.pagesize, keyword, course_id)



    }, medio_change(pager) {
      let keyword = this.keyword;
      let course_id = this.course_id;

      this.get_list(pager, this.medio_list.pagesize, keyword)

    },
    //编辑视频
    async edit_medio(index) {
      let medio_list = this.medio_list;

      let medio_info = medio_list.list[index];
      this.active_index = index;

      this.showModal = true;
      this.medio_info = medio_info;

    }, close_model() {
      this.showModal = false;
    },


    async delete_medio(index) {

      let medio_list = this.medio_list;

      let medio_info = medio_list.list[index];


      let res = await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      });

      if (res == 'confirm') {

        let result = await delete_medio({ id: medio_info.id });
        if (result.code === 200) {
          this.$message({
            message: '删除成功',
            type: 'success'
          });

          medio_list.list.splice(index, 1);

          this.medio_list = medio_list;
        } else {
          this.$message.error(res.msg);
        }
      }



    }, async submit_edit() {

      let medio_info = this.medio_info;
      if (!medio_info.medio_url) {
        this.$message.error("请上传视频");
        return false;
      }

      if (!medio_info.title) {

        this.$message.error('视频名称不能为空');
        return false;
      }

      let res = await editMedio(medio_info);
      if (res.code == 200) {

        this.$message.success("编辑成功");
        let medio_list = this.medio_list;
        //操作哪个的视频列表下标
        let active_index = this.active_index;
        medio_list.list[active_index] = medio_info;
        this.medio_list = medio_list;

      } else {
        this.$message.error(res.msg);

      }






    }, async upload_medio(e) {

      let loadingInstance = Loading.service({ text: "视频正在上传，请耐心等待" });
      let that = this;
      let file = e.file;
      let filesize = file.size;
      let filename = file.name;
      const isLtM = file.size / 1024 / 1024 < 100;
      if (!isLtM) {
        this.$message.error("请上传100M以下的视频文件");
        return false;
      }

      const formData = new FormData();  // 声明一个FormData对象
      formData.append("file", file);
      formData.append("filename", 'teachervideo ')

      let result = await axios.post(e.action, formData, {
        //添加请求头
        headers: {
          "Content-Type": "multipart/form-data"
        },
        timeout:300000
      })

      if (result.data.code == 1) {
        this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
          loadingInstance.close();
        });
        this.$message({
          message: '上传视频成功',
          type: 'success'
        });
        //构造数组，追加到数组视频列表中去

        let medio_info = this.meido_info;
        medio_info.medio_url = result.data.data.url
        medio_info.filesize = file.size / 1024 / 1024;


        this.medio_info = medio_info;




      } else {
        this.$message.error("上传失败，请重新上传");
      }




    },     //播放视频
    play_medio(index) {

      let list = this.medio_list.list;
      this.video_info = list[index];


      let videoOptions = this.videoOptions;
      videoOptions.sources[0].src = this.ossurl + list[index].medio_url;


      this.videoOptions = videoOptions;
      this.play_model = true;

    }, close_medio_play() {

      this.play_model = false;
    }


  }

}
</script>

<style lang="less"  scoped >
@import url("./../../../assets/css/teacher_common.less");

li {
  list-style: none;
}

.video-bianji {
  display: flex;
}

.shangchuan {
  cursor: pointer;
  text-align: center;
  font-size: 15px;
  color: #EA440A;
  line-height: 85px;
  margin: 0 5px;
}

.video-bianji video {
  width: 100px;
  height: 85px;
}

.video-bianji .ruanjian {
  overflow: hidden;
  margin-top: 25px;
  padding-left: 20px;
  width: 320px;
  height: 37px;
  background: #F8F8F8;
  border-radius: 8px;
  font-size: 13px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 35px;
}

.video-bianji .ruanjian input {
  width: 320px;
  height: 37px;
  background: #F8F8F8;
  border: none;
}

.center-main {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  margin-top: 40px;

}

h4 {
  margin-left: 30px;
  margin-top: 5px;
}

.xuhao {
  width: 80px;
  height: 52px;
  text-align: center;
}

.zhuangtai {
  text-align: center;
  width: 120px;
  margin: 0 0px;
}

.zuoye {
  display: flex;
  margin-top: 30px;
}

.zuoye .input-jinbi {
  width: 40px;
  height: 20px;
  margin-right: 10px;
}

.zuoye .video-input {
  width: 500px;
  height: 45px;
  background: #F8F8F8;
  border-radius: 8px;
  border: 1px solid #ECECEC;
}

.zuoye .jinbi {
  margin-top: 10px;
}

.zuoye .delect {
  color: #EA440A;
  line-height: 49px;
}

.bangong {
  width: 45px;
  height: 40px;
  margin-right: 30px;
  margin-top: 5px;
}

.tijiao {
  display: block;
  margin: 20px auto;
  width: 161px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  background: #289EB8;
  border-radius: 6px;
  color: #FFFFFF;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
}

.tianjia {
  width: 91px;
  height: 80px;
  background: #F8F8F8;
  border-radius: 8px;
  margin: 20px auto;
  color: #ADADAD;
  font-size: 45px;
  text-align: center;
  padding-top: 5px;
}

.jinbi {
  margin-left: 20px;
  display: flex;
  margin-top: 30px;
  margin-right: 20px;
}

.input-jinbi {
  width: 40px;
  height: 20px;
  margin-right: 10px;
}

input {
  outline: none;
}

.video {
  display: flex;
}

.video video {
  width: 100px;
  height: 100px;
}

.video .video-input {
  margin-top: 28px;
  width: 500px;
  height: 45px;
  background: #F8F8F8;
  border-radius: 8px;
  border: 1px solid #ECECEC;
}

.video .delect {
  margin-top: 40px;
  color: #EA440A;
}

.left {
  width: 310px;

}

.body-content ul {
  margin: 0;
  padding-left: 42px;
  padding-bottom: 30px;
}

.body-content ul li {
  list-style: none;
  height: 52px;
  font-size: 18px;
}

.body-content ul li:first-child {
  cursor: auto;
}

button {
  cursor: pointer;
  border: 0.5px solid #E9E9E9;
}

.biaoti {
  margin: 0 0px;
  width: 240px;
  text-align: center;
  overflow: hidden;
}

.shuliang {
  text-align: center;
  width: 120px;
  margin: 0 0px;
}

.liulan {
  width: 115px;
  text-align: center;
  margin: 0 0px;
}

.caozuo {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  width: 120px;
  text-align: center ! important;
  justify-content: space-between;
}

.bianji {
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #EA440A;
  width: 60px !important;
  text-align: center;
}

.shanchu {

  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #EA440A;
  width: 60px !important;
}


.course {
  width: 1150px;
  /* height: 626px; */
  overflow: auto;
  height: auto;
  background: #FFFFFF;
  border-radius: 6px;

}

.course-search {
  margin-left: 31px;
  padding-top: 51px;
  display: flex;
}

.course .search {
  position: relative;
  width: 653px;
  height: 50px;
  border: 2px solid #ECECEC;
  border-radius: 21px;
}

.course .search input {
  outline: none;
  margin-left: 55px;
  border-style: none;
  width: 570px;
  height: 45px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #ADADAD;
  line-height: 45px;
  margin-top: 2.5px;
}

.course img {
  position: absolute;
  left: 20px;
  top: 11px;
  width: 28px;
  height: 28px;
}

.course-search button {
  cursor: pointer;
  width: 115px;
  height: 50px;
  border: 1px solid #FFFFFF;
  background: #289EB8;
  border-radius: 8px;
  margin-left: 36px;
  margin-top: 0px;
  font-size: 22px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 50px;
}

.content-header {
  display: flex;
  margin-left: 74px;
  margin-top: 20px;
}

.content-header p {
  cursor: pointer;
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #323B46;
}

.content-header p:nth-child(2) {
  margin-left: 122px;
  width: 160px;
  text-align: center;
}

.content-header p:nth-child(3) {
  margin-left: 131px;
  width: 160px;
  text-align: center;
}

.content-body {
  margin-left: 35px;
  margin-top: 0px;
  margin-bottom: 50px;
  width: 1100px;
  background: #FFFFFF;
  box-shadow: 0px 0px 65px 0px rgba(51, 51, 51, 0.1);
  border-radius: 16px;
}

.content-body .body-header {
  display: flex;
  width: 100%;
  font-size: 22px !important;
  font-family: PingFang SC;
  font-weight: 500;
  color: #606060 !important
}

.content-body .body-header p {
  font-size: 24px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #323B46;
}

.content-body .body-header p:nth-child(1) {
  margin-left: 37px;
}

.content-body .body-header p:nth-child(2) {
  margin-left: 149px;
}

.content-body .body-header p:nth-child(3) {
  margin-left: 196px;
}

.content-body .body-header p:nth-child(4) {
  margin-left: 65px;
}

.content-body .body-header p:nth-child(5) {
  margin-left: 50px;
}

.content-body .body-header p:nth-child(6) {
  margin-left: 120px;
}

.body-content li {
  cursor: pointer;
  display: flex;
  font-size: 20px;
  line-height: 52px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #323B46;
  margin: 10px auto;
}




.body-content li:hover {
  background-color: #DCF6FB;
}

.body-content ul li:first-child:hover {
  background-color: #fff;
}

.body-content li .xin {
  background-color: #289EB8;
  color: #FFFFFF;
  margin-bottom: 5px;
}

.body-content button {
  border-radius: 6px;
}

/* 弹窗界面 */
.tanchuang {
  position: absolute;
  top: 45%;
  left: 35%;
  z-index: 1000;
  width: 700px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 68px 0px rgba(51, 51, 51, 0.26);
  border-radius: 12px;
}

.cha {
  width: 25px;
  height: 25px;
  margin-left: 95%;
  margin-top: 10px;
  position: relative;
}

.cha::before,
.cha::after {
  content: "";
  position: absolute;
  /*方便进行定位*/
  height: 20px;
  width: 2.5px;
  top: 2px;
  right: 9px;
  /*设置top和right使图像在20*20框中居中*/
  background: #289EB8;
}

.cha::before {
  transform: rotate(45deg);
  /*进行旋转*/
}

.cha::after {
  transform: rotate(-45deg);
}

/deep/ .el-tabs__item {
  font-size: 18px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
  padding-left: 35px;
}



.meido-play-model {
  position: fixed;
  top: 20%;
  left: 29.1666666666%;
  width: 800px;
  height: 560px;

  background: #FFFFFF;
  box-shadow: 0px 0px 68px 0px rgba(51, 51, 51, 0.26);
  border-radius: 12px;
  z-index: 2000;

  .meido-header {
    height: 45px;
    line-height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    .close {
      width: 60px;
      height: 45px;
      text-align: center;
      line-height: 45px;
      font-size: 25px;
      cursor: pointer;


    }
  }

  .mideo-content {
    height: 450px;

    .video {
      width: 720px !important;
      margin: 0 auto;

    }

    /deep/ .video-js {
      width: 720px !important;
      height: 450px !important;
    }



  }

  .medio-title {
    height: 50px;
    line-height: 50px;
    width: 720px;
    margin: 0 auto;
  }



}

.zheyan {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 500;
  background: #000000;
  opacity: 0.3;
}

.cover-img {
  height: 395px !important;
  width: 654px !important;
  margin-right: 70px;

  img {
    height: 395px !important;
    width: 654px !important;
    background-color: #f0f0f0;

  }
}
</style>
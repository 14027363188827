<template>
    <!-- <div class="left">

            <teachercenter :userInfo="userinfo"></teachercenter>
        </div> -->
    <div class="course right">
        <div class="course-search">
            <div class="search">
                <img src="../../../assets/img/搜索.png" alt="">
                <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_home">
            </div>
            <button @click="search_home">搜索</button>
        </div>
        <div class="course-content">
            <div class="content-header">
                <p>全部课程</p>
                <p>已购买({{ list.total }})</p>

            </div>
            <div class="content-body">

                <div class="body-content">
                    <ul>

                        <li class="body-header">
                            <div class="xuhao">序号</div>

                            <div class="biaoti">视频标题</div>
                            <div class="biaoti">课程标题</div>
                            <div class="biaoti">订单</div>

                            <div class="shuliang">价格</div>



                        </li>
                        <li v-for="(item, index) in list.list" :key="index" @click="play_medio(index)">
                            <div class="xuhao">{{ index + 1 }}</div>
                            <div class="biaoti">{{ item.title }}</div>
                            <div class="biaoti">{{ item.course_title }}</div>
                            <div class="biaoti">{{ item.order_sn }}</div>
                            <div class="shuliang">{{ item.price }}</div>


                        </li>
                    </ul>

                </div>
            </div>

            <div class="page">

                <el-pagination @current-change="pra_change" :current-page="list.page" :page-sizes="[1]"
                    :page-size="list.pagesize" layout="prev, pager, next, jumper" :total="list.total">
                </el-pagination>
            </div>
        </div>

        <div v-if="play_model">
            <div class="zheyan"></div>

            <div class="meido-play-model">
                <div class="meido-header">
                    <div class="el-icon-close close" @click="close_medio_play"></div>
                </div>
                <div class="mideo-content">

                    <video-player :options="videoOptions" class="video" />

                </div>
                <div class="medio-title">
                    {{ video_info.title }}
                </div>
            </div>

        </div>




    </div>
</template>
  
<script>
 

import BASEURL from "./../../../request/baseurl"
import OSSURL from "@/request/oss"
import store from "./../../../store/index"
import { getOtherOrderList } from "@/request/student.js"
import VideoPlayer from "@/components/VideoPlayer.vue";
import axios from 'axios';
export default {
    name: 'homework',
    data() {
        return {
            userinfo: {},
            baseurl: BASEURL,
            ossurl:OSSURL,

            showModal: false,
            activeName: 'first',
            list: {
                total: 0,
                list: [],
                current: 1,
                pagesize: 5
            },
            videoOptions: {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: "",
                        type: "video/mp4"
                    }
                ]
            },
            video_info: {},
            active_index: -1,
            pra_info: {},
            upload_url: BASEURL + '/api/upload/generaupload',
            keyword: '',
            course_id: null,
            play_model: false

        }
    },
    components: {
        VideoPlayer,

    },


    created() {

        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);

        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }

        let that = this;

        this.get_list(that.list.current, that.list.pagesize, '');

    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },

        async search_home() {

            let keyword = this.keyword;

            this.get_list(1, this.list.pagesize, keyword);




        }, async pra_change(pager) {
            let keyword = this.keyword;
            let that = this;


            this.get_list(pager, that.list.pagesize, keyword);





        },

        async get_list(current, pagesize, keyword) {
            let that = this;

            let res = await getOtherOrderList({ current: current, pagesize: pagesize, keyword: keyword, type: 4 });


            if (res.code === 200) {

                //    let  pra_list = JSON.parse(JSON.stringify(this.pra_list));   
                let list = this.list;

                list.total = Number(res.data.total)
                list.current = Number(res.data.current)
                list.list = res.data.list;

                this.list = list;


            } else {
                this.$message.error(res.msg);
            }

        },
        //播放视频
        play_medio(index) {

            let list = this.list.list;
            this.video_info = list[index];


            let videoOptions = this.videoOptions;
            videoOptions.sources[0].src = OSSURL + list[index].medio_url;


            this.videoOptions = videoOptions;
            this.play_model = true;

        }, close_medio_play() {

            this.play_model = false;
        }






    }

}
</script>
  
<style lang="less"  scoped >
@import url("./../../../assets/css/teacher_common.less");


h4 {
    margin-left: 30px;
    margin-top: 5px;
}

li {
    list-style: none;
}

.video-bianji {
    display: flex;
}

.video-bianji .ruanjian {
    overflow: hidden;
    margin-top: 25px;
    padding-left: 20px;
    width: 340px;
    height: 37px;
    background: #F8F8F8;
    border-radius: 8px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 35px;
}


.video-bianji .ruanjian .el-input {
    background: #F8F8F8;

}

.video-bianji .ruanjian /deep/ .el-input__inner {
    background: #F8F8F8;
    border: none;
}

.center-main {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 40px;

}

.xuhao {
    width: 80px;
    height: 52px;
    text-align: center;
}

input {
    outline: none;
}

.video {
    display: flex;
}

.video video {
    width: 100px;
    height: 100px;
}

.video .video-input {
    margin-top: 28px;
    width: 500px;
    height: 45px;
    background: #F8F8F8;
    border-radius: 8px;
    border: 1px solid #ECECEC;
}

.left {
    width: 310px;

}

.body-content ul {
    margin: 0;
    padding-left: 42px;
    padding-bottom: 30px;
}

.body-content ul li {
    list-style: none;
    height: 52px;
}

button {
    cursor: pointer;
    border: 0.5px solid #E9E9E9;
}

.biaoti {
    margin: 0;
    width: 280px;
    text-align: center;
    overflow: hidden;
}

.shuliang {
    text-align: center;
    width: 110px;
    margin: 0 0px;
}

.liulan {
    width: 120px;
    text-align: center;
    margin: 0 0px;
}

.caozuo {
    display: flex;
    margin: 0px;
    width: 160px;
    text-align: center;
    width: 160px;
    text-align: center ! important;
    justify-content: space-between;
}

.course {
    width: 1150px;
    /* height: 626px; */
    overflow: auto;
    height: auto;
    background: #FFFFFF;
    border-radius: 6px;


}

.course-search {
    margin-left: 31px;
    padding-top: 51px;
    display: flex;
}

.course .search {
    position: relative;
    width: 653px;
    height: 50px;
    border: 2px solid #ECECEC;
    border-radius: 21px;
}

.course .search input {
    outline: none;
    margin-left: 55px;
    border-style: none;
    width: 570px;
    height: 45px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ADADAD;
    line-height: 45px;
    margin-top: 2.5px;
}

.course .search img {
    position: absolute;
    left: 20px;
    top: 11px;
    width: 28px;
    height: 28px;
}

.course-search button {
    cursor: pointer;
    width: 115px;
    height: 50px;
    border: 1px solid #FFFFFF;
    background: #289EB8;
    border-radius: 8px;
    margin-left: 36px;
    margin-top: 0px;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 50px;
}


.content-header {
    display: flex;
    margin-left: 74px;
    margin-top: 20px;
}

.content-header p {
    cursor: pointer;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #323B46;
}

.content-header p:nth-child(2) {
    margin-left: 122px;
    width: 160px;
    text-align: center;
}

.content-header p:nth-child(3) {
    margin-left: 131px;
    width: 160px;
    text-align: center;
}

.content-body {
    margin-left: 35px;
    margin-top: 0px;
    margin-bottom: 50px;
    width: 1105px;
    background: #FFFFFF;
    box-shadow: 0px 0px 65px 0px rgba(51, 51, 51, 0.1);
    border-radius: 16px;
}

.content-body .body-header {
    display: flex;
    width: 100%;
    font-size: 22px !important;
    font-family: PingFang SC;
    font-weight: 500;
    color: #606060 !important
}

.content-body .body-header:hover {
    background-color: #fff;
    cursor: auto;
}

.body-content li {
    cursor: pointer;
    display: flex;
    font-size: 18px;
    line-height: 52px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333;
    margin: 10px auto;
}

.body-content li:hover {
    background-color: #DCF6FB;
}

.meido-play-model {
    position: fixed;
    top: 20%;
    left: 29.1666666666%;
    width: 800px;
    height: 560px;

    background: #FFFFFF;
    box-shadow: 0px 0px 68px 0px rgba(51, 51, 51, 0.26);
    border-radius: 12px;
    z-index: 2000;

    .meido-header {
        height: 45px;
        line-height: 45px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .close {
            width: 60px;
            height: 45px;
            text-align: center;
            line-height: 45px;
            font-size: 25px;
            cursor: pointer;


        }
    }

    .mideo-content {
        height: 450px;

        .video {
            width: 720px !important;
            margin: 0 auto;

        }

        /deep/ .video-js {
            width: 720px !important;
            height: 450px !important;
        }



    }

    .medio-title {
        height: 50px;
        line-height: 50px;
        width: 720px;
        margin: 0 auto;
    }



}

.zheyan {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 500;
    background: #000000;
    opacity: 0.3;
}

.cover-img {
    height: 395px !important;
    width: 654px !important;
    margin-right: 70px;

    img {
        height: 395px !important;
        width: 654px !important;
        background-color: #f0f0f0;

    }
}
</style>
<template>
    <div class="search">
            <div class="search-content">

 <div  v-for="(item,index) in catelist"   v-bind:key="index" :class="item.id == cate_id?'search-tap active':'search-tap' " @click="search(item.id)">{{item.name}}</div>
                
             
   

            </div>
          


    </div>
   
    







</template>      

<script>
export default {
     name:"Search",
     data(){
         return{
               
         }
          
     },
     props:{
           catelist:{
                type:Array,
                 default: function () { return [] },
                required:true
           },
           cate_id:{
                 type:Number,
                 default: function () { return 0 },
                 required:true

           }
     },methods:{
           search(id){

             
               this.$emit('searchcate',id);
                 
           }
     }  
 
}
</script>

<style lang="less" scoped>
    
    @import  "./../../assets/css/search.less";
</style>
/**   
 * api接口统一管理
 */
import {get, post } from './http.js'

/**   
 * 删除文件
 */
export const del_file = p => post('teachercenter/del_file', p)
    // 发布课程
export const replase_couser = p => post('teachercenter/teacher_release', p);

export const teacher_couse_list = p => post('teachercenter/get_my_couserlist', p);


//给课程批量增加视频，作业，模拟题
export const batch_insert_course_file = p => post('teachercenter/batch_insert_file', p);

//个人中心获取视频列表
export const get_medio_list = p => post('teachercenter/get_medio_list', p);

//删除视频
export const delete_medio = p => get('teachercenter/delete_medio', p);

export const editMedio = p => post('teachercenter/edit_medio', p);



//个人中心，作业列表  

export const praList = p => post('teachercenter/get_pra_list', p);

export const editPra = p => post('teachercenter/edit_pra', p);


export const delete_pra = p => get("teachercenter/delete_pra", p);


//个人中心，试题

export const getQuestionList = p => post("teachercenter/get_question_list", p);

export const deleteQuestion = p => get("teachercenter/delete_question", p);

export const editQuestion = p => post("teachercenter/edit_question", p);


//个人中心，模拟真题
export const getImitateList = p => post('center/teacher/get_imitate_list', p);
export const editImiteate = p => post('center/teacher/edit_imiteate', p);
export const deleteImiteate = p => get('center/teacher/delete_imiteate', p);

//关注需求

export const attenDemand = p => post("teachercenter/atten_demand", p);


export const MyAttenDemand = p => get('teachercenter/get_my_atten_demand', p);

export const delMyAttenDemand = p => get('teachercenter/delete_my_atten_demand', p)

export const addMyFriend = p => post('teachercenter/add_friend', p);


export const getMyFriend = p => post('teachercenter/get_friend', p)

export const DelFriend = p => post('teachercenter/get_friend', p)

export const getMyZhanMessage = p => get("teachercenter/getMyZhanMsg", p);


//置顶
export const topCourse = p => post('course/course_topping', p);
//获取刷新价格
export const revonatePrice = p => get('course/get_revonate_price', p);

//免费刷新

export const freeRevonateCourse = p => get('course/free_revonate_course', p);


//付费刷新

export const payRevonateCourse = p => get('course/pay_revonate_course', p);


//发布动态

export const InsertTrends = p => post('center/teacher/insert_trends', p);

//获取动态列表
export const getTrendsList = p => post('center/teacher/get_trends_list', p);

//删除动态
export const deleteTrends = p => get('center/teacher/delete_trends', p);


//评论动态

export const commentTrends = p => post('center/teacher/comment_trends', p);


//点赞/取消动态 

export const attenTrends = p => get('center/teacher/atten_trends', p);

//获取老师的课程 一个
export const getTeacherCourse = p => get('center/teacher/get_teacher_course', p)

//获取订购老师的课程的订单  

export const getCourseOrder = p => post('center/teacher/get_buy_my_course', p);

//获取其他订单
export const getOtherOrderList = p => post('center/teacher/get_other_order_list', p);

export const getOssSignature = p => post('alioss/oss', p);
import { render, staticRenderFns } from "./personal.vue?vue&type=template&id=9772c696&scoped=true&"
import script from "./personal.vue?vue&type=script&lang=js&"
export * from "./personal.vue?vue&type=script&lang=js&"
import style0 from "./personal.vue?vue&type=style&index=0&id=9772c696&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9772c696",
  null
  
)

export default component.exports
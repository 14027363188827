<template>
  <div class="big-box">
    <div class="box">
      <div @click="$router.push('/teacher/person')" :class="$route.path == '/teacher/person' ? 'active' : ''">个人信息</div>
      <div @click="$router.push('/changepaw')" :class="$route.path == '/changepaw' ? 'active' : ''">修改密码</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'personal',
  data() {
    return {

    }
  },
}
</script>

<style   scoped>
.box {
  padding-top: 20px;
  width: 195px;
  height: 138px;
  background: #FFFFFF;
  border-radius: 6px;
}

.box div {
  text-align: center;
  cursor: pointer;
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}


.box .active {
  background-color: #289EB8;
  color: #FFFFFF;

}
</style>
/**   
 * api接口统一管理
 */
import {get, post } from './http.js'

/**   
 * 课程需求接口
 * 
 */
export const demandlist = p => get('/index/get_question_list', p)

export const get_demand_info = p => get('index/get_question_info', p)

export const student_replase_question = p => post('student_center/release_question', p);

//获取我的需求
export const get_my_demand = p => post('student_center/get_my_question', p);

//删除我的需求

export const deleteDemand = p => post("student_center/del_question", p);

//编辑需求  

export const editDemand = p => post("student_center/edit_question", p);


//学生评论课程 
export const evaComment = p => post("student_center/insert_comment", p);

export const webCount = p => get("index/web_count", p)
    //学生收藏的课程
export const student_my_course_list = p => post('student_center/get_my_atten_course', p)

//用户取消收藏
export const student_del_collect_course = p => post('student_center/del_atten_course', p);




//用户点赞需求
export const likeDemand = p => post('teachercenter/like_demand', p);
import {get, post } from './http.js'

export const get_my_friend = p => post('teachercenter/get_my_friend', p)
    //获取教师，聊天列表

export const contacts_list = p => get('teachercenter/contacts_list', p)


//获取用户信息
export const get_user_info = p => post('user/get_user_info', p)

//获取聊天记录

export const get_chat_history_list = p => post('teachercenter/get_history_chat_list', p);

//标记消息已读
export const is_set_chat_read = p => post('teachercenter/set_chat_history_read', p)


export const get_chat_student_friend = p => post('student_center/get_chat_student_list', p);
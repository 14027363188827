<template>
  <div class="big-box">
    <div class="box wrap">
      <div class="content">
         <div><img src="../../assets/img/搜索.png" alt=""> </div>
        <input type="text" placeholder="输入您要搜索课程的关键字" name="keyword" v-model="keyword" @keyup.enter="search_demand">
        <button @click="search_demand">搜课程</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'stusearch',
  data() {
    return {
      keyword: ''

    }
  }, 
  components: {

  },
  methods: {

    search_demand: function () {
      let keyword = this.keyword;
      this.$router.push('/courselist?keyword=' + keyword)
    }
  }
}
</script>

<style scoped>
/* @import '../../assets/css/common.less'; */
input {
  outline: none;
}

.big-box {
  width: 100%;
  min-height: 80vh;
  background: url(../../assets/img/student.png) no-repeat;
  background-size: 100% 100%;

}

input::-webkit-input-placeholder {

  /* placeholder字体大小 */
  font-size: 18px;
}
    
.content {
  height: 60px;
  width: 840px;
  position: relative;
  padding-top: 350px;
  width: 840px;
  margin:0 auto;  
  display: flex;
  flex-direction: row;
}

.content img {
  position: absolute;
  top: 368px;
  left: 8px;
  width: 30px;
  height: 30px;
}

.content input {
  padding-left: 40px;
  margin-right: 15px;
  width: 666px;
  height: 60px;  
  font-size: 18px;
  background: #FFFFFF;
  border:none;
  border-radius: 9px;
padding-bottom: 0;
 padding-right: 0; 
 padding-top: 0;
 margin-top: 0;
}

.content button {
  cursor: pointer;
  border:none; 
  width: 113px;
  height: 60px;
  font-size: 20px;
  background: #FFFFFF;
  border-radius: 10px;
  
}

.footer {
  margin-top: 0 !important;
}
</style>
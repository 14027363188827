<template>
  <div class="box">
    <div class="wrap">
      <div class="content">
        <div class="login-content">

          <div class="login-header">
            <div class="active">忘记密码</div>
          </div>

          <div class="login-main">

            <div class="login-input">
              <input type="text" nmae="mobile" placeholder="请输入手机号" v-model="formdata.mobile">
            </div>
            <div class="iphone">
              <p>*输入绑定账户的手机号</p>
            </div>

            <div class="code">
              <input type="text" placeholder="请输入验证码" v-model="formdata.captcha">
              <button @click="getCode()" :disabled="!show">
                <span v-show="show">获取验证码</span>
              </button>
            </div>

            <div class="newpassward">
              <input type="password" name="" id="" placeholder="新密码" v-model="formdata.password">
            </div>
            <div class="twopassward">
              <input type="password" name="" id="" placeholder="再次输入新密码" v-model="formdata.re_passwrod">
            </div>

            <div class="operation">
              <button class="login-button" @click="submit_forget">确定</button>
              <button class="registered" @click="$router.push('/login')">返回登陆</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { send_msg, fogetpwd } from "../../request/api"
export default {
  name: 'forget',
  data() {
    return {
      is_teacher: false,
      formdata: {
        'mobile': '',
        'captcha': '',
        'password': '',
        're_passwrod': ''
      },
      show: true,
      count: '',
      timer: null,
    }
  },
  methods: {

    change_login: function (e) {
      if (e === 1) {

        this.is_teacher = false;
      } else {
        this.is_teacher = true;
      }

    }, getCode() {

      let that = this;
      var formData = that.formdata;

      if (!formData.mobile) {
        that.$layer.msg('请输入电话号码');
        return false;
      }
      var reg = /^(13[0-9]|19[0-9]|17[0-9]|14[0-9]|15[0-9]|16[0-9]|18[0-9])\d{8}$/;
      if (formData.mobile.length == 11) {
        if (!reg.test(formData.mobile)) {
          that.$layer.msg('电话号码格式不对');
          return false;
        }
      } else {
        that.$layer.msg('电话号码格式不对');
        return false;
      }


      send_msg({ mobile: formData.mobile, event: 'login' }).then(res => {

        if (res.code === 200) {
          if (!this.timer) {
            // eslint-disable-next-line no-undef
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              // eslint-disable-next-line no-undef
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000)
          }
        } else {

          that.$layer.msg('发送失败');

        }


      })

    }, submit_forget: function () {

      let that = this;
      var formData = that.formdata;
      if (!formData.mobile) {
        that.$layer.msg('请输入电话号码');
        return false;
      }
      var reg = /^(13[0-9]|19[0-9]|17[0-9]|14[0-9]|15[0-9]|16[0-9]|18[0-9])\d{8}$/;

      if (formData.mobile.length == 11) {
        if (!reg.test(formData.mobile)) {
          that.$layer.msg('电话号码格式不对');
          return false;
        }
      } else {
        that.$layer.msg('电话号码格式不对');
        return false;
      }

      if (!formData.captcha) {
        that.$layer.msg('验证码不能为空');
        return false;
      }
      if (formData.captcha.length != 6) {
        that.$layer.msg('验证码格式不对');
        return false;
      }


      if (formData.password.length < 6 || formData.password.length > 30) {

        that.$layer.msg('密码在6到30位');
        return false;
      }

      if (formData.password != formData.re_passwrod) {

        that.$layer.msg('两次输入密码输入不一致');
        return false;
      }

      fogetpwd({ 'mobile': formData.mobile, 'captcha': formData.captcha, newpassword: formData.password }).then(res => {

        if (res.code === 200) {
          that.$layer.msg("重置成功");

        } else {
          that.$layer.msg(res.msg);
        }

      })
    }

  }
}
</script>

<style lang="less"   scoped>
/deep/ body {
  background-color: #ffffff !important;
}

.box {
  background: url(../../assets/img/login.png) no-repeat !important;
  background-size: 100% 100% !important;
  min-height: 85vh;
  width: 100%;
}

.wrap {
  display: flex;
  justify-content: flex-end;
}

.content {
  margin-top: 15%;
  background-color: #FFFFFF;
  width: 480px;
  height: 573px;
  box-shadow: 0 0 10px 0;
  border-radius: 5px;

  .login-content {
    padding: 20px;
  }

  .login-header {
    display: flex;
    font-size: 20px;
    font-family: Microsoft YaHei;
    font-weight: bold;

    .active {
      color: #289EB8;
    }

    div {
      margin: 0 20px 10px 10px;
      color: #999999;
    }
  }

  .login-main {
    margin-top: 10px;

    .login-input {
      width: 420px;
      height: 60px;
      border-radius: 20px;
      margin-bottom: 5px;

      input {
        background-color: #F8F8F8;
        border: 0.5px #eee solid;
        outline: none;
        width: 420px;
        height: 60px;
        border-radius: 10px;
        font-size: 20px;
        padding: 0 0 0 10px;
      }
    }

    .iphone {
      margin: 0;
      display: flex;
      padding-left: 10px;

      p {
        color: #FF8620;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
    }

    .code {
      margin: 0;
      padding: 0;
      margin-bottom: 20px;

      input {
        float: left;
        background-color: #F8F8F8;
        border: 0.5px #eee solid;
        outline: none;
        width: 220px;
        height: 60px;
        border-radius: 10px;
        font-size: 20px;
        padding: 0 0 0 10px;
      }

      button {
        float: right;
        width: 156px;
        height: 60px;
        border-radius: 5px;
        border: 1px solid #eeeeee;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        cursor: pointer;

      }
    }

    .newpassward {
      margin-top: 10px;
      margin-bottom: 20px;

      input {
        background-color: #F8F8F8;
        border: 0.5px #eee solid;
        outline: none;
        width: 420px;
        height: 60px;
        border-radius: 10px;
        font-size: 20px;
        padding: 0 0 0 10px;
        color: #999999;
      }
    }

    .twopassward {
      margin-top: 10px;
      margin-bottom: 20px;

      input {
        background-color: #F8F8F8;
        border: 0.5px #eee solid;
        outline: none;
        width: 420px;
        height: 60px;
        border-radius: 10px;
        font-size: 20px;
        padding: 0 0 0 10px;
        color: #999999;

      }
    }

    .operation {
      margin-top: 70px;
      width: 420px;
      height: 60px;

      button {
        width: 195px;
        height: 60px;
      }

      .login-button {
        cursor: pointer;
        border-radius: 5px;
        float: left;
        background-color: #289EB8;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        border: 1px solid #ffffff;
      }

      .registered {
        cursor: pointer;
        float: right;
        border-radius: 5px;
        background-color: #FFFFFF;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #289EB8;
        border: 1px solid #289EB8;
      }
    }

    div {
      width: 420px;
      height: 50px;
    }

  }

  .hird-party {
    margin-top: 50px;

    .wechat {
      margin-top: 35px;

      img {
        cursor: pointer;
      }
    }

    div {
      margin: 20px auto;
    }
  }
}
</style>
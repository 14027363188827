import Vue from 'vue'
import App from './App.vue'

import axios from 'axios';
import router from './router/router';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//其他省略
// 一个“包装”了 UEditor 的 Vue 组件
import VueUeditorWrap from 'vue-ueditor-wrap';

// 在 main.js 里将它注册为全局组件
Vue.component('vue-ueditor-wrap', VueUeditorWrap);

//其他省略

import store from '../src/store/index';

import MetaInfo from 'vue-meta-info'


import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video //引入Video播放器
    // 1.设置v-throttle自定义指令  限制表单重复提交
Vue.directive('throttle', {
    bind: (el, binding) => {
        let throttleTime = binding.value; // 节流时间
        if (!throttleTime) { // 用户若不设置节流时间，则默认2s
            throttleTime = 2000;
        }
        let cbFun;
        el.addEventListener('click', event => {
            if (!cbFun) { // 第一次执行
                cbFun = setTimeout(() => {
                    cbFun = null;
                }, throttleTime);
            } else {
                event && event.stopImmediatePropagation();
            }
        }, true);
    },
});
Vue.use(ElementUI);
Vue.use(MetaInfo)
Vue.prototype.$axios = axios

Vue.config.productionTip = false

document.title = "考研信息分类平台"
components: {
        VueUeditorWrap;
    }
    // 或者在 main.js 里将它注册为全局组件
Vue.component('vue-ueditor-wrap', VueUeditorWrap);

new Vue({
    router,
    store,
    render: h => h(App),
    // 这是关键
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app')
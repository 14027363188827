<template>
     <!-- <div class="left">
               <teachercenter :userInfo="userinfo" @loginout="out_login"></teachercenter>
          </div> -->


     <div class="right">
          <div class="img">
               <img src="./../../../assets/img/icon/teacher.png"
                    alt=""></div>
          <div class="geren" @click="go_trends">
               进入<div class="dongtai">个人动态>></div>
          </div>
     </div>
</template>
<script>
import { logout } from "./../../../request/login"
import teachercenter from './../../../components/common/teachercenter'
import BASEURL from "./../../../request/baseurl"
import store from "./../../../store/index"
export default {

     data() {
          return {
               userinfo: {},
               baseurl: BASEURL,
          }
     }, components: {
          teachercenter
     }, created() {

          var userinfo = localStorage.getItem('Userinfo');
          userinfo = JSON.parse(userinfo);

          if (userinfo) {


               if (userinfo.expiretime * 1000 < new Date().getTime()) {

                    this.$router.push('/login');
                    localStorage.clear("Userinfo");
                    localStorage.clear('Authorization');

               } else {


                    this.userinfo = userinfo;
               }

          } else {
               this.$router.push('/login');
          }

     }, methods: {
          out_login: function () {
               let _this = this;
               var userinfo = _this.userinfo;
               //退出登录
               logout({ id: userinfo.id }).then(respone => {

                    if (respone.code === 1) {
                         localStorage.clear("Userinfo");

                         localStorage.clear('Authorization');
                         //退出登录，同时去除store 中的信息
                         store.commit('setUserinfo', { userinfo: '' })
                         store.commit("changeLogin", { Authorization: '' })
                         this.$router.push('/login');
                    } else {

                         _this.$layer.msg('操作未成功');
                    }

               }).catch(err => {
                    console.log(err);
               })

 

          },go_trends(){

                 if(this.userinfo.is_official == 1){

                        this.$router.push('/official_strends');  

                 }else{
                       let user_id = this.userinfo.id
                       this.$router.push('/strends?user_id='+user_id);  
                 }
                
          }


     }

}
</script>
<style scoped >
.main-content {
     display: flex;
     display: -webkit-flex;
     flex-direction: row;
     margin-top: 40px;
}

.right {
     /* margin-left: 20px; */
     margin-top: 0;
    height: 1000px;
     background: #FFFFFF;
     border-radius: 6px;
}

.img{
    padding-top: 100px;
}
 
.img img {
     display: block;
     margin: 0px auto 0;
}

.geren {
     cursor: pointer;
     font-size: 24px;
     width: 230px;
     margin: 30px auto;
     text-align: center;
}

.dongtai {
     width: 150px;
     color: #2896B8;
     display: inline-block;
}
</style>
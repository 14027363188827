<template>
  <!-- 聊天弹框 -->
  <div class="chat">
    <div class="chat-header">

      <div class="left">

        <img src="./../assets/img/header.png" alt="" v-if="!chat_user_info.avatar">
           <img :src="ossurl+chat_user_info.avatar" alt="" v-if="chat_user_info.avatar">
        <div>{{chat_user_info.nickname}}</div>
        <div>经验值: 96</div>
      </div>
      <div class="right"> <i class="el-icon-close" @click="close_chat"></i></div>

 
    </div>
    <div class="content">

      <div class="chat-content" id="chatContent">
         <div class="list-info"  v-for="(item,index) in chat_list" :key="index">
        <div class="chat-list "  v-if="item.fid !=userinfo.id">

          <div class="chat-user-header">
          
              <img src="./../assets/img/header.png" alt="" v-if="!chat_user_info.avatar &&  !chat_user_info.gender == 0">
                        <img src="./../assets/img/female.png" alt="" v-else-if="!chat_user_info.header && item.gender == 1">
                        <img :src="ossurl+chat_user_info.avatar" alt="" v-else>
            
            </div>
          <div class="chat-info">
            {{item.content}}
          </div>
 
        </div>

        <div class="chat-list is-self" v-else>

        
          <div class="chat-info">
            {{item.content}}
          </div>
          <div class="chat-user-header">
            <img src="./../assets/img/header.png" alt="" v-if="!userinfo.avatar &&  !userinfo.gender == 0">
                        <img src="../assets/img/female.png" alt="" v-else-if="!userinfo.avatar && item.gender == 1">
                        <img :src="ossurl+userinfo.avatar" alt="" v-else>
         
            
            
            </div>

        </div>
        </div>



      </div>

      <div class="chat-insert">  
        <div class="chat-send">
          <textarea name="" id="" cols="30" rows="10" v-model="sendmsg" placeholder="请输入你的内容"></textarea>
        </div>

        <div class="send-bttom">
          <div  @click="send_message" type="primary" class="send" >发送</div>
        </div>


 
      </div>



    </div>



 

 
  </div>
</template>
  <script>  
import Vue from 'vue'
import store from "@/store/index"
import BASEURL from "@/request/baseurl"
import OSSURL from "@/request/oss"
import SOCKETURL from "@/request/websockurl"

import  {get_my_friend,contacts_list,get_user_info,get_chat_history_list,is_set_chat_read} from "@/request/utalk"
import {initWebSocket,webSocketClose,sendSock} from "@/util/websock"


Vue.directive('draw', {
  inserted: function (el) {
    el.setAttribute('style', 'position: fixed; z-index: 1000');
  },
  bind: function (el) {
    el.onmousedown = function (eventDown) {
      // 保持div和鼠标相对位置不变
      let len = eventDown.clientX - el.offsetLeft;
      let topLen = eventDown.clientY - el.offsetTop;
      el.onmousemove = function (event) {

        event = event || window.event;
        let left = event.clientX - len;
        let top = event.clientY - topLen;

        left = Math.max(0, left);
        top = Math.max(0, top);
        left = Math.min(left, document.body.clientWidth - el.offsetWidth);
        top = Math.min(top, document.body.clientHeight - el.offsetHeight);
        el.style.left = left + 'px';
        el.style.top = top + 'px';

        el.onmouseup = function () {
          el.onmousemove = null;
          el.onmouseup = null;
        };
      };
    };
  }, 

  unbind: function (el) {
    el.onmousemove = null;
    el.onmouseup = null;
  }
});  
export default {
  name: "Chat",
  data() {
    return {
       default_header_logo: 'this.src="'+require('./../assets/img/female.png')+'"',
       content:'',
        chat_user_info:{},
         sendmsg:'',  
       userinfo:{},
       chat_list:[],
       baserurl:BASEURL,
       ossurl:OSSURL,
       friend_user_id:''
       
    }
  },  props:{
    toUserId:{
      type:Number,
      default:0,
      required:true
    }
  
  }, 
  
  
  computed: {
  
     getSocketMsg() {
       
      return this.$store.state.webSocketMsg
    }
  }, watch:{
       getSocketMsg:{
       handler:function(val){
           let that = this;
       
       
         that.getConfigResult(val)
          }
       },chat_list:function(){
            this.$nextTick(() => {
  
        var div = document.getElementById('chatContent')
      
        div.scrollTop = div.scrollHeight
  
  })
       }
   
  },
  
   
     
  created(){
        
       //如果拥有用户信息，则直接进入个人中心

       console.log(BASEURL)
         var  userinfo =  localStorage.getItem('Userinfo');
     if(userinfo && userinfo !='undefined'){
     
        userinfo = JSON.parse(userinfo);
        //判断时间是否过期
        if(userinfo.expiretime*1000  < new Date().getTime()){
           // this.$router.push("/login");
        }else{  
           
           this.userinfo = userinfo
           var  wb_url=SOCKETURL+"&&uid="+userinfo.id;
           console.log(wb_url);
            this.init(wb_url);  
        
             
        }
       
     }else{
        //this.$layer.msg("登录才可以与教师聊天哦");
     }

     window.addEventListener('keydown',this.handkeycode,true)

  
  },

  
  
  
  methods: {

         //控制打开关闭
    init:function(wb_url){
      let is_connect =  initWebSocket(wb_url);
       if(!is_connect){
        let inter=  setInterval(function(){
             is_connect= initWebSocket(wb_url);
               },
            1000);
            if(is_connect){
            clearInterval(inter);
            }
        
       }
 

     let to_user_id = this._props.toUserId;
     console.log(to_user_id);
       this.get_user_info_fuc(to_user_id);
       this.get_chat_history_list(to_user_id);
    }, 

    close_chat: function () {


      this.$emit('closeChat');
    },handkeycode(e){

    if(e.keyCode === 13){
       this.send_message();
    }
   
    },
    send_message:function(){
           let that = this;
           var msg = this.sendmsg;  
           if(!msg){
             return false;
           }
  var date = new Date();
        var seperator1 = "-";
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var strDate = date.getDate();
        if (month >= 1 && month <= 9) {
          month = "0" + month;
        }
        if (strDate >= 0 && strDate <= 9) {
          strDate = "0" + strDate;
        }
        var currentdate = year + seperator1 + month + seperator1 + strDate;
        var data = {"fid":that.userinfo.id,"tid":that._props.toUserId,'content':msg,'createtime':currentdate}
    
        try {
          let res = sendSock(data);
          console.log(res);  
          console.log(1111111111111111111);
        } catch (error) {
             console.log(error);
             console.log(99999);
        }   


        var chat_list = that.chat_list
            chat_list.push(data);

            that.chat_list = chat_list;

            that.sendmsg=''
     document.getElementById("chatContent").scrollIntoView(true);



    },getConfigResult:function(val){

     //将接收信息，push 到聊天记录
        let that = this;
     
       var friend_user_id =   this._props.toUserId;

         var data_json = val.data;
          var  data = JSON.parse(data_json);
           
          if (data.message === 'success'){
          
              var chat_content_data = data.data;
            
              if(chat_content_data.fid == friend_user_id){
                console.log(99999);
                var chat_list = that.chat_list;
                chat_list.push(chat_content_data);
                that.chat_list = chat_list;
                that.is_set_chat_read(chat_content_data.fid);
              }
              

       }else{
          console.log(data);
                 console.log('失败');
       }
      

  

    },async setOnmessageMessage(event) {
      
			// 自定义全局监听事件
			window.dispatchEvent(new CustomEvent('onmessageWS', {
				detail: {
					data: event.data
				}
			}))
			// //发现消息进入    开始处理前端触发逻辑
			// if (event.data === 'success' || event.data === 'heartBath') return
        },

// 父组件传递过来的事件
    setChatInfo(user_id){
       this.friend_user_id = user_id;
       this.chat_list = []
       this.get_user_info_fuc(user_id);
       this.get_chat_history_list(user_id);


    },
    mousedown(event) {
      //鼠标按下事件
      this.leftOffset = event.offsetX;
      this.topOffset = event.offsetY;
      this.isMove = true;
    },
    //鼠标移动
    mousemove(event) {
      if (!this.isMove) {
        return;
      }
      this.x = event.clientX - this.leftOffset;
      this.y = event.clientY - this.topOffset;
    },
    //鼠标抬起  
    mouseup() {
      console.log('mouseup');
      this.isMove = false;  
    }, async  get_user_info_fuc(user_id){
    
            
           let that = this;
           
       let res = await get_user_info({user_id:user_id})
        if(res.code === 200){
                 this.chat_user_info = res.data;
             
        }
       
    
         this.get_chat_history_list(user_id);


    },is_set_chat_read(user_id){

     is_set_chat_read({user_id:user_id}).then(res=>{
              if(res.code === 200){
                 
              }

 
     }) 


    },async get_chat_history_list(user_id){
       let that = this;
     let res = await  get_chat_history_list({user_id:user_id,current:1,pagesize:200})
       that.chat_list= res.data;
     
      

    }, 

  }
}


</script>




<style lang="less" scoped>
.chat {
  position: fixed;
  top: 30%;
  left: 35.156%;
  width: 600px;
  height: 420px;
  background: #FFFFFF;
  box-shadow: 0px 0px 68px 0px rgba(51, 51, 51, 0.26);
  border-radius: 12px;
  z-index: 500;

  .chat-header {
    height: 65px;
    width: 560px;
    padding: 0 20px;
    background-color: #289EB8;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;

    .left {
      flex: 3;
      display: flex;
      display: -webkit-flex;
      flex-direction: row;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-top: 7.5px;

      }

      div {
        color: #ffffff;
        font-size: 18px;
        margin-left: 25px;
        line-height: 65px;

      }
    }

    .right {
      flex: 1;
      color: #ffffff;
      font-size: 30px;
      line-height: 65px;
      text-align: right;
      background-color: #289EB8!important;
    }
  }

  .chat-content {
    height: 210px;
    width: 520px;
    padding: 20px 40px 20px;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;

    .chat-list {
      display: flex;
      display: -webkit-flex;
      flex-direction: row;

      .chat-user-header img {
        height: 45px; 
        width: 45px;
        border-radius: 50%;
        background-color: #289EB8;
      }

      .chat-info {
        max-width: 300px;
        margin-left: 20px;
        padding: 0 20px;
        background-color: #289EB8;

        color: #ffffff;
        font-size: 16px;
        border-radius: 11px;
        min-height: 30px;
        line-height: 30px;
        text-align: left;
        overflow-wrap: break-word;
        margin-bottom: 20px;

      }



    }


    .is-self {

      justify-content: flex-end;

      .chat-user-header {
        margin-right: 0;
        margin-left: 20px;
      }
    }


  }


  .chat-insert {
    height: 85px;
    width: 100%;
    border-top: 1px solid rgba(159, 159, 159, 0.26);

    .chat-send {
      height: 65px;
      border-bottom-right-radius: 12px;
      border-bottom-left-radius: 12px;

      textarea {

        width: 560px;
        padding: 0 20px !important;
        font-size: 16px;
        height: 45px;
        padding: 0;
        margin: 0;
        margin-top: 10px;
        border: none;
        outline: none;

      }

      textarea::-webkit-input-placeholder {
        font-size: 16px;
      }

    }

    .send-bttom {
      height: 30px;

      .send {
        height: 30px;
        width: 80px;
        text-align: center;
        font-size: 14px;
        background-color: #289EB8;
        color: #ffffff;
        line-height: 30px;
        border-radius: 8px;
        float: right;
        margin-right: 20px;

      }

    }
  }

}
</style>
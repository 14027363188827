/**   
 * api接口统一管理
 */
import {get, post } from './http.js'

/**   
 * api接口统一管理 
 */
export const getCatelist = p => get('index/get_cate_list', p)

export const couserselist = p => get('/index/get_curri_list', p)

//课程详情
export const get_couser_info = p => get('/index/get_curri_info', p)
    //更新课程
export const update_course = p => post('teachercenter/update_release', p)
    //删除课程
export const del_course = p => post('teachercenter/del_course', p)


export const commentList = p => post('index/get_comment_list', p);



export const student_collect_course = p => post('student_center/atten_course', p)
export const add_like_course = p => post('teachercenter/like_atten', p)


export const StreamData = p => get('index/get_stream_data', p);
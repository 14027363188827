<template>
    <div class="box">
        <div class="header">
            <div class="header-title">
                <div>U币总金额</div>
                <div>可用金额</div>
                <div>冻结中金额</div>
            </div>
            <div class="header-content">
                <div>{{ userinfo.account }}个</div>
                <div>{{ userinfo.account }}个<a href="#" class="tixian"></a></div>
                <div>{{ userinfo.freeze_account }}个</div>
            </div>
            <div class="header-footer">1U币=1元RMB</div>
        </div>
        <div class="content">
            <div class="chong">U币充值</div>
            <div class="content-con">
                <el-radio-group v-model="radio">
                    <el-radio :label="10" value="10">10U币</el-radio>
                    <el-radio :label="50" value="50">50U币</el-radio>
                    <el-radio :label="100" value="100">100U币</el-radio>
                    <el-radio :label="200" value="200">200U币</el-radio>
                    <br>
                    <el-radio :label="9">其他金额：<input type="text" class="qita" v-model="other_price"> U币</el-radio>
                </el-radio-group>
            </div>
            <div class="zhi">支付方式</div>
            <div class="zhi-two">
                <!-- <img src="./../../../assets/img/rcc.jpg" alt=""   :class="is_pay_type ==1?'img-active':''"  @click="change_pay_type(1)"> -->
                <img src="./../../../assets/img/wxpay.jpg" alt="" :class="is_pay_type == 2 ? 'img-active' : ''"
                    @click="change_pay_type(2)">
            </div>
            <div class="queren" @click="go_submit_chong">确认充值</div>
        </div>

        <div class="tanchuang" v-if="showModal">
            <div class="cha" v-if="showModal" @click="showModal = false"></div>
            <div class="tixian-title">提现</div>
            <div class="tixian-con">提现<input type="text" class="tixian-inp">RMB（可提现<p class="tixian-p">100.00</p>元）</div>
            <div class="zhi">提现至</div>
            <div class="tixian-img">
                <div class="tixian-img-one" @click="change_pay_type(1)">
                    <img src="../../../assets/img/wechat.jpg" :class="is_pay_type == 1 ? 'img-active' : ''" alt="">
                    <p>（未绑定，请先绑定微信）</p>
                </div>
                <div class="tixian-img-two" @click="change_pay_type(2)">
                    <img src="../../../assets/img/pay.jpg" :class="is_pay_type == 2 ? 'img-active' : ''" alt="">
                    <p>（未绑定，请先绑定支付宝）</p>
                </div>
            </div>
            <div class="queren">确认提现</div>
        </div>
    </div>
</template>

<script>
import store from "@/store/index"
import QRCode from "qrcode";
import { getWxPay } from "@/request/pay"

import { get_user_info } from "@/request/login"
export default {
    name: 'recharge',
    data() {
        return {
            userinfo: {},
            radio: 10,
            showModal: false,
            other_price: null,
            is_pay_type: 2,
            timer: null,
        };
    }, async created() {
        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);

        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {
                let user_info_res = await get_user_info({ user_id: userinfo.id });
                if (user_info_res.code == 200) {
                    if (user_info_res.data.account != userinfo.account || user_info_res.data.freeze_account != userinfo.freeze_account) {
                        userinfo.account = Number(user_info_res.data.account);
                        userinfo.freeze_account = Number(user_info_res.data.freeze_account);

                        var userinfo_json = JSON.stringify(userinfo);
                        store.commit('setUserinfo', { userinfo: userinfo_json })
                    }
                }


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }




    },
    methods: {
        async go_submit_chong() {
            let that = this;
            let price = 0;
            let radio = this.radio;
            //选择了其他
            if (radio === 9) {
                price = this.other_price

            } else {
                price = radio;
            }

            if (price < 1) {
                this.$message.error("充值的金额必须要大于1");
                return false;
            }

            let is_pay_type = this.is_pay_type;
            if (is_pay_type == 1) {
                this.$message.error("赞不支持支付宝支付");
                return false;
            }

            let userinfo = this.userinfo;
            if (!userinfo) {
                this.$router.push('/login');
            }
            let res = await getWxPay({ user_id: userinfo.id, price: price, pay_type: is_pay_type });
            if (res.code == 200) {

                let url = res.data;
                let img_url = await QRCode.toDataURL(url);

                this.$alert(`<img src=${img_url} />`, "请使用微信扫码支付", {
                    showClose: false,
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonText: "支付成功",
                    cancelButtonText: "支付遇到问题",
                    center: true,
                    dangerouslyUseHTMLString: true,
                    beforeClose: (action, instance, done) => {
                        if (action == "confirm") {



                            clearInterval(this.timer);
                            this.timer = null;

                            //更新用户信息
                            get_user_info({ user_id: userinfo.id }).then(res => {
                                if (res.code == 200) {
                                    userinfo.account = res.data.account;
                                    console.log(userinfo);
                                    that.userinfo = userinfo;
                                    var userinfo_json = JSON.stringify(userinfo);
                                    store.commit('setUserinfo', { userinfo: userinfo_json })
                                }




                            })

                            done();
                        } else {
                            clearInterval(this.timer);
                            this.timer = null;
                            done();
                        }
                    }

                });

            } else {
                this.$message.error(res.msg)
            }





        }, change_pay_type(type) {

            this.is_pay_type = type


        }
    }
}
</script>

<style lang="less" scoped>
.box {
    background: #ffffff;
    padding-top: 30px;
    padding-bottom: 50px;
}

.header {
    width: 1020px;
    height: 173px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.302);
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    margin: 0px auto;
}

.header-title {
    display: flex;
    font-size: 24px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
}

.header-title>div {
    margin-top: 35px;
    margin-left: 150px;
}

.header-content {
    display: flex;
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-top: 30px;
}

.header-content>div {
    margin-left: 130px;
    width: 150px;
    text-align: center;
}

.header-content>div:nth-child(2) {
    margin-left: 110px;
}

.header-content>div:nth-child(3) {
    margin-left: 110px !important;
}

.tixian {
    color: #EA440A;
}

.header-footer {
    display: flex;
    justify-content: flex-end;
    font-size: 18px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #289EB8;
    margin-right: 25px;

}

.content {
    margin-left: 75px;
    margin-top: 35px;
}

.chong {
    font-size: 24px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #333333;
}

/deep/.el-radio__inner {
    width: 18px;
    height: 18px;
}

.el-avatar,
.el-cascader-panel,
.el-radio,
.el-radio--medium.is-bordered .el-radio__label,
/deep/.el-radio__label {
    font-size: 20px !important;
}

/deep/.el-radio {
    margin-left: 50px !important;
    margin-top: 20px !important;
}

/deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #289EB8 !important;
    background: #289EB8 !important;
    width: 18px;
    height: 18px;
}

/deep/.el-radio__input.is-checked+.el-radio__label {
    color: #289EB8 !important;
}

.content-con {
    margin-top: 35px;
}

.qita {
    width: 40px;
    height: 25px;
    background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    border: 1px solid #D1D1D1;
    outline: none;
    font-size: 20px;
}

.zhi {
    font-size: 24px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #333333;
    margin-top: 30px;
}


.zhi-two img {
    width: 200px;
    height: 76px;
    border-radius: 12px;
    opacity: 1;
    border: 1px solid #D1D1D1;
    margin-top: 30px;
    margin-left: 50px;
    cursor: pointer;
}

.zhi-two .img-active {
    border: 1px solid #289EB8 !important;
}

.queren {
    width: 140px;
    height: 50px;
    background: #289EB8;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    border: 1px solid #C7C7C7;
    font-size: 20px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #FFFFFF;
    text-align: center;
    line-height: 50px;
    margin: 30px auto;
    cursor: pointer;
}

.tanchuang {
    position: absolute;
    top: 45%;
    left: 35%;
    z-index: 1000;
    width: 700px;
    min-height: 400px;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.302);
    opacity: 1;
    border: 1px solid #C5C5C5;
    padding-left: 40px;
}

.cha {
    width: 25px;
    height: 25px;
    margin-left: 95%;
    margin-top: 10px;
    position: relative;
}

.cha::before,
.cha::after {
    content: "";
    position: absolute;
    /*方便进行定位*/
    height: 20px;
    width: 2.5px;
    top: 2px;
    right: 9px;
    /*设置top和right使图像在20*20框中居中*/
    background: #289EB8;
}

.cha::before {
    transform: rotate(45deg);
    /*进行旋转*/
}

.cha::after {
    transform: rotate(-45deg);
}

.tixian-title {
    font-size: 24px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: 700;
    color: #333333;
    margin-bottom: 30px;
}

.tixian-con {
    padding-left: 15px;
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}

.tixian-inp {
    width: 35px;
    height: 23px;
    background: #FFFFFF;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    border: 1px solid #D0D0D0;
    outline: none;
    font-size: 18px;
    margin: 0 10px;
}

.tixian-p {
    display: inline-block;
    color: #EA440A;
}

.zhi {
    padding-left: 15px;
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-top: 25px;
}

.tixian-img img {
    width: 145px;
    height: 55px;
    background: #FFFFFF;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
}

.tixian-img .img-active {
    border: 1px solid #289EB8 !important;
}

.tixian-img img:first-child {
    margin-right: 165px;
}

.tixian-img {
    margin-left: 60px;
    margin-top: 15px;
    display: flex;
}

.tixian-img-one,
.tixian-img-two {
    display: flex;
    flex-direction: column;
}

.tixian-img-one p {
    margin: 0;
    margin-left: -18px;
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #289EB8;
}

.tixian-img-two p {
    margin: 0;
    margin-left: -18px;
    font-size: 16px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #289EB8;
}

.queren1 {
    width: 127px;
    height: 45px;
    background: #289EB8;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    font-size: 20px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    margin: 0 auto;
    cursor: pointer;
}
</style>
import { render, staticRenderFns } from "./Joborder.vue?vue&type=template&id=242fd152&scoped=true&"
import script from "./Joborder.vue?vue&type=script&lang=js&"
export * from "./Joborder.vue?vue&type=script&lang=js&"
import style0 from "./Joborder.vue?vue&type=style&index=0&id=242fd152&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242fd152",
  null
  
)

export default component.exports
<template>
    <!-- <div class="left">

            <teachercenter :userInfo="userinfo"></teachercenter>
        </div> -->
    <div class="course right">
        <div class="course-search">
            <div class="search">
                <img src="../../../assets/img/搜索.png" alt="">
                <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_mocktes">
            </div>
            <button @click="search_mocktes">搜索</button>
        </div>
        <div class="course-content">
            <div class="content-header">
                <p>全部试题</p>
                <p>已审核({{ exam_num }})</p>
                <p>未审核({{ dai_exam_num }})</p>
            </div>
            <div class="content-body">

                <div class="body-content">
                    <ul>
                        <li class="body-header">
                            <div class="xuhao">序号</div>
                            <div class="biaoti">试题标题</div>
                            <div class="biaoti">课程标题</div>
                            <div class="zhuangtai">状态</div>
                            <div class="shuliang">订购数</div>
                            <div class="liulan">浏览量</div>
                            <div class="caozuo"><span>操作</span></div>

                        </li>
                        <li v-for="(item, index) in question_list.list" :key="index">
                            <div class="xuhao" @click="prew_word(index)">{{ index + 1 }}</div>
                            <div class="biaoti" @click="prew_word(index)" v-html="item.title"> </div>
                            <div class="biaoti" @click="prew_word(index)"> {{ item.curri.title }}</div>
                            <div class="zhuangtai" v-if="item.statue == 0">待审核</div>
                            <div class="zhuangtai" v-else-if="item.statue == 1">已审核</div>
                            <div class="zhuangtai" v-else>未通过</div>
                            <div class="shuliang" @click="prew_word(index)">5665</div>
                            <div class="liulan" @click="prew_word(index)">{{ item.download_num }}</div>
                            <div class="caozuo">
                                <div class="bianji" @click="eidt_question(index)">编辑</div>
                                <div class="shanchu" @click="del_question(index)">删除</div>
                            </div>
                        </li>
                    </ul>

                </div>
            </div>

            <div class="page">

                <el-pagination @current-change="question_change" :current-page="question_list.page" :page-sizes="[1]"
                    :page-size="question_list.pagesize" layout="prev, pager, next, jumper" :total="question_list.total">
                </el-pagination>
            </div>
        </div>

        <div class="tanchuang" v-if="showModal">
            <div class="cha" v-if="showModal" @click="showModal = false"></div>
            <h4>试题编辑</h4>
            <ul>
                <li class="video-bianji">
                    <div class="momishiti"><img src="../../../assets/img/excel.png" alt=""></div>

                    <div class="shangchuan">
                        <el-upload class="avatar-uploader" :show-file-list="false" :action="upload_url"
                            :data="{ filename: 'teacherpar' }" name="file" accept="" :http-request="uplaod_question">
                            <el-button size="small" type="primary">上传</el-button>

                        </el-upload>


                    </div>
                    <div class="ruanjian"> <el-input type="text" placeholder="请输入内容" v-model="question_info.title"
                            maxlength="30" show-word-limit>
                        </el-input></div>
                    <div class="jinbi">
                        <input type="text" class="input-jinbi" v-model="question_info.price">
                        <div>金币</div>
                    </div>
                </li>
            </ul>
            <button class="tijiao" @click="submit_question">保存</button>
        </div>


        <!-- 文件预览 -->
        <div class="preview-model" v-if="is_show_word">
            <Preview :previewData="active_word" @closePrew="close_privew"></Preview>

        </div>




    </div>
</template>
  
<script>


import BASEURL from "./../../../request/baseurl"
import store from "./../../../store/index"
import Preview from '@/components/Preview';
import { getQuestionList, deleteQuestion, editQuestion } from "@/request/teacher.js"
import axios from 'axios';
export default {
    name: 'teacherMocktes',
    data() {
        return {
            userinfo: {},
            baseurl: BASEURL,
            is_default_index: '',
            showModal: false,
            activeName: 'first',
            question_list: {
                total: 0,
                current: 1,
                pagesize: 5,
                list: []
            },
            exam_num: 0,
            dai_exam_num: 0,
            active_index: -1,
            upload_url: BASEURL + '/api/upload/generaupload',
            question_info: {},
            keyword: '',
            course_id: null,
            active_word: {
                type: 'doc',
                id: null,
                price: 0
            },
            is_show_word: false

        }
    }, components: {
        Preview
    }, created() {
        let is_default_index = store.state.left_menu_default;
        this.is_default_index = is_default_index;
        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);

        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }

        let course_id = this.$route.query.course_id;

        if (!course_id) {
            course_id = ''
        } else {
            this.course_id = course_id;
        }

        this.get_question_list(this.question_list.current, this.question_list.pagesize, '', course_id);

    },
    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },
        async get_question_list(current, pagesize, keyword, course_id) {


            let res = await getQuestionList({ current: current, pagesize: pagesize, keyword: keyword, course_id: course_id });
            if (res.code === 200) {
                let question_list = this.question_list;
                question_list.total = res.data.total;
                question_list.current = Number(res.data.current);
                question_list.pagesize = Number(res.data.pagesize);
                question_list.list = res.data.list
                this.question_list = question_list;
                this.exam_num = res.data.exam_num;
                this.dai_exam_num = res.data.dai_exam_num

            } else {
                this.$message.error(res.msg);
            }

        }, eidt_question(index) {

            this.showModal = true;
            this.active_index = index;
            let question_list = this.question_list;
            this.question_info = question_list.list[index];


        }, async uplaod_question(e) {

            let that = this;

            let file = e.file;

            let filesize = file.size;
            let filename = file.name;
            const isLtM = file.size / 1024 / 1024 < 10;
            if (!isLtM) {
                this.$message.error("请上传10M以下的试题文件");
                return false;
            }

            const formData = new FormData();  // 声明一个FormData对象
            formData.append("file", file);
            formData.append("filename", 'teacherquestion ')

            let result = await axios.post(e.action, formData, {
                //添加请求头
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })

            if (result.data.code == 1) {
                this.$message({
                    message: '上传试题成功',
                    type: 'success'
                });
                //              //构造数组，追加到数组视频列表中去
                //              var ext_index= file.name.lastIndexOf(".");
                //  //获取后缀
                //     var ext = file.name.substring(ext_index+1);
                //      var file_type  = 3;
                //     if(ext=='ppt'){
                //           file_type =1; 
                //     }else if(ext =='xls'){
                //              file_type =2; 
                //     }else{
                //           file_type =3; 
                //     }

                //  let question_info = {'url':result.data.data.url,'filesize':file.size / 1024 / 1024,'name':file.name,'file_type':file_type,price:0};

                // let qustion_list =  that.qustion_list;
                let question_info = this.question_info;
                question_info.url = result.data.data.url;
                question_info.filesize = file.size / 1024 / 1024;

                this.question_info = question_info;




            } else {
                this.$message.error(result.data.msg);
            }



        }, async submit_question() {

            let question_info = this.question_info;

            if (!question_info.url) {
                this.$message.error("请上传试题");
                return false;
            }

            if (!question_info.title) {

                this.$message.error('试题名称不能为空');
                return false;
            }
            let res = await editQuestion(question_info);

            if (res.code == 200) {

                this.$message.success("编辑成功");
                let question_list = this.question_list;
                //操作哪个的视频列表下标
                let active_index = this.active_index;
                question_list.list[active_index] = question_info;
                this.question_list = question_list;

            } else {
                this.$message.error(res.msg);

            }




        },
        async del_question(index) {


            let question_list = this.question_list;

            let question_info = question_list.list[index];

            try {


                let res = await this.$confirm('此操作将永久删除试题, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                });

                if (res == 'confirm') {

                    let result = await deleteQuestion({ id: question_info.id });
                    if (result.code === 200) {
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });



                        question_list.list.splice(index, 1);


                        this.question_list = question_list;

                    } else {
                        this.$message.error(result.msg);
                    }
                }


            } catch (error) {
                console.log(2);
            }
        },


        // 搜索
        search_mocktes() {

            let keyword = this.keyword;
            let course_id = this.course_id;

            this.get_question_list(1, this.question_list.pagesize, keyword, course_id);




        }, async question_change(pager) {

            let keyword = this.keyword;
            let course_id = this.course_id;
            let that = this;



            let res = await getQuestionList({ current: pager, pagesize: that.question_list.pagesize, keyword: keyword, course_id });


            if (res.code == 200) {

                let question_list = this.question_list;
                question_list.total = Number(res.data.total);
                question_list.page = Number(res.data.current);
                question_list.total_page = Number(res.data.pages);
                question_list.list = res.data.list;

                this.question_list = question_list;
                // this.$emit('change', pager)
            }


        }, prew_word(index) {


            let info = this.question_list.list[index];
            let price = 0;
            //1作业练习 2模拟试题 模拟真题
            let active_word = { id: info.id, type: 2, price: price }
            this.active_word = active_word
            this.is_show_word = true



        }, close_privew() {
            this.is_show_word = false
        }




    }

}
</script>
  
<style lang="less"  scoped >
@import url("./../../../assets/css/teacher_common.less");

h4 {
    margin-left: 30px;
    margin-top: 5px;
}

li {
    list-style: none;
}

.video-bianji {
    display: flex;
}

.shangchuan {
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    color: #EA440A;
    line-height: 85px;
    margin: 0 5px;
}

.video-bianji .momishiti img {
    margin-top: 23px;
    width: 45px;
    height: 40px;
}

.video-bianji .ruanjian {
    overflow: hidden;
    margin-top: 25px;
    padding-left: 20px;
    width: 340px;
    height: 37px;
    background: #F8F8F8;
    border-radius: 8px;
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 35px;
}

.video-bianji .ruanjian .el-input {
    background-color: #F8F8F8;
}

.video-bianji .ruanjian .el-input /deep/ .el-input__inner {
    background-color: #F8F8F8;
    border: none;
}

.center-main {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 40px;

}

.xuhao {
    width: 80px;
    height: 52px;
    text-align: center;
}

.zhuangtai {
    text-align: center;
    width: 120px;
    margin: 0 0px;
}

.zuoye {
    display: flex;
    margin-top: 30px;
}

.zuoye .input-jinbi {
    width: 40px;
    height: 20px;
}

.zuoye .video-input {
    width: 500px;
    height: 45px;
    background: #F8F8F8;
    border-radius: 8px;
    border: 1px solid #ECECEC;
}

.zuoye .jinbi {
    margin-top: 10px;
}

.zuoye .delect {
    color: #EA440A;
    line-height: 49px;
}

.bangong {
    width: 45px;
    height: 40px;
    margin-right: 30px;
    margin-top: 5px;
}

.tijiao {
    display: block;
    margin: 20px auto;
    width: 161px;
    height: 33px;
    line-height: 33px;
    text-align: center;
    background: #289EB8;
    border-radius: 6px;
    color: #FFFFFF;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
}

.tianjia {
    width: 91px;
    height: 80px;
    background: #F8F8F8;
    border-radius: 8px;
    margin: 20px auto;
    color: #ADADAD;
    font-size: 45px;
    text-align: center;
    padding-top: 5px;
}

.jinbi {
    margin-left: 20px;
    display: flex;
    margin-top: 30px;
    margin-right: 20px;
}

.input-jinbi {
    width: 40px;
    height: 20px;
    margin-right: 10px;
}

input {
    outline: none;
}

.video {
    display: flex;
}

.video video {
    width: 100px;
    height: 100px;
}

.video .video-input {
    margin-top: 28px;
    width: 500px;
    height: 45px;
    background: #F8F8F8;
    border-radius: 8px;
    border: 1px solid #ECECEC;
}

.video .delect {
    margin-top: 40px;
    color: #EA440A;
}

.left {
    width: 310px;

}

.body-content ul {
    margin: 0;
    padding-left: 42px;
    padding-bottom: 30px;
}

.body-content ul li {
    list-style: none;
    height: 52px;
}

button {
    cursor: pointer;
    border: 0.5px solid #E9E9E9;
}

.biaoti {
    margin: 0 0px;
    width: 240px;
    text-align: center;
    overflow: hidden;
}

.shuliang {
    text-align: center;
    width: 110px;
    margin: 0 0px;
}

.liulan {
    width: 120px;
    text-align: center;
    margin: 0 0px;
}

.caozuo {
    display: flex;
    margin: 0px;
    width: 120px;
    text-align: center ! important;
    justify-content: space-between;
}

.caozuo span {
    width: 120px;
    text-align: center;
}





.bianji {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #EA440A;
    width: 60px !important;
    text-align: center;
}

.shanchu {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #EA440A;
    width: 60px !important;
    text-align: center;
}


.course {
    width: 1150px;
    /* height: 626px; */
    overflow: auto;
    height: auto;
    background: #FFFFFF;
    border-radius: 6px;

}

.course-search {
    margin-left: 31px;
    padding-top: 51px;
    display: flex;
}

.course .search {
    position: relative;
    width: 653px;
    height: 50px;
    border: 2px solid #ECECEC;
    border-radius: 21px;
}

.course .search input {
    outline: none;
    margin-left: 55px;
    border-style: none;
    width: 570px;
    height: 45px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ADADAD;
    line-height: 45px;
    margin-top: 2.5px;
}

.course .search img {
    position: absolute;
    left: 20px;
    top: 11px;
    width: 28px;
    height: 28px;
}

.course-search button {
    cursor: pointer;
    width: 115px;
    height: 50px;
    border: 1px solid #FFFFFF;
    background: #289EB8;
    border-radius: 8px;
    margin-left: 36px;
    margin-top: 0px;
    font-size: 22px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 50px;
}

.content-header {
    display: flex;
    margin-left: 74px;
    margin-top: 20px;
}

.content-header p {
    cursor: pointer;
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #323B46;
}

.content-header p:nth-child(2) {
    margin-left: 122px;
    width: 160px;
    text-align: center;
}

.content-header p:nth-child(3) {
    margin-left: 131px;
    width: 160px;
    text-align: center;
}

.content-body {
    margin-left: 35px;
    margin-top: 0px;
    margin-bottom: 50px;
    width: 1105px;
    background: #FFFFFF;
    box-shadow: 0px 0px 65px 0px rgba(51, 51, 51, 0.1);
    border-radius: 16px;
}

.content-body .body-header {
    display: flex;
}

.body-content li {
    cursor: pointer;
    display: flex;
    font-size: 18px;
    line-height: 52px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #323B46;
    margin: 10px auto;
}

.body-content li:first-child {
    font-size: 22px !important;
    color: #606060;
}

.body-content li:hover {
    background-color: #DCF6FB;
}

.body-content li:first-child:hover {
    background-color: #fff;
    cursor: auto;
}

.body-content li .xin {
    background-color: #289EB8;
    color: #FFFFFF;
    margin-bottom: 5px;
}

.body-content button {
    border-radius: 6px;
}

/* 弹窗界面 */
.tanchuang {
    position: absolute;
    top: 45%;
    left: 35%;
    z-index: 1000;
    width: 700px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 68px 0px rgba(51, 51, 51, 0.26);
    border-radius: 12px;
}

.cha {
    width: 25px;
    height: 25px;
    margin-left: 95%;
    margin-top: 10px;
    position: relative;
}

.cha::before,
.cha::after {
    content: "";
    position: absolute;
    /*方便进行定位*/
    height: 20px;
    width: 2.5px;
    top: 2px;
    right: 9px;
    /*设置top和right使图像在20*20框中居中*/
    background: #289EB8;
}

.cha::before {
    transform: rotate(45deg);
    /*进行旋转*/
}

.cha::after {
    transform: rotate(-45deg);
}

/deep/ .el-tabs__item {
    font-size: 18px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 35px;
}

.preview-model {

    position: fixed;
    top: 10%;
    width: 1200px;
    height: 630px;


    left: 18.75%;
    z-index: 1000;
    background: #ffffff;

    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 68px 0px rgba(51, 51, 51, 0.26);
    border-radius: 12px;


}
</style>
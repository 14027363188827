<template>
  <div id="app" v-cloak>
    <myHeader></myHeader>
    <div class="main-height"><router-view /></div>
    <Footer></Footer>
  </div>
</template>  

<script>

import Header from './components/common/Header.vue'
import Footer from './components/common/Footer.vue'
 
export default {
  name: 'App',
  components: {
    'myHeader': Header,
    "Footer": Footer
  }
}
</script>

<style>
@import "./assets/css/common.less";

@font-face {
  font-family: pingfang;
  src: url("./assets/font/pingfang.ttf");
}


.main-height {
  min-height: 900px;
}


#app {
  font-family: PingFang SC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #333333;
  font-family: pinfang;
  font-size: 20px;
  font-weight: 500;
  min-height: 100vh;



}

body {
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  color: #333333;

  margin: 0;
  height: 100%;

}


.flex-row {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
}

@import "./assets/css/common.less"
</style>
  
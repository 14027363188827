<template>
    <div class="box">
        <div class="dynamic-header">

            <div class="dynamic-img">
                <img src="./../assets/img/trends/top.jpg" alt="">
            </div>


            <div class="dynamic-mess">
                <div class="dynamic-touxiang">
                    <img :src="ossurl + teacher_info.avatar" alt="" v-if="teacher_info.avatar">
                    <img src="./../assets/img/header.png" alt="" v-else>
                </div>
                <div class="dynamic-xinxi">
                    <div class="xinxi-name">{{ teacher_info.nickname }}</div>
                    <div class="xinxi-mess">
                        学校：<div class="mess-xuexiao">{{ teacher_info.gra_schoole }}</div>
                        学历：<div class="mess-xueli">{{ teacher_info.education }}</div>
                        <!-- 专业：<div class="mess-zhuanye">{{teacher_info.major}}</div> -->
                    </div>
                </div>
                <div class="dynamic-btn">
                    <div class="btn-haoyou" @click="add_friend" v-if="!teacher_info.is_friend">+好友</div>
                    <div class="btn-haoyou" v-else>已是好友</div>
                    <div class="btn-goutong" @click="gou_tong(teacher_id)">聊天</div>
                </div>
            </div>


            <div class="dynamic-jieshao">
                个人介绍：<div class="jieshao">
                    {{ teacher_info.profile }}
                </div>
            </div>

        </div>

        <div class="dynamic-content">
            <div class="left">
                <div class="fabu" v-if="userinfo.user_type == 1 && userinfo.id == teacher_id">
                    <textarea v-model="form_data.content" name="" id="" cols="30" rows="10"
                        placeholder="快来发布一个动态吧"></textarea>

                    <div class="pre-img" v-if="form_data.imgs.length > 0 && form_data.is_type == 0">

                        <div class="pic-info" v-for="(item, key) in form_data.imgs" :key="key">

                            <img alt="" :src="baseurl + item">
                            <i class="el-icon-circle-close" @click="delete_img(key)"></i>
                        </div>
                        <div class="upload-img-input">
                            <el-upload :action="upload_url" :multiple="true" :show-file-list="false" :auto-upload="true"
                                :on-success="upload_img_success" :limit="9">
                                <i class="el-icon-plus"></i>
                            </el-upload>


                        </div>


                    </div>
                    <div class="pre-img meido-area">

                        <el-progress type="circle" :percentage="percentage"
                            v-if="percentage != 100 && form_data.is_type == 1"></el-progress>
                        <div class="pic-info" v-if="form_data.is_type == 1 && form_data.medio_url != ''">

                            <video :src="baseurl + form_data.medio_url" :controls="true"
                                class="medio">您的浏览器不支持video标签。</video>
                            <i class="el-icon-circle-close" @click="delete_medio"></i>
                        </div>
                    </div>

                    <div class="fabu-btn">
                        <div class="btn-tupian">
                            <el-upload class="upload-demo" :multiple="true" :limit="9" :action="upload_url"
                                :auto-upload="true" :on-success="upload_img_success" :show-file-list="false"
                                :on-change="uplaod_img">
                                <div class="btn-tupian">
                                    <img src="./../assets/img/trends/tupian.png" alt="">图片
                                </div>
                            </el-upload>




                            <el-upload class="upload-demo" :limit="1" :action="upload_url" :auto-upload="true"
                                :on-success="upload_medio" :on-progress="uploadVideoProcess" accept=".mp4"
                                :show-file-list="false">
                                <div class="btn-tupian">
                                    <img src="./../assets/img/trends/shipin.png" alt="">视频
                                </div>
                            </el-upload>

                        </div>


                        <div class="btn-yinsi">
                            <el-dropdown @command="handleCommand">
                                <span class="el-dropdown-link" v-if="form_data.is_public == 0">
                                    公开<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <span class="el-dropdown-link" v-if="form_data.is_public == 1">
                                    仅自己可看<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="0">公开</el-dropdown-item>
                                    <el-dropdown-item command="1">仅自己可看</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="btn-fasong" @click="submit_trend">发送</div>
                    </div>
                </div>

                <div class="back" v-if="userinfo.user_type == 1 && userinfo.id == teacher_id"></div>

                <div class="alldongtai">全部动态({{ trends_list.total }})</div>

                <div class="dongtai-list-content" v-if="trends_list.list.length > 0">
                    <div class="dongtai" v-for="(item, key) in trends_list.list" :key="key">
                        <div class="dongtai-header">
                            <div class="dongtai-header-tou">
                                <img :src="ossurl + teacher_info.avatar" alt="" v-if="teacher_info.avatar">
                                <img src="./../assets/img/header.png" alt="" v-else>


                            </div>
                            <div class="dongtai-header-xin">
                                <div class="header-xin-name">{{ teacher_info.nickname ? teacher_info.nickname : '' }}</div>
                                <div class="header-xin-time">{{ item.createtime }}</div>
                            </div>
                            <div class="dongtai-header-chu" v-if="userinfo.id == teacher_id"
                                @click="delete_trends(item.id, key)">删除</div>
                        </div>
                        <div class="dongtai-content">
                            <div class="content-wen">
                                {{ item.content }}
                            </div>
                            <div class="content-tu" v-if="item.type == 0">
                                <el-image style="width:240px; height: 150px" v-for="(imginfo, key1) in item.imgs"
                                    :key="key1" :src="imginfo" :preview-src-list="item.imgs">
                                </el-image>

                            </div>
                            <div class="content-tu" v-if="item.type == 1">
                                <video :src="ossurl + item.medio_url" :controls="true" class="medio-area">


                                </video>

                            </div>
                        </div>
                        <div class="dongtai-footer">
                            <div class="dongtai-footer-zhuan"><img src="./../assets/img/trends/zhuanfa.png" alt="">转发</div>
                            <div class="dongtai-footer-pinlun" @click="go_comment($event)"><img
                                    src="./../assets/img/trends/pinlun.png" alt="">评论</div>
                            <div class="dongtai-footer-dian" @click="atten_trends(item.id, key)" v-if="!item.is_like"><img
                                    src="./../assets/img/trends/dianzan.png" alt="">点赞</div>
                            <div class="dongtai-footer-dian" @click="atten_trends(item.id, key)" v-else><img
                                    src="./../assets/img/zan.png" alt="">取消点赞</div>
                        </div>
                        <div class="comment-area" style="display:none">

                            <div class="comment-input">
                                <input type="text" name="comment" v-model="comment_content" placeholder="输入评论">

                                <el-button type="primary" class="send" @click="send_comment(item.id)">发送</el-button>


                            </div>

                        </div>

                        <div class="comment-list" v-if="item.get_commentlist.length > 0">
                            <div class="comment-info" v-for="(comment, key2) in item.get_commentlist" :key="key2">
                                <div class="avatar">
                                    <img :src="ossurl + comment.avatar" alt="">

                                </div>
                                <div class="comment-name">
                                    <div>{{ comment.nickname ? comment.nickname : '--' }}：</div>
                                    <div>{{ comment.createtime }}</div>

                                </div>
                                <div class="comment-content">

                                    {{ comment.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 暂无动态 -->
                <div class="dongtai-list-content" v-else>
                    <el-empty description="暂无动态"></el-empty>
                </div>


            </div>
            <div class="right">
                <div class="right-area">
                    <div class="right-header"><img src="./../assets/img/trends/ke.png" alt="">我的课程</div>
                    <div v-if="course_info">
                        <div class="right-content" @click="go_detail(course_info.id)">
                            <div class="right-content-title">{{ course_info.title }}</div>
                            <div class="right-content-con">课程详情：{{ course_info.intro }}</div>
                        </div>
                        <div class="right-img"> <el-image :src="ossurl + course_info.cover_img">
                                <div slot="placeholder" class="image-slot">
                                    加载中<span class="dot">...</span>
                                </div>
                            </el-image>
                        </div>
                    </div>
                    <div v-else class="right-content">
                        <el-empty description="暂无课程"></el-empty>

                    </div>
                </div>

            </div>
        </div>


        <Chat @closeChat="close_chat" v-if="is_show_chat" :toUserId="teacher_id" />
    </div>
</template> 




<script>

import { UserInfo, deleteTrendFile } from "@/request/guan"

import { InsertTrends, getTrendsList, commentTrends, deleteTrends, attenTrends, getTeacherCourse } from "@/request/teacher"
import { addTeacherFriend } from "@/request/student"
import Chat from "@/components/Chat"
import OSSURL from '@/request/oss.js'
import BASEURL from "@/request/baseurl"
import store from "@/store/index"
import axios from 'axios';
export default {
    name: 'strends',
    data() {

        return {
            teacher_info: {},
            trends_list: {
                'total': 0,
                'page': 1,
                'pagesize': 10000,
                'list': []

            },
            course_info: {},

            form_data: {
                content: '',
                imgs: [],
                medio_url: '',

                is_public: 0,
                is_type: 0
            },
            teacher_id: null,

            imgs: [],
            baseurl: BASEURL,
            userinfo: {},
            percentage: 0,
            is_login: false,

            upload_url: BASEURL + '/api/upload/generaupload',

            comment_content: '',
            is_show_chat: false,
            ossurl: OSSURL
        }



    }, computed: {
        getUserInfo() {


            return this.$store.state.Userinfo
        }

    }, watch: {
        userinfo(val, oldval) {

            if (val) {
                this.userinfo = val;
            }

        }



    }, components: {
        Chat
    },
    created() {
        let teacher_id = this.$route.query.user_id;
        this.teacher_id = teacher_id;

        var userinfo = localStorage.getItem('Userinfo');


        //如果拥有用户信息，则直接进入个人中心
        try {

            if (userinfo) {

                //判断时间是否过期
                userinfo = JSON.parse(userinfo);


                if (userinfo.expiretime * 1000 < new Date().getTime()) {
                    this.is_login = false;
                } else {
                    this.is_login = true;
                    this.userinfo = userinfo
                }

            }
        } catch (error) {

        }
        this.initdata(teacher_id);

    }, methods: {
        async initdata(teacher_id) {

            let res = await UserInfo({ user_id: teacher_id });
            if (res.code === 200) {

                this.teacher_info = res.data


            } else {
                this.$messge.error("出现意外错误");
            }

            this.get_trends_list(this.page, this.trends_list.pagesize, teacher_id)
            this.get_teacher_course(teacher_id);



        }, async get_teacher_course(teacher_id) {

            let res = await getTeacherCourse({ teacher_id: teacher_id });
            if (res.code === 200) {
                this.course_info = res.data
            }


        },


        async get_trends_list(current, pagesize, user_id) {

            let res = await getTrendsList({ user_id: user_id, current: current, pagesize: pagesize });
            if (res.code == 200) {
                let trends_list = this.trends_list;
                trends_list.total = res.data.total;
                trends_list.page = Number(res.data.current);
                trends_list.list = res.data.list;




                this.trends_list = trends_list;


            } else {
                this.$messge.error('获取官方动态出错');
            }

        },
        //删除动态
        async delete_trends(id, key) {
 

            let result = await this.$confirm('此操作将永久动态吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });

            if (result == 'confirm') {
                let trends_list = this.trends_list;


                let res = await deleteTrends({ id: id })
                if (res.code == 200) {
                    trends_list.list.splice(key, 1)
                    this.trends_list = trends_list;
                    this.$message.success('删除成功');
                } else {
                    this.$messge.error(res.msg);
                }


            }



        }, go_detail(id) {

            this.$router.push('/coursedetail?id=' + id);
        }, course_change(page) {


            this.get_curri_list(page, this.course_list.pagesize, this.guaninfo.id);
        }, uplaod_img() {

        },

        //发布动态

        async submit_trend() {

            let form_data = this.form_data;
            if (!form_data.content && !form_data.imgs && !form_data.medio_url) {
                this.$message.error("发布动态不能为空");
                return false;
            }

            let res = await InsertTrends(form_data);
            if (res.code === 200) {
                this.$message.success("发布动态成功");
                let form_data = this.form_data;
                form_data.imgs = '';
                form_data.medio_url = '';
                form_data.content = '';
                form_data.is_public = 0,
                    form_data.is_type = 0;
                this.form_data = form_data


                this.get_trends_list(1, this.trends_list.pagesize, this.teacher_id)

            } else {
                this.$message.error('删除失败');
            }





        }, upload_img_success(e) {
            console.log(e);
            console.log();
            let form_data = this.form_data

            if (e.code == 1) {
                form_data.imgs.push(e.data.url);
                form_data.is_type = 0;
                this.form_data = form_data;
            } else {
                this.$messge.error(e.msg);
            }

        }, uploadVideoProcess(e) {
            let form_data = this.form_data
            form_data.is_type = 1;
            this.form_data = form_data;
            this.percentage = Number(e.percent.toFixed(2))

        },



        upload_medio(e) {

            let form_data = this.form_data
            if (e.code == 1) {
                form_data.medio_url = e.data.url;
                form_data.is_type = 1;
                this.form_data = form_data;
            } else {
                this.$messge.error(e.msg);
            }
        },
        //删除照片
        async delete_img(index) {

            let imgs_url = this.form_data.imgs[index]
            try {
                if (!imgs_url) {
                    this.$message.error("删除文件不能为空");
                }

                let res = await deleteTrendFile({ 'file_url': imgs_url });
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });

                    let form_data = this.form_data;
                    form_data.imgs.splice(index, 1);


                    this.form_data = form_data;
                } else {
                    this.$message({
                        message: '删除失败',
                        type: 'success'
                    });
                }

            } catch {
                this.$message.error("出现意外错误，请刷新重试");
            }

        },


        async delete_medio() {

            let imgs_url = this.form_data.medio_url
            try {
                if (!imgs_url) {
                    this.$message.error("删除文件不能为空");
                }

                let res = await deleteTrendFile({ 'file_url': imgs_url });
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });

                    let form_data = this.form_data;
                    form_data.medio_url = '';


                    this.form_data = form_data;
                } else {
                    this.$message({
                        message: '删除失败',
                        type: 'success'
                    });
                }

            } catch {
                this.$message.error("出现意外错误，请刷新重试");
            }

        },
        //切换选择公开还是私密
        handleCommand(e) {
            let form_data = this.form_data;
            form_data.is_public = e;
            this.form_data = form_data

        }, go_comment(e) {
            console.log(e);
            console.log('click');
            e.currentTarget.parentElement.nextElementSibling.style.display = 'block';


        },
        //评论
        async send_comment(trends_id) {


            let comment_content = this.comment_content;
            let userinfo = this.userinfo;
            if (!userinfo) {
                this.$message.error("需要登录哦");
                return false;
            }

            if (!userinfo.id) {
                this.$message.error("需要登录哦");
                return false;
            }

            if (!comment_content) {
                this.$message.error('请输入评论内容');

                return false;
            }

            let res = await commentTrends({ trends_id: trends_id, content: comment_content })
            if (res.code === 200) {
                this.comment_content = '';
                this.$message.success(res.msg);
            } else {
                this.$message.error(res.msg);
            }





        },
        //点赞
        async atten_trends(id, index) {

            let trends_list = this.trends_list;

            let res = await attenTrends({ id: id });
            if (res.code === 200) {
                trends_list.list[index].is_like = !trends_list.list[index].is_like;

            } else {
                this.$message.error(res.msg)
            }


        }, close_chat: function () {
            this.is_show_chat = false;
        }, gou_tong: function (to_user_id) {
            let userinfo = this.userinfo;
            if (userinfo == null) {
                this.$message.warning("需要先登录哦")
                return false;
            }

            if (Object.keys(userinfo).length < 1) {

                this.$message.warning("需要先登录哦")
                return false;
            }




            if (userinfo.user_type == 1) {

                this.$message.warning("对不起，您不是学生")
                return false;

            }



            this.to_user_id = to_user_id;
            this.is_show_chat = true;
        }, async add_friend() {

            let userinfo = this.userinfo;
            if (userinfo == null) {
                this.$message.warning("需要先登录哦")
                return false;
            }

            if (Object.keys(userinfo).length < 1) {

                this.$message.warning("需要先登录哦")
                return false;
            }


            if (userinfo.user_type == 1) {

                this.$message.warning("对不起，您不是学生")
                return false;

            }

            let teacher_id = this.teacher_id;
            let res = await addTeacherFriend({ teacher_id: teacher_id });

            if (res.code === 200) {


                this.$message.success("添加好友成功")
                let teacher_info = this.teacher_info;
                teacher_info.is_friend = true;
                this.teacher_info = teacher_info;


            } else {
                this.$message.warning(res.msg)
                return false;
            }







        },


    }

}
</script>


<style lang="less" scoped>
@import url(./../assets/css/strends.less);
</style>




<template>
    <div>
        <video ref="videoPlayer" class="video-js" :autoplay="false">
                
              
        </video>  
    </div> 
</template> 

<script>
import videojs from 'video.js';//不要忘记包括视频.js CSS，位于 .video.js/dist/video-js.css
import 'video.js/dist/video-js.css'

export default {
    name: "VideoPlayer",
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    data() {
        return { 
            player: null
        }
    },watch: {

      
  },
    mounted() {
         setTimeout(() => {
        this.$nextTick(() => {
          this.initVideo();
        },300);
      });

        
    
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose()
        }
    },methods:{
         initVideo() {
        this.player = videojs(
            this.$refs.videoPlayer,
            this.options,
            function onPlayerReady() {}
        );
        }   
    }
}
</script>
<style scoped>
  .video-js{
        height:395px !important;
        width:654px  !important;
  }


</style>
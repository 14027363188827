<template>
    <div class="box">
        <div class="header">
            <div class="header-top">
                <div class="liushui">流水号<input type="text" class="liushui-inp" v-model="number"></div>
                <div class="jiaoyi">交易时间
                    <el-date-picker v-model="value4"
                     type="daterange" align="right" unlink-panels
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" >
                    </el-date-picker></div> 
            </div> 
            <div class="header-footer">
                <div class="shouzhi">收支类型<el-select v-model="value" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select></div>
                <div class="jiaoyileixing">交易类型<el-select v-model="value1" placeholder="请选择">
                        <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select></div>
                <div class="zhifu">支付方式<el-select v-model="value2" placeholder="请选择">
                        <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select></div>
                <button class="sousuo" @click="search">搜索</button>
                <button class="chongzhi">重置</button>
            </div>
        </div>
        <div class="content"> 
            <el-table :data="list.list" style="width: 100%">
                <el-table-column prop="pay_number" label="流水号" width="300">
                </el-table-column>
                <el-table-column prop="createtime_text" label="交易时间" width="200">
                </el-table-column>
                <el-table-column prop="re_pay_type_str_text" label="收支类型" width="150">
                </el-table-column>
                <el-table-column prop="business_type_str" label="交易类型" width="100">
                </el-table-column>
                <el-table-column prop="pay_type_text" label="支付方式" width="100">
                </el-table-column>
                <el-table-column prop="price" label="金额" width="100">
                </el-table-column>
                <el-table-column prop="remark" label="备注" width="100">
                </el-table-column>
            </el-table>
        </div>

              <div class="page">

                    <el-pagination
            
                        @current-change="list_change"
                        :current-page="list.current"
                        :page-sizes="[1]" 
                        :page-size="list.pagesize"
                        layout="prev, pager, next, jumper"
                        :total="list.total">
                    </el-pagination> 
                </div>
    </div>
</template>
<script>

import BASEURL from "./../../../request/baseurl"
import store from "./../../../store/index"
import {getIncomeList} from "@/request/pay"
export default {
    name: 'studentIncome',
    data() {
        return {
            
            value4:'',
            number:'',
            options: [{
                value: 1,
                label: '收入'
            }, {
                value: 2,
                label: '支出'
            },],

            value: '',
            options1: [{
                value: 1,
                label: '充值'
            }, {
                value: 2,
                label: '消费'
            }
                , {
                value: 3,
                label: '提现'
            },],

            value1: '',
            options2: [{
                value: 1,
                label: '支付宝'
            }, {
                value: 2,
                label: '微信'
            }, {
                value: 3,
                label: '余额'
            }],
            value2: '',
           
            list:{
              total:0,
              current:1,
              pagesize:5,
              list:[]   
            },
            userinfo:{},

        }
    },created() {

           var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);
      
        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }


        this.initdata();



    },methods:{
        initdata(){
  
               this.getlist(this.list.current,this.list.pagesize,'',);

        },

        //获取记录
        async getlist(current,pagesize,number,start_time,end_time,re_pay_type,business_type,pay_type){

            let res = await  getIncomeList({current:current,pagesize:pagesize,number:number,start_time:start_time,end_time:end_time,re_pay_type:re_pay_type,business_type:business_type,pay_type:pay_type});
            if(res.code == 200){
                 
                 let list = this.list;
                 list.total = Number(res.data.total);
                 list.current  = Number(res.data.current);
                 list.list = res.data.list;
                 this.list = list; 


                 
            }else{
                   this.$message.error(res.msg);
            }

             


        }, 
         //搜索
        async search(){

           let  value4 = this.value4;
           let value  = this.value;
           let value1 = this.value1;
           let value2 = this.value2;
           let number = this.number;

    let start_time = ''
     let end_time = '';  
          if(value4){
               start_time = value4[0]; 
               end_time = value[1]
          }else{
               start_time='';
               end_time = '';  
          }

          this.getlist(1,this.list.pagesize,number,start_time,end_time,value,value1,value2);
         
            let res = await  getIncomeList({current:1,pagesize:this.list.pagesize,number:number,start_time:start_time,end_time:end_time,re_pay_type:value,business_type:value1,pay_type:value2});
     
 
            
          
             
        }, async list_change(page){
                   let  value4 = this.value4;
           let value  = this.value;
           let value1 = this.value1;
           let value2 = this.value2;
           let number = this.number;

    let start_time = ''
     let end_time = '';  
          if(value4){
               start_time = value4[0]; 
               end_time = value[1]
          }else{
               start_time='';
               end_time = '';  
          }

          this.getlist(page,this.list.pagesize,number,start_time,end_time,value,value1,value2);
         
            let res = await  getIncomeList({current:1,pagesize:this.list.pagesize,number:number,start_time:start_time,end_time:end_time,re_pay_type:value,business_type:value1,pay_type:value2});
              
        }

    }
}
</script>
<style lang="less" scoped>

@import url("./../../../assets/css/teacher_common.less");
.box {
    width: 1176px;
    
    background: #FFFFFF;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
}

.header {
    margin-left: 80px;
    padding-top: 65px;
}

.header-top {
    display: flex;
    font-size: 22px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;
}

.liushui-inp {
    width: 190px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    border: 1px solid #D1D1D1;
    outline: none;
    font-size: 22px;
    margin-left: 15px;
}

.jiaoyi {
    margin-left: 50px;
}

.header-footer {
    display: flex;
    margin-top: 25px;
    font-size: 22px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333333;

}

.el-select/deep/.el-input__inner {
    width: 95px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 3px 3px 3px 3px;
    opacity: 1;
    border: 1px solid #D1D1D1;
    margin-left: 10px;
}

.el-range-editor.is-active {
    border-color: #289EB8;
}


.el-select-dropdown__item.selected {
    color: #289EB8;
}

/deep/.el-select .el-input.is-focus .el-input__inner {
    border-color: #289EB8;
}

.jiaoyileixing {
    margin-left: 30px;
}

.zhifu {
    margin-left: 30px;
}

.sousuo {
    width: 95px;
    height: 40px;
    background: #289EB8;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    font-size: 22px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 35px;
    border: none;
    margin-left: 90px;
    margin-right: 30px;
}

.chongzhi {
    width: 95px;
    height: 40px;
    background: #fff;
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    font-size: 22px;
    font-family: Source Han Sans CN-Medium, Source Han Sans CN;
    font-weight: 500;
    color: #333;
    line-height: 35px;
    border: 1px solid #D1D1D1;
}

.content {
    margin-left: 50px;
    margin-top: 100px;
    width: 1100px;
    
    background: #FFFFFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.302);
    border-radius: 15px 15px 15px 15px;
    opacity: 1;
}

/deep/.el-table .cell {
    text-align: center !important;
}

/deep/.el-table {
    font-size: 18px !important;
}
.header-footer /deep/.el-input__icon{
     line-height: 30px !important;
}
.el-range-editor.el-input__inner {margin-left: 15px;
}
</style>
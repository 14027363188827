<template>
  <div  class="content" >
    <div class="header">

       <div class="right-icon" @click="close_pre"> <i class="el-icon-close" ></i></div>

    </div>
  <div :class="previewData.price>0?'main-content':'main-content fee-content'">
    <div  v-html="tableau"  v-loading="loading"
    element-loading-text="拼命加载中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"></div>
  </div>

    
      <div class="lock" v-if="!loading &&price>0">
       <div class="fufei">
          查看剩余内容请<span>付费</span>
       </div>
       <div >
        <i class="el-icon-d-arrow-left"></i>
       </div>

   

    </div>
   
  </div>
</template>
<script>

import axios from 'axios'
import {StreamData} from "@/request/course"
let docx = require('docx-preview');
const XLSX = require("xlsx")

export default {
  name: "index",
  props: {
    previewData: {
      default: {
        type: 'doc',
        id: null,
        price:0,
      }
    },
  },
  data() {
    return {
      tableau: '',
      pdfUrl: '',
      loading:true,
      price:0,
    }
  },
  created() {
      let that= this;
    if(this.previewData.id == '' && this.previewData.id !=null) {
      this.msgWarning('文件路径为空')
    } else {
      
      this.price = Number(this.previewData.price);

      this.get_info(this.previewData.id,this.previewData.type);
    } 
  
  }, 
  methods: { 
    async get_info(id,type){
    let res  =  await StreamData({id:id,type:type})
    if(res.code == 200){
       this.loading = false;
      this.tableau = res.data
    }else{
      this.loading = false;
       this.$message.error("出现意外错误");
    }
   

    },close_pre(){
    
    this.$emit("closePrew")
}
  }
}
</script>

<style scoped lang="less">
.content{

  .main-content{
    height:580px;
    overflow: hidden;
     


  }
  .fee-content{
    height:580px;
    overflow-x: none;
    overflow-y: scroll !important;
  }

  .header{
    text-align: right;
    font-size: 28px;
    height: 40px;
    line-height: 40px;
    .right-icon{
       width: 40px;
       line-height: 40px;
       float:right;
       cursor: pointer;

    }
   

  }
    
}

.lock{
          position: relative;
          z-index:1000;
          height:300px;
          width: 100%;
          background-color: #ffffff;
          bottom: 300px;
           text-align: center;
          .fufei{
            text-align: center;
            font-size: 18px !important;
            color:#9E9E9E !important;
            span{
               color:#289EB8 !important;
            }
          }
          i{
           
            transform:rotate(-90deg);
            color:#289EB8;
            font-size: 28px;
            margin-top: 20px;
            cursor: pointer;
          }
      }
 
  

</style>
<template>
    <div class="box">
        <div class="header">
            
           
            <el-carousel :interval="5000" arrow="always">
                <el-carousel-item v-for="itemvalue in banner_list" >
                <img :src="ossurl + itemvalue.img_image" alt=""  @click="go_link(itemvalue.url)" >
                </el-carousel-item>
       </el-carousel>  
                
          
          
          
                 
            
        </div>
        <div class="content">

            <div class="left">
                <div class="fabu" v-if="userinfo.is_official === 1">
                    <textarea v-model="form_data.content" name="" id="" cols="30" rows="10"
                        placeholder="快来发布一个动态吧"></textarea>

                    <div class="pre-img" v-if="form_data.imgs.length > 0 && form_data.is_type == 0">

                        <div class="pic-info" v-for="(item, key) in form_data.imgs" :key="key">

                            <img alt="" :src="ossurl + item">
                            <i class="el-icon-circle-close" @click="delete_img(key)"></i>
                        </div>
                        <div class="upload-img-input">
                            <el-upload :action="upload_url" :multiple="true" :show-file-list="false" :auto-upload="true"
                                :data="{ filename: 'trends' }" :on-success="upload_img_success" :limit="9">
                                <i class="el-icon-plus"></i>
                            </el-upload>
 
                        </div>
                    </div>

                     <div class="pre-img meido-area" >

                        <el-progress type="circle" :percentage="percentage"
                            v-if="percentage != 100 && form_data.is_type == 1"></el-progress>
                        <div class="pic-info" v-if="form_data.is_type == 1 && form_data.medio_url != ''">

                            <video :src="ossurl + form_data.medio_url" :controls="true"
                                class="medio">您的浏览器不支持video标签。</video>
                            <i class="el-icon-circle-close" @click="delete_medio"></i>
                        </div>
                    </div>
                    <div class="pre-img meido-area" v-if="form_data.is_type == 2">
 
                      
                          
                        <div class="pic-info" v-if="form_data.is_type == 2 && form_data.medio_url != ''" style="width:100px !important">

                            <img alt="" src="./../assets/img/trends/file-pdf.png">
                            <i class="el-icon-circle-close pdf-delete" @click="delete_medio"></i>
                        </div>
                    </div> 

                 

                    <div class="fabu-btn">
                        <div class="btn-tupian">
                            <el-upload class="upload-demo" :multiple="true" :limit="9" :action="upload_url" :before-upload="image_before_upload"
                                :auto-upload="true" accept="image/*" :on-success="upload_img_success" :data="{ filename: 'trends' }"
                                :show-file-list="false" :on-change="uplaod_img">
                                <div class="btn-tupian">
                                    <img src="./../assets/img/trends/tupian.png" alt="">图片
                                </div>
                            </el-upload>

  

 
                            <el-upload class="upload-demo" :limit="1" :action="upload_url" :auto-upload="true"
                                :data="{ filename: 'trends' }" :on-success="upload_medio" :on-progress="uploadVideoProcess"
                                accept=".mp4" :show-file-list="false">
                                <div class="btn-tupian">
                                    <img src="./../assets/img/trends/shipin.png" alt="">视频
                                </div>
                            </el-upload>
                                 <el-upload class="upload-demo" :limit="1" :action="upload_url" :auto-upload="true"
                                :data="{ filename: 'trends' }" :on-success="upload_field"   
                                accept=".pdf" :show-file-list="false">
                                <div class="btn-tupian">
                                    <img src="./../assets/img/trends/file-pdf.png" alt="">文档
                                </div>
                            </el-upload> 

                        </div>


                        <div class="btn-yinsi">
                            <el-dropdown @command="handleCommand">
                                <span class="el-dropdown-link" v-if="form_data.is_public == 0">
                                    公开<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <span class="el-dropdown-link" v-if="form_data.is_public == 1">
                                    仅自己可看<i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="0">公开</el-dropdown-item>
                                    <el-dropdown-item command="1">仅自己可看</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                        <div class="btn-fasong" @click="submit_trend">发送</div>
                    </div>


                </div>
                <div class="back" v-if="userinfo.is_official === 1"></div>

                <div class="alldongtai">全部动态({{ trends_list.total }}) </div>
                <div class="trends_search">
                     <div class="search-input"><input     v-model="trends_keyword"  placeholder="搜索想要的动态"/></div>
                      <div class="serach" @click="search_trends">搜索</div>

                </div>

                <div class="dongtai-list-content" v-if="trends_list.list.length > 0">
                    <!-- <vue-data-loading  :loading="loading" @infinite-scroll="infiniteScroll"  :completed="trends_completed" :distance="20"> -->
                    <div class="dongtai" v-for="(item, key) in trends_list.list" :key="key">
                        <div class="dongtai-header">
                            <div class="dongtai-header-tou">
                                <img :src="ossurl + item.userinfo.avatar" alt="" v-if="item.userinfo.avatar">
                                <img src="./../assets/img/header.png" alt="" v-else>


                            </div> 
                            <div class="dongtai-header-xin">
                                <div class="header-xin-name">{{ item.userinfo.nickname ? item.userinfo.nickname : '' }}
                                </div>
                                <div class="header-xin-time">{{ item.createtime }}</div>
                            </div>
                            <div class="dongtai-header-chu" v-if="userinfo.is_official === 1"
                                @click="delete_trends(item.id, key)">删除</div>
                        </div>
                        <div class="dongtai-content">
                            <div class="content-wen">
                                {{ item.content }} 
                            </div>
                            <div class="content-tu" v-if="item.type == 0">
                                <el-image style="width:180px; height: 160px" v-for="(imginfo, key1) in item.imgs"
                                    :key="key1" :src="imginfo" :preview-src-list="item.imgs">
                                </el-image>

                            </div>
                            <div class="content-tu" v-if="item.type == 1">
                                <video :src="ossurl + item.medio_url" :controls="true" class="medio-area">


                                </video> 

                            </div> 
                             <div class="content-tu" v-if="item.type == 2">
                             <img src="./../assets/img/trends/PDF.png" alt="" @click="down_file(item.medio_url)">

                            </div>  
                        </div>
                        <div class="dongtai-footer">
                            <div class="dongtai-footer-zhuan"><img src="./../assets/img/trends/zhuanfa.png" alt="">转发</div>
                            <div class="dongtai-footer-pinlun" @click="go_comment($event)"><img
                                    src="./../assets/img/trends/pinlun.png" alt="">评论</div>
                            <div class="dongtai-footer-dian" @click="atten_trends(item.id, key)" v-if="!item.is_like"><img
                                    src="./../assets/img/trends/dianzan.png" alt="">点赞</div>
                            <div class="dongtai-footer-dian" @click="atten_trends(item.id, key)" v-else><img
                                    src="./../assets/img/zan.png" alt="">取消点赞</div>
                        </div>
                        <div class="comment-area" style="display:none">

                            <div class="comment-input">
                                <input type="text" name="comment" v-model="comment_content" placeholder="输入评论">

                                <el-button type="primary" class="send" @click="send_comment(item.id)">发送</el-button>


                            </div>

                        </div>

                        <div class="comment-list" v-if="item.get_commentlist.length > 0">
                            <div class="comment-info" v-for="(comment, key2) in item.get_commentlist" :key="key2">
                                <div class="avatar">
                                    <img :src="ossurl + comment.avatar" alt="">

                                </div>
                                <div class="comment-name">
                                    <div>{{ comment.nickname ? comment.nickname : '--' }}：</div>
                                    <div>{{ comment.createtime }}</div>

                                </div>
                                <div class="comment-content">

                                    {{ comment.content }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </vue-data-loading> -->

                    <div class="page" v-show="trends_list.total > 4">

                        <el-pagination @current-change="trends_change" :current-page="trends_list.current" :page-sizes="[1]"
                            :page-size="trends_list.pagesize" layout="prev, pager, next, jumper" :total="trends_list.total">
                        </el-pagination>
                    </div>
                </div>
                <!-- 暂无动态 -->
                <div class="dongtai-list-content" v-else>
                    <el-empty description="暂无动态"></el-empty>
                </div>
  
            </div>
            <!-- <div class="page" v-if="course_list.total > 8">
            <el-pagination @current-change="course_change" :current-page="course_list.page" :page-sizes="[8]"
                :page-size="course_list.pagesize" layout="prev, pager, next, jumper" :total="course_list.total">
            </el-pagination>
        </div> -->
            <div class="right">
                <div class="right-area">
                    <div class="right-header"><img src="./../assets/img/trends/ke.png" alt="">考研真题及考研专业课学习计划下载 </div>
                    <div class="right-con">
                        <div class="sou-suo">
                            <input type="text" v-model="file_keyword" class="sou-inp" placeholder="搜索想要的真题">
                            <button class="sou" @click="search_file">搜索</button>
                        </div>
                        <ul v-if="official_file_list.list.length > 0">
                               <!-- <vue-data-loading  :loading="file_loading" @infinite-scroll="official_file"  :completed="file_completed" :distance="20"> -->
                            <li v-for="(value, key) in official_file_list.list" :key="key" @click="buy_file(value.id, key)">
                                {{ value.title }}</li>
                               <!-- </vue-data-loading> -->
                            <div class="page" v-show="official_file_list.total > 50">

                                <el-pagination @current-change="file_change" :current-page="official_file_list.current"
                                    :page-sizes="[1]" :page-size="official_file_list.pagesize"
                                    layout="prev, pager, next, jumper" :total="official_file_list.total">
                                </el-pagination> 
                            </div>
                        </ul> 
                        <ul v-else>
                            <el-empty description="暂无记录"></el-empty>

                        </ul>
                    </div>

                </div> 
 
            </div>
            <div class="erweima">
                <img src="../assets/img/baoming.png" alt="">
                <!-- <div class="bao">报名扫我</div> -->
            </div>
        </div>

    </div>
</template>  
 
<script>
import VueDataLoading from 'vue-data-loading'
import { UserInfo, deleteTrendFile, getOfficialFile, getOfficialBanner, getOfficialTrends, buyOfficialFile } from "@/request/guan"
import { InsertTrends, commentTrends, deleteTrends, attenTrends, getTeacherCourse } from "@/request/teacher"
import { confirmBuyOfficicalFiel } from "@/request/student"
import { add_like_course } from "@/request/course"
import BASEURL from "@/request/baseurl"
import OSSURL from "@/request/oss"
import store from "@/store/index"
export default {
    name: 'authority',
    data() {

        return {

            trends_list: {
                'total': 0,
                'page': 1,
                'pagesize':2,
                'list': []

            },
            trends_keyword:'',
            loading:false,

            form_data: {
                content: '',
                imgs: [],
                medio_url: '',

                is_public: 0,
                is_type: 0
            },
            teacher_id: null,

            imgs: [],
            ossurl:OSSURL,
            baseurl: BASEURL,
            userinfo: {},
            percentage: 0,
            is_login: false,
            upload_url: BASEURL + '/api/upload/generaupload',
            comment_content: '',
            official_file_list: {
                'total': 0,
                'current': 1,
                'pagesize':45,
                'list': []
            },
            file_keyword: '',
            file_loading:false,             //pdf加载
            banner_list: {},
            file_completed:false,
            trends_completed:false  //动态是否加载完成
        }



    },components: {
         VueDataLoading
   }, 
    
    
    computed: {
        getUserInfo() {


            return this.$store.state.Userinfo
        }

    }, watch: {
        userinfo(val, oldval) {

            if (val) {
                this.userinfo = val;
            }

        }

 

    },
    created() {
        let teacher_id = this.$route.query.user_id;
        this.teacher_id = teacher_id;
        var userinfo = localStorage.getItem('Userinfo');
     
        //如果拥有用户信息，则直接进入个人中心
        try {

            if (userinfo) {

                //判断时间是否过期
                userinfo = JSON.parse(userinfo);
             
                if (userinfo.expiretime * 1000 < new Date().getTime()) {
                    this.is_login = false;
                } else {
                    this.is_login = true;
                    this.userinfo = userinfo
                }

            }
        } catch (error) {

        }
        this.initdata(teacher_id);

    }, methods: {
        async initdata() {


            this.get_officicial_banner();
            this.get_officicial_list(this.trends_list.current, this.trends_list.pagesize,'');
            this.get_official_file(this.official_file_list.current, this.official_file_list.pagesize, this.file_keyword);


        },

        //购买官方课程
        async buy_file(file_id, key) {

            let id = file_id;
            let userinfo = this.userinfo;
            if (userinfo == null) {
                this.$message.warning("需要先登录哦")
                return false;
            }

            if (Object.keys(userinfo).length < 1) {

                this.$message.warning("需要先登录哦")
                return false;
            }



            if (userinfo.user_type == 1) {

                this.$message.warning("对不起，您不是学生")
                return false;

            }


            if (userinfo.is_official === 1) {
                this.$message.warning("官方不能购买")
                return false;
            }

            let official_file_list = this.official_file_list.list;

            let file_info = official_file_list[key];

            //判断用户是否已经购买过

            let result = await confirmBuyOfficicalFiel({ 'id': file_info.id, 'user_id': userinfo.id });

            if (result.code === 200) {
                if (result.data) {
                    this.$message.warning("此文件已经购买")
                    return false;
                }

            }




            let msg = '你的当前余额' + userinfo.account + 'U币,确定要花费' + file_info.price + 'U币 ,购买此文件吗?'


            let res = await this.$confirm(msg, '购买', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })


            if (res == 'confirm') {

                let bus_res = await buyOfficialFile({ id: file_id });
                if (bus_res.code === 200) {
                    this.$router.push('/student/officialfile');
                } else {
                    this.$message.error(bus_res.msg);
                }


            }



        },

        //获取官方的banner

        async get_officicial_banner() {
            let res = await getOfficialBanner();
            if (res.code === 200) {
                this.banner_list = res.data
            }
        },pullDown(e){
          
        },infiniteScroll(){

            this.loading = true;
             let trends_list = this.trends_list;
             let current = Number(trends_list.current)+1;
              this.get_officicial_list(current,trends_list.pagesize,this.trends_keyword);

             
            
             
        },
        //搜索动态
        search_trends(){
            let trends_keyword = this.trends_keyword;
            let trends_list = this.trends_list;
         
             this.get_officicial_list(1,trends_list.pagesize,trends_keyword,true);


        },
         official_file(){
              this.file_loading= true;
             let  official_file_list = this.official_file_list;
               let official_current = Number(official_file_list.current)+1;
                 this.get_official_file(official_current, this.official_file_list.pagesize, this.file_keyword);
        },
 
        //获取官方动态
        async get_officicial_list(current, pagesize,keyword='',is_search=false) {

            let res = await getOfficialTrends({ current: current, pagesize: pagesize,keyword:keyword });
            let trends_list = this.trends_list;
           
               
            if (res.code === 200) {
                trends_list.total = Number(res.data.total)
                trends_list.current = Number(res.data.current)
            
               
                for(var i=0;i<res.data.list.length;i++){
                    if(res.data.list[i].imgs){
                        for(var j=0;j<res.data.list[i].imgs.length;j++){

                            res.data.list[i].imgs[j] = OSSURL+res.data.list[i].imgs[j]
                        }
                          
                    }
                       
                }

                if(res.data.list.length< this.trends_list.pagesize){
                     this.trends_completed = true
                }

                if(is_search){
                        trends_list.list = res.data.list;
                }else{
                        trends_list.list =  res.data.list
                }
                 
              
               
               
                  
                this.trends_list = trends_list;
                this.loading= false;
            }

        },
        //动态分页
        trends_change(pager) {

            this.get_officicial_list(pager, this.trends_list.pagesize);

        },
        file_change(pager) {
            let keyword = this.file_keyword;
            this.get_official_file(pager, this.official_file_list.pagesize, keyword);

        },

        //官方题目
        async get_official_file(current, pagesize, keyword,is_search=false) {
          
            let res = await getOfficialFile({ current: current, pagesize: pagesize, keyword: keyword });

            let official_file_list = this.official_file_list;
            if (res.code === 200) {
                official_file_list.total = Number(res.data.total)
                official_file_list.current = Number(res.data.current)

                if(res.data.list<this.official_file_list.pagesize){
                     this.file_completed = true;
                }

                if(is_search){
                       official_file_list.list =res.data.list;
                }else{
                         official_file_list.list = res.data.list
                }
             
                 this.official_file_list = official_file_list;
                 this.file_loading = false;

            } else {
                this.$message.error(res.msg);
            }



        }, search_file() {

            let keyword = this.file_keyword;
            this.get_official_file(1, this.official_file_list.pagesize, keyword,true);


        },


 
        //发布动态
        async submit_trend() {
            let form_data = this.form_data;
            if (!form_data.content && !form_data.imgs && !form_data.medio_url) {
                this.$message.error("发布动态不能为空");
                return false;
            }

            let res = await InsertTrends(form_data);
            if (res.code === 200) {
                this.$message.success("发布动态成功");
                let form_data = this.form_data;
                form_data.imgs = '';
                form_data.medio_url = '';
                form_data.content = '';
                form_data.is_public = 0,
                    form_data.is_type = 0;
                this.form_data = form_data;

                this.get_officicial_list(1, this.trends_list.pagesize)

            } else {
                this.$message.error(res.msg);
            }
        }, upload_img_success(e) {

            let form_data = this.form_data

            if (e.code == 1) {
                form_data.imgs.push(e.data.url);
                form_data.is_type = 0;
                this.form_data = form_data;
            } else {
                this.$messge.error(e.msg);
            }

        }, uploadVideoProcess(e) {
            let form_data = this.form_data
            form_data.is_type = 1;
            this.form_data = form_data;
            this.percentage = Number(e.percent.toFixed(2))

        },
        //上传图片之前
        image_before_upload(e){ 
 
          
            let type = e.type;
            type = type.substr(0,5);
          if(type != 'image'){
            this.$message({ 
                 type:'error',
                 message:'文件格式不正确'
            })
                  return false;  
          }
    
          


        },go_link(e){
            console.log(e); 
            if(e){
                window.open(e, '_blank');
                 
            }

        },



        upload_medio(e) {

            let form_data = this.form_data
            if (e.code == 1) {
                form_data.medio_url = e.data.url;
                form_data.is_type = 1;
                this.form_data = form_data;
            } else {
                this.$messge.error(e.msg);
            }
        },upload_field(e){

  let form_data = this.form_data
            if (e.code == 1) {
                 form_data.imgs=''; 
                form_data.medio_url = e.data.url;
                form_data.is_type = 2;
                this.form_data = form_data;
            } else {
                this.$messge.error(e.msg);
            }
        }, 

        //删除动态
        async delete_trends(id, key) {
            let result = await this.$confirm('此操作将永久动态吗, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });

            if (result == 'confirm') {
                let trends_list = this.trends_list;


                let res = await deleteTrends({ id: id })
                if (res.code == 200) {
                    trends_list.list.splice(key, 1)
                    this.trends_list = trends_list;
                    this.$message.success('删除成功');
                } else {
                    this.$messge.error(res.msg);
                }
            }
        }, go_detail(id) {

            this.$router.push('/coursedetail?id=' + id);
        }, course_change(page) {


            this.get_curri_list(page, this.course_list.pagesize, this.guaninfo.id);
        }, uplaod_img() {

        },


        //删除照片
        async delete_img(index) {

            let imgs_url = this.form_data.imgs[index]
            try {
                if (!imgs_url) {
                    this.$message.error("删除文件不能为空");
                }

                let res = await deleteTrendFile({ 'file_url': imgs_url });
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });

                    let form_data = this.form_data;
                    form_data.imgs.splice(index, 1);


                    this.form_data = form_data;
                } else {
                    this.$message({
                        message: '删除失败',
                        type: 'success'
                    });
                }

            } catch {
                this.$message.error("出现意外错误，请刷新重试");
            }

        },
        //删除视频
        async delete_medio() {

            let imgs_url = this.form_data.medio_url
            try {
                if (!imgs_url) {
                    this.$message.error("删除文件不能为空");
                }

                let res = await deleteTrendFile({ 'file_url': imgs_url });
                if (res.code == 200) {
                    this.$message({
                        message: '删除成功',
                        type: 'success'
                    });

                    let form_data = this.form_data;
                    form_data.medio_url = '';


                    this.form_data = form_data;
                } else {
                    this.$message({
                        message: '删除失败',
                        type: 'success'
                    });
                }

            } catch {
                this.$message.error("出现意外错误，请刷新重试");
            }

        },
        //切换选择公开还是私密
        handleCommand(e) {
            let form_data = this.form_data;
            form_data.is_public = e;
            this.form_data = form_data

        }, go_comment(e) {
          
            e.currentTarget.parentElement.nextElementSibling.style.display = 'block';
        },
        //评论
        async send_comment(trends_id) {


            let comment_content = this.comment_content;
            let userinfo = this.userinfo;
            if (!userinfo) {
                this.$message.error("需要登录哦");
                return false;
            }

            if (!userinfo.id) {
                this.$message.error("需要登录哦");
                return false;
            }

            if (!comment_content) {
                this.$message.error('请输入评论内容');

                return false;
            }

            let res = await commentTrends({ trends_id: trends_id, content: comment_content })
            if (res.code === 200) {
                this.comment_content = '';
                this.$message.success(res.msg);
            } else {
                this.$message.error(res.msg);
            }




 
        },
            down_file(url) {


           

            var a = document.createElement('a') // 创建一个<a></a>标签
                 // 设置下载文件文件名

            a.href = this.ossurl +url
            //重点（如测试发现下载文件不存在/找不到，检查路径）

            a.style.display = 'none'           // 隐藏a标签
            document.body.appendChild(a)       // 将a标签追加到文档对象中
            a.click()                          // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
            a.remove()


        },
        //点赞
        async atten_trends(id, index) {

            let trends_list = this.trends_list;

            let res = await attenTrends({ id: id });
            if (res.code === 200) {
                trends_list.list[index].is_like = !trends_list.list[index].is_like;

            } else {
                this.$message.error(res.msg)
            }


        }, close_chat: function () {
            this.is_show_chat = false;
        }, gou_tong: function (to_user_id) {
            let userinfo = this.userinfo;
            if (userinfo == null) {
                this.$message.warning("需要先登录哦")
                return false;
            }

            if (Object.keys(userinfo).length < 1) {

                this.$message.warning("需要先登录哦")
                return false;
            }

            if (userinfo.user_type == 1) {

                this.$message.warning("对不起，您不是学生")
                return false;

            }
            this.to_user_id = to_user_id;
            this.is_show_chat = true;
        }, async add_friend() {
            let userinfo = this.userinfo;
            if (userinfo == null) {
                this.$message.warning("需要先登录哦")
                return false;
            }

            if (Object.keys(userinfo).length < 1) {

                this.$message.warning("需要先登录哦")
                return false;
            }

            if (userinfo.user_type == 1) {

                this.$message.warning("对不起，您不是学生")
                return false;

            }

            let teacher_id = this.teacher_id;
            let res = await addTeacherFriend({ teacher_id: teacher_id });

            if (res.code === 200) {
                this.$message.success("添加好友成功")
                let teacher_info = this.teacher_info;
                teacher_info.is_friend = true;
                this.teacher_info = teacher_info;
            } else {
                this.$message.warning(res.msg)
                return false;
            }
        },
        // async get_curri_list(current, pagesize, user_id) {
        //     let res = await GuanCurri({ user_id: user_id, current: current, pagesize: pagesize });
        //     if (res.code == 200) {
        //         let course_list = this.course_list;
        //         course_list.total = res.data.total;
        //         course_list.page = Number(res.data.current);
        //         course_list.list = res.data.list;
        //         this.course_list = course_list;


        //     } else {
        //         this.$messge.error('获取官方动态出错');
        //     }

        // }, go_detail(id) {

        //     this.$router.push('/coursedetail?id=' + id);
        // }, course_change(page) {


        //     this.get_curri_list(page, this.course_list.pagesize, this.guaninfo.id);
        // }, async go_zan(couse_id, index) {

        //     let userinfo = this.userinfo;

        //     if (userinfo == null) {
        //         this.$message.warning("需要先登录哦")
        //         return false;
        //     }
        //     if (Object.keys(userinfo).length < 1) {

        //         this.$message.warning("需要先登录哦")
        //         return false;
        //     }
        //     let res = await add_like_course({ course_id: couse_id, type: 0 });
        //     if (res.code === 200) {
        //         let course_list = this.course_list

        //         if (course_list.list[index].is_like) {
        //             course_list.list[index].is_like = false;
        //             course_list.list[index].sign_up_num = Number(course_list.list[index].sign_up_num) - 1;
        //         } else {
        //             course_list.list[index].is_like = true;
        //             course_list.list[index].sign_up_num = Number(course_list.list[index].sign_up_num) + 1;
        //         }

        //         this.course_list = course_list;
        //     }

        // }

    }

}
</script>
<style lang="less" scoped>
@import url(./../assets/css/authority.less);

.erweima {
    width: 150px;
   
   
    border-radius: 10px;
    position: fixed;
    top: 40%;
    right: 0.5%;
}

.erweima .bao {
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    color: #29ad68;
}

.erweima img {
    width: 150px;
    height: 150px;
}

.right-area {
    min-height: 500px;
}

.right {

    padding-bottom: 0;

}

.header {
    display: flex;
    height: 355px;
    border-radius: 5px;
    background-color: #ffffff;
    opacity: 1;
    margin-top: 30px;
    margin-bottom: 15px;
}


.el-carousel{
     width:1500px;
     height:355px;
}
/deep/ .el-carousel__container{
    height:355px !important;
}

.header img {
    width: 100%;
    height: 355px;

}

.header-con {
    width: 300px;
    height: 355px;
}

.header-con img {
    display: block;
    margin: 70px auto;
    width: 260px;
    height: 220px;
}

.introduce {
    font-size: 18px;
    color: #9E9E9E;
    text-align: center;
}

.header-right img {
    width: 600px;
    height: 355px;
}

.right-con {
    width: 640px;
    padding-bottom: 10px;

}

.right-con ul {
    padding: 0;
    margin-left: 20px;
}

.right-con li {
    width: 585px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 26px;
    cursor: pointer;
    margin-top: 10px;
    list-style: none;
}

.right-con li:hover {
    color: #2896B8;
}

.sou-inp {
    width: 470px;
    height: 40px;
    outline: none;
    border-radius: 100px;
    margin-left: 20px;
    margin-top: 15px;
    border: 1px solid #C4C4C4;
    padding-left: 20px;
    font-size: 20px;
}

.sou {
    margin-left: 20px;
    cursor: pointer;
    width: 80px;
    height: 44px;
    background: #2896B8;
    border-radius: 105px;
    opacity: 1;
    font-size: 26px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    border: none;
}


.page {
    margin-top: 50px;
    padding-bottom: 30px;
}

.page {
    width: 100%;


    .el-pagination {
        width: 520px;
        margin: 0 auto;
        font-size: 16px;

        /deep/ .number {
            min-width: 33px;
            height: 33px;
            text-align: center;
            line-height: 33px;
            border: 1px solid #d9d9d9 !important;
            border-radius: 4px;
            padding: 0 !important;
            margin-right: 8px !important;
            font-size: 14px;
            font-weight: 500;
        }

        /deep/ .active {
            font-weight: 500;
            color: #ffffff;
            border-color: #289EB8;
            background-color: #289EB8;
            font-size: 16px;
        }

        /deep/ .btn-prev {

            text-align: center !important;
            line-height: 33px;
            border: 1px solid #d9d9d9 !important;
            margin-right: 8px;
            min-width: 33px;
            font-size: 14px;
            height: 33px;
        }
    }

    /deep/ .btn-next {
        width: 33px;
        text-align: center !important;
        line-height: 33px;
        border: 1px solid #d9d9d9 !important;
        height: 33px;
        min-width: 33px;
        font-size: 14px;

    }


    /deep/ .el-pagination__jump {
        height: 33px;
        line-height: 33px;
    }



    .el-pager li {

        min-width: 35px;
    }


}

.alldongtai{
     height: 40px;

     line-height: 40px;
}

.trends_search{
     display: flex;
     display: -webkit-flex;
     flex-direction: row;
     justify-content: right;
      
     width: 800px;
     margin: 17px auto 20px;
     height: 40px;
     
     input{
              width: 570px;
        height: 40px;
        outline: none;
        border-radius: 100px;
        margin-left: 20px;
        margin-top: 15px;
        border: 1px solid #C4C4C4;
        padding-left: 20px;
        font-size: 20px;
     }

     .serach{
          margin-left: 20px;
            cursor: pointer;
            width: 80px;
            height: 40px;
            background: #2896B8;
            border-radius: 105px;
            opacity: 1;
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 40px;
            border: none;
            text-align: center;
            margin-top: 20px;
     }
     
}
</style>
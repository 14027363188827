<template>
    <!-- <div class="left">

            <teachercenter :userInfo="userinfo"></teachercenter>
        </div> -->
    <div class="course right">
        <div class="course-search">
            <div class="search">
                <img src="../../../assets/img/搜索.png" alt="">
                <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_demand">
            </div>
            <button @click="search_demand">搜索</button>
        </div>
        <div class="course-content">
            <div class="content-body">
                <div class="body-header">
                    <p>序号</p>
                    <p>收藏标题</p>
                    <p>发布人</p>
                    <p>收藏时间</p>
                    <p>操作</p>
                </div>
                <div class="body-content">
                    <ul>
                        <li v-for="(item, index) in demandlist.list" :key="index">
                            <div class="xuhao" @click="go_demand_detail(item.demand_id)">
                                {{ index * (demandlist.current - 1) * demandlist.pagesize + index + 1 }}</div>
                            <div class="biaoti" @click="go_demand_detail(item.demand_id)">{{ item.title }}</div>
                            <div class="zhuangtai" @click="go_demand_detail(item.demand_id)">{{ item.nickname }}</div>
                            <div class="shuliang" @click="go_demand_detail(item.demand_id)">{{ item.createtime }}</div>
                            <div class="caozuo">
                                <div class="shanchu" @click="delete_demand(item.id, index)">删除</div>
                            </div>
                        </li>
                    </ul>

                </div>
            </div>
        </div>

        <div class="page">

            <el-pagination @current-change="demand_change" :current-page="demandlist.page" :page-sizes="[1]"
                :page-size="demandlist.pagesize" layout="prev, pager, next, jumper" :total="demandlist.total">
            </el-pagination>
        </div>
    </div>
</template>
  
<script>

import teachercenter from './../../../components/common/teachercenter'
import BASEURL from "./../../../request/baseurl"
import { MyAttenDemand, delMyAttenDemand } from "@/request/teacher"
import store from "./../../../store/index"
export default {
    name: 'teacherColneed',
    data() {
        return {
            userinfo: {},
            baseurl: BASEURL,
            is_default_index: '',
            demandlist: {
                total: 0,
                current: 1,
                pagesize: 5,
                list: []
            },
            keyword: ''
        }
    }, components: {
        teachercenter
    }, created() {
        let is_default_index = store.state.left_menu_default;
        this.is_default_index = is_default_index;
        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);
        console.log(userinfo);
        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }

        this.initdata();

    }, methods: {

        initdata() {

            this.get_demand_list(this.demandlist.current, this.demandlist.pagesize, '');

        },
        async get_demand_list(current, pagesize, keyword) {

            let res = await MyAttenDemand({ current: current, pagesize: pagesize, keyword: keyword })

            if (res.code === 200) {
                let demandlist = this.demandlist;

                demandlist.total = Number(res.data.total);
                demandlist.current = Number(res.data.current);
                demandlist.list = res.data.list
                this.demandlist = demandlist
            } else {
                this.$message.error('出现意外错误');
                return false;
            }




        }, demand_change(pager) {
            let keyword = this.keyword;
            let that = this;


            let res = this.get_demand_list(pager, this.demandlist.pagesize, keyword);





        }




        , search_demand() {

            let keyword = this.keyword;
            this.get_demand_list(1, this.demandlist.pagesize, keyword)




        }, go_demand_detail(demand_id) {

            if (!demand_id) {
                this.$message.error('参数丢失');
                return false;
            }
            this.$router.push('/demandDetail?id=' + demand_id)


        },
        //删除我收藏的需求
        async delete_demand(id, index) {
            if (!id) {
                this.$message.error('参数丢失');
                return false;
            }

            let result = await this.$confirm('此操作将删除此收藏, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });
            if (result == 'confirm') {
                let res = await delMyAttenDemand({ id: id });
                if (res.code === 200) {

                    let demandlist = this.demandlist;
                    demandlist.list.splice(index, 1);
                    this.demandlist = demandlist



                } else {
                    this.$message.error(res.msg);
                }

            }




        }
    }
}
</script>
  
<style lang="less"  scoped >
@import url("./../../../assets/css/teacher_common.less");
@import url("./../../../assets/css/teachertables.less");
</style>
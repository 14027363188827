<template>

     <div class="main-content wrap">

        <div class="left">
               <teachercenter :userInfo="userinfo" @loginout="out_login"></teachercenter>
          </div>
  

          <div class="right">
              <router-view></router-view>
               <!-- <el-empty description="欢迎来到你的专属个人中心"></el-empty> -->

          </div>

 
 

     </div>
</template>
<script>
import { logout } from "./../../../request/login"
import teachercenter from './../../../components/common/teachercenter'
import BASEURL from "./../../../request/baseurl"
import store from "./../../../store/index"
export default {

     data() {
          return {
               userinfo: {},
               baseurl: BASEURL,
          }
     }, components: {
          teachercenter
     }, created() {

          var userinfo = localStorage.getItem('Userinfo');
          userinfo = JSON.parse(userinfo);
         
          if (userinfo) {


               if (userinfo.expiretime * 1000 < new Date().getTime()) {
 
                    this.$router.push('/login');
                    localStorage.clear("Userinfo");
                    localStorage.clear('Authorization');

               } else {

             
                    this.userinfo = userinfo;
               }

          } else {
               this.$router.push('/login');
          }

     }, methods: {
          out_login: function () {
               let _this = this;
               var userinfo = _this.userinfo;
               
               //退出登录
               logout({ id: userinfo.id }).then(respone => {

                    if (respone.code === 1) {
                         localStorage.clear("Userinfo");

                         localStorage.clear('Authorization');
                         //退出登录，同时去除store 中的信息
                         store.commit('setUserinfo', { userinfo: '' })
                         store.commit("changeLogin", { Authorization: '' })
                         this.$router.push('/login');
                    } else {

                         _this.$layer.msg('操作未成功');
                    }

               }).catch(err => {
                    console.log(err);
               })



          },


     }

}
</script>
<style scoped >

.left{  
      min-height: 1000px;
}
.main-content {
     display: flex;
     display: -webkit-flex;
     flex-direction: row;
     margin-top: 40px;
     margin-bottom: 30px;
}

.right {
     margin-left: 20px;
     width: 1170px;
     
  
     border-radius: 6px;
}

.el-empty {
     padding: 145px 0;
}
</style>
<template>
    <div class="big-box wrap">
        <el-form ref="form" :model="form">
            <div class="box">

                <div class="coursename">
                    <p>需求标题</p>
                    <el-input type="text" placeholder="输入标题" v-model="form.title" maxlength="25" show-word-limit>
                    </el-input>


                </div>
                <div class="classification">
                    <p>课程分类</p>



                    <el-select v-model="form.subject_id" placeholder="请选择">
                        <el-option v-for="item in cate_list" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>

                </div>



                <p class="kejianjie">需求详情</p>
                <div class="jianjie">
                    <VueUeditorWrap v-model="form.content" :config="editorConfig" editor-id="editor-demo-01">
                    </VueUeditorWrap>


                </div>

                <div class="baocun"><button type="button" @click="submit" v-throttle>保存</button></div>
            </div>
        </el-form>

    </div>
</template>
  
<script>
// Basic Use - Covers most scenarios


// Advanced Use - Hook into Quill's API for Custom Functionality
import VueUeditorWrap from 'vue-ueditor-wrap';
import { getCatelist } from "@/request/course"
import { student_replase_question, get_demand_info, editDemand } from "@/request/demand.js"
import store from "@/store/index"
import BASEURL from "@/request/baseurl"

export default {
    name: 'editDemand',
    data() {
        return {
            form: {
                id: '',
                user_id: '',
                title: '',
                subject_id: '',
                content: ''
            },
            baseurl: BASEURL,
            cate_list: [],
            Userinfo: null,
            editorConfig: {
                initialFrameHeight: 500,
                UEDITOR_HOME_URL: "/UEditor/", // 访问 UEditor 静态资源的根路径，可参考常见问题1
                serverUrl: BASEURL + "/ueditorcon/controller.php"

            },



        }
    },
    components: {
        VueUeditorWrap
    },
    created() {  

  
        let id = this.$route.query.id;
       
        if (!id) {
            return false;
        }
        try {
            var userinfo = localStorage.getItem('Userinfo');


            if (userinfo) {

                userinfo = JSON.parse(userinfo);
                //判断时间是否过期
                if (userinfo.expiretime * 1000 < new Date().getTime()) {
                    this.$router.push('/login');
                } else {


                    this.is_login = true;
                    this.Userinfo = userinfo;

                }

            } else {
                this.$router.push('/login');
            }
        } catch {
            this.$router.push('/login');
        }
        this.initdata(id);

    },
    methods: {
        async initdata(id) {

            let res = await getCatelist();

            if (res.code === 200) {
                this.cate_list = res.data;
            }

            let result = await get_demand_info({ id: id });

            if (result.code === 200) {
                this.form = result.data
            } else {
                this.$message.error(result.msg);
            }



        },
        async submit() {

            let form = this.form;
            if (!form.title) {
                this.$message.error("需求标题不可为空");
            }
            if (!form.subject_id) {
                this.$message.error("请选择课程分类");
            }

            if (!form.content) {
                this.$message.error("需求详情不可为空");
            }

            form.user_id = this.Userinfo.id;
            let form_data = { 'id': form.id, title: form.title, user_id: form.user_id, content: form.content, subject_id: form.subject_id };
            let res = await editDemand(form_data);
            if (res.code === 200) {
                this.$message.success("编辑成功");
                this.$router.push("/student/index");
            } else {
                this.$message.error(res.msg);
            }


        }

    }
}
</script>
  
<style  lang="less" scoped>
/deep/.el-input__inner:focus {
    border-color: #289EB8;
}

.el-select-dropdown__item.selected {
    color: #289EB8;
    font-weight: 700;
}

.el-input {
    width: 546px;
    height: 50px;
    line-height: 50px;
}

.el-input /deep/.el-input__inner {
    height: 50px;
    background-color: #F5F5F5;
    outline: none;
    border-radius: 6px;
    font-size: 18px;
}

.el-input /deep/ .el-input__count-inner {
    background-color: #F5F5F5;
}

.el-input /deep/ .el-input__inner:focus {
    outline: none;

}

.wrap {
    width: 1420px !important;
    background-color: #ffffff;
    border-radius: 6px;
    padding: 30px 40px;
    margin-top: 40px;
    margin-bottom: 30px;
}

textarea {
    font-size: 18px;
    outline: none;
}

select {
    border: 0.5px solid #ffffff;
    padding-left: 30px;
    outline: none;
    font-size: 18px;
}

input {

    outline: none;
    background-color: #F5F5F5;
    border: none;
}

.coursename {
    display: flex;

    margin-top: 79px;
    margin-bottom: 30px;
    height: 50px;
    line-height: 50px;
}

.coursename p {
    width: 80px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;

    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin: 0 40px 0 0;
}

.coursename input {
    width: 516px;
    height: 50px;
    background: #F5F5F5;
    border-radius: 8px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #BFBFBF;
    line-height: 28px;
    padding-left: 30px;
}

.classification {
    display: flex;
    display: -webkit-flex;

    height: 50px;
    line-height: 50px;

}

.classification p {
    font-size: 20px;
    height: 50px;

    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;

    margin: 0 40px 0 0;
    line-height: 50px;
}

.classification select {
    width: 551px;
    height: 50px;
    background: #F5F5F5;
    border-radius: 8px;
}

.classification .el-select {
    width: 546px;
    background-color: #F5F5F5;
    border-radius: 6px;
    font-size: 18px;

}

.classification .el-select /deep/ .el-input__inner {
    background-color: #F5F5F5;
    height: 50px;
    line-height: 50px;
    border: none;
    border-radius: 6px;
    font-size: 18px;
    color: #696969 !important;


}

.el-scrollbar .el-select-dropdown__list .el-select-dropdown__item {

    padding-left: 20px;
    font-size: 16px;
}

.classification select option {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333333;

}

.file {
    display: flex;
    margin-top: 35px;

}

.el-upload-list {
    margin-left: 40px !important;
}

.avatar-uploader {
    margin-left: 40px;
}


.avatar-uploader .el-upload {

    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    border: 1px dashed #d9d9d9 !important;

}

.avatar {
    width: 150px;
    height: 150px;
    display: block;

}

.el-upload-dragger {
    border: none;
}

.file input {
    margin-top: 20px;

}

.guifan {
    display: flex;

    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FF8620;
    height: 50px;
    line-height: 50px;
    width: 300px;
    margin-left: 120px;
}

.kejianjie {
    display: flex;

    margin-top: 35px;
}

.jianjie textarea {
    border: 1px solid #FFFFFF;

    display: flex;
    width: 1340px;
    height: 185px;
    background: #f0f0f0;
    border-radius: 6px;
    margin-left: 40px;
    padding: 20px;
}

.baocun {
    display: flex;
    width: 300px;
    margin: 60px auto 0;
}

.baocun button {
    cursor: pointer;
    border: 0.5px solid #FFFFFF;
    width: 300px;
    height: 62px;
    background: #289EB8;
    border-radius: 6px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}


ul {
    margin: 0;
    padding: 0;
}

ul>li {

    padding: 0;
    margin: 0;
    list-style: none !important;
}

.medio-list .medio-info {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    width: 455px;

}

.medio-list .medio-info .medio-img {
    height: 120px;
    height: 100px;

}


.medio-info {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-bottom: 20px !important;

}

.medio-info .medio-img {
    width: 120px;
    height: 100px;


}

.medio-info .medio-img img {
    width: 100%;
    height: 100%;
}

.medio-info .input {
    width: 230px;
    height: 50px;
    margin-top: 25px;
    margin-left: 30px;

}

.medio-info input {
    width: 230px;
    height: 50px;
    background-color: #ffffff !important;
    border: none !important;
    border-radius: 8px;

}

.medio-info .delete {

    line-height: 100px;
    width: 80px;
    text-align: center;
    color: #EA440A;
}


.medio-list .new-info {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;
    width: 455px;
    margin-bottom: 25px;
}

.medio-list .new-info .icon-img {
    width: 45px;
    height: 40px;
}

.medio-list .new-info .icon-img img {
    width: 45px;
    height: 40px;
    margin-top: 5px;
}

.medio-list .new-info .input {
    text-align: left;
    height: 50px;
    line-height: 50px;
    margin-left: 22px;
    width: 390px;
}

.medio-list .new-info .delete {
    width: 80px;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: #EA440A
}
</style>
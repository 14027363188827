<template>
  <div class="box">
    <div class="wrap">
      <div class="content">
        <div class="login-content">
          <div class="login-header">
            <div :class="is_pwd === false ? 'active' : ''" @click="changeType(1)">短信登录</div>
            <div :class="is_pwd === true ? 'active' : ''" @click="changeType(2)">密码登录</div>

          </div>

          <div class="login-main" v-if="is_pwd == false">
            <div class="login-input">
              <input type="text" placeholder="请输入手机号" v-model="LoginPhoneForm.account">
            </div>
            <div class="code">
              <input type="text" placeholder="输入验证码" v-model="LoginPhoneForm.code">
              <button @click="getCode(LoginPhoneForm)" class="code-btn" :disabled="!show">
                <span v-show="show">获取验证码</span>
                <span v-show="!show" class="count">{{ count }} s</span></button>
            </div>
            <div class="operation"><button @click="phone_code_login">登录</button><button
                @click="$router.push('/register')">注册</button></div>
          </div>

          <div v-else class="login-main">

            <div class="login-input">
              <input type="text" placeholder="请输入手机号或邮箱" name="account" v-model="LoginPhoneForm.account">
            </div>
            <div class="login-input">
              <input type="password" placeholder="请输入密码" name="pwd" v-model="LoginPhoneForm.password">
            </div>

            <div class="forget-pwd" @click="$router.push('/forget')">
              忘记密码
            </div>
            <div class="operation"><button @click="phoneLogin">登录</button><button
                @click="$router.push('/register')">注册</button></div>

          </div>


          <div class="third-party">
            <div>第三方登录</div>
            <div class="wechat" @click="go_qrcode"><img src="../../assets/img/wechat.png"></div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
import { userlogin, send_msg, mobilelogin } from "@/request/login.js"
import { mapMutations } from 'vuex';
import store from "../../store/index"
const TIME_COUNT = 60;
export default {
  name: "login",
  data() {
    return {
      is_pwd: false,
      LoginPhoneForm: {
        account: "",
        password: "",
        code: ''
      },

      show: true,
      count: '',
      timer: null,

    }
  },
  created() {


    var userinfo = localStorage.getItem('Userinfo');




    if (userinfo) {

      userinfo = JSON.parse(userinfo);
      if (userinfo.expiretime * 1000 < new Date().getTime()) {

        this.$router.push('/login');
        localStorage.clear("Userinfo");
        localStorage.clear('Authorization');

      } else {

        this.userinfo = userinfo;

        if (userinfo.user_type == 1) {
          this.$router.push('/teacher/index');

        } else if (userinfo.user_type == -1) {
          this.$router.push('/selectRole');
        } else if (userinfo.user_type == 0) {
          this.$router.push('/student/index');
        }
      }

    }



  },
  methods: {
    ...mapMutations(['changeLogin']),
    changeType(e) {
      if (e === 1) {

        this.is_pwd = false;
      } else {
        this.is_pwd = true;
      }

    }, getCode() {

      let that = this;
      var formData = that.LoginPhoneForm;

      if (!formData.account) {

        this.$message.error('请输入电话号码');

        return false;
      }
      var reg = /^(13[0-9]|19[0-9]|17[0-9]|14[0-9]|15[0-9]|16[0-9]|18[0-9])\d{8}$/;
      if (formData.account.length == 11) {
        if (!reg.test(formData.account)) {

          this.$message.error('手机号格式不正确');
          return false;
        }
      } else {
        this.$message.error('手机号格式不正确');
        return false;
      }


      send_msg({ mobile: formData.account, event: 'login' }).then(res => {

        if (res.code === 200) {
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000)
          }
        } else {

          this.$message({
            message: '发送失败',
            type: 'warning'
          });
          return false;

        }


      })

    },

    phoneLogin() {

      let _this = this;
      let LoginPhoneForm = this.LoginPhoneForm;
      console.log(999999);

      if (LoginPhoneForm.account == "") {
        this.$message({
          message: '账号不能为空',
          type: 'error'
        });
        //this.$alert("账号不能为空");
        return false;
      }


      if (LoginPhoneForm.password == '') {
        //this.$alert("密码不能为空");
        this.$message({
          message: '密码不能为空',
          type: 'error'
        });

      }

      //电话登录

      userlogin(LoginPhoneForm).then(response => {

        let _this = this;

        if (response.code == 1) {

          this.is_login = true;
          response.data.userinfo.youxiaotime = new Date().getTime();

          this.userinfo = response.data.userinfo


          var userinfo_json = JSON.stringify(this.userinfo);


          _this.userToken = response.data.userinfo.token;
          // 将用户token保存到vuex中

          store.commit("changeLogin", { Authorization: _this.userToken })
          //将用户信息存入本地

          store.commit('setUserinfo', { userinfo: userinfo_json })

          if (response.data.userinfo.user_type == 1) {
            _this.$router.push('/teacher/index')

          } else if (response.data.userinfo.user_type == -1) {
            _this.$router.push('/selectRole')
          } else if (response.data.userinfo.user_type == 0) {
            _this.$router.push('/student/index')
          }



        } else {
          this.$alert(response.msg)
        }



      })



    }, go_qrcode: function () {


      this.$router.push('/wxlogin')
    }, phone_code_login: function () {
      let _this = this;
      let LoginPhoneForm = this.LoginPhoneForm;

      if (LoginPhoneForm.account == "") {

        //this.$alert("账号不能为空");
        this.$message({
          message: '账号不能为空',
          type: 'error'
        });

        return false;
      }


      if (LoginPhoneForm.code == '') {
        //this.$alert("密码不能为空");
        this.$message({
          message: '验证码不能为空',
          type: 'error'
        });

        return false;
      }

      if (LoginPhoneForm.code.length != 6) {
        //this.$alert("密码不能为空");
        this.$message({
          message: '验证码格式不对',
          type: 'error'
        });

        return false;

      }
      mobilelogin({ mobile: LoginPhoneForm.account, 'captcha': LoginPhoneForm.code }).then(response => {

        if (response.code == 200) {
          this.is_login = true;
          response.data.userinfo.youxiaotime = new Date().getTime();

          this.userinfo = response.data.userinfo


          var userinfo_json = JSON.stringify(this.userinfo);


          _this.userToken = response.data.userinfo.token;
          // 将用户token保存到vuex中

          store.commit("changeLogin", { Authorization: _this.userToken })
          //将用户信息存入本地

          store.commit('setUserinfo', { userinfo: userinfo_json })

          if (response.data.userinfo.user_type == 1) {
            _this.$router.push('/teacher/index')

          } else {
            _this.$router.push('/student/index')
          }

        } else {

          //this.$alert("密码不能为空");
          this.$message({
            message: response.msg,
            type: 'error'
          });
        }

      })

    }


  }

};
</script>
<style lang="less"   scoped>
/deep/ body {
  background-color: #ffffff !important;
}

.count {
  color: #000000;
}

.box {
  background: url(../../assets/img/login.png) no-repeat !important;
  background-size: 100% 100% !important;
  min-height: 85vh;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  z-index: 2;

}

.wrap {
  display: flex;
  justify-content: flex-end;
}

.content {
  margin-top: 15%;
  width: 480px;
  height: 568px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0;
  border-radius: 6px;
  margin-bottom: 5%;
}


.login-content {

  padding: 30px;
}

.login-header {
  display: flex;
  flex-direction: row;
}


.login-header div {
  width: 75px;
  font-size: 18px;
  line-height: 18px;
  cursor: pointer;

}

.login-header .active {

  color: #289EB8;

}

.login-header :nth-child(1) {
  margin-right: 20px;
}

.login-main {
  margin-top: 20px;

}


.login-main div {
  height: 60px;
  line-height: 60px;
  margin-bottom: 20px;
}

.login-input {

  width: 100%;
  height: 60px;

}

.login-input input {
  width: 400px;
  height: 100%;
  border-radius: 6px;
  border: 1px solid #EEEEEE;
  outline: none;
  font-size: 18px;
  line-height: 60px;
  padding: 0 0px 0 20px;

  background: #F8F8F8;


}





.login-main .code input {
  height: 60px;
  width: 210px;
  border-radius: 6px;

  border: 1px solid #EEEEEE;
  outline: none;
  font-size: 18px;
  line-height: 60px;
  background: #F8F8F8;
  margin-right: 20px;
  float: left;
  padding: 0 20px;

}

.login-main .code button {
  width: 140px;
  height: 60px;
  text-align: center;
  border-radius: 6px;
  background-color: #F8F8F8;
  border: 1px solid #EEEEEE;
  font-size: 18px;
  border: none;
  float: right;
  font-weight: 400;
  color: #999999;
  cursor: pointer;


}



input::-ms-input-placeholder {
  text-align: left;
  color: #999999
}

input::-webkit-input-placeholder {
  text-align: left;
  color: #999999
}


.operation {
  display: flex;
  display: -webkit-box;
  flex-direction: row;
  justify-content: space-between;
}

.operation button {
  height: 60px;
  width: 195px;
  border: none;
  border-radius: 6px;
  line-height: 60px;
  font-size: 18px;
  cursor: pointer;


}

.operation :nth-child(1) {
  margin-right: 30px;
  background-color: #289EB8;
  color: #ffffff;


}


.operation :nth-child(2) {


  color: #289EB8;
  border: 1px solid #289EB8;

}

.third-party {

  margin-top: 40px;
}

.third-party :nth-child(1) {
  text-align: center;
  color: #999999;
  font-size: 14px;
  height: 30px;
  line-height: 30px;

}


.third-party .wechat {


  width: 68px;
  height: 68px;
  margin: 30px auto 0;
  cursor: pointer;
}


.third-party .wechat img {

  width: 68px;
  height: 68px;
  margin: auto;
}


.forget-pwd {
  color: #FF8620;
  text-align: left;
  height: 30px !important;
  line-height: 30px !important;
  cursor: pointer;
}
</style>
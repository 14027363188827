<template>
    <!-- <div class="left">

            <studentcenter :userInfo="userinfo"></studentcenter>
        </div> -->
    <div class="course right">
        <div class="course-search">
            <div class="search">
                <img src="../../../assets/img/搜索.png" alt="">
                <input type="text" placeholder="请输入关键字" v-model="keyword" @keyup.enter="search_couse">
            </div>
            <button @click="search_couse">搜索</button>
        </div>
        <div class="course-content">
            <div class="content-body">
                <!-- <div class="body-header">
                        <p>序号</p>
                        <p>课程标题</p> 
                        <p>发布人</p>
                        <p>收藏时间</p>
                        <p>操作</p>
                    </div> -->
                <div class="body-content">
                    <ul>
                        <li class="body-header">
                            <div class="xuhao">序号</div>
                            <div class="biaoti" @click="go_course_detail(item.id)">课程标题</div>
                            <div class="zhuangtai" @click="go_course_detail(item.id)">发布人</div>
                            <div class="shuliang">收藏时间</div>
                            <div class="caozuo">
                                操作
                            </div>
                        </li>

                        <li v-for="(item, index) in  course_list.list" :key="index">
                            <div class="xuhao" @click="go_course_detail(item.id)">{{
                                ((course_list.current - 1) * course_list.pagesize) + index + 1 }}</div>
                            <div class="biaoti" @click="go_course_detail(item.id)">{{ item.title }}</div>
                            <div class="zhuangtai" @click="go_course_detail(item.id)">{{ item.nickname }}</div>
                            <div class="shuliang">{{ item.createtime }}</div>
                            <div class="caozuo">
                                <div class="shanchu" @click="delete_atten_course(index)">删除</div>
                            </div>
                        </li>
                    </ul>

                </div>

                <div class="page">

                    <el-pagination @current-change="course_change" :current-page="course_list.current" :page-sizes="[1]"
                        :page-size="course_list.pagesize" layout="prev, pager, next, jumper" :total="course_list.total">
                    </el-pagination>

                </div>
            </div>
        </div>
    </div>
</template>
  
<script>

import studentcenter from '../../../components/student/studentcenter.vue'
import BASEURL from "./../../../request/baseurl"
import store from "./../../../store/index"
import { student_my_course_list, student_del_collect_course } from "@/request/demand"
export default {
    name: 'studentColneed',
    data() {
        return {
            userinfo: {},
            baseurl: BASEURL,
            is_default_index: '',
            course_list: {
                total: 0,
                current: 1,
                pagesize: 5,
                list: []
            }

        }
    }, components: {
        studentcenter
    }, created() {
        let is_default_index = store.state.left_menu_default;
        this.is_default_index = is_default_index;
        var userinfo = localStorage.getItem('Userinfo');
        userinfo = JSON.parse(userinfo);

        if (userinfo) {


            if (userinfo.expiretime * 1000 < new Date().getTime()) {

                this.$router.push('/login');
                localStorage.clear("Userinfo");
                localStorage.clear('Authorization');

            } else {


                this.userinfo = userinfo;
            }

        } else {
            this.$router.push('/login');
        }

        this.init();

    }, methods: {

        async init() {


            this.my_course_list(this.course_list.current, this.course_list.pagesize, '');


        }, async my_course_list(current, pagesize, keyword) {
            let course_list = this.course_list;
            let res = await student_my_course_list({ current: current, pagesize: pagesize, keyword: keyword });
            if (res.code === 200) {
                course_list.total = Number(res.data.total);
                course_list.current = Number(res.data.current);
                course_list.pagesize = Number(res.data.pagesize);
                course_list.list = res.data.list;
                this.course_list = course_list;

            } else {
                this.$message.error(res.msg);
            }







        },


        course_change(pager) {

            let keyword = this.keyword;
            this.my_course_list(pager, this.course_list.pagesize, keyword);

        }, search_couse() {

            let keyword = this.keyword;
            this.my_course_list(1, this.course_list.pagesize, keyword);

        },


        go_course_detail(id) {

            this.$router.push('/coursedetail?id=' + id);
        }, async delete_atten_course(index) {

            let res = await this.$confirm('此操作将永久删除该收藏, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            });


            let course_list = this.course_list;
            if (res == 'confirm') {

                let cousre_info = course_list.list[index];
                let userInfo = this.userinfo;

                if (!cousre_info) {
                    this.$message.error("出现意外错误");
                }

                let result = await student_del_collect_course({ course_id: cousre_info.id, user_id: userInfo.id });
                if (result.code === 200) {
                    this.$message.success("删除成功");
                    course_list.list.splice(index, 1);
                    this.course_list = course_list
                } else {
                    this.$message.error(res.msg);
                }
            }

        }



    }

}
</script>
  
<style lang="less"  scoped >
@import url("./../../../assets/css/teacher_common.less");

.xuhao {
    width: 53px;
    height: 52px;
    text-align: center;
}

.zhuangtai {
    text-align: center;
    width: 170px;

}

.center-main {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    margin-top: 40px;

}

input {
    outline: none;
}

.left {
    width: 310px;

}

.body-content ul {
    margin: 0;
    padding-left: 42px;
    padding-bottom: 30px;
}

.body-content ul li {
    list-style: none;
    height: 52px;
}

button {
    cursor: pointer;
    border: 0.5px solid #E9E9E9;
}

.biaoti {
    margin: 0 30px;
    width: 390px;
    text-align: center;
    overflow: hidden;
}

.shuliang {
    text-align: center;
    width: 140px;

}

.liulan {
    width: 80px;
    text-align: center;
    margin: 0 20px;
}

.caozuo {
    width: 200px;
    text-align: center;
}

.shanchu {
    margin-left: 0px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #EA440A;
    width: 200px;
}


.course {
    width: 1150px;
    /* height: 626px; */
    overflow: auto;
    height: auto;
    background: #FFFFFF;
    border-radius: 6px;


}

.course-search {
    margin-left: 31px;
    padding-top: 51px;
    display: flex;
    margin-bottom: 50px;
    height: 50px;
}

.course .search {
    position: relative;
    width: 653px;
    height: 50px;
    border: 2px solid #ECECEC;
    border-radius: 21px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
}

.course .search input {
    outline: none;
    margin-left: 55px;
    border-style: none;
    width: 570px;
    height: 48px;
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ADADAD;
    line-height: 45px;
    line-height: 50px;

}

.course img {
    position: absolute;
    left: 20px;
    top: 12px;
    width: 28px;
    height: 28px;
}

.course-search button {
    cursor: pointer;
    width: 115px;
    height: 50px;
    border: 1px solid #FFFFFF;
    background: #289EB8;
    border-radius: 8px;
    margin-left: 36px;

    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 50px;
}


.content-body {
    margin-left: 35px;
    margin-top: 0px;
    margin-bottom: 50px;
    width: 1060px;
    background: #FFFFFF;
    box-shadow: 0px 0px 65px 0px rgba(51, 51, 51, 0.1);
    border-radius: 16px;
}

.content-body .body-header {
    height: 70px !important;
    line-height: 70px !important;
    display: flex;
}

.content-body .body-header:hover {
    background-color: #fff;
    cursor: auto;
}

.content-body .body-header div {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #606060;

}

.body-content li {
    cursor: pointer;
    display: flex;
    font-size: 18px;
    line-height: 52px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333;
    margin: 10px auto;
}

.body-content li:hover {
    background-color: #DCF6FB;
}

/deep/ .el-tabs__item {
    font-size: 18px !important;
}

/deep/ .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
    padding-left: 35px;
}
</style>
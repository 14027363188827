<template>
  <div class="box">
    <div class="content wapp">
      <div class="main">
        <div class="title">
          <h1>优客理理发布须知</h1>
        </div>
        <div class="info">

          <p>根据国家的各项法律和法规，在本平台上发布任何文字、图片、音频和视频等资料应当遵守本须知。</p>

          <h2>一、本平台内容审核具体细则</h2>
          <p>依据网络短视频内容审核基本标准，网络播放的短视频节目，及其标题、名称、评论、弹幕、表情包等，其语言、表演、字幕、背景中不得出现以下具体内容（常见问题）：</p>
          <h3>（一）攻击我国政治制度、法律制度的内容</h3>
          <p>
            1.调侃、讽刺、反对、谩骂中国特色社会主义道路、理论、制度和文化以及国家既定重大方针政策的；
          </p>
          <p>
            2.对宪法等国家重大法律法规的制定、修订进行曲解、否定、攻击、谩骂，或对其中具体条款进行调侃、讽刺、反对、谩骂的；
          </p>
          <p>
            3. 削弱、背离、攻击、诋毁中国共产党领导的；
          </p>
          <p>
            4.对改革开放以来国家所实行的重大方针政策进行调侃、否定、攻击的；
          </p>
          <p>
            5.篡改、娱乐化解读我国政治制度和法律制度中的特定名词称谓的；
          </p>
          <h3>（二）分裂国家的内容</h3>
          <p>
            6.反对、攻击、曲解“一个中国”“一国两制”的；
          </p>
          <p>
            7.反映台独、港独、藏独、疆独等的言行、活动、标识的，包括影像资料、作品、语音、言论、图片、文字、反动旗帜、标语口号等各种形式（转播中央新闻单位新闻报道除外）；
          </p>
          <p>
            8.持有台独、港独、藏独、疆独等分裂国家立场的艺人及组织团体制作或参与制作的节目、娱乐报道、作品宣传的；
          </p>
          <p>
            9.对涉及领土和历史事件的描写不符合国家定论的；
          </p>
          <h3>（三）损害国家形象的内容</h3>
          <p>
            10.贬损、玷污、恶搞中国国家和民族的形象、精神和气质的；
          </p>
          <p>
            11.以焚烧、毁损、涂划、玷污、践踏、恶搞等方式侮辱国旗、国徽的；在不适宜的娱乐商业活动等场合使用国旗、国徽的；
          </p>
          <p>
            12.篡改、恶搞国歌的；在不适宜的商业和娱乐活动中使用国歌，或在不恰当的情境唱奏国歌，有损国歌尊严的；
          </p>
          <p>
            13.截取党和国家领导人讲话片段可能使原意扭曲或使人产生歧义，或通过截取视频片段、专门制作拼凑动图等方式，歪曲放大展示党和国家领导人语气语意语态的；
          </p>
          <p>
            14.未经国家授权或批准，特型演员和普通群众通过装扮、模仿党和国家领导人形象，参加包括主持、表演、演讲、摆拍等活动，谋取利益或哗众取宠产生不良影响的（依法批准的影视作品或文艺表演等除外）；
          </p>
          <p>
            15.节目中人物穿着印有党和国家领导人头像的服装鞋帽，通过抖动、折叠印有头像的服装鞋帽形成怪异表情的；
          </p>
          <h3>（四）损害革命领袖、英雄烈士形象的内容</h3>
          <p>
            16.歪曲、丑化、亵渎、否定革命领袖、英雄烈士事迹和精神的；
          </p>
          <p>
            17.不当使用及恶搞革命领袖、英雄烈士姓名、肖像的；
          </p>
          <h3>（五）泄露国家秘密的内容</h3>
          <p>
            18.泄露国家各级党政机关未公开的文件、讲话的；
          </p>
          <p> 19.泄露国家各级党政机关未公开的专项工作内容、程序与工作部署的； </p>
          <p> 20.泄露国防、科技、军工等国家秘密的； </p>
          <p> 21.私自发布有关党和国家领导人的个人工作与生活信息、党和国家领导人家庭成员信息的； </p>
          <h3>（六）破坏社会稳定的内容 </h3>
          <p> 22.影响公共秩序与公共安全的群体性事件的；</p>
          <p> 23.传播非省级以上新闻单位发布的灾难事故信息的；</p>
          <p> 24.非新闻单位制作的关于灾难事故的影响、后果的节目的； </p>
          <h3>（七)损害民族与地域团结的内容 </h3>
          <p> 25.通过语言、称呼、装扮、图片、音乐等方式嘲笑、调侃、伤害民族和地域感情、破坏安定团结的；</p>
          <p>26.将正常的安全保卫措施渲染成民族偏见与对立的；</p>
          <p> 27.传播可能引发误解的内容的；</p>
          <p>28.对独特的民族习俗和宗教信仰猎奇渲染，甚至丑化侮辱的；</p>
          <p>29.以赞同、歌颂的态度表现历史上民族间征伐的残酷血腥战事的；</p>
          <h3>（八）违背国家宗教政策的内容 </h3>
          <p> 30.展示宗教极端主义和邪教组织及其主要成员的活动，以及他们的“教义”与思想的；</p>
          <p>31.不恰当地比较不同宗教、教派的优劣，可能引发宗教、教派之间矛盾和冲突的；</p>
          <p>32.过度展示和宣扬宗教教义、教规、仪式内容的；</p>
          <p>33.将宗教极端主义与合法宗教活动混为一谈，将正常的宗教信仰与宗教活动渲染成极端思想与行动，或将极端思想与行动解释成正常的宗教信仰与宗教活动的；</p>
          <p>34.戏说和调侃宗教内容，以及各类恶意伤害民族宗教感情言论的；</p>
          <h3>（九）传播恐怖主义的内容</h3>
          <p> 35.表现境内外恐怖主义组织的；</p>
          <p>36.详细展示恐怖主义行为的；</p>
          <p>37.传播恐怖主义及其主张的；</p>
          <p>38.传播有目的、有计划、有组织通过自焚、人体炸弹、打砸抢烧等手段发动的暴力恐怖袭击活动视频（中央新闻媒体公开报道的除外），或转发对这些活动进行歪曲事实真相的片面报道和视频片段的；</p>

          <h3>（十）歪曲贬低民族优秀文化传统的内容</h3>
          <p>39.篡改名著、歪曲原著精神实质的；</p>
          <p>40.颠覆经典名著中重要人物人设的；</p>
          <p>41.违背基本历史定论，任意曲解历史的；</p>
          <p>42.对历史尤其是革命历史进行恶搞或过度娱乐化表现的;</p>

          <h3>（十一）恶意中伤或损害人民军队、国安、警察、行政、司法等国家公务人员形象和共产党党员形象的内容</h3>
          <p>43.恶意截取执法人员执法工作过程片段，将执法人员正常执法营造成暴力执法效果的；</p>
          <p>44.传播未经证实的穿着军装人员打架斗殴、集会、游行、抗议、上访的；</p>
          <p>45.正面展现解放军形象时用语过度夸张的；</p>

          <h3>（十二）美化反面和负面人物形象的内容</h3>
          <p>46.为包括吸毒嫖娼在内的各类违法犯罪人员及黑恶势力人物提供宣传平台，着重展示其积极一面的；</p>
          <p>47.对已定性的负面人物歌功颂德的；</p>

          <h3>（十三）宣扬封建迷信，违背科学精神的内容</h3>
          <p>48.开设跳大神、破太岁、巫蛊术、扎小人、道场作法频道、版块、个人主页，宣扬巫术作法等封建迷信思想的；</p>
          <p>49.鼓吹通过法术改变人的命运的；</p>
          <p>50.借民间经典传说宣扬封建迷信思想的；</p>

          <h3>（十四）宣扬不良、消极颓废的人生观、世界观和价值观的内容</h3>
          <p>51.宣扬拜金主义和享乐主义的；</p>
          <p>52.展示违背伦理道德的糜烂生活的;</p>
          <p>53.宣传和宣扬丧文化、自杀游戏的；</p>
          <p>54.展现同情、支持婚外情、一夜情的；</p>
          <h3>（十五）渲染暴力血腥、展示丑恶行为和惊悚情景的内容</h3>
          <p>55.表现黑恶势力群殴械斗、凶杀、暴力催债、招募打手、雇凶杀人等猖狂行为的；</p>
          <p>56.细致展示凶暴、残酷的犯罪过程及肉体、精神虐待的；</p>
          <p>57.细致展示吸毒后极度亢奋的生理状态、扭曲的表情，展示容易引发模仿的各类吸毒工具与吸毒方式的；</p>
          <p>58.细致展示酗酒后失控状态的；</p>
          <p>59.细致展示老虎机、推币机、打鱼机、上分器、作弊器等赌博器具，以及千术、反千术等赌博技巧与行为的；</p>
          <p>60.展现过度的生理痛苦、精神歇斯底里，对普通观看者可能造成强烈感官和精神刺激，从而引发身心惊恐、焦虑、厌恶、恶心等不适感的画面、台词、音乐及音效的；</p>
          <p>61.宣扬以暴制暴，宣扬极端的复仇心理和行为的;</p>
          <h3>（十六）展示淫秽色情，渲染庸俗低级趣味，宣扬不健康和非主流的婚恋观的内容</h3>
          <p>62.具体展示卖淫、嫖娼、淫乱、强奸等情节的；</p>
          <p>63.直接展示性行为的；</p>
          <p>64.视频中出现色情推广的；</p>
          <p>65.展示呻吟、叫床等声音、特效的；</p>
          <p>66.以猎奇宣扬的方式对境外“红灯区”进行拍摄的；</p>
          <p>67.展现有性交易内容的夜店、洗浴按摩场所的；</p>
          <p>68.表现和展示非正常的性关系、性行为的；</p>

          <p>69.展示和宣扬不健康的婚恋观和婚恋状态的；</p>
          <p>70.宣扬和炒作非主流婚恋观的；</p>
          <p>71.以单纯感官刺激为目的，集中细致展现接吻、爱抚、淋浴及类似的与性行为有关的间接表现或暗示的，包括裸露或长时间聚焦胸部、臀部等部位，聚焦走光、偷拍、凸点，渲染恋足、原味丝袜等性癖好；</p>
          <p>72.有明显的性挑逗、性骚扰、性侮辱或类似效果的画面、台词、音乐及音效的；</p>
          <p>73.展示男女性器官，或仅用肢体掩盖或用很小的遮盖物掩盖人体隐秘部位及衣着过分暴露的；</p>
          <p>74.使用粗俗语言，展示恶俗行为的；</p>
          <p>75.以隐晦、低俗的语言表达使人产生性行为和性器官联想的内容的；</p>
          <p>76.以成人电影、情色电影、三级片被审核删减内容的影视剧的“完整版”“未删减版”“未删节版”“被删片段”“汇集版”作为视频节目标题、分类或宣传推广的；</p>
          <p>77.以偷拍、走光、露点及各种挑逗性文字或图片作为视频节目标题、分类或宣传推广的；</p>
          <p>78.使用易引发性联想的文字作为标题的；</p>
          <h3>（十七）侮辱、诽谤、贬损、恶搞他人的内容</h3>

          <p>79.侮辱、诽谤、贬损、恶搞历史人物及其他真实人物的形象、名誉的；</p>
          <p>80.贬损、恶搞他国国家领导人，可能引发国际纠纷或造成不良国际影响的；</p>
          <p>81.侮辱、贬损他人的职业身份、社会地位、身体特征、健康状况的；</p>

          <h3>（十八）有悖于社会公德的内容</h3>

          <p>82.以恶搞方式描绘重大自然灾害、意外事故、恐怖事件、战争等灾难场面的；</p>
          <p>83.以肯定、赞许的基调或引入模仿的方式表现打架斗殴、羞辱他人、污言秽语的；</p>
          <p>84.为违背公序良俗或游走在社会道德边缘的行为提供展示空间的；</p>

          <h3>(十九）不利于未成年人健康成长的内容</h3>

          <p>85.表现未成年人早恋的，以及抽烟酗酒、打架斗殴、滥用毒品等不良行为的；</p>
          <p>86.人物造型过分夸张怪异，对未成年人有不良影响的；</p>
          <p>87.展示未成年人或者未成年人形象的动画、动漫人物的性行为，或让人产生性妄想的；</p>
          <p>88.侵害未成年人合法权益或者损害未成年人身心健康的;</p>

          <h3>（二十）宣扬、美化历史上侵略战争和殖民史的内容</h3>

          <p>89.宣扬法西斯主义、极端民族主义、种族主义的；</p>
          <p>90.是非不分，立场错位，无视或忽略侵略战争中非正义一方的侵略行为，反而突出表现正义一方的某些错误的；</p>
          <p>91.使用带有殖民主义色彩的词汇、称谓、画面的；</p>

          <h3>（二十一）其他违反国家有关规定、社会道德规范的内容</h3>

          <p>92．将政治内容、经典文化、严肃历史文化进行过度娱乐化展示解读，消解主流价值的；</p>
          <p>93.从事反华、反党、分裂、恐怖活动的特定组织或个人制作或参与制作的节目，及其开设的频道、版块、主页、账号的；</p>
          <p>94.违法犯罪、丑闻劣迹者制作或参与制作的节目的；</p>
          <p>95.侵犯个人隐私，恶意曝光他人身体与疾病、私人住宅、婚姻关系、私人空间、私人活动的；</p>
          <p>96.对国家有关规定已明确的标识、呼号、称谓、用语进行滥用、错用的；</p>
          <p>97.破坏生态环境，虐待动物，捕杀、食用国家保护类动物的；</p>
          <p>98.展示个人持有具有杀伤力的危险管制物品的；</p>
          <p>99.在节目中植入非法、违规产品和服务信息的；</p>
          <p>100.其他有违法律、法规和社会公序良俗的。</p>
          <p>违反上述规定，本平台保留追求法律责任的权力，并配合公检法机关的调查。</p>
          <h2>二、上传视频具体要求</h2>
          <p>（1）不安装控件：不支持断点续传，视频文件最大200M。安装控件：支持断点续传，IE浏览器视频文件最大4G，其他浏览器视频文件最大2G。</p>
          <p>（2）目前上传支持的视频格式</p>
          <p>常见在线流媒体格式：mp4、flv、f4v、webm
            移动设备格式：m4v、mov、3gp、3g2
            RealPlayer ：rm、rmvb
            微软格式 ：wmv、avi、asf
            MPEG视频 ：mpg、mpeg、mpe、ts
            DV格式 ：div、dv、divx
            其他格式 ：vob、dat、mkv、swf、lavf、cpk、dirac、ram、qt、fli、flc、mod</p>
          <p>（3）视频时长</p>
          <p>不支持时长小于1秒或大于10小时的视频文件，上传后将不能成功转码.</p>
          <p>（4）高清视频上传说明</p>
          <p>支持转码为高/超清，上传的原视频需达到以下标准：
            高清 ( 360P )：视频分辨率>=640×360，视频码率>=800kbps
            超清 ( 720P )：视频分辨率>=960×540，视频码率>=1500kbps
            蓝光 (1080P) ：视频分辨率>=1920×1080，视频码率>=2500kbps</p>
          <p>（5）上传视频处理流程</p>
          <p>1、上传：将视频上传至服务器。</p>
          <p>2、转码：上传成功后服务器将视频转码成播放器可识别的格式。</p>
          <p>3、审核：转码完成后视频进入内容审核阶段。</p>
          <p>4、发布：审核通过，视频正式发布。</p>
          <p>本平台保留具体解释权。</p>
        </div>
      </div>


    </div>
  </div>
</template>  
  
<script>

export default {
  name: 'agreement',
  data() {
    return {

    }
  },
  components: {

  },
  methods: {


  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style   scoped>
.box {
  background-color: #ffffff;
}

.content {
  width: 1500px;
  margin: 0 auto;

}

.main {
  padding-top: 2px;
}

.main .title {

  margin-top: 50px;
  text-align: center;
  color: #333333;
  font-size: 18px;
  font-weight: bold;
  height: 12px;
  line-height: 12px;

}

.main .info {

  text-indent: 2;
  line-height: 20px;
  font-size: 18px;
  margin-top: 50px;
  padding-bottom: 30px;
}
</style>

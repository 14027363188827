<template>
  <div class="wrap">
    <div class="nav">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/teachsearch'}">老师搜需求</el-breadcrumb-item>
        <el-breadcrumb-item>
          <p class="xuqiu">需求详情</p>
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div> 
    <div class="content">
      <div class="xiangqing">
        <div class="teaxiangqing">
          <div class="title-left">
            <img src="../../assets/img/header.png" alt="" v-if="!demand_info.userinfo.avatar">
            <img :src="baseurl+demand_info.userinfo.avatar" alt="" v-else>
          </div> 
          <div class="teaxinxi title-right">
            <div class="top">
              <div class="teaname">{{ demand_info.userinfo.nickname ? demand_info.userinfo.nickname : '---' }}</div>
              <div class="teaInformation">
                <p>学校: {{ demand_info.userinfo.school_name ? demand_info.userinfo.school_name : '---' }}</p>
                <!-- <p>专业: {{ demand_info.userinfo.major ? demand_info.userinfo.major : '---' }}</p> -->
              </div>
            </div>

            <div class="bottom"> 
              <div class="teamess">
                <div class="dianzan" @click="like_demand(demand_info.id)">
                  <img src="../../assets/img/zan.png" alt="" v-if="demand_info.is_like">
                   <img src="../../assets/img/no-zan.png" alt="" v-else>
                  <p>{{demand_info.like_num}}</p>
                </div> 
                <div class="liulan">
                  <img src="../../assets/img/see.png" alt="">
                  <p>{{ demand_info.click_num }}</p>
                </div>  
              </div> 

              <div class="oprate">
                  <button class="shoucang add-friend" @click="add_friend" v-if="!demand_info.is_friend" >加为好友</button>
                   <button class="shoucang add-friend" v-else>已为好友</button>
                <button class="shoucang" @click="collection_demand(demand_info.id)" v-if="!demand_info.is_atten">收藏</button>
                <button class="shoucang" @click="collection_demand(demand_info.id)" v-else>取消收藏</button>
                <button class="goutong" @click="gou_tong(demand_info.userinfo.id)">沟通</button>
              </div>
            </div>
          </div>

        </div>
        <div class="product">
          <h3>{{ demand_info.title }}</h3>

          <div class="demand-content" v-html="demand_info.content">

          </div>
        </div>

      </div>
      <div class="kexuqiu">
        <h3>其他课程需求</h3>
        <div class="xuqiu-content" v-for="(item, index) in demand_list.list" v-bind:key="index">
          <div class="xuhao" @click="go_demand(item.id)">{{ index + 1 }}</div>
          <p class="demand-list-title" @click="go_demand(item.id)">{{ item.title }}</p>
          <img src="../../assets/img/see.png" alt="">
          <p class="see-num" @click="go_demand(item.id)">{{ item.click_num }}</p>
          <button>沟通</button>
        </div>

        <!-- <Pagination  
                  @change="demand_change"
                  :current="demand_list.page"
                  :pageSize="demand_list.pagesize"
                  :total="demand_list.total"
                  :hideOnSinglePage="hideOnSinglePage"
                  :showQuickJumper="true"
                  :showTotal="false"
                  placement="right"
                  /> -->
      </div>
    </div> 

    <Chat @closeChat="close_chat" v-if="is_show_chat" :toUserId="to_user_id" />
  </div>
</template>
 
<script>

import { get_demand_info, demandlist ,likeDemand} from "./../../request/demand"



import {attenDemand,addMyFriend}  from "@/request/teacher"
import { Loading } from 'element-ui';

import BASEURL from "@/request/baseurl"
import Chat from "../../components/Chat"
export default {
  name: 'demandDetail',
  data() {
    return {
      currentPage1: 5,
      currentPage2: 5,
      currentPage3: 5,
      currentPage4: 4,
      demand_info: {
        userinfo:{
           avatar:''
        }
      },
      baseurl: BASEURL,
      demand_list: {
        'total': 0,
        'page': 1,
        'pagesize': 14,
        'list': []
      },
      is_show_chat: false,
      userinfo: null,
      is_login: false
    }
  }, components: {
 
    Chat
  },
  created() {
    try {
      var userinfo = localStorage.getItem('Userinfo');


      if (userinfo) {

        userinfo = JSON.parse(userinfo);
        //判断时间是否过期
        if (userinfo.expiretime * 1000 < new Date().getTime()) {
          this.is_login = false;
        } else {
          this.is_login = true;
          this.userinfo = userinfo;

        }

      }
    } catch {
    
    }

    let id = this.$route.query.id;
   
    this.init_data(id);

  },
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    } 
    , async init_data(id) {

      let loadingInstance1 = Loading.service({ fullscreen: true });
      let res = await get_demand_info({ id: id,click:true });
      if (res.code == 200) {
        loadingInstance1.close();
       
        this.demand_info = res.data;
         document.title = res.data.title;
      } else {
        console.log('请求失败');
      }

      let list_res = await demandlist({ current: 1, pagesize: 14, keywrod: '' });

      if (list_res.code == 200) {
        let demand_list = this.demand_list;
        demand_list.total = Number(list_res.data.total);
        demand_list.page = Number(list_res.data.current);
        demand_list.list = list_res.data.list;
        this.demand_list = demand_list
      }
 

      },
       
      
      go_demand(id){
       
            this.init_data(id);
  
       },gou_tong(to_user_id){
          let userinfo = this.userinfo ;
            if(userinfo == null){
           this.$message.warning("需要先登录哦")
          return false;
           }
        
      
        if( Object.keys(userinfo).length  <1){ 
              
             this.$message.warning("需要先登录哦")
             return false;
           }  
          console.log(userinfo.user_type);

     
 
               if(userinfo.user_type !=1){

                    this.$message.warning("对不起，您不是教师")
                      return false;
                 
                  }
         
          

           this.to_user_id = to_user_id;
          this.is_show_chat = true;
          
       },
   close_chat(){
            this.is_show_chat = false;
    },  
    
      //收藏
     async collection_demand(demand_id){
             let userinfo = this.userinfo ;
             if(userinfo == null){
             this.$message.warning("需要先登录哦")
            return false;
           }
        
      
          if( Object.keys(userinfo).length  <1){ 
              
             this.$message.warning("需要先登录哦")
             return false;
           }  

          if(userinfo.user_type !=1){

             this.$message.warning("对不起，您不是教师")
             return false;
                 
          }

          let res = await attenDemand({demand_id:demand_id});
          if(res.code === 200){
              
           let  demand_info = this.demand_info;
           demand_info.is_atten = !demand_info.is_atte
          this.demand_info = demand_info;
        
              

          }else{
                 this.$message.warning(res.msg)
                return false;
          }


         
       



     },
       //点赞
     async like_demand(demand_id){

          let userinfo = this.userinfo ;
             if(userinfo == null){
             this.$message.warning("需要先登录哦")
            return false;
           }
        
      
          if( Object.keys(userinfo).length  <1){ 
              
             this.$message.warning("需要先登录哦")
             return false;
           }  
 

         let res =  await likeDemand({demand_id:demand_id});
         if(res.code === 200){

           let demand_info = this.demand_info;
           if(demand_info.is_like){
               demand_info.like_num =   Number(demand_info.like_num)-1;
           }else{
                 demand_info.like_num =   Number(demand_info.like_num)+1;
           }
            demand_info.is_like = !demand_info.is_like;
          
            this.demand_info = demand_info
             
         }else{
              this.$message.warning(res.msg)
             return false;
         }


     },
      //添加好友
     async add_friend(){

       let userinfo = this.userinfo;


       let demand_info = this.demand_info;
        if(userinfo == null){
            this.$message.warning("需要先登录哦")
          return false;
        }

        if (Object.keys(userinfo).length < 1) {

          this.$message.warning("需要先登录哦")
          return false;
        }


       if (userinfo.user_type !=1) {

        this.$message.warning("对不起，您不是教师")
        return false;

      }
 
         let student_id = demand_info.userinfo.id;

         let  res = await addMyFriend({student_id:student_id});
         if(res.code === 200){
              this.$message.success("添加好友成功");
               demand_info.is_friend = !demand_info.is_friend;
               this.demand_info = demand_info;
         }else{
               this.$message.error(res.msg)
         }


     }
  }
}
</script>

<style lang="less" scoped>
.wrap {
  margin-bottom: 30px;
}

.nav {

  margin-top: 52px;
}

.nav .el-breadcrumb {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
}

.nav .xuqiu {
  margin-top: 0;
  color: #289EB8;
}

.content {
  display: flex;
}

.xiangqing {
  margin-top: 20px;

  width: 915px;
  min-height: 761px;
  background: #FFFFFF;
  border-radius: 6px;
}

.xiangqing .teaxiangqing {
  display: flex;
  display: -webkit-flex;
  margin-top: 55px;
  margin-left: 41px;
}

.teaxiangqing {
  .title-left {
    width: 100px;
    height: 100px;
    margin-right: 35px;

    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
  }
}


.title-right {
  width: 700px;

  .top {
    height: 80px;

  }

  .bottom {
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: space-between;

    .oprate {
      display: flex;
      display: -webkit-flex;
      flex-direction: row;
      width: 380px;
    }
  }

}



.teaxinxi {
  display: flex;
  flex-direction: column;

}

.teaxinxi .teaname {
  font-size: 26px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #010000;
}

.teaxinxi .teaInformation {
  margin-top: 20px;
  display: flex;

}

.teaxinxi .teamess {
  display: flex;
  margin-top: 22px;
  margin-left: 8px;
}

.teaxinxi .teamess img {
  width: 18px;
  height: 18px;
}

.teaxinxi .teamess p {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 19px;
}

.teaxinxi .teaInformation p {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #818181;
  line-height: 30px;
  margin: auto 10px;
}

.dianzan {
  display: flex;
  margin-right: 57px;
}

.dianzan p {
  margin: 0;
}

.dianzan img {
  margin-right: 14px;
}

.liulan {
  display: flex;
}

.liulan img {
  margin-right: 14px;
}

.liulan p {
  margin: 0;
}

.shoucang {
  width: 112px;
  height: 36px;
  border: 1px solid #289EB8;
  border-radius: 10px;
  background-color: #FFFFFF;
  outline: none;
  cursor: pointer;

}

.goutong {
  cursor: pointer;
  width: 112px;
  height: 36px;
  border: 1px solid #289EB8;
  background: #289EB8;
  border-radius: 10px;
  color: #FFFFFF;
  margin-left: 38px;
  outline: none;

}

.product {

  margin-top: 60px;
  width: 855px;
  margin: 65px auto 0;
}

.product h4 {
  display: flex;
  margin: 0;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 96px;
}

.product p {
  margin: 0;
  margin-right: 52px;
  text-align: left;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}

.img {
  width: 881px;
  height: 311px;
  background: #F5F8FA;
  border-radius: 15px;
}

.kexuqiu {
  margin-top: 20px;
  margin-left: 47px;
  width: 517px;
  min-height: 761px;
  background: #FFFFFF;
  border-radius: 6px;
  padding-right: 20px;

}

.kexuqiu h3 {
  display: flex;
  margin-left: 17px;
  margin-top: 28px;
}

.xuqiu-content {
  margin-left: 28px;
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
}

.xuqiu-content .xuhao {
  width: 27px;
  height: 27px;
  background: #BF433B;
  border-radius: 4px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-right: 17px;
  text-align: center;
}

.xuqiu-content .demand-list-title {
  max-width: 300px;
  font-size: 18px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  margin: 0;

}

.xuqiu-content .demand-list-title:hover {
  color: #289EB8;
}

p {
  margin: 0;
  padding: 0;
}

.xuqiu-content img {
  margin-left: 15px;
  margin-right: 8px;
  width: 23px;
  height: 16px;
  margin-top: 2px;
}


.see-num {
  color: #B5B5B5;
  font-size: 16px;
  max-width: 55px;
}

.xuqiu-content button {
  cursor: pointer;
  margin-left: 10px;
  width: 55px;
  height: 25px;
  background-color: #FFFFFF;
  border: 2px solid #E6E6E6;
  border-radius: 6px;
  color: #289EB8;
}

.add-friend{
  margin-right: 38px ;
  color:#289EB8;
  background-color:#dfdfdf;
  cursor: pointer;
}
</style>